import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import withRouter from "@src/hooks/withRouter";
import Header from "@components/Interface/MainList/header";
import ImagePreviewWidget from "@components/Global/Widgets/views/imagePreviewWidget";
import LoadingPage from "@components/Global/General/loadingPage";
import { initiateAdvertiserForm } from "../services/actions/advertiserActions";

class AdvertiserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerColor: "#fff",
    };
  }

  UNSAFE_componentWillMount() {
    this.props.initiateAdvertiserForm(this.props.router.params.id);
  }

  renderLogo() {
    return (
      <div className="form-ad-preview">
        <ImagePreviewWidget
          filePreview={this.props.post.adLogo.preview}
          fileType={this.props.post.adLogo.type}
          autoplayVideo={false}
          width={192}
          height={130}
        />
      </div>
    );
  }

  render() {
    if (!this.props.post) {
      return <LoadingPage pageName="Advertiser" />;
    }

    if (this.props.post.state === "ERROR") {
      return <div> Please make sure Ad block is turned off </div>;
    }
    const { post } = this.props;

    return (
      <div>
        <Header
          bgColor={this.state.headerColor}
          title="Advertiser Details"
          titleColor={this.state.titleColor}
          buttonColor={this.state.buttonColor}
          searchEnabled={null}
          searchValue={this.state.searchValue}
          searchAction={null}
        />
        <div className="fillOut-form">
          <div className="form-container">
            <div className="view-form">
              <h2>{post.name} </h2>
              <div className="description-box" style={{ width: "100%" }}>
                <hr />
              </div>
              <div className="description-box" style={{ width: "100%" }}>
                {post.adLogo ? (
                  <div>
                    {this.renderLogo()}
                    <hr />
                  </div>
                ) : null}
              </div>
              <div className="description-box" style={{ width: "100%" }}>
                <h3 style={{ color: "rgb(255, 162, 67)" }}> Contact </h3>
                <p>
                  <b>Name:</b> {post.contact_name}
                </p>
                <p>
                  <b>Email:</b> {post.contact_email}
                </p>
                <p>
                  <b>Phone:</b> {post.contact_phone}
                </p>
              </div>
              <div className="description-box" style={{ width: "100%" }}>
                <hr />
                <h3 style={{ color: "rgb(255, 162, 67)" }}> Location </h3>
                <p>
                  <b>Address: </b>
                  {post.contact_address_line1} {post.contact_address_line2}
                </p>
                <p>
                  <b>Postal Code:</b> {post.contact_postal_code}
                </p>
                <p>
                  <b>City:</b> {post.contact_city}
                </p>
                <p>
                  <b>Country:</b> {post.contact_country}
                </p>
              </div>
              <div className="description-box" style={{ width: "100%" }}>
                <hr />
                <h3 style={{ color: "rgb(255, 162, 67)" }}>Additional Notes</h3>
                <p> {post.comments}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { post: state.advertisers.form };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ initiateAdvertiserForm }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvertiserForm));
