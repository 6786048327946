// Previews Video and Image given Dimensions
/* Widget for updated image uploader */

import React, { Component } from "react";
import { connect } from "react-redux";

class ImagePreviewWidget extends Component {
  renderPreview() {
    if (this.props.fileType.includes("image")) {
      return (
        <div style={{ width: this.props.width, height: this.props.height }}>
          <img src={this.props.filePreview} alt="preview" />
        </div>
      );
    }

    if (this.props.fileType.includes("video")) {
      return (
        <div style={{ width: this.props.width, height: this.props.height }}>
          {this.props.autoplayVideo ? (
            <video autoPlay muted loop src={this.props.filePreview}>
              Your browser does not support the video tag.
            </video>
          ) : (
            <video controls muted src={this.props.filePreview}>
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <div className="image-preview-widget h-100 d-flex justify-content-center">
        {this.renderPreview()}
      </div>
    );
  }
}

export default connect(null, null)(ImagePreviewWidget);
