// ALL ACTIONS FOR PROPERTIES

import _ from "lodash";

import {
  SET_PROPERTY_FORM,
  SET_PROPERTY_CONTENT,
  SET_PROPERTY_PHOTO,
  SET_PROPERTY_LOGO,
} from "@App/actionTypes";
import { datetimeAsApiData, datetimeAsFormData } from "@utilities/dateHelper";
import Elev8ApiService from "@lib/services/Elev8Api/Elev8ApiService";
import { setFormDataNoId } from "@components/Authentication/actions/settingActions";
import {
  addFlashMessage,
  currentlySaving,
} from "@components/Interface/General/services/actions/applicationStateActions";
import { getPropertyType } from "./propertyActions";

export function clearPropertyFormRelatedInfo() {
  return function (dispatch) {
    dispatch(setPropertyForm(null));
    dispatch(setPropertyLogo(null));
    dispatch(setPropertyPhoto(null));
  };
}

export function setPropertyForm(updatedForm) {
  return {
    type: SET_PROPERTY_FORM,
    payload: updatedForm,
  };
}

export function setPropertyContent(propertyContent) {
  return {
    type: SET_PROPERTY_CONTENT,
    payload: propertyContent,
  };
}

export function setPropertyLogo(propertyLogo) {
  return {
    type: SET_PROPERTY_LOGO,
    payload: propertyLogo,
  };
}

export function setPropertyPhoto(propertyPhoto) {
  return {
    type: SET_PROPERTY_PHOTO,
    payload: propertyPhoto,
  };
}

export function fetchPropertyContent(id) {
  return function (dispatch) {
    Elev8ApiService.getOldEndpoint(`property_content/${id}`).then((response) => {
      dispatch(setPropertyContent(response.data));
    });
  };
}

export function clearPropertyContent() {
  return function (dispatch) {
    dispatch(setPropertyContent(null));
  };
}

export function initiateEditPropertyForm(id) {
  return function (dispatch) {
    Elev8ApiService.getOldEndpoint(`properties/${id}?include_screens=true&include_users=true`)
      .then((response) => {
        const newData = response.data;
        const updatedForm = _.extend({}, newData);

        // set logo and photo media
        let propertyLogo = null;
        if (newData.logo) {
          propertyLogo = newData.logo;
          dispatch(formatPropertyImage(propertyLogo, "logo"));
        }
        let propertyPhoto = null;
        if (newData.photo) {
          propertyPhoto = newData.photo;
          dispatch(formatPropertyImage(propertyPhoto, "photo"));
        }

        updatedForm.install_date = datetimeAsFormData(updatedForm.install_date);
        updatedForm.rev_share_start_at = datetimeAsFormData(updatedForm.rev_share_start_at);

        updatedForm.sales_tax = updatedForm.sales_tax ? updatedForm.sales_tax : "";
        // Set Onsite and Primary User
        if (newData.primary_user) {
          updatedForm.primary_user_id = newData.primary_user.id;
        } else {
          updatedForm.primary_user_id = "";
        }

        // Set feeds
        updatedForm.feed_ids = _.map(newData.feeds, "id");
        updatedForm.formId = id;

        updatedForm.translatedPropertyType = getPropertyType(updatedForm.type);

        // convert cents to dollar
        updatedForm.revenue_share = (updatedForm.revenue_share * 100).toFixed(2);

        dispatch(setPropertyForm(updatedForm));
      })
      .catch((e) => {
        console.log("Can't initiate edit property form", e);
      });
  };
}

/** ************************************
FORM ACTIONS
************************************* */
export function formatPropertyImage(image, type) {
  return function (dispatch) {
    if (image) {
      const imageBundle = {
        name: image.name,
        type: image.type,
        category: image.category,
        mediaId: image.id,
        preview: image.url,
      };
      if (type === "logo") {
        dispatch(setPropertyLogo(imageBundle));
      } else {
        dispatch(setPropertyPhoto(imageBundle));
      }
    }
  };
}

function updatePropertySpecificFeatures(props) {
  const formFields = props;
  // convert dollars to cents
  formFields.revenue_share = props.revenue_share / 100;

  if (props.property_type === "Commercial") {
    formFields.has_gym = false;
    formFields.has_pool = false;
    formFields.family_friendly = false;
    formFields.adult_only = false;
    formFields.number_of_units = 0;
    formFields.resident_parking = false;
    formFields.visitor_parking = false;
  } else if (props.property_type !== "Other") {
    formFields.building_sqft = 0;
  }
  return formFields;
}

function updateFeeds(propertyFeeds) {
  const feedResult = propertyFeeds.map((feed) => feed.value);
  return feedResult;
}

function packagePropertyForm(props) {
  // update property specific features
  const formFields = updatePropertySpecificFeatures(props);

  // get proper feeds
  formFields.feed_ids = updateFeeds(props.chosenFeeds);

  formFields.communication_language = props.communication_language.value;

  formFields.sales_tax = props.sales_tax || props.sales_tax !== "" ? props.sales_tax : null;

  formFields.install_date = datetimeAsApiData(props.install_date);
  formFields.rev_share_start_at = datetimeAsApiData(props.rev_share_start_at);

  return formFields;
}

/** ******************************
FORM TO DATABASE
******************************* */

function postProperty(props) {
  const propertyForm = packagePropertyForm(props);

  return Elev8ApiService.oldPut(`properties/${propertyForm.formId}`, propertyForm).then(() => {
    window.location.reload();
  });
}

export function updateProperty(props) {
  return function (dispatch) {
    saveNotifications(() => postProperty(props), dispatch);
  };
}

export function updatePropertyImage(imageType, newImage) {
  return function (dispatch) {
    let media = null;
    if (newImage && newImage.mediaId == null) {
      const fileData = setFormDataNoId(newImage.file, newImage.category, newImage.name);
      return Elev8ApiService.oldPost("media", fileData, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        const newMediaData = response.data;
        media = {
          name: newMediaData.name,
          type: newMediaData.type,
          category: newMediaData.category,
          mediaId: newMediaData.id,
          preview: newMediaData.url,
        };
        if (imageType === "logo") {
          dispatch(setPropertyLogo(media));
        } else {
          dispatch(setPropertyPhoto(media));
        }
      });
    }

    if (imageType === "logo") {
      dispatch(setPropertyLogo(media));
    } else {
      dispatch(setPropertyPhoto(media));
    }
  };
}

export function updateScreens(screens) {
  return function () {
    Promise.all(
      screens.map((screen) => Elev8ApiService.oldPut(`screens/${screen.id}`, screen))
    ).then(() => {
      window.location.reload();
    });
  };
}

function saveNotifications(handleSave, dispatch) {
  currentlySaving(true)(dispatch);
  return handleSave()
    .then(() => {
      addFlashMessage("success", "keywords.propertyRelated.formActions.saved")(dispatch);
    })
    .catch(() => {
      addFlashMessage("danger", "keywords.errorRelated.invalidForm")(dispatch);
    })
    .finally(() => {
      currentlySaving(false)(dispatch);
    });
}
