import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import withRouter from "@hooks/withRouter";
import { ApiTypes } from "../../../lib/services/Elev8Api/lib";
import Elev8ApiService from "../../../lib/services/Elev8Api/Elev8ApiService";
import CampaignGroupSerializer from "../../../utilities/CampaignGroupSerializer";
import { fetchAdvertisers } from "../../Advertiser/services/actions/advertiserActions";
import { fetchAllUsers } from "../../User/General/services/actions/generalUserActions";
import PermissionService, { Resources, Actions } from "../../User/Services/permissionService";
import { now } from "../../../utilities/dateStringHelper";

import TextInput from "../../Global/Widgets/textInput";
import DropDownWidget from "../../Global/Widgets/views/dropDown";
import DateRangePicker from "../../Global/Widgets/updatedWidgets/dateRangePicker";

import { CAMPAIGN_TYPE_MAP } from "../../constants";

class CampaignGroupCreateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      formFields: {
        name: "",
        start_at: now(),
        end_at: now(),
        advertiser_id: null,
        owner_id: null,
        campaign_type: "advertising",
      },
    };
    this.props.fetchAdvertisers();
    this.props.fetchAllUsers();
  }

  handleChange = (event) => {
    this.setState({
      formFields: { ...this.state.formFields, [event.target.name]: event.target.value },
    });
  };

  handleDropDownChange = (name, value) => {
    this.setState({
      formFields: { ...this.state.formFields, [name]: value },
    });
  };

  handleDateChange = (startAt, endAt) => {
    this.setState({
      formFields: { ...this.state.formFields, start_at: startAt, end_at: endAt },
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleCreate = () => {
    this.setState({ show: false });
    this.createCampaignGroup(this.state);
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  validCampaignGroup = () => {
    const fields = this.state.formFields;
    if (
      fields.name.trim() !== "" &&
      fields.advertiser_id !== null &&
      fields.owner_id !== null &&
      fields.campaign_type !== ""
    ) {
      return true;
    }
    return false;
  };

  createCampaignGroup = () => {
    const serializedForm = CampaignGroupSerializer.serializeForm(this.state.formFields);
    Elev8ApiService.create(ApiTypes.campaign_group, serializedForm).then((campaignGroup) => {
      this.props.router.navigate(`/campaign_group/edit/${campaignGroup.data.data.id}`);
    });
  };

  getSelectOptions = (dataList) =>
    dataList.map((item) => ({
      value: item.id,
      label: item.name ? item.name : `${item.first_name} ${item.last_name}`,
    }));

  getCampaignCreators = (users) => {
    const campaignCreators = users.filter((user) =>
      PermissionService.can(Actions.UPDATE, Resources.CAMPAIGNS, user)
    );
    return campaignCreators;
  };

  renderModal() {
    const { t } = this.props;

    return (
      <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Campaign Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            label={t("keywords.general.name")}
            value={this.state.formFields.name}
            handleChange={this.handleChange}
            required
            type="text"
            name="name"
          />
          <DateRangePicker
            onChange={this.handleDateChange}
            startDate={this.state.formFields.start_at}
            endDate={this.state.formFields.end_at}
            showDuration
          />
          <DropDownWidget
            formValue={this.state.formFields.campaign_type}
            formPropertyName="campaign_type"
            required
            updateForm={this.handleDropDownChange}
            multiSelect={false}
            title="Campaign Type"
            options={CAMPAIGN_TYPE_MAP}
          />
          <DropDownWidget
            formValue={this.state.formFields.advertiser_id}
            formPropertyName="advertiser_id"
            required
            updateForm={this.handleDropDownChange}
            multiSelect={false}
            title="Client"
            options={this.getSelectOptions(this.props.advertisers)}
          />
          <DropDownWidget
            formValue={this.state.formFields.owner_id}
            formPropertyName="owner_id"
            required
            updateForm={this.handleDropDownChange}
            multiSelect={false}
            title="VI Owner"
            options={this.getSelectOptions(this.getCampaignCreators(this.props.users))}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            disabled={!this.validCampaignGroup(this.state)}
            onClick={this.handleCreate}
          >
            {t("keywords.buttonActions.create")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const { t } = this.props;
    if (!this.props.advertisers || !this.props.users) {
      return null;
    }

    return (
      <>
        {this.renderModal()}
        <Button variant="success" className="pull-right" onClick={this.handleShow}>
          {t("keywords.buttonActions.create")}
        </Button>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    advertisers: state.advertisers.all,
    users: state.user.general.all,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchAdvertisers, fetchAllUsers }, dispatch);
}
export default withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CampaignGroupCreateModal))
);
