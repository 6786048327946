import React, { Component } from "react";
import { connect } from "react-redux";
import Fuse from "fuse.js";
import moment from "moment";
import { withTranslation } from "react-i18next";

import Pill from "@components/Global/General/pill";
import Header from "@components/Interface/MainList/header";
import GeneralTable from "@components/Interface/MainList/generalTable";
import LoadingPage from "@components/Global/General/loadingPage";
import { fetchSideNotice, deleteSideNotice } from "../services/actions/sideNoticeActions";

class SideNoticeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
    };
    this.deleteAction = this.deleteAction.bind(this);
    this.open = this.open.bind(this);
    this.deleteAction = this.deleteAction.bind(this);
    this.deletingIcon = this.deletingIcon.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.fetchNotices();
  }

  fetchNotices() {
    this.props.fetchSideNotice(this.props.loggedInUser);
  }

  deleteAction(currRow) {
    this.props.deleteSideNotice(currRow.id, this.props.loggedInUser);
  }

  open(currRow) {
    this.setState({ currRow });
  }

  deletingIcon() {
    this.props.deleteAction(this.state.currRow);
    this.close();
  }

  updateSearch(event) {
    this.setState({ searchValue: event.target.value });
  }

  render() {
    const { t } = this.props;
    const sideNoticeName = t("pageNames.sideNotices");

    if (!this.props.sidenotice) {
      return <LoadingPage pageName={sideNoticeName} />;
    }

    const tableFormat = [
      {
        columnTitle: "",
        columnSize: "1%",
        fieldType: "padding",
      },
      {
        columnTitle: t("keywords.statusRelated.name"),
        columnSize: "5%",
        fieldType: "component",
        fieldValue: "statusPill",
      },
      {
        columnTitle: t("keywords.userRelated.creator"),
        columnSize: "10%",
        fieldType: "text",
        fieldValue: "creator",
      },
      {
        columnTitle: t("keywords.dateRelated.start"),
        columnSize: "10%",
        fieldType: "text",
        fieldValue: "tailoredStartDate",
      },
      {
        columnTitle: t("keywords.dateRelated.end"),
        columnSize: "10%",
        fieldType: "text",
        fieldValue: "tailoredEndDate",
      },
      {
        columnTitle: t("keywords.general.title"),
        columnSize: "15%",
        fieldType: "text",
        fieldValue: "title",
        extraCss: "u-word-break",
      },
      {
        columnTitle: t("keywords.general.content"),
        columnSize: "25%",
        fieldType: "text",
        fieldValue: "body",
        extraCss: "u-word-break",
      },
      {
        columnTitle: "",
        columnSize: "15%",
        fieldType: "edit",
        editAction: "/sidenotice/edit/:id",
        deleteAction: true,
      },
    ];

    const options = {
      keys: ["body", "title"],
      threshold: 0.1,
      ignoreLocation: true,
    };

    let searchResult = this.props.sidenotice;

    searchResult.map((sideNoticeItem) => {
      const endDate = moment(sideNoticeItem.endDate);
      const startDate = moment(sideNoticeItem.startDate);
      const today = moment();

      if (endDate >= today && sideNoticeItem.published && startDate <= today) {
        sideNoticeItem.statusPill = <Pill text={t("keywords.statusRelated.active")} type="green" />;
      } else {
        sideNoticeItem.statusPill = (
          <Pill text={t("keywords.statusRelated.inactive")} type="disabled" />
        );
      }
    });

    if (this.state.searchValue.trim() !== "") {
      const fuse = new Fuse(this.props.sidenotice, options); // "list" is the item array
      searchResult = fuse.search(this.state.searchValue);
      searchResult = searchResult.map((result) => result.item);
    }
    return (
      <div>
        <Header
          title={sideNoticeName}
          newRoute="/sidenotice/new"
          count={searchResult.length}
          searchEnabled
          searchValue={this.state.searchValue}
          searchAction={this.updateSearch}
        />
        <GeneralTable
          tableData={searchResult}
          tableFormat={tableFormat}
          deleteAction={this.deleteAction}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sidenotice: state.client.sideNotice.all,
  };
}

export default connect(mapStateToProps, {
  fetchSideNotice,
  deleteSideNotice,
})(withTranslation()(SideNoticeList));
