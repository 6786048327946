import Elev8ApiService from "../../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../../lib/services/Elev8Api/lib";

class PropertyDataHelper {
  static fetchNameAndAddress() {
    const sparseFieldSets = {
      fields: {
        property: ["id", "name", "address_line1", "address_city"],
      },
    };

    return new Promise((resolve, reject) => {
      Elev8ApiService.index(ApiTypes.property, sparseFieldSets)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  static fetchPropertyScreenContentInfo(id) {
    const sparseFieldSets = {
      fields: {
        property: ["id", "network", "screens"],
        screen: ["elev8_anywhere_url"],
      },
      include: ["screens", "network"],
    };

    return new Promise((resolve, reject) => {
      Elev8ApiService.read(ApiTypes.property, id, sparseFieldSets)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
}

export default PropertyDataHelper;
