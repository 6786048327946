import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withTranslation } from "react-i18next";
import ScreenNotice from "./screenNotice";

class SidebarPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentNotice: {},
      notices: [...this.activeNotices()],
    };
  }

  UNSAFE_componentWillMount() {
    this.playContent();
  }

  componentWillUnmount() {
    clearTimeout(this.state.nextPlay);
  }

  activeNotices = () => {
    const today = moment();
    return this.props.notices.filter((notice) => {
      const endDate = moment(notice.end_date);
      const startDate = moment(notice.start_date);
      return endDate >= today && notice.published && startDate <= today;
    });
  };

  playContent() {
    const currentNotice = this.getNextNotice();
    const nextPlay = setTimeout(this.playContent.bind(this), 10 * 1000);

    this.setState({
      currentNotice,
      nextPlay,
    });
  }

  getNextNotice() {
    const loop = this.state.notices;
    const currentNotice = loop.pop();

    this.resetLoopIfEmpty(loop);
    return currentNotice;
  }

  resetLoopIfEmpty(loop) {
    if (loop.length === 0) {
      this.setState({
        notices: [...this.activeNotices()],
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="container align-center u-color-white pb-1">
        <div className="row no-gutters tablet_wrapper__content--property-logo-holder align-items-center">
          {this.props.logo && <img src={this.props.logo} className="col-6 mx-auto pb-5 pt-5" />}
        </div>

        <div className="row">
          <div className="col-12 u-transparent-black">
            <h1>
              {new Date().toLocaleString(t("language.locale"), {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="col-12 u-transparent-black-lighter">
            <h1>
              {new Date().toLocaleString(t("language.locale"), {
                weekday: "long",
                month: "long",
                day: "numeric",
              })}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pt-2">
            <ScreenNotice notice={this.state.currentNotice} />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SidebarPlayer);
