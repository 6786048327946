import React from "react";
import PropTypes from "prop-types";

export default function SummarySection({ title, callout, children }) {
  return (
    <div className="summarySection">
      <div className="summarySection__main">
        <div className="summarySection__title">{title}</div>
        <div className="summarySection__list">{children}</div>
      </div>
      {callout && <div className="summarySection__callout">{callout}</div>}
    </div>
  );
}

SummarySection.defaultProps = {
  callout: null,
};

SummarySection.propTypes = {
  title: PropTypes.string.isRequired,
  callout: PropTypes.node,
  children: PropTypes.node.isRequired,
};
