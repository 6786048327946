import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Elev8CampaignPropertiesContext from "@contexts/Elev8CampaignPropertiesContext";
import ProposalSection from "../ProposalSection";
import CampaignProposalForm from "./CampaignProposal/CampaignProposalForm";
import CampaignProposalLabel from "./CampaignProposal/CampaignProposalLabel";
import CampaignProposalSummary from "./CampaignProposal/CampaignProposalSummary";
import CampaignProposalMarketBreakdown from "./CampaignProposal/CampaignProposalMarketBreakdown";
import CampaignProposalPropertiesContainer from "./CampaignProposal/CampaignProposalPropertiesContainer";

function CampaignProposalContainer({
  readOnly,
  onDeleteCampaignProposal,
  onRevertCampaignProposalChanges,
  selectedCampaignProposalIndex,
}) {
  const { t } = useTranslation();
  const elev8Properties = useContext(Elev8CampaignPropertiesContext);
  const [deletingProposal, setDeletingProposal] = React.useState(false);

  async function handleDeleteClick() {
    setDeletingProposal(true);
    await onDeleteCampaignProposal(selectedCampaignProposalIndex);
    setDeletingProposal(false);
  }

  function handleResetClick() {
    onRevertCampaignProposalChanges(selectedCampaignProposalIndex);
  }

  function deleteButtonLabel() {
    if (deletingProposal) {
      return t("keywords.buttonActions.deleting");
    }

    return t("keywords.buttonActions.delete");
  }

  function selectedCampaignProposalActions() {
    if (selectedCampaignProposalIndex == null || readOnly) return [];
    return [
      {
        label: t("keywords.buttonActions.reset"),
        disabled: deletingProposal,
        variant: "primary",
        onClick: handleResetClick,
      },
      {
        label: deleteButtonLabel(),
        disabled: deletingProposal,
        variant: "danger",
        onClick: handleDeleteClick,
      },
    ];
  }

  return (
    <>
      <div className="campaignProposalContainer__outerText display-text">
        {t("proposalTerms.currentCampaignSelected")}
      </div>
      <ProposalSection
        title={<CampaignProposalLabel campaignProposalIndex={selectedCampaignProposalIndex} />}
        actions={selectedCampaignProposalActions()}
      >
        {selectedCampaignProposalIndex !== null && (
          <>
            <CampaignProposalForm
              key={`campaignProposal-${selectedCampaignProposalIndex}`}
              readOnly={readOnly}
              elev8Properties={elev8Properties}
              selectedCampaignProposalIndex={selectedCampaignProposalIndex}
            />
            <hr />
            <CampaignProposalSummary
              key={`campaignProposalSummary-${selectedCampaignProposalIndex}`}
              selectedCampaignProposalIndex={selectedCampaignProposalIndex}
            />
            <CampaignProposalMarketBreakdown
              key={`campaignProposalBreakdown-${selectedCampaignProposalIndex}`}
              elev8Properties={elev8Properties}
              selectedCampaignProposalIndex={selectedCampaignProposalIndex}
            />
            <hr />
            <CampaignProposalPropertiesContainer
              key={`campaignProposalProperties-${selectedCampaignProposalIndex}`}
              readOnly={readOnly}
              elev8Properties={elev8Properties}
              selectedCampaignProposalIndex={selectedCampaignProposalIndex}
            />
          </>
        )}
      </ProposalSection>
    </>
  );
}

CampaignProposalContainer.defaultProps = {
  selectedCampaignProposalIndex: null,
};

CampaignProposalContainer.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  onDeleteCampaignProposal: PropTypes.func.isRequired,
  onRevertCampaignProposalChanges: PropTypes.func.isRequired,
  selectedCampaignProposalIndex: PropTypes.number,
};

export default CampaignProposalContainer;
