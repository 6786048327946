import bigNoticeImage from "../../../../style/images/dashboardBigNoticeIcon.png";
import newUserImage from "../../../../style/images/dashboardNewUsersIcon.png";
import sideNoticeImage from "../../../../style/images/dashboardSideNoticeIcon.png";

import { Resources } from "../../User/Services/permissionService";

const SideNoticePanel = {
  type: "pageNames.sideNotices",
  createUrl: "/sidenotice/new",
  description: "dashboard.panel.sideNotice.description",
  iconUrl: sideNoticeImage,
  css: "u-color-side-notice",
  resource: Resources.NOTICES,
};

const BigNoticePanel = {
  type: "pageNames.bigNotice_plural",
  createUrl: "/bignotice/new",
  description: "dashboard.panel.bigNotice.description",
  iconUrl: bigNoticeImage,
  css: "u-color-big-notice",
  resource: Resources.BIG_NOTICES,
  requiredRole: "property_user",
};

const NoticePanel = {
  ...BigNoticePanel,
  type: "noticeTerms.notice_plural",
  requiredRole: "non_vc_property_user",
};

const UserAccountPanel = {
  type: "pageNames.users",
  createUrl: "/user/new",
  description: "dashboard.panel.users.description",
  iconUrl: newUserImage,
  css: "u-color-user-account",
  resource: Resources.USERS,
};

const PROPERTY_USER_PANELS = [SideNoticePanel, BigNoticePanel, NoticePanel, UserAccountPanel];

export default PROPERTY_USER_PANELS;
