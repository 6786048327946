import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Figure from "react-bootstrap/Figure";
import { withTranslation } from "react-i18next";
import ImageUploader from "../../Global/Widgets/views/imageUploader";

const fileMaxSize = 10 * 1000 * 1000; // 10 MB

class PropertyImageSection extends Component {
  render() {
    const { formFields, handleImageChange, photo, logo, isPropertyManager, t } = this.props;
    const fileSizeText = t("fileTerms.sizeInMB", { size: 10 });
    return (
      <React.Fragment>
        <Row>
          <Form.Group as={Col} md="4">
            {isPropertyManager ? (
              <Figure>
                {logo ? (
                  <Figure.Image width={150} src={logo.preview} />
                ) : (
                  <span>{t("keywords.fileRelated.imageRelated.noLogo")}</span>
                )}
                <Figure.Caption>{t("keywords.propertyRelated.logo")}</Figure.Caption>
              </Figure>
            ) : (
              <ImageUploader
                maxWidth={300}
                fileSizeText={fileSizeText}
                fileMaxSize={fileMaxSize}
                dimensions="250x250 px"
                acceptedFiles="image/*"
                currMedia={logo}
                imageWidth={150}
                imageHeight={150}
                imageTitle={t("keywords.propertyRelated.logo")}
                imageDescription={t("keywords.propertyRelated.logoPurpose")}
                updateFormAction={handleImageChange}
                formPropertyName="logo"
                currentForm={formFields}
                imageCategory="logo"
                required={false}
                newForm={false}
              />
            )}
          </Form.Group>
          <Form.Group as={Col} md="4">
            {isPropertyManager ? null : (
              <ImageUploader
                maxWidth={300}
                fileSizeText={fileSizeText}
                fileMaxSize={fileMaxSize}
                dimensions="250x250 px"
                acceptedFiles="image/*"
                currMedia={photo}
                imageWidth={150}
                imageHeight={150}
                imageTitle={t("keywords.fileRelated.imageRelated.upload")}
                imageDescription={t("keywords.propertyRelated.imagePurpose")}
                updateFormAction={handleImageChange}
                formPropertyName="propertyPhoto"
                currentForm={formFields}
                imageCategory="photo"
                required={false}
                newForm={false}
              />
            )}
          </Form.Group>
        </Row>
        <div className="w-100">
          <hr style={{ borderTop: "2px solid rgba(0, 0, 0, 0.1)" }} />
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(PropertyImageSection);
