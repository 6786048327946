import { Navigate } from "react-router-dom";
import React from "react";

const PublicComponent = ({ component: Component, auth, noRedirect, ...rest }) => {
  if (!auth || noRedirect) {
    return (
      <Component {...rest} />
    );
  }

  return (<Navigate to="/dashboard" replace />);
};

export default PublicComponent;
