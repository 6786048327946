import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Card } from "react-bootstrap";

import NavigationButton from "./NavigationButton";

export default function TopBar({ title, navigation, notification, actions }) {
  function renderNavigation(navigationInfo) {
    if (!navigationInfo) return null;
    return (
      <NavigationButton
        key={uuidv4()}
        path={navigationInfo.path}
        buttonText={navigationInfo.label}
      />
    );
  }

  return (
    <Card className="topBar">
      <div className="topBar__section">
        <div className="topBar__section">{renderNavigation(navigation)}</div>
        <div className="topBar__section">
          <div className="topBar__title">{title}</div>
          {notification}
        </div>
      </div>
      <div className="topBar__section">{actions}</div>
    </Card>
  );
}

TopBar.propTypes = {
  title: PropTypes.string,
  navigation: PropTypes.shape({
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  notification: PropTypes.node,
  actions: PropTypes.arrayOf(PropTypes.object),
};

TopBar.defaultProps = {
  title: "",
  navigation: null,
  notification: "",
  actions: [],
};
