import React, { Component } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { withTranslation } from "react-i18next";
import SimpleImageUploader from "./simpleImageUploader";
import { MAX_IMAGE_FILE_SIZE } from "../../../constants";

/**
 * @deprecated. Please use the ImageGalleryPicker, a React hook friendly version of this class
 */

class GalleryPicker extends Component {
  constructor(...args) {
    super(...args);
    this.state = {};
  }

  componentWillUnmount() {
    this.cleanUpImagePreview();
  }

  handleFileUpload = (file) => {
    this.cleanUpImagePreview();
    const imagePreview = URL.createObjectURL(file);

    this.setState({
      uploadedImage: file,
      selectedImage: file,
      imagePreview,
    });

    const media = {
      name: file.name,
      type: file.type.split("/")[0],
      category: this.props.imageCategory,
      url: imagePreview,
      id: null,
      file,
    };

    this.props.onMediaSelect(media, true);
  };

  handleSelectImage = (image) => {
    this.setState({
      selectedImage: image,
    });

    this.props.onMediaSelect(image, false);
  };

  handleDeleteImage = (image) => {
    this.props.onMediaDelete(image);
  };

  cleanUpImagePreview() {
    URL.revokeObjectURL(this.state?.imagePreview);
  }

  renderImageUpload = () => {
    const { t } = this.props;
    const { selectedImage, uploadedImage, imagePreview } = this.state;
    const isSelected = selectedImage === uploadedImage && selectedImage != null;

    return (
      <div
        key="gallery-picker"
        className={`gallery-picker__image ${isSelected ? "gallery-picker__image--selected" : ""}`}
      >
        <SimpleImageUploader
          fileSizeText={t("fileTerms.sizeInMB", { size: 10 })}
          fileMaxSize={MAX_IMAGE_FILE_SIZE}
          file={uploadedImage}
          preview={imagePreview}
          onFileUpload={this.handleFileUpload}
        />
      </div>
    );
  };

  renderImage = (image) => {
    const isSelected = this.state.selectedImage === image;

    return (
      <React.Fragment key={image.id}>
        <button
          type="button"
          className={`gallery-picker__image ${isSelected ? "gallery-picker__image--selected" : ""}`}
          onClick={() => this.handleSelectImage(image)}
        >
          <img
            data-tip="uploaded image"
            data-for={`image-${image.id}`}
            alt={image.name}
            src={image.url}
          />
        </button>

        <button type="button" onClick={() => this.handleDeleteImage(image)}>
          <i className="simple-image-delete__icon fa fa-times-circle" />
        </button>

        <ReactTooltip
          effect="solid"
          id={`image-${image.id}`}
          border
          type="light"
          className="tool-tip"
          getContent={() => (
            <div className="tool-tip__container">
              <div className="text-left">
                <div className="tool-tip__description">{image.name}</div>
              </div>
            </div>
          )}
        />
      </React.Fragment>
    );
  };

  render() {
    const imagesSortedByIdDesc = this.props.mediaImages.sort((a, b) => b.id - a.id);

    return (
      <div>
        <div className="notice-background-images">
          <div className="d-flex flex-wrap justify-content-start">
            {this.renderImageUpload()}
            {imagesSortedByIdDesc.map(this.renderImage)}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(GalleryPicker);
