import React, { Component } from "react";
import Navigate from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import Fuse from "fuse.js";

import { isPropertyUser } from "@utilities/UserRoleHelper";
import Header from "@components/Interface/MainList/header";
import GeneralTable from "@components/Interface/MainList/generalTable";
import { fetchAllUsers, removeUser } from "../services/actions/generalUserActions";

class UserTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
    };
    this.updateSearch = this.updateSearch.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (
      isPropertyUser(this.props.loggedInUser.role) &&
      this.props.loggedInUser.properties.manager.length === 0
    ) {
      <Navigate to="/dashboard" />;
    }
    this.props.fetchAllUsers();
  }

  getTableFormat() {
    if (isPropertyUser(this.props.loggedInUser.role)) {
      return this.propertyUserTableFormat();
    }
    return this.adminTableFormat();
  }

  deleteAction = (currRow) => {
    this.props.removeUser(currRow);
  };

  updateSearch(event) {
    this.setState({ searchValue: event.target.value });
  }

  modifiableUsers() {
    const { loggedInUser, users } = this.props;
    if (users == null) {
      return users;
    }

    if (!isPropertyUser(loggedInUser.role)) {
      return users;
    }

    const activeUsers = users.filter(
      (user) =>
        /* will use PermissionService when backend sets users with no_access to have empty capabiltities */
        user.role !== "no_access" && user.id !== loggedInUser.id
    );

    return activeUsers.filter((user) => {
      const userPropertiesAll = user.properties.manager.concat(user.properties.member);
      const isPropertyMangerOfRelatedProperty =
        // eslint-disable-next-line no-undef
        _.intersection(userPropertiesAll, loggedInUser.properties.manager).length !== 0;

      return isPropertyMangerOfRelatedProperty;
    });
  }

  propertyUserTableFormat() {
    const { t } = this.props;

    return [
      {
        columnTitle: "",
        columnSize: "2%",
        fieldType: "padding",
      },
      {
        columnTitle: t("keywords.general.name"),
        columnSize: "20%",
        fieldType: "text",
        fieldValue: "fullName",
        isBold: false,
      },
      {
        columnTitle: t("keywords.userRelated.phone.name"),
        columnSize: "10%",
        fieldType: "text",
        fieldValue: "phone",
        isBold: false,
      },
      {
        columnTitle: t("keywords.userRelated.email.name"),
        columnSize: "45%",
        fieldType: "text",
        fieldValue: "email",
        isBold: false,
      },
      {
        columnTitle: "",
        columnSize: "10%",
        fieldType: "edit",
        editAction: "/user/edit/:id",
        deleteAction: this.deleteAction,
      },
    ];
  }

  adminTableFormat() {
    const { t } = this.props;

    return [
      {
        columnTitle: "",
        columnSize: "2%",
        fieldType: "padding",
      },
      {
        columnTitle: t("keywords.general.name"),
        columnSize: "25%",
        fieldType: "text",
        fieldValue: "fullName",
        isBold: false,
      },

      {
        columnTitle: t("keywords.userRelated.role.name"),
        columnSize: "5%",
        fieldType: "text",
        fieldValue: "role",
        isBold: false,
      },
      {
        columnTitle: t("keywords.userRelated.phone.name"),
        columnSize: "10%",
        fieldType: "text",
        fieldValue: "phone",
        isBold: false,
      },
      {
        columnTitle: t("keywords.userRelated.email.name"),
        columnSize: "10%",
        fieldType: "text",
        fieldValue: "email",
        isBold: false,
      },
      {
        columnTitle: t("keywords.userRelated.company.name"),
        columnSize: "10%",
        fieldType: "text",
        fieldValue: "company",
        isBold: false,
      },
      {
        columnTitle: t("keywords.locationRelated.city.name"),
        columnSize: "10%",
        fieldType: "text",
        fieldValue: "address_city",
        isBold: false,
      },
      {
        columnTitle: t("keywords.locationRelated.country.name"),
        columnSize: "10%",
        fieldType: "text",
        fieldValue: "address_country",
        isBold: false,
      },
      {
        columnTitle: "",
        columnSize: "10%",
        fieldType: "edit",
        editAction: "/user/edit/:id",
      },
    ];
  }

  render() {
    const { t } = this.props;
    const userName = t("pageNames.users");

    if (!this.modifiableUsers()) {
      return null;
    }
    const options = {
      keys: ["fullName", "role", "email", "company", "address_city", "address_country"],
      threshold: 0.1,
      ignoreLocation: true,
    };

    let searchResult = this.modifiableUsers();

    if (this.state.searchValue.trim() !== "") {
      const fuse = new Fuse(searchResult, options); // "list" is the item array
      searchResult = fuse.search(this.state.searchValue);
      searchResult = searchResult.map((result) => result.item);
    }

    return (
      <div>
        <Header
          count={searchResult.length}
          title={userName}
          newRoute="/user/new"
          searchValue={this.state.searchValue}
          searchAction={this.updateSearch}
          searchEnable
        />
        <GeneralTable
          tableData={searchResult}
          tableFormat={this.getTableFormat()}
          deleteAction={this.deleteAction}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.user.general.all,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchAllUsers, removeUser }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserTable));
