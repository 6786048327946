import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";

import BasicTable from "../../Global/General/BasicTable";
import Header from "../../Interface/MainList/header";
import ScreenHealthService, { MaintenanceStatus } from "../services/ScreenHealthService";
import ScreenHealthFilters, { globalFilterFn } from "./components/ScreenHealthFilters";
import LoadingPage from "../../Global/General/loadingPage";

const UptimeReportUrl =
  "https://vertical-city.metabaseapp.com/public/dashboard/4db5c565-92ee-4502-ba16-bcb9bccb9af8?relative_date=past30days";

const MaintenanceStatusIconMapping = {
  [MaintenanceStatus.Elevator]: <i title="Elevator Maintenance" className="fa fa-cog" />,
  [MaintenanceStatus.Screen]: <i title="Screen Maintenance" className="fa fa-plug" />,
};

class ScreenHealthDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      cities: [],
      filters: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    ScreenHealthService.getData().then((data) => {
      const uiTableData = data.map((item) => ({
        city: item.city,
        propertyId: item.property.id,
        propertyName: item.property.name,
        screenName: item.screenName,
        operatingSystems: item.operatingSystems.join("\n"),
        hostNames: item.hostNames.join("\n"),
        iccids: item.iccids.join("\n"),
        noSyncError: {
          label: errorColumnData(item, item.syncTimeErrorAt),
          startAt: item.syncTimeErrorAt,
        },
        noUpdateError: {
          label: errorColumnData(item, item.updateTimeErrorAt),
          startAt: item.updateTimeErrorAt,
        },
        playError: {
          label: errorColumnData(item, item.playErrorAt),
          startAt: item.playErrorAt,
        },
        noEventError: {
          label: errorColumnData(item, item.eventErrorAt, true),
          startAt: item.eventErrorAt,
        },
        maintenanceStatus: item.maintenanceStatus,
        visionDisabled: item.visionDisabled,
      }));

      this.setState({
        data: uiTableData,
        cities: _.uniq(data.map((item) => item.city)),
        isLoading: false,
      });
    });
  }

  handleFiltersChange = (filters) => {
    this.setState({ filters });
  };

  columns = () => [
    {
      Header: "Screen Details",
      columns: [
        {
          Header: "\u00A0",
          accessor: "propertyId",
          disableSortBy: true,
          Cell: renderPropertyLink,
        },
        {
          Header: "City",
          accessor: "city",
          filter: "matchesAny",
        },
        {
          Header: "Property",
          accessor: "propertyName",
        },
        {
          Header: "Name",
          accessor: "screenName",
        },
        {
          Header: "Operating System",
          accessor: "operatingSystems",
        },
        {
          Header: "Host Name",
          accessor: "hostNames",
        },
        {
          Header: "ICCID",
          accessor: "iccids",
        },
      ],
    },
    {
      Header: "Errors",
      columns: [
        {
          Header: "Sync Error",
          cellStyle: errorCellStyle,
          accessor: "noSyncError.label",
          sortType: errorTimeSorter,
        },
        {
          Header: "Update Error",
          cellStyle: errorCellStyle,
          accessor: "noUpdateError.label",
          sortType: errorTimeSorter,
        },
        {
          Header: "Play Error",
          cellStyle: errorCellStyle,
          accessor: "playError.label",
          sortType: errorTimeSorter,
        },
        {
          Header: "Vision Error",
          cellStyle: errorCellStyle,
          accessor: "noEventError.label",
          sortType: errorTimeSorter,
        },
      ],
    },
  ];

  data = () => this.state.data;

  render() {
    // We must show a loading page before rendering anything because otherwise on the initial render the
    // "cities" filter will be empty causing everything to be filtered out once we get the first set of data.
    if (this.state.isLoading) {
      return <LoadingPage pageName="Screen Health Dashboard" />;
    }

    return (
      <>
        <Header title="Screen Health Dashboard">
          <Button href={UptimeReportUrl} target="_blank">
            <i className="fa fa-bar-chart" />
          </Button>
        </Header>
        <ScreenHealthFilters cities={this.state.cities} onFilterChange={this.handleFiltersChange} />
        <BasicTable
          columns={this.columns()}
          data={this.data()}
          tableAttributes={{
            size: "sm",
            striped: true,
          }}
          filters={this.state.filters}
          globalFilterFn={globalFilterFn}
        />
      </>
    );
  }
}

function renderPropertyLink({ cell }) {
  return (
    <a href={`/properties/edit/${cell.value}/form`}>
      <i className="fa fa-link"> </i>
    </a>
  );
}

function maintenanceIcon(item) {
  const { maintenanceStatus } = item;

  if (undergoingMaintenance(maintenanceStatus)) {
    return MaintenanceStatusIconMapping[maintenanceStatus];
  }
}

function errorColumnData(item, errorTimestamp, showVisionStatus = false) {
  const { visionDisabled } = item;

  let columnContent = moment.unix(errorTimestamp).toString();

  if (visionDisabled && showVisionStatus) {
    columnContent = "Vision Disabled";
  }

  if (!errorTimestamp) {
    columnContent = "No Error";
  }

  return (
    <div>
      {maintenanceIcon(item)} {columnContent}
    </div>
  );
}

function errorCellStyle({ cell }) {
  const allDataForRow = cell.row.original;
  const hasNoError = cell.value.props.children.includes("No Error");

  if (
    undergoingMaintenance(allDataForRow.maintenanceStatus) ||
    cell.value.props.children.includes("Vision Disabled")
  ) {
    return { backgroundColor: "#aaa" };
  }

  if (hasNoError) {
    return { backgroundColor: "green" };
  }

  return { backgroundColor: "red" };
}

function undergoingMaintenance(maintenanceStatus) {
  return [MaintenanceStatus.Elevator, MaintenanceStatus.Screen].includes(maintenanceStatus);
}

function errorTimeSorter(rowA, rowB, columnId, desc) {
  const errorAccessor = columnId.split(".")[0];

  const errorTimeA = rowA.original[errorAccessor].startAt;
  const errorTimeB = rowB.original[errorAccessor].startAt;
  const sortResult = errorTimeA - errorTimeB;

  return desc ? sortResult * -1 : sortResult;
}

export default withTranslation()(ScreenHealthDashboard);
