/*
NEWS FEED
*/

import _ from "lodash";

import { SET_NEWS_FEEDS, SET_NEWS_FEED_FORM } from "../../../../../App/actionTypes";

const INITIAL_STATE = {
  all: null,
  form: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_NEWS_FEEDS:
      return { ...state, all: action.payload };
    case SET_NEWS_FEED_FORM:
      return { ...state, form: action.payload };
  }
  return state;
}
