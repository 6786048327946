import React, { Component } from "react";

import template from "../../../../../style/images/template2.png";

class ScreenPreview extends Component {
  render() {
    const { imageUrl, screenTitle, screenDescription } = this.props;
    const hasText = !!(screenTitle || screenDescription);
    return (
      <div className="screen-preview">
        <img src={template} style={{ width: "100%" }} />
        <div className="screen-preview__background-image">
          <img src={imageUrl} style={{ width: "100%", height: "100%" }} />
        </div>
        {hasText ? (
          <div className="screen-preview__notice-content">
            <div className="screen-preview__notice-content__title">{screenTitle}</div>
            <p className="screen-preview__notice-content__description pt-2">{screenDescription}</p>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ScreenPreview;
