/* ACTIONS FOR PANEL ICONS */

import moment from "moment";
import { unstableHistory } from "@App/history";
import { fetchMainFeedMediaList } from "../../../MainFeedMedia/services/actions/mainFeedMediaActions";
import { SET_MAIN_FEED_FORM, SET_MAIN_FEEDS } from "../../../../../App/actionTypes";
import TargetingSerializer from "../../../../../utilities/targetingSerializer";
import {
  addFlashMessage,
  currentlySaving,
} from "../../../../Interface/General/services/actions/applicationStateActions";
import i18n from "../../../../../i18n";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";
import Elev8ApiService from "../../../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../../../lib/services/Elev8Api/lib";

/** *****************************************
GLOBAL ACTION
******************************************* */

export function setMainFeedForm(updatedForm) {
  return {
    type: SET_MAIN_FEED_FORM,
    payload: updatedForm,
  };
}

export function setMainFeedList(currForm) {
  return {
    type: SET_MAIN_FEEDS,
    payload: currForm,
  };
}

export function fetchMainFeedList() {
  const sparseFieldSets = {
    include: ["creator", "properties", "background"],
    fields: {
      big_notice: [
        "id",
        "properties",
        "creator",
        "title",
        "content",
        "start_date",
        "end_date",
        "published",
        "background",
      ],
      user: ["id", "first_name", "last_name", "role"],
      property: ["id", "name"],
      media: ["id", "name"],
    },
  };

  return (dispatch) => {
    Elev8ApiService.index(ApiTypes.big_notice, sparseFieldSets, {
      "filter[end_date][gt]": moment().subtract(1, "years").format("YYYY-MM-DD HH:mm:ss"),
    })
      .then((bigNotices) => {
        let notificationData = bigNotices.map((bigNotice) => {
          const { attributes } = bigNotice;
          const item = {};
          const creator = bigNotice.creator().attributes;
          item.id = attributes.id;
          item.title = attributes.title;
          item.creator = `${creator.first_name} ${creator.last_name}`;
          item.ownerType = creator.role;
          item.properties = bigNotice.properties().map((p) => p.attributes);
          item.propertyIds = item.properties.map((p) => p.id);
          item.propertyNames = item.properties.map((p) => p.name).join(", ");
          item.startDate = moment(attributes.start_date);
          item.endDate = moment(attributes.end_date);
          item.tailoredStartDate = item.startDate
            .locale(i18n.t("language.locale"))
            .format("MMMM Do YYYY");
          item.tailoredEndDate = item.endDate
            .locale(i18n.t("language.locale"))
            .format("MMMM Do YYYY");
          item.content = attributes.content && attributes.content.body;
          item.image = bigNotice.background() && bigNotice.background().attributes.name;
          item.published = attributes.published;
          return item;
        });

        notificationData = notificationData.reverse();

        dispatch(setMainFeedList(notificationData));
      })
      .catch((e) => {
        console.log("Can't fetch main feed", e);
      });
  };
}

/** *****************************************
INITIALIZING FORM ACTIONS
******************************************* */

export function initiateMainFeedForm(id) {
  return (dispatch) => {
    if (id == null) {
      const newForm = {
        startDate: moment(),
        endDate: moment().add(21, "days"),
        minDate: moment(),
        title: "",
        body: "",
        published: true,
        selectedProperties: [],
        media: null,
        formId: null,
        targeting: TargetingSerializer.defaultTargeting(),
      };
      dispatch(setMainFeedForm(newForm));
    } else {
      dispatch(initiateEditMainFeed(id));
    }
  };
}

export function initiateEditMainFeed(id) {
  return (dispatch) => {
    Elev8ApiService.getOldEndpoint(`big_notices/${id}`)
      .then((response) => {
        const newData = response.data;

        // if the start date is before the current date, set min date to start date
        // if the start date is after the current date, set mind date to current date.beginning

        let minDate = moment();
        if (moment(newData.start_date).isBefore(minDate)) {
          minDate = moment(newData.start_date, "YYYY-MM-DD HH:mm:ss");
        }
        // //console.log("min date is", minDate);

        const newForm = {
          startDate: moment(newData.start_date, "YYYY-MM-DD HH:mm:ss"),
          endDate: moment(newData.end_date, "YYYY-MM-DD HH:mm:ss"),
          minDate,
          title: newData.title,
          body: newData.content.body,
          media: newData.background,
          published: newData.published,
          selectedProperties: newData.property_ids,
          formId: newData.id,
          targeting: TargetingSerializer.serialize(newData.targeting || {}),
        };
        dispatch(fetchMainFeedMediaList(newData.property_ids));
        dispatch(setMainFeedForm(newForm));
      })
      .catch((e) => {
        console.log("Can't fetch main feed form (initiate edit main feed)", e);
      });
  };
}

/** *****************************************************
TO DATABASE
****************************************************** */

export function createMainFeed(props) {
  return (dispatch) => {
    saveNotifications(() => {
      if (props.media.id) {
        return postMainFeed(props);
      }
      return postNewMedia(props).then((media) => {
        props.media = media;

        return postMainFeed(props);
      });
    }, dispatch);
  };
}

function postMainFeed(props) {
  const updatedProps = {};
  updatedProps.property_ids = props.selectedProperties;
  updatedProps.background_id = props.media.id;
  updatedProps.title = props.title;
  updatedProps.start_date = moment(props.startDate).format("YYYY-MM-DD HH:mm:ss");
  updatedProps.end_date = moment(props.endDate).format("YYYY-MM-DD HH:mm:ss");
  updatedProps.published = props.published;
  updatedProps.targeting = TargetingSerializer.deserialize(props.targeting);

  const content = {};
  content.body = props.body;
  updatedProps.content = content;

  updatedProps.content_type = getContentType(props.title, props.body);

  return Elev8ApiService.oldPost("big_notices", updatedProps).then(() => {
    unstableHistory.push("/bignotice");
  });
}

/* 
  Setting a contentType here for users using the old big notice form (mainfeedForm) 
  since the content type option does not exist on the form ui (it exists in the new
  form for non VC-network users)
*/

function getContentType(title, body) {
  if (title.length === 0 && body.length === 0) {
    return "image";
  }
  return "text";
}

function postNewMedia(props) {
  const formData = new FormData();

  Object.keys(props.media).forEach((key) => {
    formData.append(key, props.media[key]);
  });

  props.selectedProperties.forEach((propId) => {
    formData.append("property_ids[]", propId);
  });

  return Elev8ApiService.oldPost("media", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  }).then((mediaResponse) => mediaResponse.data);
}

export function updateMainFeed(props) {
  return (dispatch) => {
    saveNotifications(() => {
      if (props.media.id) {
        return putMainFeed(props);
      }
      return postNewMedia(props).then((media) => {
        props.media = media;

        return putMainFeed(props);
      });
    }, dispatch);
  };
}

function putMainFeed(props) {
  const updatedProps = {};
  updatedProps.property_ids = props.selectedProperties;
  updatedProps.background_id = props.media.id;
  updatedProps.title = props.title;
  updatedProps.start_date = moment(props.startDate).format("YYYY-MM-DD HH:mm:ss");
  updatedProps.end_date = moment(props.endDate).format("YYYY-MM-DD HH:mm:ss");
  updatedProps.published = props.published;
  updatedProps.targeting = TargetingSerializer.deserialize(props.targeting);

  const content = {};
  content.body = props.body;
  updatedProps.content = content;

  updatedProps.content_type = getContentType(props.title, props.body);

  return Elev8ApiService.oldPut(`big_notices/${props.formId}`, updatedProps).then(() => {
    unstableHistory.push("/bignotice");
  });
}

export function deleteMainFeed(id, user) {
  return (dispatch) => {
    Elev8ApiService.delete(ApiTypes.big_notice, id)
      .then(() => {
        dispatch(fetchMainFeedList(user));
        unstableHistory.push("/bignotice");
      })
      .catch((e) => {
        console.log("Can't delete main feed", e);
      });
  };
}

function saveNotifications(handleSave, dispatch) {
  currentlySaving(true)(dispatch);

  return handleSave()
    .then(() => {
      addFlashMessage("success", "bigNotice.formActions.saved")(dispatch);
    })
    .catch(() => {
      addFlashMessage("danger", "keywords.errorRelated.invalidForm")(dispatch);
    })
    .finally(() => {
      currentlySaving(false)(dispatch);
    });
}
