import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Line } from "rc-progress";
import moment from "moment";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Header from "../../Interface/MainList/header";

import { fetchCampaigns } from "../../Campaign/GeneralView/services/actions/campaignActions";
import LoadingPage from "../../Global/General/loadingPage";

import CampaignPill from "../../Global/General/campaignPill";

// MAIN CLASS
class StaffDashboard extends Component {
  constructor(props) {
    super(props);
    this.renderCampaigns = this.renderCampaigns.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.fetchCampaigns(
      {
        include: ["advertiser", "owner", "campaign_group"],
        fields: {
          advertiser: ["id", "name"],
          user: ["id", "first_name"],
          campaign: [
            "id",
            "status",
            "name",
            "start_at",
            "end_at",
            "advertiser",
            "owner",
            "campaign_group",
          ],
          campaign_group: ["id", "name"],
        },
      },
      {
        "filter[status]": "active",
        "filter[end_at][gt]": moment().subtract(1, "months").format("YYYY-MM-DD HH:mm:ss"),
      }
    );
  }

  renderCampaigns(post) {
    const currentDay = moment().startOf("day");
    const endDay = moment(post.end_at);
    const startDay = moment(post.start_at).startOf("day");
    let daysDone = Math.abs(startDay.diff(currentDay, "days"));
    const dateRange = Math.abs(endDay.diff(startDay, "days")) + 1;
    let percentage = (daysDone / dateRange) * 100;

    if (currentDay < startDay) {
      daysDone = 0;
      percentage = 0;
    } else if (currentDay > endDay) {
      daysDone = dateRange;
      percentage = 100;
    }

    // set Color
    let strokeColor = "";

    switch (true) {
      case percentage < 16:
        strokeColor = "#FFA144";
        break;
      case percentage < 32:
        strokeColor = "#FFD230";
        break;
      case percentage < 48:
        strokeColor = "#B1E21D";
        break;
      case percentage < 64:
        strokeColor = "#27d48f";
        break;
      case percentage < 80:
        strokeColor = "#16E0D9";
        break;
      case percentage < 100:
        strokeColor = "#6821BA";
        break;
      default:
        strokeColor = "#3030D3";
        break;
    }

    return (
      <div className="campaign-dash-row" key={`campaign_${post.id}`}>
        <div className="baseState">
          <CampaignPill state={post.state} />
        </div>
        <div className="info">
          <div className="campaign-group-title">{post.campaignGroupName}</div>
          <div className="campaign-title">{post.name}</div>
          <div className="advertiser">
            Client:
            <Link to={`/advertisers/${post.advertiser_id}`}>
              <span>{post.client}</span>
            </Link>
          </div>
        </div>
        <div className="time">
          <p>
            <b>Start:</b> {post.tailoredStartDate}
          </p>
          <p>
            <b>End:</b> {post.tailoredEndDate}
          </p>
        </div>
        <div className="mini-progress">
          {post.state == "Draft" ? (
            <p> None Available</p>
          ) : (
            <div>
              <p>
                <b>{daysDone}</b>/{dateRange} Days
              </p>
              <Line percent={percentage} strokeWidth="4" strokeColor={strokeColor} />
            </div>
          )}
        </div>

        <Link to={`/campaign/property-summary/${post.id}`}>
          <button type="button" className="btn fa fa-building" />
        </Link>
        <Link to={`/campaign/ads/${post.id}`}>
          <button type="button" className="btn fa fa-image" />
        </Link>
        <Link to={`/campaign/demo/${post.id}`}>
          <button type="button" className="btn fa fa-tv" />
        </Link>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const dashboardName = t("pageNames.dashboard");
    if (!this.props.campaigns) {
      return <LoadingPage pageName={dashboardName} />;
    }

    const staffCampaigns = _.filter(this.props.campaigns, {
      owner_id: this.props.loggedInUser.id,
    });

    // LIVE CAMPAIGNS
    let totalLiveCampaigns = _.filter(this.props.campaigns, {
      state: "Active",
    });
    totalLiveCampaigns = _.size(totalLiveCampaigns);

    let staffLiveCampaigns = _.filter(staffCampaigns, {
      state: "Active",
    });
    staffLiveCampaigns = _.size(staffLiveCampaigns);

    // PENDING CAMPAIGNS
    let totalPendingCampaigns = _.filter(this.props.campaigns, {
      state: "Pending",
    });
    totalPendingCampaigns = _.size(totalPendingCampaigns);

    let staffPendingCampaigns = _.filter(staffCampaigns, {
      state: "Pending",
    });
    staffPendingCampaigns = _.size(staffPendingCampaigns);

    return (
      <div>
        <Header title="Dashboard" />
        <section className="dashboard-tick">
          <div className="staff-box">
            <i className="fa fa-lightbulb-o" />
            <h2>
              {this.props.loggedInUser.first_name} {this.props.loggedInUser.last_name}
            </h2>
            <p>{this.props.loggedInUser.role}</p>
            <p>{this.props.loggedInUser.email}</p>
            <Link className="small-link" to="/account-settings">
              Edit Account
            </Link>
          </div>
          <Link className="box live" to="/flights">
            <i className="fa fa-area-chart" />
            <h2> {staffLiveCampaigns}</h2>
            <p style={{ color: "#219a6a" }}>
              <b>Live Campaigns</b>
            </p>
            <p> {totalLiveCampaigns} overall</p>
          </Link>
          <Link className="box pending" to="/flights">
            <i className="fa fa-clock-o" />
            <h2> {staffPendingCampaigns}</h2>
            <p style={{ color: "#fb7e29" }}>
              <b>Upcoming Campaigns</b>
            </p>
            <p> {totalPendingCampaigns} overall</p>
          </Link>
        </section>

        <section className="dashboard-tick campaign-box">
          <div className="campaign-list">
            <div className="flex-end-align">
              <div className="dash-header">My Recent Campaigns</div>
            </div>
            <hr />
            {staffCampaigns.slice(0, 6).map(this.renderCampaigns)}
          </div>
        </section>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchCampaigns }, dispatch);
}

function mapStateToProps(state) {
  return {
    campaigns: state.campaign.general.all,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StaffDashboard));
