import { Resources } from "../../../User/Services/permissionService";

export const DASHBOARD = {
  name: "pageNames.dashboard",
  icon: "fa-tachometer",
  url: "dashboard",
};

export const CAMPAIGN_GROUPS = {
  name: "pageNames.campaignGroups",
  icon: "fa-th-large",
  url: "campaign_groups",
  resource: Resources.CAMPAIGNS,
};

export const CAMPAIGNS = {
  name: "pageNames.campaigns",
  icon: "fa-indent",
  url: "flights",
  resource: Resources.CAMPAIGNS,
};

export const CAMPAIGN_PROPOSALS = {
  name: "pageNames.campaignProposals",
  icon: "fa-bell",
  url: "campaign-proposals",
  resource: Resources.CAMPAIGN_GROUP_PROPOSALS,
};

export const ADVERTISERS = {
  name: "pageNames.advertisers",
  icon: "fa-user",
  url: "advertisers",
  resource: Resources.ADVERTISERS,
};

export const PROPERTIES = {
  name: "pageNames.properties",
  icon: "fa-building-o",
  url: "properties",
  resource: Resources.PROPERTIES,
};

export const SIDENOTICE = {
  name: "pageNames.sideNotices",
  icon: "fa-list-ul",
  url: "sidenotice",
  resource: Resources.NOTICES,
};

export const MAINFEED = {
  name: "pageNames.bigNotices",
  icon: "fa-align-justify",
  url: "bignotice",
  resource: Resources.BIG_NOTICES,
};

export const RSSFEED = {
  name: "pageNames.rssFeeds",
  icon: "fa-rss",
  url: "rss-feeds",
  resource: Resources.FEEDS,
};

export const USERS = {
  name: "pageNames.users",
  icon: "fa-users",
  url: "users",
  resource: Resources.USERS,
};

export const MYACCOUNT = {
  name: "pageNames.myAccount",
  icon: "fa-cog",
  url: "account-settings",
};

export const HOWTOUSE = {
  name: "pageNames.howToUse",
  icon: "fa-info-circle",
  url: "http://help.visiomedia.ca",
  externalLink: true,
};

export default [
  DASHBOARD,
  CAMPAIGN_GROUPS,
  CAMPAIGNS,
  CAMPAIGN_PROPOSALS,
  ADVERTISERS,
  PROPERTIES,
  SIDENOTICE,
  MAINFEED,
  RSSFEED,
  USERS,
  MYACCOUNT,
  HOWTOUSE,
];
