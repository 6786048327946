import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import CampaignProposalSelector from "./CampaignProposals/CampaignProposalSelector";
import CampaignProposalContainer from "./CampaignProposals/CampaignProposalContainer";

export default function CampaignProposalsContainer({
  readOnly,
  onAddCampaignProposal,
  onDeleteCampaignProposal,
  onRevertCampaignProposalChanges,
  onSelectCampaignProposal,
  selectedCampaignProposalIndex,
}) {
  const { formState } = useFormContext();

  function handleAddCampaignProposal(newCampaignProposalIndex) {
    onAddCampaignProposal();
    onSelectCampaignProposal(newCampaignProposalIndex);
  }

  return (
    <>
      <CampaignProposalSelector
        dirtyCampaignProposals={formState.dirtyFields.campaign_proposals}
        onAddCampaignProposal={handleAddCampaignProposal}
        onSelectCampaignProposal={onSelectCampaignProposal}
        readOnly={readOnly}
        selectedCampaignProposalIndex={selectedCampaignProposalIndex}
      />
      <CampaignProposalContainer
        onDeleteCampaignProposal={onDeleteCampaignProposal}
        onRevertCampaignProposalChanges={onRevertCampaignProposalChanges}
        readOnly={readOnly}
        selectedCampaignProposalIndex={selectedCampaignProposalIndex}
      />
    </>
  );
}

CampaignProposalsContainer.defaultProps = {
  selectedCampaignProposalIndex: null,
};

CampaignProposalsContainer.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  onAddCampaignProposal: PropTypes.func.isRequired,
  onDeleteCampaignProposal: PropTypes.func.isRequired,
  onRevertCampaignProposalChanges: PropTypes.func.isRequired,
  selectedCampaignProposalIndex: PropTypes.number,
  onSelectCampaignProposal: PropTypes.func,
};
