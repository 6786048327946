import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import FormStatus from "@utilities/FormStatus";

import CampaignPropertyDataHelper from "@components/Campaign/Creation/Property/services/CampaignPropertyDataHelper";
import PermissionService, { Actions, Resources } from "@components/User/Services/permissionService";
import Elev8CampaignPropertiesContext from "@src/contexts/Elev8CampaignPropertiesContext";
import CampaignGroupProposalDataHelper from "./services/CampaignGroupProposalDataHelper";
import { CampaignGroupProposalSerializer } from "./services/CampaignGroupProposalSerializer";
import CampaignProposalDataHelper from "./services/CampaignProposalDataHelper";
import { CampaignProposalSerializer } from "./services/CampaignProposalSerializer";
import ProposalTopBar from "./ProposalTopBar";
import CampaignGroupProposalContainer from "./CampaignGroupProposalContainer";
import CampaignProposalsContainer from "./CampaignProposalsContainer";

export default function ProposalPage({ loggedInUser }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [formStatus, setFormStatus] = useState(FormStatus.LOADING);
  const [elev8Properties, setElev8Properties] = useState([]);
  const [initialFormData, setInitialFormData] = useState(null);
  const [selectedCampaignProposalIndex, setSelectedCampaignProposalIndex] = useState(null);
  const formMethods = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadProposal() {
      let existingProposal;
      if (id) {
        existingProposal = await CampaignGroupProposalDataHelper.fetchProposal(id);
      }
      initializeForm(existingProposal);
    }
    loadProposal();
  }, [id, formMethods.reset]);

  useEffect(() => {
    async function loadElev8Properties() {
      CampaignPropertyDataHelper.fetchPropertyData()
        .then((propertyData) => {
          setElev8Properties(propertyData);
        })
        .catch(() => {
          alert(t("keywords.appRelated.anErrorOccurred"));
        });
    }
    loadElev8Properties();
  }, []);

  function initializeForm(apiObject) {
    const formData = CampaignGroupProposalSerializer.formData(apiObject);
    setInitialFormData(formData);
    formMethods.reset(formData);
    setFormStatus(FormStatus.EDIT);
  }

  function userCanCreateCampaignGroups() {
    return PermissionService.can(Actions.CREATE, Resources.CAMPAIGN_GROUPS, loggedInUser);
  }

  function userCanEditCampaignGroups() {
    return PermissionService.can(Actions.UPDATE, Resources.CAMPAIGN_GROUPS, loggedInUser);
  }

  function userCanEditProposal() {
    if (formMethods.getValues("campaign_group_id")) return false;
    if (formMethods.getValues("status") === "submitted" && !userCanCreateCampaignGroups()) {
      return false;
    }

    return true;
  }

  function campaignGroupID() {
    return String(formMethods.getValues("campaign_group_id"));
  }

  function alertError() {
    alert(t("keywords.errorRelated.tryAgain"));
  }

  function updateProposal(apiObject) {
    CampaignGroupProposalDataHelper.updateProposal(apiObject, id)
      .then((updatedApiObject) => {
        initializeForm(updatedApiObject);
      })
      .catch(() => {
        alertError();
        setFormStatus(FormStatus.EDIT);
      });
  }

  function createProposal(apiObject) {
    CampaignGroupProposalDataHelper.createProposal(apiObject)
      .then((response) => {
        navigate(`/campaign-proposal/edit/${response.attributes.id}`);
      })
      .catch(() => {
        alertError();
        setFormStatus(FormStatus.EDIT);
      });
  }

  function submitProposal() {
    setFormStatus(FormStatus.SUBMITTING);
    CampaignGroupProposalDataHelper.submitProposal(id)
      .then((updatedApiObject) => {
        initializeForm(updatedApiObject);
      })
      .catch(() => {
        alertError();
        setFormStatus(FormStatus.EDIT);
      });
  }

  function convertProposal() {
    setFormStatus(FormStatus.SUBMITTING);
    CampaignGroupProposalDataHelper.completeProposal(id)
      .then((updatedApiObject) => {
        initializeForm(updatedApiObject);
      })
      .catch(() => {
        alertError();
        setFormStatus(FormStatus.EDIT);
      });
  }

  function handleAddCampaignProposal() {
    const existingCampaignProposals = formMethods.getValues("campaign_proposals");
    const newCampaignProposal = CampaignProposalSerializer.formData();
    const newCampaignProposalsList = [...existingCampaignProposals, newCampaignProposal];
    formMethods.setValue("campaign_proposals", newCampaignProposalsList, { shouldDirty: true });
  }

  async function handleDeleteCampaignProposal(index) {
    try {
      const existingCampaignProposals = formMethods.getValues("campaign_proposals");
      const proposalToDelete = existingCampaignProposals[index];
      if (proposalToDelete.id) {
        await CampaignProposalDataHelper.deleteProposal(proposalToDelete.id);
      }
      setSelectedCampaignProposalOnDelete(existingCampaignProposals.length);
      removeCampaignProposalFromList(index);
      return true;
    } catch (apiError) {
      const errorMessage = apiError.response.data.errors[0].detail;
      alert(`Error: ${errorMessage}`);
      return false;
    }
  }

  function setSelectedCampaignProposalOnDelete(numberOfCampaigns) {
    if (selectedCampaignProposalIndex === numberOfCampaigns - 1) {
      setSelectedCampaignProposalIndex(0);
    }
  }

  function removeCampaignProposalFromList(index) {
    const campaignProposals = formMethods.getValues("campaign_proposals");
    const newCampaignProposalsList = campaignProposals.filter((_, i) => i !== index);
    if (!newCampaignProposalsList.length) {
      newCampaignProposalsList.push(CampaignProposalSerializer.formData());
    }
    formMethods.setValue("campaign_proposals", newCampaignProposalsList);
  }

  function handleRevertCampaignProposalChanges(campaignProposalIndex) {
    const currentFormData = formMethods.getValues();
    const campaignProposalReference = `campaign_proposals.${campaignProposalIndex}.id`;
    const existingCampaignProposalId = formMethods.getValues(campaignProposalReference);
    if (existingCampaignProposalId) {
      currentFormData.campaign_proposals[campaignProposalIndex] =
        initialFormData.campaign_proposals[campaignProposalIndex];
    } else {
      currentFormData.campaign_proposals[campaignProposalIndex] =
        CampaignProposalSerializer.formData();
    }
    formMethods.reset(currentFormData, { keepDirty: true });
  }

  const onSubmit = (data) => {
    setFormStatus(FormStatus.SAVING);
    const apiObject = CampaignGroupProposalSerializer.apiObject(data);
    if (id) {
      updateProposal(apiObject);
    } else {
      createProposal(apiObject);
    }
  };

  return (
    <div className="proposals">
      <Elev8CampaignPropertiesContext.Provider value={elev8Properties}>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <ProposalTopBar
              formStatus={formStatus}
              userCanEditProposal={userCanEditProposal()}
              userCanEditCampaignGroups={userCanEditCampaignGroups()}
              onSubmit={submitProposal}
              onConvert={convertProposal}
              associatedCampaignGroupID={campaignGroupID()}
            />
            <CampaignGroupProposalContainer
              readOnly={!userCanEditProposal()}
              formStatus={formStatus}
            />
            <CampaignProposalsContainer
              readOnly={!userCanEditProposal()}
              onAddCampaignProposal={handleAddCampaignProposal}
              onDeleteCampaignProposal={handleDeleteCampaignProposal}
              onRevertCampaignProposalChanges={handleRevertCampaignProposalChanges}
              onSelectCampaignProposal={setSelectedCampaignProposalIndex}
              selectedCampaignProposalIndex={selectedCampaignProposalIndex}
            />
          </form>
        </FormProvider>
      </Elev8CampaignPropertiesContext.Provider>
    </div>
  );
}

ProposalPage.propTypes = {
  loggedInUser: PropTypes.shape({
    capabilities: PropTypes.object,
  }).isRequired,
};
