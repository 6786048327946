export function convertSnakeCaseToPascalCase(s) {
  if (typeof s !== "string") return "";
  return s.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : "" + d.toUpperCase()));
}

export function convertSnakeCaseToTitleCase(s) {
  if (typeof s !== "string") return "";
  return s.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : " " + d.toUpperCase()));
}

export function idArrayFromString(s) {
  if (typeof s !== "string") return "";
  const extractedIds = s
    .replace(/\D/g, ",")
    .split(",")
    .filter((value) => {
      return !!value === true && parseInt(value, 10) > 0;
    });
  return extractedIds;
}

