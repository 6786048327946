import Elev8ApiService from "../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes, JsonApiObjectFactory } from "../../../lib/services/Elev8Api/lib";

class AdvertiserDataHelper {
  static REQUEST_OPTIONS = {
    include: [],
    fields: {
      advertiser: ["id", "name"],
    },
  };

  static fetchAdvertisers() {
    return new Promise((resolve, reject) => {
      Elev8ApiService.index(ApiTypes.advertiser, this.REQUEST_OPTIONS).then((response) => {
        if (response) resolve(response);
        reject(response);
      });
    });
  }

  static createAdvertiser(form) {
    return new Promise((resolve, reject) => {
      Elev8ApiService.create(ApiTypes.advertiser, form)
        .then((response) => {
          const item = response.data.data;
          resolve(
            JsonApiObjectFactory.createJsonApiObject(item, response.data.included, item.type)
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default AdvertiserDataHelper;
