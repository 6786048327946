import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { withTranslation } from "react-i18next";
import LoadingPage from "../../Global/General/loadingPage";
import {
  fetchPropertyContent,
  clearPropertyContent,
} from "../services/actions/propertyFormActions";
import PropertyContentDemo from "./propertyContentDemo";

class VCPropertyContent extends Component {
  UNSAFE_componentWillMount() {
    this.props.fetchPropertyContent(this.props.propertyId);
  }

  filteredContent() {
    const filtered = { ...this.props.content };
    const allValidCampaigns = this.props.content.ads.map((ad) => ad.campaign_id);

    filtered.campaigns = this.props.content.campaigns.filter((campaign) =>
      allValidCampaigns.includes(campaign.id)
    );

    return filtered;
  }

  componentWillUnmount() {
    this.props.clearPropertyContent();
  }

  render() {
    const { t } = this.props;

    if (this.props.content === null) {
      return <LoadingPage pageName={t("pageNames.screenPreview")} />;
    }

    return (
      <div>
        <div className="d-flex align-items-center">
          <h2 className="d-inline">{t("keywords.screenRelated.currentLoop")}</h2>
          <a data-tip data-for="demo-tooltip" className="information-button pl-2">
            <h5>
              {t("keywords.general.whatIsThis")}
              <i className="fa fa-question-circle" aria-hidden="true" />
            </h5>
          </a>
        </div>
        <ReactTooltip effect="solid" id="demo-tooltip" border type="dark">
          <div className="text-left impressions-tooltip-content">
            <b>{t("propertyContentDemo.title")}</b>
            <hr />
            <p> {t("keywords.general.actualResults")}</p>
            <p>{t("propertyContentDemo.someContentWill")}</p>
            <p>{t("propertyContentDemo.someContentMay")}</p>
            <p>{t("propertyContentDemo.feeds")}</p>
          </div>
        </ReactTooltip>
        <PropertyContentDemo content={this.filteredContent()} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    content: state.property.general.content,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchPropertyContent, clearPropertyContent }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VCPropertyContent));
