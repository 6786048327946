import React, { Component, createRef } from "react";

import { connect } from "react-redux";

// CUSTOM COMPONENTS
import Dropzone from "react-dropzone";
import ImagePreviewWidget from "../../../../../Global/Widgets/views/imagePreviewWidget";

// Custom Actions
const fileMaxSize = 100 * 1000 * 1000; // 100MB

class ImageUploaderStep extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }

  componentWillUnmount() {
    this.cleanUpImagePreview();
  }

  onDrop(files) {
    if (files[0] === undefined) {
      return;
    }

    this.cleanUpImagePreview();

    const fileSize = files[0].size;
    if (fileSize <= fileMaxSize) {
      const media = {
        name: files[0].name,
        type: files[0].type,
        category: "ad",
        file: files[0],
        preview: URL.createObjectURL(files[0]),
        mediaId: null,
      };
      this.props.updatingLocalAdForm("currMedia", media, this.props.localAd);
      this.forceUpdate();
    }
  }

  cleanUpImagePreview() {
    URL.revokeObjectURL(this.props?.localAd?.currMedia?.preview);
  }

  render() {
    const { localAd, campaign, width, height, displayableWidth, displayableHeight } = this.props;

    const advertMod = localAd.currMedia ? "Update Media" : "Upload Media";
    const acceptedImages = campaign.videoEnabled
      ? { "image/*": [".png", ".jpg", ".jpeg"], "video/*": [".mp4", ".mpeg"] }
      : { "image/*": [".png", ".jpg", ".jpeg"] };

    const dropzoneRef = createRef();

    return (
      <div className="preview-section">
        <div className="flex-center">
          {this.props.localAd.currMedia ? (
            <div>
              <ImagePreviewWidget
                filePreview={localAd.currMedia.preview}
                fileType={localAd.currMedia.type}
                autoplayVideo
                width={displayableWidth}
                height={displayableHeight}
              />
            </div>
          ) : (
            <div className="no-image-preview">
              <p> No Image Currently Selected </p>
            </div>
          )}
        </div>

        <div className="allowed-dimensions">
          <p>
            <b> Dimensions: </b>
            {width} x {height}
          </p>
          <p>
            <b> Max Size: </b>100 MB
          </p>
        </div>
        <Dropzone
          className="dropzone-zone"
          onDrop={this.onDrop}
          accept={acceptedImages}
          ref={dropzoneRef}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: "dropzone" })} className="step3-dropzone-button">
              <input {...getInputProps()} />
              {advertMod}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default connect(null, null)(ImageUploaderStep);
