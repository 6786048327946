import {} from "../../../App/actionTypes";

/** ********************************
SETTING ACTIONS
********************************** */

// SETTING THE FORM DATA
export function setFormData(image_file, category, title, adId) {
  const media_props = {};
  media_props.name = title;
  media_props.category = category;
  media_props.file = image_file;
  media_props.advertiser_id = adId;

  let newType = image_file.type;
  newType = newType.split("/");
  media_props.type = newType[0];

  const formData = new FormData();
  for (const key in media_props) {
    formData.append(key, media_props[key]);
  }

  return formData;
}

export function setFormDataNoId(image_file, category, title) {
  const media_props = {};
  media_props.name = title;
  media_props.category = category;
  media_props.file = image_file;

  let newType = image_file.type;
  newType = newType.split("/");
  media_props.type = newType[0];

  const formData = new FormData();
  for (const key in media_props) {
    formData.append(key, media_props[key]);
  }

  return formData;
}
