import React from "react";
import PropTypes from "prop-types";

export default function ToggleSlider({ label, value, onChange, variant, size }) {
  return (
    <div className="toggleSlider">
      <div className="toggleSlider__label">{label}</div>
      <label className={`toggleSlider__track ${size}`}>
        <input type="checkbox" checked={value} onChange={onChange} />
        <span className={`toggleSlider__switch ${variant} ${size}`} />
      </label>
    </div>
  );
}

ToggleSlider.defaultProps = {
  variant: "primary",
  size: null,
};

ToggleSlider.propTypes = {
  value: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
  size: PropTypes.string,
};
