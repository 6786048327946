import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Dropdown from "../../Global/Widgets/updatedWidgets/dropDown";
import { PROPERTY_PAYMENT_METHODS, PROPERTY_SALES_TAX } from "../../../components/constants";
import SingleDatePicker from "../../Global/Widgets/views/singleDatePicker";
import NumberInput from "../../Global/Widgets/updatedWidgets/numberInput";

class BillingSection extends Component {
  handleFormChange = (event) => {
    const target = {
      value: event.target.value,
      name: event.target.name,
    };
    this.props.handleChange(target);
  };

  handleTimeChange = (name, time) => {
    const target = {
      name,
      value: time,
    };
    this.props.handleChange(target);
  };

  render() {
    const { isPropertyManager, formFields, t } = this.props;

    if (isPropertyManager) {
      return null;
    }
    return (
      <div className="form-section">
        <div className="form-section__title pt-4">
          {t("keywords.billingRelated.billingInformation")}
        </div>
        <Row>
          <Form.Group as={Col} controlId="installdate">
            <SingleDatePicker
              label={t("keywords.propertyRelated.revShareStartDate.name")}
              updateTime={this.handleTimeChange.bind(this, "rev_share_start_at")}
              date={this.props.formFields.rev_share_start_at}
              showTimeSelect={true}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <NumberInput
              label={t("keywords.propertyRelated.revenueRelated.revenueShare")}
              value={formFields.revenue_share}
              required
              handleChange={this.handleFormChange}
              suffix="%"
              max={99}
              name="revenue_share"
            />
          </Form.Group>
          <Form.Group as={Col} controlId="Payment Method">
            <Dropdown
              includeBlank
              label={t("keywords.billingRelated.paymentMethod")}
              value={formFields.payment_method}
              handleChange={this.handleFormChange}
              name="payment_method"
              options={PROPERTY_PAYMENT_METHODS}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="Property Tax">
            <Dropdown
              includeBlank
              label={t("keywords.billingRelated.salesTax")}
              value={formFields.sales_tax}
              handleChange={this.handleFormChange}
              name="sales_tax"
              options={PROPERTY_SALES_TAX}
            />
          </Form.Group>
        </Row>
      </div>
    );
  }
}

BillingSection.propTypes = {
  isPropertyManager: PropTypes.bool.isRequired,
  formFields: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default withTranslation()(BillingSection);
