import Elev8ApiService from "../../../lib/services/Elev8Api/Elev8ApiService";

export const MaintenanceStatus = {
  NoMaintenance: "no_maintenance",
  Elevator: "elevator_in_progress",
  Screen: "screen_in_progress",
};

class ScreenHealthService {
  static getData() {
    return Elev8ApiService.server()
      .get("/screen_config_health")
      .then((response) => {
        if (response.status === 200) {
          return ScreenHealthService._extractData(response.data);
        }

        return [];
      });
  }

  static _extractData(payload) {
    const properties = ScreenHealthService._extractIncluded(payload, "property");

    return payload.data.map((result) => {
      const { attributes } = result;

      const property = properties[attributes.property_id];

      return {
        city: property.address_city,
        property: { id: property.id, name: property.name },
        screenName: attributes.name,
        operatingSystems: attributes.screen_operating_systems,
        hostNames: attributes.screen_host_names,
        iccids: attributes.screen_iccids,
        syncTimeErrorAt: attributes.last_sync_time_error,
        updateTimeErrorAt: attributes.last_update_time_error,
        playErrorAt: Math.min(attributes.last_play_time_error, attributes.play_count_error),
        eventErrorAt: attributes.last_event_time_error,
        maintenanceStatus: attributes.current_maintenance,
        visionDisabled: attributes.vision_disabled,
      };
    });
  }

  static _extractIncluded(payload, type) {
    const includedOfType = payload.included
      .filter((item) => item.type === type)
      .map((item) => item.attributes);

    return includedOfType.reduce((out, item) => {
      // eslint-disable-next-line no-param-reassign
      out[item.id] = item;

      return out;
    }, {});
  }
}

export default ScreenHealthService;
