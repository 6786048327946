import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { withTranslation } from "react-i18next";

import {
  updateMainFeed,
  deleteMainFeed,
  createMainFeed,
  initiateMainFeedForm,
} from "@components/Client/MainFeed/services/actions/mainFeedActions";
import { fetchFeedProperties } from "@components/Property/services/actions/propertyManagerActions";
import {
  fetchMainFeedMediaList,
  deleteMediaProperties,
} from "@components/Client/MainFeedMedia/services/actions/mainFeedMediaActions";

// Templates
import DateRangePicker from "@components/Global/Widgets/views/dateRangePicker";
import SubmitButtonForm from "@components/Global/Widgets/views/submitButtonForm";
import TextInput from "@components/Global/Widgets/textInput";
import ScreenPreview from "@components/Global/Widgets/views/screenPreview";
import TableSelector from "@components/Global/Widgets/views/tableSelector";
import Header from "@components/Interface/MainList/header";
import BasicLayout from "@components/Global/Layouts/basic_layout";
import GalleryPicker from "@components/Global/Widgets/views/galleryPicker";
import TargetingSelector from "@components/Global/Widgets/views/TargetingSelector/targetingSelector";
import { addFlashMessage } from "@components/Interface/General/services/actions/applicationStateActions";
import LoadingPage from "@components/Global/General/loadingPage";
import CollapsibleCard from "@components/Global/Widgets/views/collapsibleCard";
import ConfirmModal from "@components/Global/Widgets/views/ConfirmModal";

class MainFeedForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPropertyExpanded: true,
      selectImageExpanded: true,
      showMediaDeleteConfirmationModal: false,
      mediaToDelete: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchMainFeedForm();
    this.setProperties();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.properties !== this.props.properties) {
      if (this.props.properties.length === 1) {
        const selectedPropertyId = this.props.properties[0].id;
        this.props.fetchMainFeedMediaList([selectedPropertyId]);
        this.updateFormFields([{ name: "selectedProperties", value: [selectedPropertyId] }]);
      }
      if (prevProps.feedForm !== this.props.feedForm) {
        this.setState({ formFields: this.props.feedForm });
      }
    }
    if (prevProps.feedForm !== this.props.feedForm) {
      this.setState({ formFields: this.props.feedForm });
    }
  }

  fetchMainFeedForm = () => {
    const currentRoute = this.props.location.pathname;
    this.props.fetchMainFeedMediaList([]);
    this.props.fetchFeedProperties(this.props.loggedInUser);
    if (currentRoute === "/bignotice/new") {
      this.props.initiateMainFeedForm(null);
    } else {
      this.props.initiateMainFeedForm(this.props.params.id);
    }
  };

  updateTime = (startDate, endDate) => {
    const newStartDate = { name: "startDate", value: startDate };
    const newEndDate = { name: "endDate", value: endDate };
    this.updateFormFields([newStartDate, newEndDate]);
  };

  handleNoticeCheck = (e) => {
    const targets = [{ name: "published", value: e.target.checked }];
    this.setState({
      formFields: {
        ...this.state.formFields,
        ...targets.reduce((acc, target) => ({ ...acc, [target.name]: target.value }), {}),
      },
    });
  };

  handleFormChange = (event) => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    const target = {
      name: event.target.name,
      value,
    };
    this.updateFormFields([target]);
  };

  updateFormFields = (targets) => {
    this.setState({
      formFields: {
        ...this.state.formFields,
        ...targets.reduce((acc, target) => ({ ...acc, [target.name]: target.value }), {}),
      },
    });
  };

  setNewBackground = (media) => {
    const target = { name: "media", value: media };
    this.updateFormFields([target]);
  };

  onDelete = () => {
    this.props.deleteMainFeed(this.props.feedForm.formId, this.props.loggedInUser);
  };

  updateTextBox = (e) => {
    const target = { name: "body", value: e.target.value };
    this.updateFormFields([target]);
  };

  updateTargeting = (targeting) => {
    const target = { name: "targeting", value: targeting };
    this.updateFormFields([target]);
  };

  bodyLength = () => {
    const body = this.state.formFields.body || "";
    return body.length;
  };

  checkValid = () => {
    const start = this.state.formFields.startDate.format("MMMM Do YYYY h:mm a");
    const end = this.state.formFields.endDate.format("MMMM Do YYYY h:mm a");
    if (
      this.state.formFields.title.length >= 0 &&
      this.state.formFields.body.length >= 0 &&
      this.state.formFields.media !== null &&
      start !== end
    ) {
      return true;
    }
    return false;
  };

  submitMainFeed = () => {
    // check validation
    const validated = this.checkValid();

    if (this.props.feedForm.formId && validated) {
      this.props.updateMainFeed(this.state.formFields);
    } else if (validated) {
      this.props.createMainFeed(this.state.formFields);
    } else {
      this.props.addFlashMessage("danger", "keywords.errorRelated.invalidForm");
    }
  };

  // For Property
  selectRow = (row, isSelected) => {
    const { selectedProperties } = this.state.formFields;
    let target;
    let newSelected;

    if (isSelected) {
      newSelected = [...selectedProperties, row.id];
      target = { value: newSelected, name: "selectedProperties" };
    } else {
      newSelected = [...selectedProperties.filter((id) => id !== row.id)];
      target = { value: newSelected, name: "selectedProperties" };
    }
    this.props.fetchMainFeedMediaList(newSelected);
    this.updateFormFields([target]);
  };

  handleDeselectAll = () => {
    this.props.fetchMainFeedMediaList([]);
    this.updateFormFields([{ value: [], name: "selectedProperties" }]);
  };

  handleSelectAll = () => {
    const propertyIds = [...this.props.properties.map((property) => property.id)];
    this.props.fetchMainFeedMediaList(propertyIds);
    this.updateFormFields([{ value: propertyIds, name: "selectedProperties" }]);
  };

  setProperties = () => {
    if (!this.state.formFields || !this.props.properties) {
      return;
    }

    const propertySelected = this.state.formFields.selectedProperties.length !== 0;

    if (this.props.properties.length >= 1 && !propertySelected) {
      this.props.properties.map((property) => this.selectRow(property, true));
    }
  };

  handleMediaSelect = (media) => {
    this.setNewBackground(media);
  };

  showMediaDeleteConfirmation = (media) => {
    this.setState({
      mediaToDelete: media,
      showMediaDeleteConfirmationModal: true,
    });
  };

  resetMediaDeleteConfirmation = () => {
    this.setState({
      mediaToDelete: null,
      showMediaDeleteConfirmationModal: false,
    });
  };

  handleConfirmMediaDelete = () => {
    this.props.deleteMediaProperties(this.state.mediaToDelete).then(() => {
      const { selectedProperties } = this.state.formFields;
      this.props.fetchMainFeedMediaList(selectedProperties);
    });
    this.resetMediaDeleteConfirmation();
  };

  renderDateSelect = () => {
    const { t } = this.props;

    return (
      <CollapsibleCard header={t("keywords.dateRelated.selectDates")}>
        <DateRangePicker
          startDate={this.state.formFields.startDate}
          endDate={this.state.formFields.endDate}
          updateTime={this.updateTime}
          minDate={this.state.formFields.minDate}
          maxDate={moment().add(5, "y")}
          showTime
          showDuration
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </CollapsibleCard>
    );
  };

  renderPropertySelect = () => {
    if (this.props.properties === undefined) {
      return null;
    }
    const { t } = this.props;
    const propertyTableFormat = [
      { field: "name", size: "40%", label: t("keywords.general.name") },
      { field: "area1", size: "60%", label: t("keywords.locationRelated.address") },
    ];

    const propertySelected = this.state.formFields.selectedProperties.length !== 0;
    const border = propertySelected ? null : "danger";
    const onExpandClick = (expanded) => {
      this.setState({ selectPropertyExpanded: expanded });
    };

    let header = t("keywords.propertyRelated.select");
    if (propertySelected) {
      const selectedProperties = this.props.properties.filter((p) =>
        this.state.formFields.selectedProperties.includes(p.id)
      );

      if (selectedProperties) {
        header += ` (${selectedProperties.length}) - ${selectedProperties
          .slice(0, 10)
          .map((prop) => prop.name)
          .join(", ")}`;
      }
    }

    return (
      <CollapsibleCard
        header={header}
        border={border}
        expanded={this.state.selectPropertyExpanded}
        onExpandClick={onExpandClick}
      >
        {!propertySelected ? (
          <div className="error"> {t("keywords.errorRelated.required")} </div>
        ) : null}
        <TableSelector
          tableData={this.props.properties}
          tableFormat={propertyTableFormat}
          tableType="checkbox"
          selected={this.state.formFields.selectedProperties}
          onSelectAction={this.selectRow}
          deselectAll={this.handleDeselectAll}
          selectAll={this.handleSelectAll}
          sortByField="name"
        />
      </CollapsibleCard>
    );
  };

  renderImageSelect = () => {
    const { t } = this.props;

    const imageSelected = !!this.state.formFields.media;
    const selectedImage = this.state.formFields.media;
    const border = imageSelected ? null : "danger";
    const onExpandClick = (expanded) => {
      this.setState({ selectImageExpanded: expanded });
    };

    return (
      <CollapsibleCard
        header={t("widgets.galleryPicker.select")}
        border={border}
        expanded={this.state.selectImageExpanded}
        onExpandClick={onExpandClick}
      >
        {!imageSelected ? (
          <div className="error">{t("keywords.errorRelated.required")}</div>
        ) : (
          <div className="alert alert-success" role="alert">
            Selected{" "}
            <a target="_blank" rel="noopener noreferrer" href={selectedImage.url}>
              {selectedImage.name}
            </a>
          </div>
        )}
        {this.renderGalleryPicker()}
      </CollapsibleCard>
    );
  };

  renderTextOverlayDetails = () => {
    const { t } = this.props;
    const charactersLeft = 600 - this.bodyLength();

    return (
      <CollapsibleCard header={t("bigNotice.contentHeader")} defaultExpanded>
        <TextInput
          label={t("keywords.noticeRelated.title")}
          testId="notice-title"
          value={this.state.formFields.title}
          handleChange={this.handleFormChange}
          maxLength={100}
          hideWordCount={false}
          required
          type="text"
          name="title"
        />
        <div style={{ padding: "3px" }} />
        <div className="header-column">
          <div className="form-group">
            <div className="base-flex">
              <div className="title">{t("keywords.general.description")}</div>
            </div>

            <textarea
              className="form-control"
              value={this.state.formFields.body}
              onChange={this.updateTextBox.bind(this)}
              maxLength={600}
            />
            <div className={`title text-right ${charactersLeft < 5 ? "error" : ""}`}>
              {`${t("keywords.general.charactersLeft")} ${charactersLeft}`}
            </div>
          </div>
        </div>
      </CollapsibleCard>
    );
  };

  renderTargeting = () => {
    const { t } = this.props;

    return (
      <CollapsibleCard header={t("bigNotice.advancedDisplayOptions")} defaultExpanded={false}>
        <TargetingSelector
          targetingData={this.state.formFields.targeting}
          onTargetingChange={this.updateTargeting}
          showDemographic={false}
        />
      </CollapsibleCard>
    );
  };

  renderForm = () => (
    <>
      {this.renderDateSelect()}
      {this.renderPropertySelect()}
      {this.renderImageSelect()}
      {this.renderTextOverlayDetails()}
      {this.renderTargeting()}
    </>
  );

  renderPreview = () => {
    const { t } = this.props;

    const mediaUrl = this.state.formFields.media ? this.state.formFields.media.url : "";

    return (
      <>
        <div className="header-column">
          <div className="title-note">{t("keywords.general.actualResults")}</div>
        </div>

        <ScreenPreview
          imageUrl={mediaUrl}
          screenTitle={this.state.formFields.title}
          screenDescription={this.state.formFields.body}
        />
        <Form.Group controlId="published">
          <div className="checkbox-style">
            <label className="check-container">
              <h6>{t("noticeTerms.publishNotice")}</h6>
              <input
                type="checkbox"
                checked={this.state.formFields.published}
                onChange={this.handleNoticeCheck}
              />
              <span className="checkmark" />
            </label>
          </div>
        </Form.Group>
      </>
    );
  };

  renderGalleryPicker = () => {
    const { t } = this.props;

    if (this.state.formFields.selectedProperties.length === 0) {
      return (
        <div className="notice-background-images p-2">
          <p> {t("widgets.galleryPicker.message")} </p>
        </div>
      );
    }

    return (
      <GalleryPicker
        mediaImages={this.props.mediaImages}
        onMediaSelect={this.handleMediaSelect}
        onMediaDelete={this.showMediaDeleteConfirmation}
        imageCategory="big_notice_background"
      />
    );
  };

  renderMediaDeleteConfirmationModal = () => {
    const { t } = this.props;
    return (
      <ConfirmModal
        title={t("keywords.buttonActions.deleting")}
        show={this.state.showMediaDeleteConfirmationModal}
        confirm={{
          label: t("keywords.buttonActions.delete"),
          variant: "danger",
          action: this.handleConfirmMediaDelete,
        }}
        cancel={{
          label: t("keywords.buttonActions.cancel"),
          variant: "outline-secondary",
          action: this.resetMediaDeleteConfirmation,
        }}
      >
        {t("keywords.buttonActions.deleteMessage")}
      </ConfirmModal>
    );
  };

  render() {
    const { t, bigNoticeName } = this.props;

    if (
      !this.state.formFields ||
      !this.state.formFields.targeting ||
      !this.props.properties ||
      !this.props.mediaImages
    ) {
      return <LoadingPage pageName={bigNoticeName} />;
    }

    return (
      <BasicLayout>
        <BasicLayout.MainContent>
          <Header title={bigNoticeName} />
          <Container fluid>
            <Row style={{ minHeight: "100%" }}>
              <Col xs="12" lg="7" xl="6" className="py-2" style={{ backgroundColor: "white" }}>
                {this.renderForm()}
              </Col>
              <Col xs="12" lg="5" xl="6" className="bg-light py-2">
                {this.renderPreview()}
              </Col>
            </Row>
          </Container>
          {this.renderMediaDeleteConfirmationModal()}
        </BasicLayout.MainContent>
        <BasicLayout.Footer>
          <SubmitButtonForm
            className="px-4"
            formId={this.props.feedForm.formId}
            category={bigNoticeName}
            exitRoute="/bignotice"
            createButtonName={t("keywords.buttonActions.create")}
            enableDelete
            deleteAction={this.onDelete}
            createAction={this.submitMainFeed}
            updateAction={this.submitMainFeed}
          />
        </BasicLayout.Footer>
      </BasicLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    mediaImages: state.client.mainFeedMedia.all,
    feedForm: state.client.mainFeed.form,
    properties: state.property.pM.all,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMainFeed,
      deleteMainFeed,
      createMainFeed,
      fetchFeedProperties,
      fetchMainFeedMediaList,
      initiateMainFeedForm,
      addFlashMessage,
      deleteMediaProperties,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MainFeedForm));
