import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { withTranslation } from "react-i18next";

class MultiSelect extends Component {
  onSelect = (value) => {
    const target = {
      name: this.props.name,
      value,
    };
    this.props.handleChange(target);
  };

  render() {
    const { name, label, required, options, value, enableMultiselect, isClearable, t } = this.props;
    const errorPresent = !!(value === "" && required);

    let selectedValue = value;
    if (!enableMultiselect) {
      selectedValue = options.find((option) => option.value === value);
    }

    return (
      <>
        <Form.Label>{label}</Form.Label>
        <div className={`${errorPresent ? "multiselect--error" : ""}`}>
          <Select
            isMulti={enableMultiselect}
            name={name}
            value={selectedValue}
            options={options}
            onChange={this.onSelect}
            isClearable={isClearable}
          />
        </div>
        {value === "" && required ? (
          <div className="error">{t("keywords.errorRelated.required")}</div>
        ) : null}
      </>
    );
  }
}

export default withTranslation()(MultiSelect);
