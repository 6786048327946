import CSV from "papaparse";
import _ from "lodash";

export function generateCsv(rowData, columnKeys, columnTitles, delimiter) {
  if (rowData.length === 0) {
    return "No results found";
  }
  const csvData = rowData.map((inputRow) => columnKeys.map((key) => _.get(inputRow, key)));
  return CSV.unparse({ fields: columnTitles, data: csvData }, { delimiter });
}

export function convertToJson(csvString) {
  const jsonFormat = CSV.parse(csvString);
  return jsonFormat.data;
}

export function downloadCsv(csvString, name = "export") {
  const blob = new Blob([csvString], { type: "text/csv" });
  const filename = `${name}.csv`;
  // For IE
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob, { type: "text/csv" });
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
