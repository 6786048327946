/** ****************
SIDEBAR REDUCER
**************** */

import { TOGGLE_SIDEBAR } from "../../../../../App/actionTypes";

const INITIAL_STATE = { toggled: true };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, toggled: action.payload };
  }
  return state;
}
