import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";

import LoginContainer from "./loginContainer";
import { loginUser } from "../../Authentication/actions/sessionActions";
import TextInput from "../../Global/Widgets/textInput";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.loginUser(this.state.email, this.state.password);
  };

  handleChange = (event) => {
    const { value } = event.target;
    const { name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  renderAlert() {
    const { t } = this.props;

    if (this.props.errorMessage) {
      return (
        <div data-test-id="login-error-message" className="alert alert-danger">
          <strong>{t("keywords.errorRelated.oops")}</strong> {this.props.errorMessage}
        </div>
      );
    }
    return <div className="alert empty" />;
  }

  render() {
    const { t } = this.props;

    return (
      <LoginContainer>
        <div className="login-box">
          <form data-test-id="login-form" onSubmit={this.handleSubmit}>
            <h4>{t("loginRelatedPages.userLogin")}</h4>
            <div className="sign-container">
              <hr />
              <TextInput
                label={t("keywords.userRelated.email.name")}
                testId="login-email"
                value={this.state.email}
                handleChange={this.handleChange}
                required
                type="text"
                name="email"
              />
              <div className="my-3" />
              <TextInput
                label={t("keywords.appRelated.password.name")}
                testId="login-password"
                value={this.state.password}
                handleChange={this.handleChange}
                required
                type="password"
                name="password"
              />
            </div>
            <div className="button-logins my-2">
              <p className="tosDescription">
                {t("tosTerms.statement")}
                {` `}
                <a
                  className="mb-2"
                  href="https://verticalcity.com/tos"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("tosTerms.tos")}
                </a>
              </p>
              <button type="submit" className="btn btn-primary" data-test-id="login-button">
                {t("keywords.appRelated.login")}
              </button>
              <Link
                to="/forgot-password"
                className="forgot-password"
                data-test-id="forgot-password-link"
              >
                {t("keywords.appRelated.password.forgotWithQuestion")}
              </Link>
            </div>
          </form>
          {this.renderAlert()}
        </div>
      </LoginContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loginUser }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login));
