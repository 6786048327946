/** *******************************
 APPLICATION STATE ACTIONS
 ******************************** */

import {
  SET_FLASH_MESSAGE,
  REMOVE_FLASH_MESSAGE,
  SHOW_SAVING_MODAL,
} from "../../../../../App/actionTypes";

const FLASH_MESSAGE_DURATION = 3000;
let FLASH_MESSAGE_COUNTER = 0;

export function addFlashMessage(type, message) {
  return function addFlashMessageDispatcher(dispatch) {
    FLASH_MESSAGE_COUNTER += 1;
    const flashMessageId = FLASH_MESSAGE_COUNTER;

    dispatch({
      type: SET_FLASH_MESSAGE,
      payload: { flashMessageId, type, message },
    });

    setTimeout(() => {
      dispatch({
        type: REMOVE_FLASH_MESSAGE,
        payload: { flashMessageId },
      });
    }, FLASH_MESSAGE_DURATION);
  };
}

export function removeFlashMessage(message) {
  return {
    type: REMOVE_FLASH_MESSAGE,
    payload: { flashMessageId: message.flashMessageId },
  };
}

export function currentlySaving(isSaving) {
  return function currentlySavingDispatcher(dispatch) {
    dispatch({
      type: SHOW_SAVING_MODAL,
      payload: isSaving,
    });
  };
}
