import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class ErrorPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    return <p className="lead text-center pt-3">{t("keywords.appRelated.anErrorOccurred")}</p>;
  }
}

export default withTranslation()(ErrorPage);
