import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function BigNoticeScreenPreview({ title, content, backgroundImage, contentType }) {
  const { t } = useTranslation();

  function renderTextLayout() {
    return (
      <>
        <div className="titleContainerForWebkitLineClampPadding">
          <div className="title">{title}</div>
        </div>
        <div className="content">{content}</div>
      </>
    );
  }

  function renderEmptyImageWarning() {
    return (
      <div className="bigNoticeContent__missingImage">
        <i className="fa fa-image" />
        <b>{t("keywords.fileRelated.imageRelated.missingError")}</b>
      </div>
    );
  }

  function renderImageLayout() {
    if (!backgroundImage) return renderEmptyImageWarning();

    return <img src={backgroundImage.url} className="image" alt="Big Notice" />;
  }

  function renderScreenPreview() {
    return (
      <div className="screenPreviewContainer">
        <div className="bigNoticeContent">
          {contentType === "image" ? renderImageLayout() : renderTextLayout()}
        </div>
      </div>
    );
  }

  return (
    <>
      <h4 className="mb-3">{t("pageNames.screenPreview")}</h4>
      {renderScreenPreview()}
      <b className="my-3">{t("keywords.general.actualResults")}</b>
    </>
  );
}

BigNoticeScreenPreview.defaultProps = {
  title: "",
  content: "",
  contentType: null,
  backgroundImage: null,
};

BigNoticeScreenPreview.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  backgroundImage: PropTypes.object,
  contentType: PropTypes.string,
};
