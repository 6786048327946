import React, { Component } from "react";
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

let tooltipCounter = 0;

class WarningMessageTooltip extends Component {
  render() {
    const { message, place } = this.props;

    const uniqueId = `WarningMessageTooltip-${tooltipCounter}`;
    tooltipCounter += 1;

    return (
      <React.Fragment>
        <OverlayTrigger placement={place} overlay={<Tooltip id={uniqueId}>{message}</Tooltip>}>
          <Badge variant="warning" className="m-1">
            <i className="fa fa-info" aria-hidden="true" />
          </Badge>
        </OverlayTrigger>
      </React.Fragment>
    );
  }
}

export default WarningMessageTooltip;
