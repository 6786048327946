import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withTranslation } from "react-i18next";

// Custom
import {
  fetchPropertyContent,
  clearPropertyContent,
} from "../../Property/services/actions/propertyFormActions";
import { fetchFeedProperties } from "../../Property/services/actions/propertyManagerActions";

import Header from "../../Interface/MainList/header";
import LoadingPage from "../../Global/General/loadingPage";
import DashboardPanel from "./dashboardPanel";
import DashboardSchedulePanel from "./dashboardSchedulePanel";
import PROPERTY_USER_PANELS from "./dashboardPanelReference";

import PermissionService, { Actions } from "../../User/Services/permissionService";

class PropertyUserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProperty: null,
      propertyOptions: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.props.fetchFeedProperties(this.props.loggedInUser);
  }

  componentDidUpdate(prevProps) {
    if (this.props.properties == null || this.props.properties.length === 0) return;

    if (prevProps.properties !== this.props.properties) {
      const propertyOptions = [];
      this.props.properties.forEach(function setNewProperty(property) {
        const newProperty = {
          id: property.id,
          label: property.name,
          screens: property.screen_installation_configs().map((screen) => screen.attributes),
        };
        propertyOptions.push(newProperty);
      });
      this.setState({ propertyOptions });
      this.setState({ selectedProperty: propertyOptions[0] });
      this.props.fetchPropertyContent(propertyOptions[0].id);
    }
  }

  componentWillUnmount() {
    this.props.clearPropertyContent();
  }

  changeProperty = (selectedProperty) => {
    this.setState({ selectedProperty });
    this.props.fetchPropertyContent(selectedProperty.id);
  };

  canAccess = (panelItem) => {
    return PermissionService.can(Actions.LIST, panelItem.resource, this.props.loggedInUser);
  };

  renderPanel = (panelItem) => {
    if (!this.canAccess(panelItem)) {
      return null;
    }
    if (panelItem.requiredRole && this.props.loggedInUser.role !== panelItem.requiredRole) {
      return null;
    }
    return (
      <Col xs={4} md={12} className="px-0 px-sm-1">
        <DashboardPanel {...panelItem} />
      </Col>
    );
  };

  render() {
    const { t } = this.props;
    const dashboardName = t("pageNames.dashboard");

    if (this.props.properties == null) {
      return <LoadingPage pageName={dashboardName} />;
    }

    if (this.props.properties && this.props.properties.length === 0) {
      return (
        <h2 className="text-center pt-3">
          {t("propertyTerms.noPropertiesAssignedForThisAccount")}
        </h2>
      );
    }

    return (
      <React.Fragment>
        <Header bgColor="#fff" title={dashboardName} />
        <Row className="mx-2">
          <Col md={7} className="order-md-first">
            <DashboardSchedulePanel
              content={this.props.content}
              properties={this.state.propertyOptions}
              loggedInUser={this.props.loggedInUser}
              selectedProperty={this.state.selectedProperty}
              changeProperty={this.changeProperty}
            />
          </Col>
          <Col md={5} className="order-first">
            <Row className="justify-content-center">
              {PROPERTY_USER_PANELS.map(this.renderPanel.bind(this))}
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { fetchPropertyContent, fetchFeedProperties, clearPropertyContent },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    properties: state.property.pM.all,
    content: state.property.general.content,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PropertyUserDashboard));
