import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authenticationReducer from "../components/Authentication/reducers/authenticationReducer";
import personalReducer from "../components/User/Personal/services/reducers/personalReducer";
import generalUserReducer from "../components/User/General/services/reducers/generalReducer";
import applicationStateReducer from "../components/Interface/General/services/reducers/applicationStateReducer";
import sideBarReducer from "../components/Interface/SideBar/services/reducers/sideBarReducer";
import advertiserReducer from "../components/Advertiser/services/reducers/advertiserReducer";
import propertyReducer from "../components/Property/services/reducers/propertyReducer";
import pmPropertyReducer from "../components/Property/services/reducers/pmReducer";
import rssFeedReducer from "../components/Client/RssFeed/services/reducers/rssFeedReducer";
import sideNoticeIconReducer from "../components/Client/SideNoticeIcon/services/reducers/sideNoticeIconReducer";
import sideNoticeReducer from "../components/Client/SideNotice/services/reducers/sideNoticeReducer";
import mainFeedMediaReducer from "../components/Client/MainFeedMedia/services/reducers/mainFeedMediaReducer";
import mainFeedReducer from "../components/Client/MainFeed/services/reducers/mainFeedReducer";

import campaignConfigurationsReducer from "../components/Campaign/Creation/Configuration/services/configurationReducer";
import adStackReducer from "../components/Campaign/Creation/AdStack/services/reducer/adStackReducer";
import generalCampaignReducer from "../components/Campaign/GeneralView/services/reducers/campaignReducer";

const rootReducer = combineReducers({
  auth: authenticationReducer,
  application: applicationStateReducer,
  user: combineReducers({
    personal: personalReducer,
    general: generalUserReducer,
  }),
  property: combineReducers({
    general: propertyReducer,
    pM: pmPropertyReducer,
  }),
  form: formReducer,
  interface: combineReducers({
    sideBar: sideBarReducer,
  }),
  advertisers: advertiserReducer,
  client: combineReducers({
    rssFeed: rssFeedReducer,
    sideNoticeIcon: sideNoticeIconReducer,
    sideNotice: sideNoticeReducer,
    mainFeedMedia: mainFeedMediaReducer,
    mainFeed: mainFeedReducer,
  }),
  campaign: combineReducers({
    creation: combineReducers({
      config: campaignConfigurationsReducer,
      adStack: adStackReducer,
    }),
    general: generalCampaignReducer,
  }),
});

export default rootReducer;
