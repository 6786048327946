import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import PermissionService, { Actions, Resources } from "../../User/Services/permissionService";

import PropertyManagerDashboard from "./propertyUserDashboard";
import StaffDashboard from "./staffDashboard";
import RsmDashboard from "./RsmDashboard";

class MainDashboard extends Component {
  render() {
    if (this.props.loggedInUser.role === "rsm_sales_associate") {
      return <RsmDashboard />;
    }

    if (PermissionService.can(Actions.UPDATE, Resources.CAMPAIGNS, this.props.loggedInUser)) {
      return <StaffDashboard loggedInUser={this.props.loggedInUser} />;
    }

    return <PropertyManagerDashboard loggedInUser={this.props.loggedInUser} />;
  }
}

export default withTranslation()(MainDashboard);
