/* eslint-disable no-undef */
import React from "react";
import ReactDOM from "react-dom/client";
import "../style/css/custom.scss";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

import App from "./App/app";
import AppProviders from "./AppProviders";

const userId = localStorage.getItem("userId");

(async () => {
  // LDProvider allows us to use LaunchDarkly feature flags.
  // Usage of LaunchDarkly will change over time, meaning sometimes there will be no feature flags in play
  // Use the withLDConsumer wrapper or useFlags, useLDClient hooks to access flag values where needed.
  // https://docs.launchdarkly.com/sdk/client-side/react/react-web#using-withldconsumer
  // https://docs.launchdarkly.com/sdk/client-side/react/react-web#hooks
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.LD_CLIENT_SIDE_ID,
    options: {
      streaming: true,
    },
  });

  const root = ReactDOM.createRoot(document.getElementById("root"));

  root.render(
    <AppProviders>
      <LDProvider>
        <App id={userId} />
      </LDProvider>
    </AppProviders>
  );
})();
