import { JsonApiObject, User, Property, CampaignReport, Campaign, CampaignGroup } from "../index";
import { convertSnakeCaseToPascalCase } from "../../../../../utilities/stringHelpers";

const ModelClasses = {
  User,
  Property,
  CampaignReport,
  Campaign,
  CampaignGroup,
};

export class JsonApiObjectFactory {
  static createJsonApiObject = (item, included) => {
    return JsonApiObjectFactory._createModel(item, included);
  };

  // -- Private -- //

  static _getModelClassName(apiTypeString) {
    return convertSnakeCaseToPascalCase(apiTypeString);
  }

  static _createModel(item, included) {
    const modelClassName = JsonApiObjectFactory._getModelClassName(item.type);
    const modelClass = ModelClasses[modelClassName];

    if (modelClass == undefined) {
      return JsonApiObjectFactory._createDefaultDataObject(item, included);
    }

    return new modelClass(item, included);
  }

  static _createDefaultDataObject(item, included) {
    return new JsonApiObject(item, included);
  }
}
