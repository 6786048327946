import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withTranslation } from "react-i18next";
import Dropdown from "../../Global/Widgets/updatedWidgets/dropDown";
import Checkbox from "../../Global/Widgets/updatedWidgets/checkBox";
import TextArea from "../../Global/Widgets/updatedWidgets/textArea";
import NumberInput from "../../Global/Widgets/updatedWidgets/numberInput";

class PropertyCharacteristicsSection extends Component {
  handleFormChange = (event) => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    const target = {
      name: event.target.name,
      value,
    };
    this.props.handleChange(target);
  };

  render() {
    const { formFields, isPropertyManager, t } = this.props;
    const propertyType = formFields.property_type;
    return (
      <div className="form-section">
        <div className="form-section__title pt-4">{t("keywords.propertyRelated.features")}</div>
        <Row>
          {!isPropertyManager ? (
            <>
              <Form.Group as={Col} md="auto">
                <Dropdown
                  label={t("keywords.general.type")}
                  value={propertyType}
                  handleChange={this.handleFormChange}
                  required
                  name="property_type"
                  options={["Condo", "Luxury Rental", "Commercial", "Other"]}
                />
              </Form.Group>

              <React.Fragment>
                <Form.Group as={Col} md="2">
                  <NumberInput
                    label={t("keywords.propertyRelated.floors.numberOf")}
                    value={formFields.floors}
                    handleChange={this.handleFormChange}
                    required
                    name="floors"
                  />
                </Form.Group>
                {propertyType === "Condo" ||
                propertyType === "Luxury Rental" ||
                propertyType === "Other" ? (
                  <Form.Group as={Col} md="2">
                    <NumberInput
                      label={t("keywords.propertyRelated.units.numberOf")}
                      value={formFields.number_of_units}
                      handleChange={this.handleFormChange}
                      name="number_of_units"
                    />
                  </Form.Group>
                ) : null}
                {propertyType === "Commercial" || propertyType === "Other" ? (
                  <Form.Group as={Col} md="2">
                    <NumberInput
                      label={t("keywords.propertyRelated.size.sqFt")}
                      value={formFields.building_sqft}
                      handleChange={this.handleFormChange}
                      type="number"
                      name="building_sqft"
                    />
                  </Form.Group>
                ) : null}
                <Form.Group as={Col} md="2">
                  <NumberInput
                    label={t("keywords.propertyRelated.weeklyCirculation")}
                    value={formFields.weekly_circulation_estimate}
                    disabled
                    type="number"
                    name="weekly_circulation_estimate"
                  />
                </Form.Group>
              </React.Fragment>
            </>
          ) : null}
        </Row>
        <div className="form-section__title pt-4">
          {t("keywords.propertyRelated.ammenitiesRelated.header")}
        </div>
        <Row>
          <Form.Group as={Col} md="auto" sm="12" controlId="Dogs">
            <Checkbox
              type="checkbox"
              label={t("keywords.propertyRelated.animalRelated.dogs")}
              name="allows_dogs"
              value={formFields.allows_dogs}
              handleChange={this.handleFormChange}
            />
          </Form.Group>
          <Form.Group as={Col} md="auto" sm="12" controlId="Cats">
            <Checkbox
              type="checkbox"
              label={t("keywords.propertyRelated.animalRelated.cats")}
              name="allows_cats"
              value={formFields.allows_cats}
              handleChange={this.handleFormChange}
            />
          </Form.Group>
          {propertyType === "Condo" ||
          propertyType === "Luxury Rental" ||
          propertyType === "Other" ? (
            <React.Fragment>
              <Form.Group as={Col} md="auto" sm="12" controlId="ResidentParking">
                <Checkbox
                  type="checkbox"
                  label={t("keywords.propertyRelated.ammenitiesRelated.residentParking")}
                  name="resident_parking"
                  value={formFields.resident_parking}
                  handleChange={this.handleFormChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="auto" sm="12" controlId="VisitorParking">
                <Checkbox
                  type="checkbox"
                  label={t("keywords.propertyRelated.ammenitiesRelated.visitorParking")}
                  name="visitor_parking"
                  value={formFields.visitor_parking}
                  handleChange={this.handleFormChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="auto" sm="12" controlId="Gym">
                <Checkbox
                  type="checkbox"
                  label={t("keywords.propertyRelated.ammenitiesRelated.gym")}
                  name="has_gym"
                  value={formFields.has_gym}
                  handleChange={this.handleFormChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="auto" sm="12" controlId="Pool">
                <Checkbox
                  type="checkbox"
                  label={t("keywords.propertyRelated.ammenitiesRelated.pool")}
                  name="has_pool"
                  value={formFields.has_pool}
                  handleChange={this.handleFormChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="auto" sm="12" controlId="Adults">
                <Checkbox
                  type="checkbox"
                  label={t("keywords.propertyRelated.restrictionRelated.adultsOnly")}
                  name="adult_only"
                  value={formFields.adult_only}
                  handleChange={this.handleFormChange}
                />
              </Form.Group>

              <Form.Group as={Col} md="auto" sm="12" controlId="FamilyFriendly">
                <Checkbox
                  type="checkbox"
                  label={t("keywords.propertyRelated.restrictionRelated.familyFriendly")}
                  name="family_friendly"
                  value={formFields.family_friendly}
                  handleChange={this.handleFormChange}
                />
              </Form.Group>
            </React.Fragment>
          ) : null}
        </Row>
        {isPropertyManager ? null : (
          <Row>
            <Form.Group as={Col} sm="12" controlId="formBasicChecbox">
              <TextArea
                rows="2"
                name="amenities"
                value={formFields.amenities}
                handleChange={this.handleFormChange}
                label={t("keywords.propertyRelated.ammenitiesRelated.description")}
              />
            </Form.Group>
          </Row>
        )}
      </div>
    );
  }
}

export default withTranslation()(PropertyCharacteristicsSection);
