//Helper class that helps create uris with sparse fieldsets, pagination, ect.

export class UriCreator {
  static createUri(baseUrl, sparseFieldsets) {
    if (sparseFieldsets == null) {
      return baseUrl;
    } else {
      const query = UriCreator._createQuery(sparseFieldsets);
      return `${baseUrl}?${query}`;
    }
  }

  // -- Private -- //

  static _createQuery(sparseFieldsets) {
    const { fields, include } = sparseFieldsets;
    let params = new URLSearchParams();

    params = UriCreator._addIncludedQuery(include, params);
    params = UriCreator._addSparseFieldsetQuery(fields, params);
    return params.toString();
  }

  static _addIncludedQuery(includedFields, params) {
    if (!includedFields) return params;
    const includedRelationships = includedFields.join(",");
    if (includedRelationships != "") {
      params.set(`include`, includedRelationships);
    }
    return params;
  }

  static _addSparseFieldsetQuery(sparseFieldsets, params) {
    Object.entries(sparseFieldsets).forEach((field) => {
      params.set(`fields[${field[0]}]`, field[1]);
    });
    return params;
  }
}
