/* ACTIONS FOR PANEL ICONS */
import _ from "lodash";
import moment from "moment";
import { unstableHistory } from "@App/history";
import i18n from "../../../../../i18n";
import { SET_NEWS_FEED_FORM, SET_NEWS_FEEDS } from "../../../../../App/actionTypes";
import TargetingSerializer from "../../../../../utilities/targetingSerializer";
import "moment/locale/fr-ca";
import "moment/locale/en-ca";
import Elev8ApiService from "../../../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../../../lib/services/Elev8Api/lib";

/** *****************************************
GLOBAL ACTION
******************************************* */

export function setSideNoticeForm(updatedForm) {
  return {
    type: SET_NEWS_FEED_FORM,
    payload: updatedForm,
  };
}

export function clearSideNoticeForm() {
  return {
    type: SET_NEWS_FEED_FORM,
    payload: null,
  };
}

export function setSideNotice(sideNoticeData) {
  return {
    type: SET_NEWS_FEEDS,
    payload: sideNoticeData,
  };
}

export function fetchSideNotice(currentUser) {
  const sparseFieldSets = {
    include: ["creator", "properties"],
    fields: {
      side_notice: ["id", "creator", "title", "content", "start_date", "end_date", "published"],
      user: ["id", "first_name", "last_name", "role"],
      property: ["id"],
    },
  };
  return function (dispatch) {
    Elev8ApiService.index(ApiTypes.side_notice, sparseFieldSets, {
      "filter[end_date][gt]": moment().subtract(1, "years").format("YYYY-MM-DD HH:mm:ss"),
    }).then((sideNotices) => {
      let notificationData = sideNotices.map((sideNotice) => {
        const { attributes } = sideNotice;
        const item = {};
        const creator = sideNotice.creator().attributes;
        item.id = attributes.id;
        item.title = attributes.title;
        item.creator = `${creator.first_name} ${creator.last_name}`;
        item.ownerType = creator.role;
        item.properties = sideNotice.properties().map((p) => p.attributes);
        item.propertyIds = item.properties.map((p) => p.id);
        item.startDate = moment(attributes.start_date);
        item.endDate = moment(attributes.end_date);
        item.tailoredStartDate = item.startDate
          .locale(i18n.t("language.locale"))
          .format("MMMM Do YYYY");
        item.tailoredEndDate = item.endDate
          .locale(i18n.t("language.locale"))
          .format("MMMM Do YYYY");
        item.body = attributes.body;
        item.published = attributes.published;
        return item;
      });
      notificationData = notificationData.reverse();

      dispatch(setSideNotice(notificationData));
    });
  };
}

/** *****************************************
INITIALIZING FORM ACTIONS
******************************************* */

export function initiateSideNoticeForm(id) {
  return function (dispatch) {
    if (id == null) {
      const newForm = {
        startDate: moment(),
        endDate: moment().add(21, "days"),
        minDate: moment(),
        title: "",
        body: "",
        selectedProperties: [],
        published: true,
        formId: null,
        targeting: TargetingSerializer.defaultTargeting(),
      };
      dispatch(setSideNoticeForm(newForm));
    } else {
      dispatch(initiateEditSideNoticeForm(id));
    }
  };
}

export function initiateEditSideNoticeForm(id) {
  return function (dispatch) {
    Elev8ApiService.getOldEndpoint(`notices/${id}`)
      .then((response) => {
        const newData = response.data;
        let minDate = moment();
        if (moment(newData.start_date).isBefore(minDate)) {
          minDate = moment(newData.start_date, "YYYY-MM-DD HH:mm:ss");
        }
        const newForm = {
          startDate: moment(newData.start_date, "YYYY-MM-DD HH:mm:ss"),
          endDate: moment(newData.end_date, "YYYY-MM-DD HH:mm:ss"),
          minDate,
          title: newData.title,
          body: newData.body,
          selectedProperties: newData.property_ids,
          published: newData.published,
          formId: newData.id,
          targeting: TargetingSerializer.serialize(newData.targeting),
        };
        dispatch(setSideNoticeForm(newForm));
      })
      .catch((e) => {
        console.log("Can't initiate edit sidenotice form", e);
      });
  };
}

/** ******************************************************
FORM ACTIONS
********************************************************* */

export function updatingSideNoticeForm(type, newData, oldData) {
  return function (dispatch) {
    const updatedForm = _.extend({}, oldData);
    switch (type) {
      case "time":
        var startDate = newData[0];
        var endDate = newData[1];
        updatedForm.startDate = startDate;
        updatedForm.endDate = endDate;
        break;
      case "deselectAll":
        var deselected = _.difference(updatedForm.selectedProperties, newData);
        updatedForm.selectedProperties = deselected;
        break;
      case "selectAll":
        var selected = _.union(updatedForm.selectedProperties, newData);
        updatedForm.selectedProperties = selected;
        break;
      case "targeting":
        updatedForm.targeting = newData;
        break;
      default:
        updatedForm[type] = newData;
        break;
    }
    dispatch(setSideNoticeForm(updatedForm));
  };
}

/** *****************************************************
TO DATABASE
****************************************************** */
export function createSideNotice(feedForm) {
  return function (dispatch) {
    const props = {};
    props.property_ids = feedForm.selectedProperties;
    props.title = feedForm.title;
    props.body = feedForm.body;
    props.targeting = TargetingSerializer.deserialize(feedForm.targeting);
    (props.start_date = moment(feedForm.startDate).format("YYYY-MM-DD HH:mm:ss")),
      (props.end_date = moment(feedForm.endDate).format("YYYY-MM-DD HH:mm:ss")),
      (props.published = feedForm.published);
    Elev8ApiService.oldPost("notices", props)
      .then(() => {
        unstableHistory.push("/sidenotice");
      })
      .catch((e) => {
        console.log("Can't create news feed", e);
      });
  };
}

export function editSideNotice(feedForm) {
  return function (dispatch) {
    const props = {};
    props.property_ids = feedForm.selectedProperties;
    props.title = feedForm.title;
    props.body = feedForm.body;
    props.icon_id = null;
    props.targeting = TargetingSerializer.deserialize(feedForm.targeting);
    (props.start_date = moment(feedForm.startDate).format("YYYY-MM-DD HH:mm:ss")),
      (props.end_date = moment(feedForm.endDate).format("YYYY-MM-DD HH:mm:ss")),
      (props.published = feedForm.published);
    Elev8ApiService.oldPut(`notices/${feedForm.formId}`, props)
      .then(() => {
        unstableHistory.push("/sidenotice");
      })
      .catch((e) => {
        console.log("Can't edit news feed", e);
      });
  };
}

export function deleteSideNotice(id, user) {
  return function (dispatch) {
    Elev8ApiService.delete(ApiTypes.side_notice, id)
      .then(() => {
        dispatch(fetchSideNotice(user));
        unstableHistory.push("/sidenotice");
      })
      .catch(() => {});
  };
}
