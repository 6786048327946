/* Widget for a textbox form */

import React, { Component } from "react";
import { connect } from "react-redux";

// MAIN CLASS
class CheckBox extends Component {
  constructor(props) {
    super(props);
    this.updateCheckbox = this.updateCheckbox.bind(this);
  }

  updateCheckbox(e) {
    this.props.updateForm(this.props.formPropertyName, e.target.checked, this.props.formProps);
  }

  /* style={{"borderLeftColor": formColor} */
  render() {
    const { checkValue, label } = this.props;

    return (
      <label className="checkbox-style">
        <p>{label}</p>
        <input type="checkbox" checked={checkValue} onChange={this.updateCheckbox} />
        <span className="checkmark" />
      </label>
    );
  }
}

export default connect(null, null)(CheckBox);
