import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchPersonalForm } from "../components/User/Personal/services/actions/personalActions";
import Elev8Router from "./elev8Router";

class App extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.auth) {
      this.fetchUserInfo();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      fetchUserInfo();
    }
  }

  fetchUserInfo() {
    this.props.fetchPersonalForm();
  }

  render() {
    return <Elev8Router auth={this.props.auth} />;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth.authenticated,
  };
}

export default connect(mapStateToProps, { fetchPersonalForm })(App);
