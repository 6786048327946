import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import LoginContainer from "./loginContainer";
import { sendEmail } from "../../Authentication/actions/sessionActions";
import TextInput from "../../Global/Widgets/textInput";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailMessage: "",
      message2: "",
      showEmail: true,
      email: "",
    };
  }

  // Retrieve user input
  handleResetPassword = (e) => {
    const { t } = this.props;

    e.preventDefault();
    this.setState({
      emailMessage: `${t("loginRelatedPages.aSecureLink")} ${this.state.email}`,
      message2: t("loginRelatedPages.checkSpam"),
      showEmail: false,
    });
    this.props.sendEmail(this.state.email);
  };

  // Show error message for incorrect Login
  renderAlert() {
    const { t } = this.props;

    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>{t("keywords.errorRelated.oops")}</strong> {this.props.errorMessage}
        </div>
      );
    }
  }

  handleChange = (event) => {
    const { value } = event.target;
    const { name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <LoginContainer>
        <form onSubmit={this.handleResetPassword}>
          <h4>{t("keywords.appRelated.password.forgot")}</h4>
          <div className="sign-container">
            <hr />
            {this.state.showEmail ? (
              <div>
                <p>{t("loginRelatedPages.enterEmail")}</p>
                <br />
                <TextInput
                  label={t("keywords.userRelated.email.name")}
                  testId="reset-email"
                  value={this.state.email}
                  handleChange={this.handleChange}
                  required
                  type="email"
                  name="email"
                />
                <br />
                <div className="button-logins">
                  <button type="submit" action="submit" className="btn btn-primary">
                    {t("keywords.appRelated.password.reset")}
                  </button>
                  <Link to="/login" className="forgot-password">
                    {t("loginRelatedPages.returnToLogin")}
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <b>{this.state.emailMessage}</b>
                <p>{this.state.message2}</p>
                <Link to="/login"> {t("loginRelatedPages.returnToLogin")}</Link>
              </div>
            )}
          </div>
        </form>
      </LoginContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ sendEmail }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ForgotPassword));
