// React
import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import Elev8ApiService from "@lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "@lib/services/Elev8Api/lib";
import { updateScreens } from "@components/Property/services/actions/propertyFormActions";
import ScreenFields from "./screenFields";

class ScreenList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screens: this.props.initialScreens,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitClicked = this.submitClicked.bind(this);
  }

  addScreen = () => {
    Elev8ApiService.create(ApiTypes.screen_installation_config, {
      property_id: this.props.propertyId,
    }).then(() => {
      window.location.reload();
    });
  };

  hasScreens() {
    return this.state.screens != undefined && this.state.screens.length !== 0;
  }

  submitClicked() {
    // if we want we can optimize this by only
    // saving screens that have been changed...
    return this.props.updateScreens(this.state.screens);
  }

  handleChange(id, field, value) {
    const index = this.state.screens.findIndex((screen) => screen.id === id);
    const screens = [...this.state.screens];
    const screenToUpdate = screens[index];

    screenToUpdate[field] = value;
    screens[index] = screenToUpdate;

    this.setState({ screens });
  }

  render() {
    if (this.hasScreens()) {
      return (
        <div className="p-2 u-background-color-white">
          {this.state.screens.map((screen) => (
            <ScreenFields
              name={screen.name}
              notes={screen.notes || ""}
              big_notice_frequency={screen.big_notice_frequency}
              big_notice_duration={screen.big_notice_duration}
              current_maintenance={screen.current_maintenance}
              vision_disabled={screen.vision_disabled}
              id={screen.id}
              property_id={screen.property_id}
              screen_type={screen.screen_type}
              key={screen.id}
              handleChange={this.handleChange}
            />
          ))}

          <Container fluid className="p-3">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button size="lg" onClick={this.addScreen} style={{ width: "30%" }}>
                + Add New Screen
              </Button>
              <Button size="lg" onClick={this.submitClicked} style={{ width: "30%" }}>
                Save Screens
              </Button>
            </div>
          </Container>
        </div>
      );
    }
    return <div>No screens to display</div>;
  }
}

ScreenList.propTypes = {
  initialScreens: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateScreens }, dispatch);
}

export default connect(null, mapDispatchToProps)(ScreenList);
