import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";

import AdvertiserDataHelper from "../../services/AdvertiserDataHelper";
import AdvertiserSerializer from "../../services/AdvertiserSerializer";
import FormStatus from "../../../../utilities/FormStatus";
import WarningMessageTooltip from "../../../Global/Widgets/views/warningMessageTooltip";
import TextInput from "../../../Global/Widgets/textInput";

export default function NewAdvertiserForm({ show, onSave }) {
  const { t } = useTranslation();
  const [formStatus, setFormStatus] = useState(FormStatus.EDIT);
  const { control, getValues, handleSubmit, reset } = useForm({
    defaultValues: AdvertiserSerializer.formData(),
  });

  function createAdvertiser(apiObject) {
    AdvertiserDataHelper.createAdvertiser(apiObject)
      .then((newAdvertiser) => {
        onSave(newAdvertiser.attributes.id);
        alert("new advertiser has been added to the list");
        reset();
      })
      .catch((response) => {
        alert("please check required fields and try again");
        console.log(response);
      })
      .finally(() => {
        setFormStatus(FormStatus.EDIT);
      });
  }

  function onSubmit() {
    setFormStatus(FormStatus.SAVING);
    const formData = getValues();
    const apiObject = AdvertiserSerializer.apiObject(formData);
    createAdvertiser(apiObject);
  }

  if (!show) return null;

  return (
    <div className="newAdvertiserContainer">
      <div className="newAdvertiserContainer__header" style={{ width: "100%" }}>
        <div style={{ margin: "0.25em 0.5em" }}>
          {t("advertiserTerms.newAdvertiser")}
          <WarningMessageTooltip
            message={t("advertiserTerms.addNewAdvertiserHelperMessage")}
            place="bottom"
          />
        </div>
        <Button
          variant="success"
          disabled={formStatus === FormStatus.SAVING}
          onClick={handleSubmit(onSubmit)}
        >
          {t("keywords.buttonActions.create")}
        </Button>
      </div>
      <div className="flexForm newAdvertiserForm">
        <div className="flexForm__group">
          <Form.Group className="flexForm__field">
            <Form.Label>{t("advertiserTerms.company")} *</Form.Label>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value, name } }) => (
                <TextInput value={value} handleChange={onChange} name={name} type="text" />
              )}
            />
          </Form.Group>
          <Form.Group className="flexForm__field">
            <Form.Label>{t("advertiserTerms.contactName")} *</Form.Label>
            <Controller
              control={control}
              name="contact_name"
              render={({ field: { onChange, value, name } }) => (
                <TextInput value={value} handleChange={onChange} name={name} type="text" />
              )}
            />
          </Form.Group>
          <Form.Group className="flexForm__field">
            <Form.Label>{t("advertiserTerms.contactEmail")} *</Form.Label>
            <Controller
              control={control}
              name="contact_email"
              render={({ field: { onChange, value, name } }) => (
                <TextInput value={value} handleChange={onChange} name={name} type="text" />
              )}
            />
          </Form.Group>
          <Form.Group className="flexForm__field">
            <Form.Label>{t("advertiserTerms.contactPhone")} *</Form.Label>
            <Controller
              control={control}
              name="contact_phone"
              render={({ field: { onChange, value, name } }) => (
                <TextInput value={value} handleChange={onChange} name={name} type="text" />
              )}
            />
          </Form.Group>
        </div>
        <div className="flexForm__group">
          <Form.Group className="flexForm__field--xl">
            <Form.Label>{t("keywords.locationRelated.addressLine1.name")} *</Form.Label>
            <Controller
              control={control}
              name="contact_address_line1"
              render={({ field: { onChange, value, name } }) => (
                <TextInput value={value} handleChange={onChange} name={name} type="text" />
              )}
            />
          </Form.Group>
          <Form.Group className="flexForm__field--xl">
            <Form.Label>{t("keywords.locationRelated.addressLine2.name")}</Form.Label>
            <Controller
              control={control}
              name="contact_address_line2"
              render={({ field: { onChange, value, name } }) => (
                <TextInput value={value} handleChange={onChange} name={name} type="text" />
              )}
            />
          </Form.Group>
          <Form.Group className="flexForm__field--fixedSm">
            <Form.Label>{t("keywords.locationRelated.postalOrZipCode.name")} *</Form.Label>
            <Controller
              control={control}
              name="contact_postal_code"
              render={({ field: { onChange, value, name } }) => (
                <TextInput value={value} handleChange={onChange} name={name} type="text" />
              )}
            />
          </Form.Group>
          <Form.Group className="flexForm__field">
            <Form.Label>{t("keywords.locationRelated.city.name")} *</Form.Label>
            <Controller
              control={control}
              name="contact_city"
              render={({ field: { onChange, value, name } }) => (
                <TextInput value={value} handleChange={onChange} name={name} type="text" />
              )}
            />
          </Form.Group>
          <Form.Group className="flexForm__field">
            <Form.Label>{t("keywords.locationRelated.provinceOrState.name")} *</Form.Label>
            <Controller
              control={control}
              name="contact_province"
              render={({ field: { onChange, value, name } }) => (
                <TextInput value={value} handleChange={onChange} name={name} type="text" />
              )}
            />
          </Form.Group>
          <Form.Group className="flexForm__field">
            <Form.Label>{t("keywords.locationRelated.country.name")} *</Form.Label>
            <Controller
              control={control}
              name="contact_country"
              render={({ field: { onChange, value, name } }) => (
                <TextInput value={value} handleChange={onChange} name={name} type="text" />
              )}
            />
          </Form.Group>
        </div>
        <div className="flexForm__group">
          <Form.Group className="flexForm__field--xl">
            <Form.Label>{t("advertiserTerms.comments")}</Form.Label>
            <Controller
              control={control}
              name="comments"
              render={({ field: { onChange, value, name } }) => (
                <TextInput value={value} handleChange={onChange} name={name} type="text" />
              )}
            />
          </Form.Group>
        </div>
      </div>
    </div>
  );
}

NewAdvertiserForm.propTypes = {
  show: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};
