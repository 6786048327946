import React, { Component } from "react";

export default class ColorPicker extends Component {
  valueChanged(e) {
    this.props.updateForm(this.props.formPropertyName, e.target.value, this.props.currentForm);
  }

  render() {
    const { value, description, formWidth } = this.props;
    return (
      <div className="text-form-widget" style={{ flexBasis: formWidth }}>
        <div className="title">{description} </div>
        <input type="color" value={value} onChange={this.valueChanged.bind(this)} />
      </div>
    );
  }
}
