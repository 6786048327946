import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { withTranslation } from "react-i18next";

class TargetingWeatherSelector extends Component {
  handleWeatherClick = (clickedItem) => {
    const weather = _.cloneDeep(this.props.weather);

    const item = _.find(weather, (i) => i.value === clickedItem.value);
    item.present = !item.present;

    this.props.onWeatherChange(weather);
  };

  renderWeather = (weather) => {
    const { t } = this.props;
    const variant = weather.present ? "primary" : "outline-primary";

    return (
      <Col sm={12} md={2} key={weather.value} className="me-1 text-center">
        <Button
          variant={variant}
          onClick={this.handleWeatherClick.bind(this, weather)}
          className={`wi ${weather.cssValue}`}
          style={{ display: "block", width: "100%" }}
        />
        <small>{t(weather.description)}</small>
      </Col>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className="ad-step-form">
        <div className="form-group">
          <div className="subtitle">
            <h6>{t("keywords.weatherRelated.name")}</h6>
          </div>
          <Row className="g-0">{this.props.weather.map(this.renderWeather)}</Row>
        </div>
      </div>
    );
  }
}

TargetingWeatherSelector.propTypes = {
  weather: PropTypes.arrayOf(PropTypes.any).isRequired,
  onWeatherChange: PropTypes.func.isRequired,
};

export default withTranslation()(TargetingWeatherSelector);
