import React from "react";
import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { enCA, frCA } from "date-fns/locale";
import { useTranslation } from "react-i18next";

import ReactDatePickerInput from "../views/reactDatePickerInput";
import { dateToString, parseLocaleDate } from "../../../../utilities/dateStringHelper";

registerLocale("fr-CA", frCA);
registerLocale("en-CA", enCA);

export default function SingleDatePicker({
  date,
  minDate,
  maxDate,
  label,
  onChange,
  readOnly,
  titleStyle,
}) {
  const { t } = useTranslation();
  const calendarMinDate = minDate ? parseLocaleDate(minDate) : null;
  const calendarMaxDate = maxDate ? parseLocaleDate(maxDate) : null;
  const selected = date ? parseLocaleDate(date) : null;

  function handleChange(newDate) {
    onChange(dateToString(newDate));
  }

  return (
    <DatePicker
      locale={t("language.locale")}
      readOnly={readOnly}
      customInput={
        <ReactDatePickerInput titleStyle={titleStyle} label={label} readOnly={readOnly} />
      }
      selected={selected}
      onChange={handleChange}
      minDate={calendarMinDate}
      maxDate={calendarMaxDate}
      dateFormat={t("language.dateFormat")}
    />
  );
}

SingleDatePicker.defaultProps = {
  minDate: null,
  maxDate: null,
  label: "",
};

SingleDatePicker.propTypes = {
  date: PropTypes.string.isRequired,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
