import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import { useFormContext, useFormState } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import StatusPill from "../Global/General/StatusPill";
import FormStatus from "../../utilities/FormStatus";
import TopBar from "./ProposalTopBar/TopBar";
import { StatusTypes } from "../../utilities/StatusTypes";
import NavigationButton from "./ProposalTopBar/NavigationButton";

export default function ProposalTopBar({
  formStatus,
  onConvert,
  onSubmit,
  userCanEditProposal,
  userCanEditCampaignGroups,
  associatedCampaignGroupID,
}) {
  const { t, i18n } = useTranslation();
  const { isDirty } = useFormState();
  const { getValues } = useFormContext();
  const proposalStatus = getValues("status");
  const isConvertible = getValues("isConvertible");
  const [showSubmitModal, setShowSubmitModal] = useState(false);

  function formLoading() {
    [FormStatus.SAVING, FormStatus.LOADING, FormStatus.SUBMITTING, FormStatus.CONVERTING].includes(
      formStatus
    );
  }

  function submitDisabled() {
    return !isConvertible || isDirty || formLoading();
  }

  function handleSubmitOnClick() {
    setShowSubmitModal(true);
  }

  const submitButton = (
    <Button
      disabled={submitDisabled()}
      variant="primary"
      key="proposal-submit"
      onClick={handleSubmitOnClick}
    >
      {formStatus === FormStatus.SUBMITTING
        ? `${t("proposalTerms.submitting")}...`
        : t("proposalTerms.submitForScheduling")}
    </Button>
  );

  function convertDisabled() {
    return submitDisabled() || !userCanEditProposal;
  }

  const convertButton = (
    <Button
      disabled={convertDisabled()}
      variant="primary"
      key="proposal-complete"
      onClick={onConvert}
    >
      {formStatus === FormStatus.SUBMITTING
        ? `${t("proposalTerms.converting")}...`
        : t("proposalTerms.convert")}
    </Button>
  );

  function saveDisabled() {
    return formLoading() || !userCanEditProposal;
  }

  const saveButton = (
    <Button disabled={saveDisabled()} type="submit" variant="success" key="proposal-save">
      {formStatus === FormStatus.SAVING
        ? `${t("keywords.buttonActions.saving")}...`
        : t("keywords.buttonActions.save")}
    </Button>
  );

  function getCampaignGroupPath() {
    return `/campaign_group/edit/${associatedCampaignGroupID}`;
  }

  const campaignGroupNavigationButton = (
    <NavigationButton
      key={uuidv4()}
      path={getCampaignGroupPath()}
      buttonText={`${t("keywords.campaignRelated.goToCampaign", {
        id: associatedCampaignGroupID,
      })}`}
    />
  );

  function getStatusDetails() {
    if (!proposalStatus || !(proposalStatus in StatusTypes)) {
      return { cssValue: "disabled", description: "" };
    }

    return StatusTypes[proposalStatus];
  }

  function renderStatusPill() {
    return <StatusPill status={getStatusDetails()} />;
  }

  const actionButtons = [];
  if (proposalStatus === "draft") {
    actionButtons.push(submitButton);
  }
  if (proposalStatus === "submitted" && userCanEditProposal) {
    actionButtons.push(convertButton);
  }
  if (userCanEditProposal) {
    actionButtons.push(saveButton);
  }
  if (associatedCampaignGroupID && userCanEditCampaignGroups) {
    actionButtons.push(campaignGroupNavigationButton);
  }

  function handleCancelSubmit() {
    setShowSubmitModal(false);
  }

  function handleConfirmSubmit() {
    setShowSubmitModal(false);
    onSubmit();
  }

  // TODO: we need an audit and consolidation of our modals in the application
  // Replace this bootstrap modal with the agreed upon component when it gets created
  const submitModal = (
    <Modal show={showSubmitModal} onHide={handleCancelSubmit} size="md">
      <Modal.Header style={{ justifyContent: "center" }}>
        <Modal.Title>
          {i18n.format(t("proposalTerms.submitForScheduling"), "uppercase")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            textAlign: "center",
            padding: "1rem 4em",
          }}
        >
          <p>{t("proposalTerms.submitConfirmDescription")}</p>
          <p style={{ fontWeight: "bold", paddingTop: "2rem" }}>
            {i18n.format(t("proposalTerms.operationCannotBeUndoneWarning"), "uppercase")}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <Button variant="outline-secondary" onClick={handleCancelSubmit}>
          {t("navigationTerms.goBack")}
        </Button>
        <Button variant="primary" onClick={handleConfirmSubmit}>
          {t("proposalTerms.submit")}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      <TopBar
        title={t("proposalTerms.campaignGroupProposal")}
        navigation={{
          path: "/campaign-proposals",
          label: t("proposalTerms.campaignGroupProposals"),
        }}
        notification={renderStatusPill()}
        actions={actionButtons}
      />
      {submitModal}
    </>
  );
}

ProposalTopBar.propTypes = {
  formStatus: PropTypes.oneOf(Object.values(FormStatus)).isRequired,
  onConvert: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userCanEditProposal: PropTypes.bool.isRequired,
  userCanEditCampaignGroups: PropTypes.bool.isRequired,
  associatedCampaignGroupID: PropTypes.string,
};

ProposalTopBar.defaultProps = {
  associatedCampaignGroupID: "",
};
