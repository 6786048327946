import React, { Component } from "react";
import PropTypes from "prop-types";
import BasicTable from "../../Global/General/BasicTable";

class UsersSection extends Component {
  columns = () => {
    return [
      {
        Header: "Name",
        accessor: (row) => `${row.first_name} ${row.last_name}`,
      },
      {
        Header: "Email",
        accessor: "email",
      },
    ];
  };

  render() {
    return (
      <React.Fragment>
        <div className="form-section__title pt-4">Associated Users</div>
        <BasicTable
          tableHeader={() => null}
          columns={this.columns()}
          data={this.props.users}
          tableAttributes={{
            size: "sm",
            striped: true,
            bordered: true,
          }}
        />
      </React.Fragment>
    );
  }
}

UsersSection.propTypes = {
  users: PropTypes.array,
};

export default UsersSection;
