import React, { Component } from "react";
import PropTypes from "prop-types";

class CampaignReportingRenderedReportPage extends Component {
  render() {
    const { report } = this.props;

    if (report) {
      const contentUrl = report.getLookerUrl();

      if (contentUrl) {
        return (
          <iframe
            src={contentUrl}
            title="Campaign Report"
            style={{ width: "100%", height: "88vh" }}
          />
        );
      }
    }
    return <article>No report found</article>;
  }
}

CampaignReportingRenderedReportPage.propTypes = {
  report: PropTypes.object.isRequired,
};

export default CampaignReportingRenderedReportPage;
