import { add } from "date-fns";
import Elev8ApiService from "../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../lib/services/Elev8Api/lib";
import { dateToString, apiDateAsFormData, formDateAsApiData } from "./dateStringHelper";

const CampaignSerializer = {
  deserializeForm(campaignForm) {
    return deserializingForm(campaignForm);
  },
  serializeForm(campaignForm) {
    return serializingForm(campaignForm);
  },
  serializeUpdates(campaignForm) {
    return serializingUpdates(campaignForm);
  },
};

export default CampaignSerializer;

function deserializingForm(campaignForm) {
  const campaignAttributes = campaignForm.attributes;

  const deserializedForm = {
    owner_id: parseInt(campaignForm.relationships.owner.id, 10),
    id: campaignAttributes.id,
    start_at: apiDateAsFormData(campaignAttributes.start_at),
    end_at: apiDateAsFormData(campaignAttributes.end_at),
    billable_start: apiDateAsFormData(campaignAttributes.billable_start),
    billable_end: apiDateAsFormData(campaignAttributes.billable_end),
    min: apiDateAsFormData(campaignAttributes.start_at),
    max: dateToString(add(new Date(), { years: 5 })),
    name: campaignAttributes.name,
    ad_duration: campaignAttributes.ad_duration,
    comments: campaignAttributes.comments,
    advertiser_id: parseInt(campaignForm.relationships.advertiser.id, 10),
    billingCycle: campaignAttributes.billingCycle,
    monetized: campaignAttributes.monetized,
    standard_price_cents: campaignAttributes.standard_price_cents,
    actual_value_cents: campaignAttributes.actual_value_cents,
    property_ids: campaignForm.relationships.properties.map((p) => parseInt(p.id, 10)),
    status: campaignAttributes.status,
    videoEnabled: campaignAttributes.video_enabled,
    fullscreen: campaignAttributes.fullscreen,
    mode: "active",
    report_code: campaignAttributes.report_code,
    campaign_type: campaignAttributes.campaign_type,
    bookedImpressions: campaignAttributes.booked_impressions,
    campaignGroupId: parseInt(campaignForm.relationships.campaign_group.id, 10),
  };

  return deserializedForm;
}

function serializeProperty(propertyIds) {
  if (propertyIds === undefined) {
    return [];
  }

  return propertyIds.map((propertyId) => ({
    id: propertyId.toString(),
    type: ApiTypes.property.type,
  }));
}

function serializingForm(campaignForm) {
  const propertyFormat = serializeProperty(campaignForm.property_ids);

  const attributes = {
    id: campaignForm.id,
    name: campaignForm.name,
    status: campaignForm.status,
    start_at: formDateAsApiData(campaignForm.start_at, "startOfDay"),
    end_at: formDateAsApiData(campaignForm.end_at, "endOfDay"),
    standard_price_cents: campaignForm.standard_price_cents,
    actual_value_cents: campaignForm.actual_value_cents,
    currency: campaignForm.price_currency,
    comments: campaignForm.comments,
    ad_duration: campaignForm.ad_duration,
    billable_start: formDateAsApiData(campaignForm.billable_start, "startOfDay"),
    billable_end: formDateAsApiData(campaignForm.billable_end, "endOfDay"),
    monetized: campaignForm.monetized,
    video_enabled: campaignForm.videoEnabled,
    fullscreen: campaignForm.fullscreen,
    campaign_type: campaignForm.campaign_type,
    booked_impressions: campaignForm.bookedImpressions,
  };

  const relationships = {
    properties: { data: propertyFormat },
    owner: Elev8ApiService.setToJsonApiFormat(ApiTypes.user.type, campaignForm.owner_id),
    advertiser: Elev8ApiService.setToJsonApiFormat(
      ApiTypes.advertiser.type,
      campaignForm.advertiser_id
    ),
    campaign_group: Elev8ApiService.setToJsonApiFormat(
      ApiTypes.campaign_group.type,
      campaignForm.campaignGroupId
    ),
  };

  return Elev8ApiService.setToJsonApiFormat(
    ApiTypes.campaign.type,
    campaignForm.id,
    attributes,
    relationships
  );
}

function serializingUpdates(campaignUpdates) {
  const updateData = {
    ...campaignUpdates,
    start_at: campaignUpdates.start_at && formDateAsApiData(campaignUpdates.start_at, "startOfDay"),
    end_at: campaignUpdates.end_at && formDateAsApiData(campaignUpdates.end_at, "endOfDay"),
    actual_value_cents: campaignUpdates.actual_value_dollars
      ? campaignUpdates.actual_value_dollars * 100
      : undefined,
    standard_price_cents: campaignUpdates.standard_price_dollars
      ? campaignUpdates.standard_price_dollars * 100
      : undefined,
  };
  // Allow override attribute to be removed entirely
  if (updateData.actual_value_dollars === "") {
    updateData.actual_value_cents = null;
  }
  // Can't be removed; must be a number
  if (updateData.standard_price_dollars === "") {
    updateData.standard_price_cents = 0;
  }
  // Temporary duplication until the billable_start/end attributes are removed
  if (updateData.start_at) {
    updateData.billable_start = updateData.start_at;
  }
  if (updateData.end_at) {
    updateData.billable_end = updateData.end_at;
  }

  let relationships = {};

  if (updateData.owner_id) {
    relationships.owner = Elev8ApiService.setToJsonApiFormat(
      ApiTypes.user.type,
      updateData.owner_id
    );
  }
  if (updateData.advertiser_id) {
    relationships.advertiser = Elev8ApiService.setToJsonApiFormat(
      ApiTypes.advertiser.type,
      campaignForm.advertiser_id
    );
  }
  if (updateData.campaignGroupId) {
    relationships.campaign_group = Elev8ApiService.setToJsonApiFormat(
      ApiTypes.campaign_group.type,
      campaignForm.campaignGroupId
    );
  }
  if (updateData.property_ids) {
    const serializedProperties = serializeProperty(updateData.property_ids);
    relationships.properties = { data: serializedProperties };
  }
  return Elev8ApiService.setToJsonApiFormat(
    ApiTypes.campaign.type,
    updateData.id,
    updateData,
    relationships
  );
}
