import React from "react";
import Form from "react-bootstrap/Form";

export function Filter({ column }) {
  return <div style={{ marginTop: 5 }}>{column.canFilter && column.render("Filter")}</div>;
}

export function DefaultColumnFilter({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) {
  return (
    <Form.Control
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`search (${length}) ...`}
    />
  );
}

export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const options = React.useMemo(() => {
    const preFilteredOptions = new Set();
    preFilteredRows.forEach((row) => {
      preFilteredOptions.add(row.values[id]);
    });
    return [...preFilteredOptions.values()];
  }, [id, preFilteredRows]);

  return (
    <Form.Control
      id="custom-select"
      as="select"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Form.Control>
  );
}
