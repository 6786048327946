/*
Account Settings Form
*/

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { withTranslation } from "react-i18next";
import { updatePersonalInfo, updatePersonalPassword } from "../services/actions/personalActions";
import { fetchUserForm, updateUserForm } from "../../General/services/actions/generalUserActions";
import { LANGUAGE_OPTIONS } from "../../../../locales/reference";

// Templates
import BasicLayout from "../../../Global/Layouts/basic_layout";
import TextBox from "../../../Global/Widgets/views/textBox";
import Header from "../../../Interface/MainList/header";
import DropDownWidget from "../../../Global/Widgets/views/dropDown";

class AccountSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formColor: "rgb(46, 87, 234)",
      password: { current_password: "", password: "", confirmPassword: "" },
      notSamePassword: "",
      validEmail: "",
    };
    this.updateAction = this.updateAction.bind(this);
    this.updatePasswordForm = this.updatePasswordForm.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.resetUserInfo = this.resetUserInfo.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.fetchUser();
  }

  fetchUser() {
    this.props.fetchUserForm(this.props.loggedInUser.id);
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  updateAction() {
    const { t } = this.props;

    if (
      this.props.userForm.first_name.trim() !== "" &&
      this.props.userForm.email.trim() !== "" &&
      this.props.userForm.first_name.trim() !== ""
    ) {
      if (this.validateEmail(this.props.userForm.email)) {
        this.props.updatePersonalInfo(this.props.userForm, this.props.loggedInUser.id);
        this.setState({ validEmail: "" });
      } else {
        this.setState({ validEmail: t("keywords.userRelated.email.invalid") });
      }
    }
  }

  updatePasswordForm(type, newData, oldData) {
    const updatedForm = _.extend({}, oldData);
    updatedForm[type] = newData;
    this.setState({ password: updatedForm });
  }

  updatePassword() {
    const { t } = this.props;

    if (this.state.password.password !== this.state.password.confirmPassword) {
      this.setState({
        notSamePassword: `** ${t("keywords.appRelated.password.noMatch")}`,
      });
    } else if (this.state.password.password.length < 8) {
      this.setState({
        notSamePassword: `** ${t("keywords.appRelated.password.requirements")}`,
      });
    } else {
      this.setState({
        notSamePassword: "",
        password: { current_password: "", password: "", confirmPassword: "" },
      });
      this.props.updatePersonalPassword(this.state.password, this.props.loggedInUser.id);
    }
  }

  resetUserInfo() {
    this.props.fetchUserForm(this.props.loggedInUser.id);
  }

  renderUserInfoForm = () => {
    const { t } = this.props;
    const options = LANGUAGE_OPTIONS.map((option) => ({
      value: option.value,
      label: t(option.label),
    }));
    return (
      <>
        <h4> {t("accountSettings.infoSection.header")}</h4>
        <div className="header-column">
          <div className="column-header">{t("accountSettings.infoSection.contactInformation")}</div>
        </div>
        <TextBox
          textValue={this.props.userForm.first_name}
          updateForm={this.props.updateUserForm}
          formPropertyName="first_name"
          description={t("keywords.userRelated.firstName.name")}
          placeHolder={t("keywords.userRelated.firstName.placeholder")}
          inputType="text"
          formWidth={350}
          formColor={this.state.formColor}
          formProps={this.props.userForm}
          required
          maxLength={50}
          hideWordCount
        />

        <div className="small-block" />

        <TextBox
          textValue={this.props.userForm.last_name}
          updateForm={this.props.updateUserForm}
          formPropertyName="last_name"
          description={t("keywords.userRelated.lastName.name")}
          placeHolder={t("keywords.userRelated.lastName.placeholder")}
          inputType="text"
          formWidth={350}
          formColor={this.state.formColor}
          formProps={this.props.userForm}
          required
          maxLength={50}
          hideWordCount
        />

        <div className="small-block" />

        <TextBox
          textValue={this.props.userForm.phone}
          updateForm={this.props.updateUserForm}
          formPropertyName="phone"
          description={t("keywords.userRelated.phone.name")}
          placeHolder={t("keywords.userRelated.phone.placeholder")}
          inputType="text"
          formWidth={350}
          formColor={this.state.formColor}
          formProps={this.props.userForm}
          required
          maxLength={50}
          hideWordCount
        />
        <TextBox
          textValue={this.props.userForm.email}
          updateForm={this.props.updateUserForm}
          formPropertyName="email"
          description={t("keywords.userRelated.email.name")}
          placeHolder={t("keywords.userRelated.email.placeholder")}
          inputType="text"
          formWidth={350}
          formColor={this.state.formColor}
          formProps={this.props.userForm}
          required
          maxLength={50}
          hideWordCount
        />

        <div style={{ padding: "10px" }} />

        <div className="header-column">
          <div className="column-header">{t("accountSettings.infoSection.generalInformation")}</div>
        </div>

        <TextBox
          textValue={this.props.userForm.company}
          updateForm={this.props.updateUserForm}
          formPropertyName="company"
          description={t("keywords.userRelated.company.name")}
          placeHolder={t("keywords.userRelated.company.placeholder")}
          inputType="text"
          formColor={this.state.formColor}
          formProps={this.props.userForm}
          formWidth={570}
          required={false}
          maxLength={50}
          hideWordCount
        />
        <TextBox
          textValue={this.props.userForm.address_line_1}
          updateForm={this.props.updateUserForm}
          formPropertyName="address_line_1"
          description={t("keywords.locationRelated.addressLine1.name")}
          placeHolder={t("keywords.locationRelated.addressLine1.placeholder")}
          inputType="text"
          formColor={this.state.formColor}
          formProps={this.props.userForm}
          formWidth={570}
          required={false}
          maxLength={50}
          hideWordCount
        />

        <TextBox
          textValue={this.props.userForm.address_line_2}
          updateForm={this.props.updateUserForm}
          formPropertyName="address_line_2"
          description={t("keywords.locationRelated.addressLine2.name")}
          placeHolder={t("keywords.locationRelated.addressLine1.placeholder")}
          inputType="text"
          formColor={this.state.formColor}
          formProps={this.props.userForm}
          formWidth={570}
          required={false}
          maxLength={50}
          hideWordCount
        />

        <TextBox
          textValue={this.props.userForm.address_postal_code}
          updateForm={this.props.updateUserForm}
          formPropertyName="address_postal_code"
          description={t("keywords.locationRelated.postalOrZipCode.name")}
          placeHolder={t("keywords.locationRelated.postalOrZipCode.placeholder")}
          inputType="text"
          formColor={this.state.formColor}
          formProps={this.props.userForm}
          formWidth={570}
          required={false}
          maxLength={50}
          hideWordCount
        />

        <TextBox
          textValue={this.props.userForm.address_city}
          updateForm={this.props.updateUserForm}
          formPropertyName="address_city"
          description={t("keywords.locationRelated.city.name")}
          placeHolder={t("keywords.locationRelated.city.placeholder")}
          inputType="text"
          formColor={this.state.formColor}
          formProps={this.props.userForm}
          formWidth={570}
          required={false}
          maxLength={50}
          hideWordCount
        />

        <TextBox
          textValue={this.props.userForm.address_province}
          updateForm={this.props.updateUserForm}
          formPropertyName="address_province"
          description={t("keywords.locationRelated.provinceOrState.name")}
          placeHolder={t("keywords.locationRelated.provinceOrState.placeholder")}
          inputType="text"
          formColor={this.state.formColor}
          formProps={this.props.userForm}
          formWidth={570}
          required={false}
          maxLength={50}
          hideWordCount
        />

        <TextBox
          textValue={this.props.userForm.address_country}
          updateForm={this.props.updateUserForm}
          formPropertyName="address_country"
          description={t("keywords.locationRelated.country.name")}
          placeHolder={t("keywords.locationRelated.country.placeholder")}
          inputType="text"
          formColor={this.state.formColor}
          formProps={this.props.userForm}
          formWidth={570}
          required={false}
          maxLength={50}
          hideWordCount
        />

        <DropDownWidget
          formValue={this.props.userForm.locale}
          formPropertyName="locale"
          currentForm={this.props.userForm}
          formWidth={570}
          updateForm={this.props.updateUserForm}
          multiSelect={false}
          title={t("language.siteLanguage")}
          options={options}
          formType="Custom"
          isClearable={false}
          required
        />

        <div className="small-block" />
        <div className="error"> {this.state.validEmail} </div>
        <div className="error-content" />
        <Button variant="primary" size="lg" block onClick={this.updateAction} className="my-3">
          {t("accountSettings.infoSection.updateButton")}
        </Button>
      </>
    );
  };

  renderPasswordForm = () => {
    const { t } = this.props;

    return (
      <>
        <hr className="d-sm-none" />
        <h4> {t("accountSettings.passwordSection.header")}</h4>
        <div style={{ padding: "11px" }} />
        <TextBox
          textValue={this.state.password.current_password}
          updateForm={this.updatePasswordForm}
          formPropertyName="current_password"
          description={t("keywords.appRelated.password.current")}
          placeHolder={t("keywords.appRelated.password.current")}
          inputType="password"
          formWidth={350}
          formColor={this.state.formColor}
          formProps={this.state.password}
          required={false}
          maxLength={256}
          hideWordCount
        />
        <div className="small-block" />
        <TextBox
          textValue={this.state.password.password}
          updateForm={this.updatePasswordForm}
          formPropertyName="password"
          description={t("keywords.appRelated.password.new")}
          placeHolder={t("keywords.appRelated.password.new")}
          inputType="password"
          formWidth={350}
          formColor={this.state.formColor}
          formProps={this.state.password}
          required={false}
          maxLength={256}
          hideWordCount
        />
        <div className="small-block" />
        <TextBox
          textValue={this.state.password.confirmPassword}
          updateForm={this.updatePasswordForm}
          formPropertyName="confirmPassword"
          description={t("keywords.appRelated.password.retype")}
          placeHolder={t("keywords.appRelated.password.retype")}
          inputType="password"
          formWidth={350}
          formColor={this.state.formColor}
          formProps={this.state.password}
          required={false}
          maxLength={256}
          hideWordCount
        />
        <div className="small-block" />
        <div className="error-content">
          <div className="error"> {this.state.notSamePassword}</div>
        </div>
        <Button variant="primary" size="lg" block onClick={this.updatePassword} className="my-3">
          {t("keywords.appRelated.password.update")}
        </Button>
      </>
    );
  };

  render() {
    const { t } = this.props;
    if (!this.props.userForm) {
      return null;
    }
    return (
      <BasicLayout>
        <BasicLayout.MainContent>
          <Header title={t("accountSettings.barHeader")} />
          <Container fluid>
            <Row style={{ minHeight: "100%" }}>
              <Col
                xs="12"
                lg="7"
                xl="6"
                className="py-sm-4 px-sm-5"
                style={{ backgroundColor: "white" }}
              >
                {this.renderUserInfoForm()}
              </Col>
              <Col
                xs="12"
                lg="5"
                xl="6"
                className="py-sm-4 px-sm-5"
                style={{ backgroundColor: "white" }}
              >
                {this.renderPasswordForm()}
              </Col>
            </Row>
          </Container>
        </BasicLayout.MainContent>
      </BasicLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    userForm: state.user.general.form,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePersonalPassword,
      updateUserForm,
      fetchUserForm,
      updatePersonalInfo,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AccountSettings));
