import React from "react";
import { useTranslation } from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import SingleDatePicker from "../../../Global/Widgets/updatedWidgets/singleDatePicker";
import TargetingSelector from "../../../Global/Widgets/views/TargetingSelector/targetingSelector";

export default function ScreenSchedulingSettings() {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const startDate = useWatch({
    control,
    name: "start_date",
  });

  const endDate = useWatch({
    control,
    name: "end_date",
  });

  const currentTargeting = useWatch({
    control,
    name: "targeting",
  });

  function renderDateSelection() {
    return (
      <Row g={0}>
        <Col sm={12} md={6} className="pr-md-2">
          <Controller
            control={control}
            name="start_date"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <SingleDatePicker
                label={t("keywords.dateRelated.start")}
                onChange={onChange}
                maxDate={endDate}
                date={value}
                titleStyle="h6"
              />
            )}
          />
        </Col>
        <Col sm={12} md={6}>
          <Controller
            control={control}
            name="end_date"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <SingleDatePicker
                label={t("keywords.dateRelated.end")}
                onChange={onChange}
                minDate={startDate}
                date={value}
                titleStyle="h6"
              />
            )}
          />
        </Col>
      </Row>
    );
  }

  function renderTargetSelection() {
    if (!currentTargeting) return null;
    return (
      <div className="mt-2">
        <Controller
          control={control}
          name="targeting"
          rules={{
            required: true,
            validate: {
              periodsOfDay: (targeting) =>
                targeting.periodsOfDay.find((time) => time.present === true) ||
                `*** ${t("keywords.dateRelated.timeEachDayMissingError")}`,
              daysOfWeek: (targeting) =>
                targeting.daysOfWeek.find((day) => day.present === true) ||
                `*** ${t("keywords.dateRelated.daysOfWeek.missingError")}`,
            },
          }}
          render={({ field: { onChange } }) => (
            <TargetingSelector
              targetingData={currentTargeting}
              onTargetingChange={onChange}
              showDemographic={false}
              showWeather={false}
              errors={errors.targeting}
            />
          )}
        />
      </div>
    );
  }

  return (
    <>
      <hr className="my-5" />
      <h4 className="mb-3 "> {t("noticeTerms.scheduleNotice")}</h4>
      <ErrorMessage
        errors={errors}
        name="targeting"
        message="Error"
        render={({ message }) => <b className="error">{message}</b>}
      />
      {renderDateSelection()}
      {renderTargetSelection()}
    </>
  );
}
