import React, { Component } from "react";
import Container from "react-bootstrap/Container";

class ReportingContent extends Component {
  render() {
    const { children } = this.props;

    return (
      <Container fluid className="reportingContent__content py-4">
        {children}
      </Container>
    );
  }
}

export default ReportingContent;
