import { JsonApiObject } from "../index";
import { StatusTypes } from "../../../../../utilities/StatusTypes";
import CampaignTypes from "../../../../../utilities/CampaignTypes";

// eslint-disable-next-line import/prefer-default-export
export class CampaignGroup extends JsonApiObject {
  getStatus() {
    const campaigns = this.campaigns();
    const statuses = campaigns.map((campaign) => {
      return campaign.getStatus();
    });
    if (statuses.includes(StatusTypes.active)) {
      return StatusTypes.active;
    }
    if (statuses.includes(StatusTypes.pending)) {
      return StatusTypes.pending;
    }
    if (statuses.includes(StatusTypes.complete)) {
      return StatusTypes.complete;
    }
    return StatusTypes.draft;
  }

  getCampaigns() {
    return this.campaigns();
  }

  getAdvertiserName() {
    if (this.advertiser()) {
      return this.advertiser().attributes.name;
    }
    return "INVALID/MISSING";
  }

  getOwnerName() {
    if (this.owner()) {
      return `${this.owner().attributes.first_name} ${this.owner().attributes.last_name}`;
    }
    return "INVALID/MISSING";
  }

  getCampaignTypeName() {
    return CampaignTypes.campaignTypeLocale(this.attributes.campaign_type);
  }
}
