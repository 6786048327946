import Elev8ApiService from "../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../lib/services/Elev8Api/lib";

class CampaignDataHelper {
  static fetchCampaign(id) {
    const sparseFieldSets = {
      include: ["ads", "ads.media", "campaign_group", "campaign_locations", "properties"],
      fields: {
        campaign: [
          "id",
          "actual_value_cents",
          "ad_duration",
          "billable_start",
          "billable_end",
          "booked_impressions",
          "campaign_type",
          "comments",
          "end_at",
          "fullscreen",
          "name",
          "rsm_flight_id",
          "standard_price_cents",
          "start_at",
          "status",
          "video_enabled",
          "ads",
          "campaign_group",
          "campaign_locations",
          "properties",
        ],
        ad: [
          "id",
          "title",
          "targeting",
          "media",
          "start_date",
          "end_date",
          "published",
          "screen_count",
        ],
        media: ["id", "url", "type"],
        campaign_location: ["id", "property_id", "loop_spots"],
        campaign_group: ["id"],
        property: ["id"],
        network: ["id", "name"],
      },
    };
    return new Promise((resolve, reject) => {
      Elev8ApiService.read(ApiTypes.campaign, id, sparseFieldSets)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static fetchCampaignAds(id) {
    const sparseFieldSets = {
      include: ["ads", "ads.media"],
      fields: {
        campaign: ["id", "ads"],
        ad: ["id", "title", "targeting", "media"],
        media: ["id", "url", "type"],
      },
    };
    return new Promise((resolve, reject) => {
      Elev8ApiService.read(ApiTypes.campaign, id, sparseFieldSets)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static fetchCampaignProperties(id) {
    const sparseFieldSets = {
      include: ["properties"],
      fields: {
        property: ["id"],
      },
    };
    return new Promise((resolve, reject) => {
      Elev8ApiService.read(ApiTypes.campaign, id, sparseFieldSets)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default CampaignDataHelper;
