import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import { ApiTypes } from "../../../../../lib/services/Elev8Api/lib";
import Elev8ApiService from "../../../../../lib/services/Elev8Api/Elev8ApiService";
import { idArrayFromString } from "../../../../../utilities/stringHelpers";

class PropertySelectionById extends Component {
  updatePropertyIds = (event) => {
    this.props.updateBulkIdSelection(event.target.value);
  };

  handleImportFromSpotzi = () => {
    const filters = {
      "filter[email]": this.props.userEmail,
    };
    Elev8ApiService.index(ApiTypes.spotzi_location, null, filters).then((response) => {
      let inputtedIds = [];
      if (response.length > 0) {
        const [lastSpotziLocationExport] = response.slice(-1);
        inputtedIds = JSON.parse(lastSpotziLocationExport.attributes.property_ids);
      }
      this.props.updateBulkIdSelection(inputtedIds.join(","));
    });
  };

  getInvalidSelectedIds = () => {
    const inputtedIds = idArrayFromString(this.props.rawPropertiesByBulkIds);
    const validSelectedIds = this.props.validIds.map(String);
    const invalidSelectedIds = inputtedIds.filter((id) => !validSelectedIds.includes(id));
    return [...new Set(invalidSelectedIds)];
  };

  renderClipBoardInformation = () => {
    const validSelectedIds = this.props.validIds;
    const invalidSelectedIds = this.getInvalidSelectedIds();
    return (
      <>
        <div>
          <b>
            Format: Copy and paste column directly from spreadsheet or use separated values, ie.
            5,10,12
          </b>
          <p>
            <b>NOTE: This will replace the existing list of properties. </b>
          </p>
        </div>
        {validSelectedIds.length === 0 ? null : (
          <div className="pt-2 text-break">
            <b>Currently selected IDs:</b> {this.props.validIds.join(",")}{" "}
          </div>
        )}
        {invalidSelectedIds.length === 0 ? null : (
          <div className="error text-break mt-2">
            <b>Invalid IDs (will not be saved): </b>
            {invalidSelectedIds.join(",")}
          </div>
        )}
      </>
    );
  };

  render() {
    return (
      <Row g={0}>
        <Col sm={12}>
          <textarea
            className="p-2 w-100"
            value={this.props.rawPropertiesByBulkIds}
            onChange={this.updatePropertyIds}
            placeholder="Enter property IDs here"
          />
          <Button className="mb-1" variant="outline-success" onClick={this.handleImportFromSpotzi}>
            <i className="fa fa-bullseye" /> Import from Spotzi
          </Button>
        </Col>
        <Col sm={12}>{this.renderClipBoardInformation()}</Col>
      </Row>
    );
  }
}

PropertySelectionById.propTypes = {
  updateBulkIdSelection: PropTypes.func.isRequired,
  availableProperties: PropTypes.array,
  validIds: PropTypes.array,
  rawPropertiesByBulkIds: PropTypes.string,
  userEmail: PropTypes.string.isRequired,
};

export default withTranslation()(PropertySelectionById);
