/** *************************************************************
PERSONAL ACCOUNT INFORMATION
*************************************************************** */

import { SET_USER_FORM, SET_PERSONAL_INFO, AUTH_USER } from "../../../../../App/actionTypes";
import { logoutUser } from "../../../../Authentication/actions/sessionActions";
import i18n from "../../../../../i18n";
import {
  addFlashMessage,
  currentlySaving,
} from "../../../../Interface/General/services/actions/applicationStateActions";

import Elev8ApiService from "../../../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../../../lib/services/Elev8Api/lib";

/** *************************
REDUCER ACTIONS
************************* */

export function setUserForm(newForm) {
  return {
    type: SET_USER_FORM,
    payload: newForm,
  };
}

export function setPersonalForm(newForm) {
  return {
    type: SET_PERSONAL_INFO,
    payload: newForm,
  };
}

/** *************************
PERSONAL INFO
************************* */

export function fetchPersonalForm() {
  return function (dispatch) {
    Elev8ApiService.server()
      .get("/token_user")
      .then((response) => {
        if (response.status === 200) {
          const userData = response.data.data.attributes;
          i18n.changeLanguage(userData.locale);
          dispatch(setPersonalForm(userData));
          dispatch({ type: AUTH_USER });
        } else {
          dispatch(logoutUser());
        }
      })
      .catch((e) => {
        dispatch(logoutUser());
      });
  };
}

// reset personal info
export function settingPersonalInfo() {
  return function (dispatch) {
    Elev8ApiService.server()
      .get("/token_user")
      .then((response) => {
        if (response.status === 200) {
          const userData = response.data.data.attributes;
          i18n.changeLanguage(userData.locale);
          dispatch(setPersonalForm(userData));
          dispatch({ type: AUTH_USER });
        } else {
          dispatch(logoutUser());
        }
      });
  };
}

export function updatePersonalInfo(userForm, id) {
  i18n.changeLanguage(userForm.locale);
  const jsonApiFormattedForm = Elev8ApiService.setToJsonApiFormat(
    ApiTypes.user.type,
    userForm.id,
    userForm
  );

  return function (dispatch) {
    saveUserForm(() => {
      return postPersonalInfo(jsonApiFormattedForm, id);
    }, dispatch).then(() => {
      dispatch(fetchPersonalForm());
    });
  };
}

function postPersonalInfo(userForm, userId) {
  return Elev8ApiService.server().put(`/users/${userId}`, userForm);
}

export function updatePersonalPassword(password, userId) {
  const jsonApiFormattedForm = Elev8ApiService.setToJsonApiFormat("password", userId, password);

  return function (dispatch) {
    saveUserForm(() => {
      return postPassword(jsonApiFormattedForm, userId);
    }, dispatch);
  };
}

function postPassword(password, userId) {
  return Elev8ApiService.server()
    .put(`/users/${userId}`, password)
    .then((response) => {
      if (!response.status === 200) {
        const error = new Error();
        error.name = "invalidPassword";
        throw error;
      }
    });
}

function saveUserForm(handleSave, dispatch) {
  currentlySaving(true)(dispatch);

  return handleSave()
    .then(() => {
      addFlashMessage("success", "keywords.appRelated.updateSuccess")(dispatch);
    })
    .catch((error) => {
      if (error == "invalidPassword") {
        addFlashMessage("danger", "keywords.appRelated.password.invalidCurrent")(dispatch);
      } else {
        addFlashMessage("danger", "keywords.errorRelated.invalidForm")(dispatch);
      }
    })
    .finally(() => {
      currentlySaving(false)(dispatch);
    });
}
