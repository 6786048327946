import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Fuse from "fuse.js";
import moment from "moment";

// Templates
import Header from "@components/Interface/MainList/header";
import GeneralTable from "@components/Interface/MainList/generalTable";
import LoadingPage from "@components/Global/General/loadingPage";
import Pill from "@components/Global/General/pill";
import { determineNoticeTitleByRole } from "@utilities/UserRoleHelper";

// Custom
import {
  fetchMainFeedList,
  deleteMainFeed,
} from "@components/Client/MainFeed/services/actions/mainFeedActions";

class MainFeedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
    };
    this.deleteAction = this.deleteAction.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.fetchFeeds();
  }

  fetchFeeds() {
    this.props.fetchMainFeedList(this.props.loggedInUser);
  }

  deleteAction(currRow) {
    this.props.deleteMainFeed(currRow.id, this.props.loggedInUser);
  }

  updateSearch(event) {
    this.setState({ searchValue: event.target.value });
  }

  bigNoticeName() {
    const { t, loggedInUser } = this.props;
    const { role } = loggedInUser;
    return t(determineNoticeTitleByRole(role, "_plural"));
  }

  render() {
    const { t } = this.props;
    const bigNoticeName = this.bigNoticeName();

    if (!this.props.mainfeed) {
      return <LoadingPage pageName={bigNoticeName} />;
    }

    const tableFormat = [
      {
        columnTitle: "",
        columnSize: "1%",
        fieldType: "padding",
      },
      {
        columnTitle: t("keywords.statusRelated.name"),
        columnSize: "5%",
        fieldType: "component",
        fieldValue: "statusPill",
      },
      {
        columnTitle: t("keywords.userRelated.creator"),
        columnSize: "10%",
        fieldType: "text",
        fieldValue: "creator",
      },
      {
        columnTitle: t("keywords.dateRelated.start"),
        columnSize: "7%",
        fieldType: "text",
        fieldValue: "tailoredStartDate",
      },
      {
        columnTitle: t("keywords.dateRelated.end"),
        columnSize: "7%",
        fieldType: "text",
        fieldValue: "tailoredEndDate",
      },
      {
        columnTitle: t("keywords.general.title"),
        columnSize: "10%",
        fieldType: "text",
        fieldValue: "title",
        extraCss: "u-word-break",
      },
      {
        columnTitle: t("keywords.general.content"),
        columnSize: "20%",
        fieldType: "text",
        fieldValue: "content",
        extraCss: "u-word-break",
      },
      {
        columnTitle: t("keywords.noticeRelated.image"),
        columnSize: "10%",
        fieldType: "text",
        fieldValue: "image",
        extraCss: "u-word-break",
      },
      {
        columnTitle: t("keywords.propertyRelated.name"),
        columnSize: "15%",
        fieldType: "text",
        fieldValue: "propertyNames",
      },
      {
        columnTitle: "",
        columnSize: "10%",
        fieldType: "edit",
        editAction: "/bignotice/edit/:id",
        deleteAction: this.deleteAction,
      },
    ];

    const options = {
      threshold: 0.1,
      ignoreLocation: true,
      keys: ["content", "title", "propertyNames"],
    };

    let searchResult = this.props.mainfeed;

    searchResult.forEach((feedItem) => {
      const today = moment();

      if (feedItem.endDate >= today && feedItem.published && feedItem.startDate <= today) {
        feedItem.statusPill = <Pill text={t("keywords.statusRelated.active")} type="green" />;
      } else {
        feedItem.statusPill = <Pill text={t("keywords.statusRelated.inactive")} type="disabled" />;
      }
    });

    if (this.state.searchValue.trim() !== "") {
      const fuse = new Fuse(this.props.mainfeed, options);
      searchResult = fuse.search(this.state.searchValue);
      searchResult = searchResult.map((result) => result.item);
    }

    return (
      <div>
        <Header
          title={bigNoticeName}
          newRoute="/bignotice/new"
          searchEnabled
          count={searchResult.length}
          searchValue={this.state.searchValue}
          searchAction={this.updateSearch}
        />
        <GeneralTable
          tableData={searchResult}
          tableFormat={tableFormat}
          deleteAction={this.deleteAction}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mainfeed: state.client.mainFeed.all,
  };
}

export default connect(mapStateToProps, {
  fetchMainFeedList,
  deleteMainFeed,
})(withTranslation()(MainFeedList));
