import React, { Component } from "react";
import Collapse from "react-bootstrap/Collapse";
import Card from "react-bootstrap/Card";

class CollapsibleCard extends Component {
  constructor(props, ...args) {
    super(props, ...args);

    let initialExpanded = true;
    if (props.expanded != null) {
      initialExpanded = props.expanded;
    } else if (props.defaultExpanded) {
      initialExpanded = props.defaultExpanded;
    }

    const onExpandClick = this.props.onExpandClick || ((expanded) => this.setState({ expanded }));

    this.state = {
      expanded: initialExpanded,
      onExpandClick,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({ expanded: this.props.expanded });
    }
  }

  collapseToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.state.onExpandClick(!this.state.expanded);
  };

  render() {
    const headerChevron = this.state.expanded ? "up" : "down";
    return (
      <Card border={this.props.border}>
        <Card.Header onClick={this.collapseToggle} style={{ textOverflow: "ellipses" }}>
          <div className="d-flex">
            <div className="flex-grow-1 d-flex justify-content-between mr-3">
              <h6>{this.props.header}</h6>
              <h6>{this.props.subheader}</h6>
            </div>
            <div className="flex-shrink-1">
              <i className={`fa fa-angle-${headerChevron}`} />
            </div>
          </div>
        </Card.Header>
        <Collapse in={this.state.expanded}>
          <Card.Body>{this.props.children}</Card.Body>
        </Collapse>
      </Card>
    );
  }
}

export default CollapsibleCard;
