export const SIMPLE_DAY_TIME = [
  { value: "morning", present: true, description: "Morning" },
  { value: "afternoon", present: true, description: "Afternoon" },
  { value: "evening", present: true, description: "Evening" },
  { value: "night", present: true, description: "Night" },
];

export const GENDER_REFERENCE = {
  male: {
    description: "Male",
    cssValue: "fa fa-male",
  },
  female: {
    description: "Female",
    cssValue: "fa fa-female",
  },
};

export const AGE_REFERENCE = {
  "young adult": {
    description: "Young adult",
  },
  adult: {
    description: "Adult",
  },
  senior: {
    description: "Senior",
  },
};

export const WEATHER_REFERENCE = {
  sunny: {
    cssValue: "wi wi-sunny",
  },
  raining: {
    cssValue: "wi wi-rain",
  },
  overcast: {
    cssValue: "wi wi-cloudy",
  },
  snowing: {
    cssValue: "wi wi-snowflake-cold",
  },
  "partially cloudy": {
    cssValue: "wi wi-day-cloudy",
  },
};

export const DAYS_OF_WEEK_REFERENCE = {
  monday: {
    description: "Monday",
    abbr: "M",
  },
  tuesday: {
    description: "Tuesday",
    abbr: "T",
  },
  wednesday: {
    description: "Wednesday",
    abbr: "W",
  },
  thursday: {
    description: "Thursday",
    abbr: "R",
  },
  friday: {
    description: "Friday",
    abbr: "F",
  },
  saturday: {
    description: "Saturday",
    abbr: "S",
  },
  sunday: {
    description: "Sunday",
    abbr: "SU",
  },
};

/// OLD REFERENCES
export const DAYS_OF_WEEK_TRUE = [
  { name: "Monday", abbr: "M", present: true },
  { name: "Tuesday", abbr: "T", present: true },
  { name: "Wednesday", abbr: "W", present: true },
  { name: "Thursday", abbr: "R", present: true },
  { name: "Friday", abbr: "F", present: true },
  { name: "Saturday", abbr: "S", present: true },
  { name: "Sunday", abbr: "SU", present: true },
];

export const AGE_UNDERSCORE_TRUE = [
  { name: "Young Adult", present: true, value: "young adult" },
  { name: "Adult", present: true, value: "adult" },
  { name: "Senior", present: true, value: "senior" },
];

export const WEATHER_LIST_UNDERSCORE_TRUE = [
  { value: "sunny", present: true, description: "Sunny", cssValue: "wi-day-sunny" },
  {
    value: "partially cloudy",
    present: true,
    description: "Partially Cloudy",
    cssValue: "wi-day-cloudy",
  },
  { value: "overcast", present: true, description: "Overcast", cssValue: "wi-cloudy" },
  { value: "raining", present: true, description: "Rainy", cssValue: "wi-rain" },
  { value: "snowing", present: true, description: "Snowy", cssValue: "wi-snowflake-cold" },
];
