import Elev8ApiService from "../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../lib/services/Elev8Api/lib";

class CampaignGroupDataHelper {
  static fetchFormData(id) {
    const sparseFieldsets = {
      include: [
        "campaigns",
        "campaigns.properties",
        "campaigns.campaign_locations",
        "campaigns.properties.screen_installation_configs",
      ],
      fields: {
        campaign_groups: ["name", "id", "vi_salesforce_id", "rsm_oms_id"],
        campaign: [
          "id",
          "name",
          "start_at",
          "end_at",
          "booked_impressions",
          "properties",
          "campaign_locations",
          "screen_count",
          "standard_price_cents",
          "loop_spots",
          "ad_duration",
          "properties",
        ],
        property: ["id", "name", "screen_count", "screen_installation_configs"],
        campaign_location: ["id", "property_id", "loop_spots"],
        screen_installation_config: ["id", "current_maintenance"],
      },
    };

    return new Promise((resolve, reject) => {
      Elev8ApiService.read(ApiTypes.campaign_group, id, sparseFieldsets)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          console.log("Can't fetch campaign group...", e);
          reject(e);
        });
    });
  }

  static fetchCampaignGroupCampaigns(id) {
    const sparseFieldsets = {
      include: ["campaigns", "campaigns.properties", "campaigns.campaign_locations"],
      fields: {
        campaign_groups: ["id"],
        campaign: [
          "id",
          "name",
          "start_at",
          "end_at",
          "booked_impressions",
          "properties",
          "campaign_locations",
          "screen_count",
          "standard_price_cents",
          "loop_spots",
          "ad_duration",
        ],
        property: ["id", "name", "screen_count"],
        campaign_location: ["id", "property_id", "loop_spots"],
      },
    };

    return new Promise((resolve, reject) => {
      Elev8ApiService.read(ApiTypes.campaign_group, id, sparseFieldsets)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          console.log("Can't fetch campaign group...", e);
          reject(e);
        });
    });
  }
}

export default CampaignGroupDataHelper;
