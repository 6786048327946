import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Header extends Component {
  render() {
    const { count, newRoute, searchAction, searchValue, title, t } = this.props;
    const xsWidth = newRoute ? 6 : 12;
    return (
      <div className="table-header">
        <Row className="justify-content-between h-100 align-items-center">
          <Col xs={xsWidth} md="auto" className="pb-3 pb-md-0">
            <div className="d-flex">
              <h4>{title}</h4>
              {count ? <h4 className="px-1 d-none d-md-block">({count})</h4> : null}
            </div>
          </Col>
          {newRoute ? (
            <Col xs={6} md="auto" className="text-right order-md-last">
              <Link to={newRoute}>
                <Button type="button" variant="success">
                  + {t("keywords.buttonActions.create")}
                </Button>
              </Link>
            </Col>
          ) : null}
          <Col md="auto" className="order-md-3">
            {this.props.children}
          </Col>
          {searchAction ? (
            <Col md={4} className="order-md-3">
              <div className="table-header__search-bar ">
                <i className="fa fa-search" />
                <input
                  type="text"
                  placeholder={`${t("keywords.general.searchFor")}...`}
                  onChange={searchAction}
                  value={searchValue}
                />
              </div>
            </Col>
          ) : null}
        </Row>
      </div>
    );
  }
}

export default withTranslation()(Header);
