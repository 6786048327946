/*

 CONFIGURATION REDUCER FOR A CAMPAIGN

 Anything that involves updating a main campaign

 */

import { SET_CAMPAIGN_CONFIG, SET_CONFIG_STATUS } from "../../../../../App/actionTypes";

const INITIAL_STATE = { form: null, status: { active: false, status: "inactive" } };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CAMPAIGN_CONFIG:
      return { ...state, form: action.payload };
    case SET_CONFIG_STATUS:
      return { ...state, status: action.payload };
  }
  return state;
}
