import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";

import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import withRouter from "@hooks/withRouter";
import { ApiTypes } from "@lib/services/Elev8Api/lib";
import CampaignSerializer from "@utilities/campaignSerializer";
import Elev8ApiService from "@lib/services/Elev8Api/Elev8ApiService";
import { getSumFromObjectArray, getDollarsFromCents } from "@utilities/mathHelpers";
import { apiDurationInDays } from "@utilities/dateStringHelper";
import CampaignGroupSerializer from "@utilities/CampaignGroupSerializer";
import { fetchAdvertisers } from "@components/Advertiser/services/actions/advertiserActions";
import { fetchAllUsers } from "@components/User/General/services/actions/generalUserActions";
import PermissionService, { Resources, Actions } from "@components/User/Services/permissionService";
import { SelectColumnFilter } from "@components/Global/General/BasicTableFilter";
import LoadingPage from "@components/Global/General/loadingPage";
import BasicTable from "@components/Global/General/BasicTable";
import TextInput from "@components/Global/Widgets/textInput";
import ConfirmModal from "@components/Global/Widgets/views/ConfirmModal";
import SingleDatePicker from "@components/Global/Widgets/updatedWidgets/singleDatePicker";
import Dropdown from "@components/Global/Widgets/updatedWidgets/dropDown";

import CampaignReportDataHelper from "../services/campaignGroupDataHelper";

import { CAMPAIGN_TYPE_MAP } from "../../constants";

class CampaignGroupEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successAlertVisible: false,
      errorAlertVisible: false,
      campaignGroup: null,
      creatingCampaign: false,
      updatingCampaignId: null,
      copyingCampaignId: null,
      showDeleteCampaignModal: false,
    };

    this.loadCampaignGroups();
    this.props.fetchAdvertisers();
    this.props.fetchAllUsers();
  }

  loadCampaignGroups = () => {
    const { id } = this.props.router.params;
    CampaignReportDataHelper.fetchFormData(id)
      .then((campaignGroup) => {
        campaignGroup.attributes = CampaignGroupSerializer.deserializeForm(campaignGroup);
        const transformedCampaignsData = campaignGroup.getCampaigns().map((campaign) => ({
          ...campaign.attributes,
          property_count: campaign.properties().length,
          active_screen_count: campaign.getTotalActiveScreenCount(),
          loop_spots_description: this.loopSpotsDescription(campaign),
        }));
        const campaignGroupAttributes = {
          ...campaignGroup.attributes,
          campaigns: transformedCampaignsData,
          booked_impressions: getSumFromObjectArray(transformedCampaignsData, "booked_impressions"),
          standard_price_cents: getSumFromObjectArray(
            transformedCampaignsData,
            "standard_price_cents"
          ),
        };
        this.setState({ campaignGroup: campaignGroupAttributes });
      })
      .catch(() => {
        this.showErrorAlert();
      });
  };

  reloadCampaigns = () => {
    const { id } = this.props.router.params;
    CampaignReportDataHelper.fetchCampaignGroupCampaigns(id).then((campaignGroup) => {
      const transformedCampaignsData = campaignGroup.getCampaigns().map((campaign) => ({
        ...campaign.attributes,
        property_count: campaign.properties().length,
        screen_count: campaign.getTotalScreenCount(),
        loop_spots_description: this.loopSpotsDescription(campaign),
      }));
      this.setState((prevState) => ({
        campaignGroup: {
          ...prevState.campaignGroup,
          campaigns: transformedCampaignsData,
          booked_impressions: getSumFromObjectArray(transformedCampaignsData, "booked_impressions"),
          standard_price_cents: getSumFromObjectArray(
            transformedCampaignsData,
            "standard_price_cents"
          ),
        },
      }));
    });
  };

  loopSpotsDescription = (campaign) => {
    const loopSpots = campaign
      .properties()
      .map((property) => parseInt(campaign.getLoopSpotsForProperty(property.attributes.id), 10));
    if (loopSpots.length === 0) {
      return "0";
    }
    if (Math.min(...loopSpots) === Math.max(...loopSpots)) {
      return String(Math.min(...loopSpots));
    }
    return `${Math.min(...loopSpots)} - ${Math.max(...loopSpots)}`;
  };

  handleChange = (event) => {
    this.setState({
      campaignGroup: { ...this.state.campaignGroup, [event.target.name]: event.target.value },
    });
  };

  handleDateChange = (name, date) => {
    const event = {
      target: {
        name,
        value: date,
      },
    };
    this.handleChange(event);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const valid = this.validCampaignGroup();
    if (valid) {
      this.updateCampaignGroup();
    }
    this.setState({ validated: true });
  };

  validCampaignGroup = () => {
    if (this.state.campaignGroup.name.trim() !== "") {
      return true;
    }
    return false;
  };

  serializeCampaigns(campaignIds) {
    if (campaignIds === undefined) {
      return [];
    }

    return campaignIds.map((campaignId) => ({
      id: campaignId.toString(),
      type: ApiTypes.campaign.type,
    }));
  }

  updateCampaignGroup = () => {
    const serializedForm = CampaignGroupSerializer.serializeForm(this.state.campaignGroup);
    Elev8ApiService.update(ApiTypes.campaign_group, serializedForm, this.state.campaignGroup.id)
      .then((response) => {
        if (response.status === 200) {
          this.showSuccessAlert();
        }
      })
      .catch(() => {
        this.showErrorAlert();
      });
  };

  createCampaign = () => {
    this.setState({ creatingCampaign: true });

    const { campaignGroup } = this.state;
    const campaignName = `Flight #${campaignGroup.campaigns.length + 1}`;
    const newCampaign = {
      name: campaignName,
      price_currency: "CAD",
      status: "inactive",
      campaignGroupId: campaignGroup.id,
      start_at: campaignGroup.start_at,
      end_at: campaignGroup.end_at,
      billable_start: campaignGroup.start_at,
      billable_end: campaignGroup.end_at,
      campaign_type: campaignGroup.campaign_type,
      advertiser_id: campaignGroup.advertiser_id,
      owner_id: campaignGroup.owner_id,
      fullscreen: false,
      ad_duration: 10,
      bookedImpressions: 0,
      videoEnabled: false,
    };

    const serializedForm = CampaignSerializer.serializeForm(newCampaign);
    return Elev8ApiService.create(ApiTypes.campaign, serializedForm)
      .then((response) => {
        if (response.status === 201) {
          this.setState({ creatingCampaign: false });
          this.props.router.navigate(`/flight/edit/${response.data.data.attributes.id}`);
        }
      })
      .catch(() => {
        this.setState({ creatingCampaign: false });
        this.showErrorAlert();
      });
  };

  showSuccessAlert = () => {
    this.setState({ successAlertVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ successAlertVisible: false });
      }, 2000);
    });
  };

  showErrorAlert = () => {
    this.setState({ errorAlertVisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ errorAlertVisible: false });
      }, 5000);
    });
  };

  getSelectOptions = (dataList) =>
    dataList.map((item) => ({
      value: item.id,
      label: item.name ? item.name : `${item.first_name} ${item.last_name}`,
    }));

  getCampaignCreators = (users) => {
    const campaignCreators = users.filter((user) =>
      PermissionService.can(Actions.UPDATE, Resources.CAMPAIGNS, user)
    );
    return campaignCreators;
  };

  handleDeleteCampaign = (id) => {
    this.setState({
      updatingCampaignId: id,
      showDeleteCampaignModal: true,
    });
  };

  handleConfirmDeleteCampaign = () => {
    const campaignId = this.state.updatingCampaignId;

    Elev8ApiService.delete(ApiTypes.campaign, campaignId)
      .then(() => {
        this.showSuccessAlert();
        this.reloadCampaigns();
      })
      .catch((error) => {
        this.showErrorAlert();
        throw error;
      })
      .finally(() => {
        this.setState({
          updatingCampaignId: null,
          showDeleteCampaignModal: false,
        });
      });
  };

  handleCancelDeleteCampaign = () => {
    this.setState({
      updatingCampaignId: null,
      showDeleteCampaignModal: false,
    });
  };

  onCopyCampaign = (id) => {
    this.setState({
      copyingCampaignId: id,
    });
    Elev8ApiService.copy(ApiTypes.campaign, id)
      .then(() => {
        this.showSuccessAlert();
        this.reloadCampaigns();
      })
      .catch((error) => {
        this.showErrorAlert();
        throw error;
      })
      .finally(() => {
        this.setState({
          copyingCampaignId: null,
        });
      });
  };

  renderCampaignActions = ({ cell }) => {
    const { t } = this.props;
    const campaignId = cell.value;
    const savingThisCampaign = campaignId === this.state.updatingCampaignId;
    const copyingThisCampaign = campaignId === this.state.copyingCampaignId;

    return (
      <div className="d-flex">
        <Link to={`/flight/edit/${cell.value}`}>
          <Button
            disabled={savingThisCampaign || copyingThisCampaign}
            variant="outline-success"
            size="sm mr-1"
          >
            {t("keywords.buttonActions.edit")}
          </Button>
        </Link>
        <Button
          disabled={copyingThisCampaign}
          variant="outline-secondary"
          size="sm mr-1"
          onClick={() => this.onCopyCampaign(campaignId)}
        >
          {copyingThisCampaign ? (
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            />
          ) : null}

          {t("keywords.buttonActions.copy")}
        </Button>
        <Button
          disabled={savingThisCampaign || copyingThisCampaign}
          variant="outline-danger"
          size="sm"
          onClick={() => this.handleDeleteCampaign(campaignId)}
        >
          {t("keywords.buttonActions.delete")}
        </Button>
      </div>
    );
  };

  campaignColumns() {
    return [
      {
        Header: "ID",
        accessor: "id",
        disableFilters: true,
      },
      {
        Header: "Start",
        accessor: "start_at",
        disableFilters: true,
      },
      {
        Header: "End",
        accessor: "end_at",
        disableFilters: true,
      },
      {
        Header: "Flight Name",
        accessor: "name",
      },
      {
        Header: "Booked Impressions",
        accessor: "booked_impressions",
      },
      {
        Header: "Property Count",
        accessor: "property_count",
      },
      {
        Header: "Ad Length",
        accessor: "ad_duration",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Loop Spots",
        accessor: "loop_spots_description",
      },
      {
        Header: "Active Screens",
        accessor: "active_screen_count",
      },
      {
        Header: "",
        accessor: "id",
        id: "actions",
        disableSortBy: true,
        Cell: this.renderCampaignActions,
        disableFilters: true,
      },
    ];
  }

  renderCampaignCreateButton = () => {
    const createCampaignStatus = this.state.creatingCampaign
      ? "Creating Flight..."
      : "Create Flight";
    return (
      <Button
        className="pull-right"
        variant="success"
        disabled={this.state.creatingCampaign}
        onClick={this.createCampaign}
      >
        {this.state.creatingCampaign ? (
          <span
            className="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
          />
        ) : null}
        <span>{createCampaignStatus}</span>
      </Button>
    );
  };

  formatCampaignDuration = () => {
    const { t, i18n } = this.props;
    const combinedCampaignDurations = this.state.campaignGroup.campaigns.reduce(
      (total, campaign) => {
        if (!campaign.start_at || !campaign.end_at) {
          return total;
        }
        const campaignDuration = apiDurationInDays(campaign.start_at, campaign.end_at);
        return total + campaignDuration;
      },
      0
    );
    return i18n.format(
      t("keywords.dateRelated.daysWithCount", { count: Math.round(combinedCampaignDurations) }),
      "lowercase"
    );
  };

  render() {
    const { t } = this.props;
    if (!this.props.advertisers || !this.props.users || !this.state.campaignGroup) {
      return <LoadingPage pageName="Campaign Group" />;
    }

    return (
      <>
        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={(e) => this.handleSubmit(e)}
          className="main-form__container"
        >
          <div className="form-section">
            <Alert
              variant="danger"
              show={this.state.errorAlertVisible}
              onClose={() => this.setState({ errorAlertVisible: false })}
              dismissible
            >
              Something went wrong, please try again.
            </Alert>
            <Alert
              variant="success"
              show={this.state.successAlertVisible}
              onClose={() => this.setState({ successAlertVisible: false })}
              dismissible
            >
              Changes saved.
            </Alert>
            <div className="campaignForm__sectionHeader">
              <div>Campaign Group</div>
              <div>
                <Link to="/campaign_groups">
                  <Button className="mr-1" variant="outline-secondary">
                    {t("keywords.buttonActions.cancel")}
                  </Button>
                </Link>
                <Button variant="success" type="submit">
                  {t("keywords.buttonActions.update")}
                </Button>
              </div>
            </div>
            <Row>
              <Form.Group as={Col} lg="6">
                <TextInput
                  label={t("keywords.general.name")}
                  value={this.state.campaignGroup.name}
                  handleChange={this.handleChange}
                  required
                  type="text"
                  name="name"
                />
              </Form.Group>
              <Form.Group as={Col} lg="2">
                <SingleDatePicker
                  label={t("keywords.dateRelated.start")}
                  onChange={(date) => this.handleDateChange("start_at", date)}
                  date={this.state.campaignGroup.start_at}
                  dateFormat="MMMM d, yyyy"
                />
              </Form.Group>
              <Form.Group as={Col} lg="2">
                <SingleDatePicker
                  label={t("keywords.dateRelated.end")}
                  onChange={(date) => this.handleDateChange("end_at", date)}
                  date={this.state.campaignGroup.end_at}
                  minDate={this.state.campaignGroup.start_at}
                  dateFormat="MMMM d, yyyy"
                />
              </Form.Group>
              <Form.Group as={Col} lg="2">
                <TextInput label="Duration" value={this.formatCampaignDuration()} readOnly />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Dropdown
                  label="Campaign Type"
                  value={this.state.campaignGroup.campaign_type}
                  handleChange={this.handleChange}
                  name="campaign_type"
                  required
                  options={CAMPAIGN_TYPE_MAP}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Dropdown
                  label="Client"
                  value={this.state.campaignGroup.advertiser_id}
                  handleChange={this.handleChange}
                  name="advertiser_id"
                  options={this.getSelectOptions(this.props.advertisers)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Dropdown
                  label="VI Owner"
                  value={this.state.campaignGroup.owner_id}
                  handleChange={this.handleChange}
                  name="owner_id"
                  options={this.getSelectOptions(this.getCampaignCreators(this.props.users))}
                />
              </Form.Group>
              <Form.Group as={Col} lg="2">
                <TextInput
                  label="VI SF #"
                  value={this.state.campaignGroup.vi_salesforce_id || ""}
                  handleChange={this.handleChange}
                  type="text"
                  name="vi_salesforce_id"
                />
              </Form.Group>
              <Form.Group as={Col} lg="2">
                <TextInput
                  label="RSM OMS #"
                  value={this.state.campaignGroup.rsm_oms_id || ""}
                  handleChange={this.handleChange}
                  type="text"
                  name="rsm_oms_id"
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} lg="3">
                <TextInput
                  label="Total $"
                  value={String(getDollarsFromCents(this.state.campaignGroup.standard_price_cents))}
                  readOnly
                  name="campaignValue"
                />
              </Form.Group>
              <Form.Group as={Col} lg="3">
                <TextInput
                  label="Booked Impressions"
                  value={String(this.state.campaignGroup.booked_impressions)}
                  readOnly
                  name="totalBookedImpressions"
                />
              </Form.Group>
            </Row>
          </div>
          <div className="form-section">
            <Row>
              <Col>
                <div className="form-section__title pt-2">Flights</div>
              </Col>
              <Col>{this.renderCampaignCreateButton()}</Col>
            </Row>
            <div className="mainTable mainTable__withBorder pt-3">
              <BasicTable
                tableHeader={() => null}
                initialSort={[{ id: "id", desc: true }]}
                noResultsDescription="No flights present"
                columns={this.campaignColumns()}
                data={this.state.campaignGroup.campaigns}
              />
            </div>
          </div>
        </Form>
        <ConfirmModal
          cancel={{
            label: "Cancel",
            variant: "outline-secondary",
            action: this.handleCancelDeleteCampaign,
          }}
          confirm={{
            label: "Delete",
            variant: "danger",
            action: this.handleConfirmDeleteCampaign,
          }}
          show={this.state.showDeleteCampaignModal}
        >
          <p>Are you sure you want to delete this flight?</p>
        </ConfirmModal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    advertisers: state.advertisers.all,
    users: state.user.general.all,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchAdvertisers, fetchAllUsers }, dispatch);
}
export default withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CampaignGroupEdit))
);
