/*

Single Date Picker (No Range) 

*/

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactDatePickerInput from "./reactDatePickerInput";

// MAIN CLASS
class SingleDatePicker extends Component {
  handleChange = (date) => {
    this.props.updateTime(moment(date));
  };

  render() {
    const { date, minDate, maxDate, label, showTimeSelect, dateFormat } = this.props;

    const calendarMinDate = minDate ? moment(minDate).toDate() : null;
    const calendarMaxDate = maxDate ? moment(maxDate).toDate() : null;
    const selected = date ? new Date(date) : null;
    return (
      <DatePicker
        customInput={<ReactDatePickerInput label={label} />}
        selected={selected}
        onChange={this.handleChange}
        minDate={calendarMinDate}
        maxDate={calendarMaxDate}
        showTimeSelect={showTimeSelect}
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat={dateFormat}
        timeCaption="time"
      />
    );
  }
}

SingleDatePicker.defaultProps = {
  dateFormat: "MMMM d, yyyy h:mm aa",
  minDate: null,
  maxDate: null,
  label: "",
  showTimeSelect: false,
};

SingleDatePicker.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  dateFormat: PropTypes.string,
  updateTime: PropTypes.func.isRequired,
};

export default connect(null, null)(SingleDatePicker);
