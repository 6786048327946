import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import FilterControls from "./FilterControls";

class TopBar extends Component {
  toggleSideBar = () => {
    this.props.toggleSideBar();
  };

  render() {
    return (
      <Navbar variant="dark" className="vertical-impression-navbar u-color-white" expand="lg">
        <Col xs={12} sm={4}>
          <Navbar.Brand className="vertical-impression-navbar__brand">
            <Row className="align-items-center" g={0}>
              <Col xs="auto" className="d-flex">
                <Button variant="link" onClick={this.toggleSideBar} className="p-0 mr-2 d-sm-none">
                  <i className="fa fa-bars" style={{ color: "white" }} />
                </Button>
              </Col>
              <Col className="d-flex">
                <span className="logo logo--vi-light logo--sm" />
              </Col>
            </Row>
            <Row className="pt-2">
              <Col className="text-uppercase u-color-white reportingContent__title">
                <span className="font-weight-light u-gilroy-regular">
                  {this.props.campaignName}
                </span>{" "}
                <span>Campaign Reporting</span>{" "}
                <span className="font-weight-light u-gilroy-regular">
                  ({this.props.campaignGroupId}.{this.props.campaignId})
                </span>
              </Col>
            </Row>
          </Navbar.Brand>
        </Col>
        <Col xs={12} sm={6}>
          <FilterControls
            selectedCities={this.props.selectedCities}
            selectedPropertyTypes={this.props.selectedPropertyTypes}
            uniquePropertyTypes={this.props.uniquePropertyTypes}
            uniqueCities={this.props.uniqueCities}
            setFilters={this.props.setFilters}
            disabledFilters={this.props.disabledFilters}
          />
        </Col>
      </Navbar>
    );
  }
}

export default withTranslation()(TopBar);
