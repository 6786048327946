import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useRowSelect,
  useMountedLayoutEffect,
} from "react-table";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { Filter, DefaultColumnFilter } from "./BasicTableFilter";

function matchesAnyFilter(rows, ids, filterValue) {
  return rows.filter((row) =>
    ids.some((id) => {
      const rowValue = row.values[id];
      return filterValue.includes(rowValue);
    })
  );
}

function NoResults({ noResultsDescription }) {
  const { t } = useTranslation();
  const description = noResultsDescription || t("keywords.general.noResults");
  return (
    <div className="basicTable__noResults">
      <i className="fa fa-search" /> {description}
    </div>
  );
}

function ColumnHeader({ column }) {
  let sortIcon = "sort";

  if (column.isSorted) {
    if (column.isSortedDesc) {
      sortIcon = "sort-down";
    } else {
      sortIcon = "sort-up";
    }
  }

  return (
    <Col className="basicTable__columnHeader">
      <span title={column.render("Header")}>{column.render("Header")}</span>
      {column.canSort ? <i className={`fa fa-${sortIcon} px-2`} /> : null}
    </Col>
  );
}

function ResultTableBody({ tableState, className, onSelectedRowsChange }) {
  const { getTableBodyProps, prepareRow, rows } = tableState;

  return (
    <tbody className={className} {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        const selectedStyle = row.isSelected ? "basicTable__selected" : "";
        return (
          <tr
            {...row.getRowProps()}
            className={selectedStyle}
            onClick={() => (onSelectedRowsChange ? row.toggleRowSelected() : null)}
          >
            {row.cells.map((cell) => (
              <td
                {...cell.getCellProps({
                  style: _.isFunction(cell.column.cellStyle)
                    ? cell.column.cellStyle({ cell })
                    : cell.column.cellStyle,
                })}
              >
                {cell.render("Cell")}
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
}

function ResultTableFooter({ tableState, className }) {
  const { footerGroups } = tableState;

  return (
    <tfoot className={className}>
      {footerGroups.map(({ getFooterGroupProps, headers }) => (
        <tr {...getFooterGroupProps()}>
          {headers.map(({ getFooterProps, render }) => (
            <td {...getFooterProps()}>{render("Footer")}</td>
          ))}
        </tr>
      ))}
    </tfoot>
  );
}

function ResultTableHeader({ tableState, className, showFilters }) {
  const { headerGroups } = tableState;

  return (
    <thead className={className}>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th key={column.id} {...column.getHeaderProps()} className="basicTable__tableHeader">
              <div {...column.getSortByToggleProps()}>{column.render(ColumnHeader)}</div>
              {showFilters && <Filter column={column} />}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}

function RenderTableHeader(rows, preFilteredRows, tableHeader) {
  if (tableHeader) {
    return tableHeader(rows, preFilteredRows);
  }

  return (
    <div>
      Showing {rows.length} of {preFilteredRows.length} results...
    </div>
  );
}

function ResultTable({
  tableState,
  tableAttributes,
  headerClassName,
  footerClassName,
  bodyClassName,
  compact,
  isCollapsed,
  showFilters,
  showFooter,
  onSelectedRowsChange,
}) {
  const { tableHeader, getTableProps, rows, preFilteredRows } = tableState;

  return (
    <div className="basicTable__container">
      {!compact && RenderTableHeader(rows, preFilteredRows, tableHeader)}
      <Table {...tableAttributes} {...getTableProps()}>
        <ResultTableHeader
          tableState={tableState}
          className={headerClassName}
          showFilters={showFilters}
        />
        {!isCollapsed ? (
          <ResultTableBody
            tableState={tableState}
            className={bodyClassName}
            onSelectedRowsChange={onSelectedRowsChange}
          />
        ) : null}
        {showFooter && <ResultTableFooter tableState={tableState} className={footerClassName} />}
      </Table>
    </div>
  );
}

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

const getRowId = (row, relativeIndex, parent) => {
  if (row.id) {
    return row.id;
  }
  if (row.attributes && row.attributes.id) {
    return row.attributes.id;
  }

  return parent ? [parent.id, relativeIndex].join(".") : relativeIndex;
};

function BasicTable({
  columns,
  data,
  filters,
  initialSort,
  globalFilterFn,
  tableHeader,
  tableAttributes,
  headerClassName,
  footerClassName,
  bodyClassName,
  compact,
  noResultsDescription,
  selectedRows,
  onSelectedRowsChange,
  showFilters,
  showFooter,
  isCollapsed,
}) {
  const memoizedTableHeader = React.useMemo(() => tableHeader, [tableHeader]);
  const memoizedColumns = React.useMemo(() => columns, [columns]);
  const memoizedData = React.useMemo(() => data, [data]);
  const memoizedSelectedRows = React.useMemo(() => selectedRows || [], [data]);
  const memoizedInitialSort = React.useMemo(() => initialSort || [], [initialSort]);
  const memoizedGlobalFilter = React.useMemo(() => globalFilterFn, [globalFilterFn]);
  const memoizedFilters = React.useMemo(
    () => ({
      matchesAny: matchesAnyFilter,
    }),
    [matchesAnyFilter]
  );

  const tableState = useTable(
    {
      tableHeader: memoizedTableHeader,
      columns: memoizedColumns,
      data: memoizedData,
      getRowId,
      initialState: {
        sortBy: memoizedInitialSort,
        selectedRowIds: memoizedSelectedRows,
      },
      filterTypes: memoizedFilters,
      globalFilter: memoizedGlobalFilter,
      defaultColumn: { Filter: DefaultColumnFilter },
      autoResetFilters: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (onSelectedRowsChange) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  const {
    rows,
    setGlobalFilter,
    setFilter,
    state: { selectedRowIds },
  } = tableState;

  React.useEffect(() => {
    if (filters) {
      setGlobalFilter(filters.global || undefined);
      Object.keys(filters).forEach((filter) => {
        if (filter !== "global") {
          setFilter(filter, filters[filter]);
        }
      });
    }
  }, [filters, data]);

  useMountedLayoutEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange(selectedRowIds);
  }, [onSelectedRowsChange, selectedRowIds]);

  return (
    <>
      <ResultTable
        tableState={tableState}
        tableAttributes={tableAttributes}
        headerClassName={headerClassName}
        footerClassName={footerClassName}
        bodyClassName={bodyClassName}
        compact={compact}
        isCollapsed={isCollapsed}
        showFilters={showFilters}
        showFooter={showFooter}
        onSelectedRowsChange={onSelectedRowsChange}
      />

      {!compact && rows.length === 0 ? (
        <NoResults noResultsDescription={noResultsDescription} />
      ) : null}
    </>
  );
}

BasicTable.defaultProps = {
  filters: {},
  initialSort: [],
  globalFilterFn: "text",
  tableHeader: null,
  tableAttributes: {},
  bodyClassName: null,
  headerClassName: null,
  footerClassName: null,
  compact: false,
  noResultsDescription: null,
  selectedRows: {},
  onSelectedRowsChange: null,
  showFilters: true,
  showFooter: false,
};

BasicTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.array.isRequired,
  filters: PropTypes.object,
  initialSort: PropTypes.array,
  globalFilterFn: PropTypes.any,
  tableHeader: PropTypes.func,
  tableAttributes: PropTypes.object,
  headerClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  compact: PropTypes.bool,
  noResultsDescription: PropTypes.string,
  selectedRows: PropTypes.object,
  onSelectedRowsChange: PropTypes.func,
  showFilters: PropTypes.bool,
  showFooter: PropTypes.bool,
};

export default BasicTable;
