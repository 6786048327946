import React from "react";
import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { isBefore } from "date-fns";
import { enCA, frCA } from "date-fns/locale";

import {
  dateToString,
  formDurationInDays,
  parseLocaleDate,
} from "../../../../utilities/dateStringHelper";
import ReactDatePickerInput from "../views/reactDatePickerInput";

registerLocale("fr-CA", frCA);
registerLocale("en-CA", enCA);

export default function DateRangePicker({
  startDate,
  endDate,
  onChange,
  showDuration,
  minDate,
  maxDate,
}) {
  const { t, i18n } = useTranslation();

  function handleChangeStart(date) {
    let newEndDate = endDate;
    const newStartDate = dateToString(date);
    if (newStartDate > endDate) {
      newEndDate = newStartDate;
    }
    onChange(newStartDate, newEndDate);
  }

  function handleChangeEnd(date) {
    const newEndDate = dateToString(date);
    onChange(startDate, newEndDate);
  }

  const numberOfDays = Math.round(formDurationInDays(startDate, endDate));
  const start = parseLocaleDate(startDate);
  const end = parseLocaleDate(endDate);
  const error = isBefore(end, start) ? t("keywords.dateRelated.badRange") : "";
  const calendarMaxDate = maxDate ? parseLocaleDate(maxDate) : null;
  const calendarMinDate = minDate ? parseLocaleDate(minDate) : null;
  return (
    <div className="form-group">
      <DatePicker
        customInput={<ReactDatePickerInput label={t("keywords.dateRelated.startDate")} />}
        locale={t("language.locale")}
        selected={start}
        timeCaption={t("keywords.dateRelated.time")}
        minDate={calendarMinDate}
        maxDate={calendarMaxDate}
        selectsStart
        dateFormat={t("language.dateFormat")}
        startDate={start}
        endDate={end}
        onChange={handleChangeStart}
        popperPlacement="bottom"
        popperModifiers={{
          flip: {
            behavior: ["bottom"], // don't allow it to flip to be above
          },
          preventOverflow: {
            enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
          },
          hide: {
            enabled: false, // turn off since needs preventOverflow to be enabled
          },
        }}
      />
      <DatePicker
        customInput={<ReactDatePickerInput label={t("keywords.dateRelated.endDate")} />}
        selected={end}
        locale={t("language.locale")}
        timeCaption={t("keywords.dateRelated.time")}
        selectsEnd
        minDate={start}
        maxDate={calendarMaxDate}
        dateFormat={t("language.dateFormat")}
        startDate={start}
        endDate={end}
        onChange={handleChangeEnd}
        popperPlacement="bottom"
        popperModifiers={{
          flip: {
            behavior: ["bottom"], // don't allow it to flip to be above
          },
          preventOverflow: {
            enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
          },
          hide: {
            enabled: false, // turn off since needs preventOverflow to be enabled
          },
        }}
      />
      {showDuration ? (
        <div className="duration-text">
          {i18n.format(
            t("keywords.dateRelated.daysWithCount", { count: numberOfDays }),
            "lowercase"
          )}
        </div>
      ) : null}
      <div className="error">{error} </div>
    </div>
  );
}

DateRangePicker.defaultProps = {
  minDate: null,
  maxDate: null,
  showDuration: false,
};

DateRangePicker.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showDuration: PropTypes.bool,
};
