import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import withRouter from "@hooks/withRouter";
import { checkSession } from "@components/Authentication/actions/sessionActions";

class PrivateComponent extends Component {
  componentDidUpdate() {
    if (this.props.user) {
      this.props.checkSession();
    } else {
      this.props.router.navigate("/login");
    }
  }

  render() {
    const { component: ComponentFromProps, auth, user, ...rest } = this.props;
    if (!auth) {
      return <Navigate to="/login" replace />;
    }
    if (user) {
      return (
        <UserContext.Provider value={user}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ComponentFromProps {...rest} loggedInUser={user} />
        </UserContext.Provider>
      );
    }
    return null;
  }
}

const UserContext = React.createContext(null);
const ConnectedPrivateComponent = withRouter(connect(null, { checkSession })(PrivateComponent));

export { UserContext, ConnectedPrivateComponent as PrivateComponent };
