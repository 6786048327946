import Elev8ApiService from "../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes, JsonApiObjectFactory } from "../../../lib/services/Elev8Api/lib";

class CampaignGroupProposalDataHelper {
  static LIST_REQUEST_OPTIONS = {
    include: ["owner", "vi_owner", "advertiser", "campaign_proposals", "campaign_group"],
    fields: {
      campaign_group_proposal: [
        "id",
        "status",
        "name",
        "owner",
        "advertiser",
        "campaign_group_id",
        "property_ids",
        "vi_owner",
        "campaign_proposals",
        "campaign_group",
      ],
      campaign_group: ["name", "id"],
      advertiser: ["name", "id"],
      owner: ["name"],
      vi_owner: ["name"],
      campaign_proposal: ["campaign_group_proposal_id", "name"],
    },
  };

  static GET_REQUEST_OPTIONS = {
    include: ["owner", "vi_owner", "advertiser", "campaign_proposals", "campaign_group"],
    fields: {
      campaign_group_proposal: [
        "id",
        "name",
        "owner_id",
        "advertiser",
        "campaign_group_id",
        "property_ids",
        "vi_owner",
        "status",
        "campaign_proposals",
        "campaign_group",
        "convertible?",
      ],
      campaign_group: ["id", "name"],
      user: ["id", "name", "email"],
      advertiser: ["id", "name"],
      campaign_proposal: [
        "id",
        "name",
        "start_at",
        "end_at",
        "loop_spots",
        "campaign_type",
        "rate_type",
        "ad_duration",
        "fullscreen",
        "rate_value_cents",
        "discount_percent",
        "target_screens",
        "booked_impressions",
        "standard_price_cents",
        "property_ids",
        "campaign_group_proposal",
      ],
    },
  };

  // TODO: either remove reject or handle the reject case
  static fetchProposals() {
    return new Promise((resolve, reject) => {
      Elev8ApiService.index(ApiTypes.campaign_group_proposal, this.LIST_REQUEST_OPTIONS).then(
        (response) => {
          resolve(response);
        }
      );
    });
  }

  // TODO: either remove reject or handle the reject case
  static fetchProposal(id) {
    return new Promise((resolve, reject) => {
      Elev8ApiService.read(ApiTypes.campaign_group_proposal, id, this.GET_REQUEST_OPTIONS).then(
        (response) => {
          resolve(response);
        }
      );
    });
  }

  static updateProposal(form, id) {
    return new Promise((resolve, reject) => {
      Elev8ApiService.partialUpdate(
        ApiTypes.campaign_group_proposal,
        form,
        id,
        this.GET_REQUEST_OPTIONS
      ).then((response) => {
        if (response.status === 200) {
          const item = response.data.data;
          resolve(
            JsonApiObjectFactory.createJsonApiObject(item, response.data.included, item.type)
          );
        }
        reject(response);
      });
    });
  }

  static createProposal(form) {
    return new Promise((resolve, reject) => {
      Elev8ApiService.create(ApiTypes.campaign_group_proposal, form).then((response) => {
        if (response.status === 201) {
          const item = response.data.data;
          resolve(
            JsonApiObjectFactory.createJsonApiObject(item, response.data.included, item.type)
          );
        }
        reject(response);
      });
    });
  }

  static submitProposal(id) {
    return new Promise((resolve, reject) => {
      Elev8ApiService.submit(ApiTypes.campaign_group_proposal, id, this.GET_REQUEST_OPTIONS).then(
        (response) => {
          if (response.status === 200) {
            const item = response.data.data;
            resolve(
              JsonApiObjectFactory.createJsonApiObject(item, response.data.included, item.type)
            );
          }
          reject(response);
        }
      );
    });
  }

  static completeProposal(id) {
    return new Promise((resolve, reject) => {
      Elev8ApiService.complete(ApiTypes.campaign_group_proposal, id, this.GET_REQUEST_OPTIONS).then(
        (response) => {
          if (response.status === 200) {
            const item = response.data.data;
            resolve(
              JsonApiObjectFactory.createJsonApiObject(item, response.data.included, item.type)
            );
          }
          reject(response);
        }
      );
    });
  }
}

export default CampaignGroupProposalDataHelper;
