/* Configuration for Advertiser form */

const fileMaxSize = 5 * 1000 * 1000; // 5MB

export const ADVERTISER_FORM_STYLE = {
  formColor: "#3f51b5",
  subject: "Advertiser",
  icon: "fa-columns",
};

export const ADVERTISER_GROUPS = [
  {
    id: 1,
    name: "Image",
    color: ADVERTISER_FORM_STYLE.color,
  },
  {
    id: 2,
    name: "Personal Information",
    color: ADVERTISER_FORM_STYLE.color,
  },
  {
    id: 3,
    name: "Location",
    color: ADVERTISER_FORM_STYLE.color,
  },
  {
    id: 5,
    name: "Additional",
    color: ADVERTISER_FORM_STYLE.color,
  },
];

export const ADVERTISER_FIELDS = [
  {
    formType: "media",
    maxWidth: 300,
    fileSizeText: "5 MB",
    fileMaxSize,
    dimensions: "250px250 px",
    acceptedFiles: "image/*",
    imageWidth: 120,
    imageHeight: "100%",
    imageTitle: "Upload New Logo",
    fieldName: "adLogo",
    imageCategory: "logo",
    validate: false,
    groupCategory: 1,
  },
  {
    formType: "header",
    title: "Personal Information",
  },
  {
    formType: "text",
    fieldName: "name",
    description: "Company",
    placeHolder: "Company name",
    validate: true,
    groupCategory: 2,
    formWidth: 350,
  },
  {
    formType: "text",
    fieldName: "contact_name",
    description: "Client",
    placeHolder: "Client name",
    validate: true,
    groupCategory: 2,
    formWidth: 350,
  },
  {
    formType: "text",
    fieldName: "contact_phone",
    description: "Phone",
    placeHolder: "Phone number",
    validate: true,
    groupCategory: 2,
    formWidth: 350,
  },
  {
    formType: "text",
    fieldName: "contact_email",
    description: "Email",
    placeHolder: "Email",
    validate: true,
    groupCategory: 2,
    formWidth: 350,
  },
  {
    formType: "text",
    fieldName: "contact_address_line1",
    description: "Address Line 1",
    placeHolder: "Adress line 1",
    validate: true,
    groupCategory: 3,
    formWidth: 350,
  },
  {
    formType: "text",
    fieldName: "contact_address_line2",
    description: "Address Line 2",
    placeHolder: "Address line 2",
    validate: false,
    groupCategory: 3,
    formWidth: 350,
  },
  {
    formType: "text",
    fieldName: "contact_postal_code",
    description: "Postal Code",
    placeHolder: "Postal code",
    validate: true,
    groupCategory: 3,
    formWidth: 350,
  },
  {
    formType: "text",
    fieldName: "contact_city",
    description: "City",
    placeHolder: "City",
    validate: true,
    groupCategory: 3,
    formWidth: 350,
  },
  {
    formType: "text",
    fieldName: "contact_province",
    description: "Province",
    placeHolder: "Province",
    validate: true,
    groupCategory: 3,
    formWidth: 350,
  },
  {
    formType: "text",
    fieldName: "contact_country",
    description: "Country",
    placeHolder: "Country",
    validate: true,
    groupCategory: 3,
    formWidth: 350,
  },
  {
    formType: "text",
    fieldName: "comments",
    description: "Additional Comments",
    placeHolder: "comments",
    validate: false,
    groupCategory: 4,
    formWidth: 760,
  },
];
