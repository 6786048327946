/*
MAIN FEED MEDIA
*/

import _ from "lodash";

import { SET_MAIN_FEED_MEDIA_LIST } from "../../../../../App/actionTypes";

const INITIAL_STATE = {
  all: null,
  single: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_MAIN_FEED_MEDIA_LIST:
      return { ...state, all: action.payload };
  }
  return state;
}
