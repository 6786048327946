import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ImagePreviewWidget from "../../Global/Widgets/views/imagePreviewWidget";
import BigNoticePreview from "../../Global/Widgets/views/bigNoticePreview";

class MainFeedPlayer extends Component {
  emptyState() {
    const { t } = this.props;

    return (
      <h4 className="h-100 u-color-white">{t("keywords.screenRelated.noBigNoticeContent")}</h4>
    );
  }

  render() {
    let player;

    if (this.props.type === "campaign") {
      player = (
        <ImagePreviewWidget
          filePreview={this.props.media.url}
          fileType={this.props.media.type}
          autoplayVideo
          width="100%"
          height="100%"
        />
      );
    }

    if (this.props.type === "big_notice") {
      const url = this.props.media ? this.props.media.url : null;
      player = (
        <BigNoticePreview
          imageUrl={url}
          screenTitle={this.props.content.title}
          screenDescription={this.props.content.content.body}
        />
      );
    }

    player = player || this.emptyState();

    return player;
  }
}

export default withTranslation()(MainFeedPlayer);
