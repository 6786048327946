export const ApiTypes = {
  user: {
    endpoint: "/users",
    type: "user",
  },
  campaign_group: {
    type: "campaign_group",
    endpoint: `/campaign_groups`,
  },
  campaign: {
    endpoint: "/campaigns",
    type: "campaign",
  },
  property: {
    endpoint: "/properties",
    type: "property",
  },
  advertiser: {
    endpoint: "/advertisers",
    type: "advertiser",
  },
  ad: {
    type: "ad",
  },
  big_notice: {
    endpoint: "/big_notices",
    type: "big_notice",
  },
  side_notice: {
    endpoint: "/notices",
    type: "side_notice",
  },
  campaign_revenue: {
    type: "campaign_revenue",
    endpoint: `/campaign_revenue`,
  },
  campaign_report: {
    type: "campaign_report",
    endpoint: `/reporting/campaign_report`,
  },
  campaign_group_proposal: {
    type: "campaign_group_proposal",
    endpoint: "/campaign_group_proposals",
  },
  campaign_proposal: {
    endpoint: "/campaign_proposals",
    type: "campaign_proposal",
  },
  screen_installation_config: {
    type: "screen_installation_config",
    endpoint: "/screen_installation_configs",
  },
  spotzi_location: {
    type: "spotzi_location",
    endpoint: "/spotzi_locations",
  },
  media: {
    type: "media",
  },
};
