import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { withTranslation } from "react-i18next";
import TextInput from "../../Global/Widgets/textInput";

class PropertyContactSection extends Component {
  handleFormChange = (event) => {
    const target = {
      name: event.target.name,
      value: event.target.value,
    };
    this.props.handleChange(target);
  };

  render() {
    const { isPropertyManager, formFields, t } = this.props;

    if (isPropertyManager) {
      return (
        <div className="form-section">
          <div className="form-section__title text-uppercase pt-2">{formFields.name}</div>
        </div>
      );
    }
    return (
      <div className="form-section">
        <div className="form-section__title pt-2">{t("keywords.general.information")}</div>
        <Row>
          <Form.Group as={Col} md="3">
            <TextInput
              label={t("keywords.propertyRelated.propertyName")}
              value={formFields.name}
              handleChange={this.handleFormChange}
              required
              type="text"
              name="name"
            />
          </Form.Group>
          <Form.Group as={Col} md="3">
            <TextInput
              label={t("keywords.userRelated.contact.contactName")}
              value={formFields.contact_name}
              handleChange={this.handleFormChange}
              required
              type="text"
              name="contact_name"
            />
          </Form.Group>
          <Form.Group as={Col} md="3">
            <TextInput
              label={t("keywords.userRelated.email.contactEmail")}
              value={formFields.contact_email}
              handleChange={this.handleFormChange}
              required
              type="text"
              name="contact_email"
            />
          </Form.Group>
          <Form.Group as={Col} md="3">
            <TextInput
              label={t("keywords.userRelated.phone.contactPhone")}
              value={formFields.contact_phone}
              handleChange={this.handleFormChange}
              required
              type="text"
              name="contact_phone"
            />
          </Form.Group>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(PropertyContactSection);
