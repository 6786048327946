import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";
import PropTypes from "prop-types";

import ReportingHeader from "../ReportingSection";
import CreateMapPropertyPopup from "../../../../Global/Widgets/map/MapPropertyPopup";
import PropertyClusterMap from "../../../../Global/Widgets/map/PropertyClusterMap";

class CampaignLocationMapSection extends Component {
  renderPopup = CreateMapPropertyPopup({ getServedImpressions: null });

  render() {
    const { properties } = this.props;

    return (
      <React.Fragment>
        <ReportingHeader title="Full Property Map" />
        <Card>
          <PropertyClusterMap
            height="500px"
            renderPopup={this.renderPopup}
            properties={properties}
          />
        </Card>
      </React.Fragment>
    );
  }
}

CampaignLocationMapSection.propTypes = {
  properties: PropTypes.array,
};

export default withTranslation()(CampaignLocationMapSection);
