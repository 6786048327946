import React, { Component } from "react";
import withRouter from "@hooks/withRouter";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import RodalSaving from "@components/Campaign/Creation/AdStack/views/widgets/adSavingModal";
import PermissionService, { Actions, Resources } from "@components/User/Services/permissionService";

import TextBox from "@components/Global/Widgets/views/textBox";
import AdDateRange from "@components/Campaign/Creation/AdStack/views/widgets/adDateRange";
import PropertyStep from "@components/Campaign/Creation/AdStack/views/widgets/propertyStep";
import ImageUploader from "@components/Campaign/Creation/AdStack/views/widgets/imageUploader";
import LoadingPage from "@components/Global/General/loadingPage";

import selectedPropertySelector from "@components/Campaign/Creation/selectors/propertySelector";
import { fetchCampaignConfigurations } from "@components/Campaign/Creation/Configuration/services/configurationActions";
import fetchPropertiesForOldCampaign from "@components/Campaign/Creation/Property/services/actions/propertyMapActions";
import {
  initiateSingleAd,
  fetchSingleAd,
  updatingLocalAdForm,
  updateLocalAd,
  createLocalAd,
  initiateSaveAds,
  closeAd,
  clearAdForm,
} from "@components/Campaign/Creation/AdStack/services/actions/adStackActions";
import TargetingSelector from "@components/Global/Widgets/views/TargetingSelector/targetingSelector";
import ToggleSlider from "@components/Global/Widgets/updatedWidgets/toggleSlider";

class AdEdit extends Component {
  constructor(props) {
    super(props);
    this.state = { mode: "New", action: "Save" };
    this.saveAd = this.saveAd.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.onTargetingChange = this.onTargetingChange.bind(this);
    this.ok = this.ok.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.fetchPropertiesForOldCampaign();
    const currentRoute = this.props.router.location.pathname;
    if (currentRoute.includes("/flight/ad/new/")) {
      this.props.fetchCampaignConfigurations(this.props.router.params.id);
      this.props.initiateSingleAd(this.props.router.params.id);
      this.setState({ mode: "New", action: "Save" });
    } else {
      this.props.fetchSingleAd(this.props.router.params.id);
      this.setState({ mode: "Edit", action: "Update" });
    }
  }

  componentWillUnmount() {
    this.props.clearAdForm();
  }

  handleStatusChange = (event) => {
    this.props.updatingLocalAdForm("published", event.target.checked, this.props.ad);
  };

  onTargetingChange(targeting) {
    this.props.updatingLocalAdForm("targeting", targeting, this.props.ad);
  }

  saveChanges() {
    this.props.savingCampaignConfigStatus(this.props.config);
  }

  ok() {
    this.props.closeAd();
    this.forceUpdate();
  }

  saveAd() {
    // check if all the requirements are met;
    const gender = _.filter(this.props.ad.gender, { present: true });
    const daysOfWeek = _.filter(this.props.ad.daysOfWeek, { present: true });
    const periodsOfDay = _.filter(this.props.ad.periodsOfDay, { present: true });
    const age = _.filter(this.props.ad.age, { present: true });
    const title = this.props.ad.title.trim();
    const weatherLength = _.filter(this.props.ad.weather, { present: true });

    if (
      gender.length > 0 &&
      daysOfWeek.length > 0 &&
      periodsOfDay.length > 0 &&
      age.length > 0 &&
      title.length > 0 &&
      weatherLength.length > 0 &&
      this.props.ad.selectedProperties.length > 0 &&
      this.props.ad.currMedia
    ) {
      if (this.state.mode === "New") {
        this.props.createLocalAd(this.props.ad, this.props.config, true);
      } else {
        this.props.updateLocalAd(this.props.ad, this.props.config, true);
      }
    }
  }

  canEditAds() {
    const user = this.props.loggedInUser;
    return PermissionService.can(Actions.UPDATE, Resources.ADS, user);
  }

  renderPublishSlider() {
    if (this.canEditAds()) {
      return (
        <ToggleSlider
          variant="success"
          label="Publish Ad"
          value={this.props.ad.published}
          onChange={this.handleStatusChange}
        />
      );
    }
    const publishedDescription = this.props.ad.published ? "Published" : "Unpublished";
    return (
      <span>
        <b>Current Ad State:</b> {publishedDescription}{" "}
      </span>
    );
  }

  render() {
    if (
      !this.props.config ||
      !this.props.properties ||
      !this.props.ad ||
      !this.props.ads ||
      !this.props.filteredProperties
    ) {
      return <LoadingPage pageName="Ad" />;
    }
    let width = 960;
    let height = 700;
    let displayableWidth = 272;
    let displayableHeight = 196;

    if (this.props.config.fullscreen) {
      width = 1368;
      height = 768;
      displayableWidth = 300;
      displayableHeight = 168;
    }

    let validSubmit = false;

    // check valid
    const gender = _.filter(this.props.ad.gender, { present: true });
    const daysOfWeek = _.filter(this.props.ad.daysOfWeek, { present: true });
    const periodsOfDay = _.filter(this.props.ad.periodsOfDay, { present: true });
    const age = _.filter(this.props.ad.age, { present: true });
    const title = this.props.ad.title.trim();
    const weatherLength = _.filter(this.props.ad.weather, { present: true });
    const start = this.props.ad.adStart;
    const end = this.props.ad.adEnd;
    if (
      gender.length > 0 &&
      daysOfWeek.length > 0 &&
      periodsOfDay.length > 0 &&
      age.length > 0 &&
      title.length > 0 &&
      weatherLength.length > 0 &&
      this.props.ad.selectedProperties.length > 0 &&
      this.props.ad.currMedia &&
      start !== end
    ) {
      validSubmit = true;
    }

    return (
      <div>
        <div className="ad-form-container">
          <div className="action-button-container">
            <div className="info"> {this.state.mode} Ad</div>
            <div className="action">
              <Link to={`/flight/edit/${this.props.config.id}`} className="cancel">
                Cancel
              </Link>
              {validSubmit ? (
                <button type="button" className="save" onClick={this.props.initiateSaveAds}>
                  Save
                </button>
              ) : (
                <div className="save disabled">Save</div>
              )}
            </div>
          </div>
          <div className="ad-content">
            <div className="ad-left">
              <h3> Advert Image </h3>

              <ImageUploader
                localAd={this.props.ad}
                campaign={this.props.config}
                updatingLocalAdForm={this.props.updatingLocalAdForm}
                displayableWidth={displayableWidth}
                displayableHeight={displayableHeight}
                width={width}
                height={height}
              />
            </div>
            <div className="ad-right">
              <div>
                <TextBox
                  textValue={this.props.ad.title}
                  updateForm={this.props.updatingLocalAdForm}
                  formPropertyName="title"
                  description="Ad Name"
                  placeHolder="Ad Name"
                  inputType="text"
                  formWidth={350}
                  formColor="red"
                  formProps={this.props.ad}
                  required
                />
                <div className="small-block" />
                <AdDateRange />
                <h4> Target Time </h4>
                <TargetingSelector
                  targetingData={this.props.ad}
                  onTargetingChange={this.onTargetingChange}
                />
                <PropertyStep
                  canSelectProperties={this.canEditAds()}
                  adInfo={this.props.ad}
                  adProperties={this.props.filteredProperties}
                  updatingLocalAdForm={this.props.updatingLocalAdForm}
                />
                {this.renderPublishSlider()}
              </div>
            </div>
          </div>
        </div>

        <RodalSaving
          status={this.props.status}
          ok={this.ok}
          saveChanges={this.saveAd}
          cancel={this.ok}
          title={`${this.state.mode} Ad`}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    config: state.campaign.creation.config.form,
    filteredProperties: selectedPropertySelector(state),
    properties: state.property.general.all,
    ad: state.campaign.creation.adStack.single,
    ads: state.campaign.creation.adStack.all,
    status: state.campaign.creation.adStack.status,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPropertiesForOldCampaign,
      fetchCampaignConfigurations,
      initiateSingleAd,
      fetchSingleAd,
      updatingLocalAdForm,
      updateLocalAd,
      createLocalAd,
      initiateSaveAds,
      closeAd,
      clearAdForm,
    },
    dispatch
  );
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdEdit));
