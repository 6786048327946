/** *********************
AUTHENTICATION 
********************* */

export const AUTH_USER = "AUTH_USER";
export const UNAUTH_USER = "UNAUTH_USER";
export const ERROR_MSG = "ERROR_MSG";
export const NO_ERROR_MSG = "NO_ERROR_MSG";
export const VALID_PASSWORD = "VALID_PASSWORD";
export const INVALID_PASSWORD = "INVALID_PASSWORD";

/** *********************
USER 
********************* */

// PERSONAL
export const SET_PERSONAL_INFO = "SET_PERSONAL_INFO";
export const SET_PASSWORD_STATUS = "SET_PASSWORD_STATUS";

// OTHER
export const SET_ALL_USERS = "SET_ALL_USERS";
export const SET_USER_FORM = "SET_USER_FORM";

/** ****************************
INTERFACE 
****************************** */

// SIDEBAR
export const SET_FLASH_MESSAGE = "SET_FLASH_MESSAGE";
export const REMOVE_FLASH_MESSAGE = "REMOVE_FLASH_MESSAGE";
export const SHOW_SAVING_MODAL = "SHOW_SAVING_MODAL";

// SIDEBAR
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

/** ****************************
ADVERTISERS 
****************************** */

export const FETCH_ADVERTISERS = "FETCH_ADVERTISERS";
export const SET_ADVERTISER_FORM = "SET_ADVERTISER_FORM";

/** ****************************
PROPERTIES 
****************************** */

export const FETCH_PROPERTIES = "FETCH_PROPERTIES";
export const SET_FEED_PROPERTIES = "SET_FEED_PROPERTIES";
export const SET_PROPERTY_FORM = "SET_PROPERTY_FORM";
export const SET_PROPERTY_PHOTO = " SET_PROPERTY_PHOTO";
export const SET_PROPERTY_LOGO = "SET_PROPERTY_LOGO";
export const SET_PROPERTY_CONTENT = "SET_PROPERTY_CONTENT";

/** ****************************
CLIENT 
****************************** */

// RSSFEED
export const SET_RSS_FEED_FORM = "SET_RSS_FEED_FORM";
export const FETCH_RSS_FEEDS = "FETCH_RSS_FEEDS";
export const SET_RSS_FEED_DATA = "SET_RSS_FEED_DATA";

// SIDEPANELICONS
export const SET_ICON_FORM = "SET_ICON_FORM";
export const FETCH_PANEL_ICONS = "FETCH_PANEL_ICONS";

// SIDEPANEL
export const SET_NEWS_FEED_FORM = "SET_NEWS_FEED_FORM";
export const SET_NEWS_FEEDS = "SET_NEWS_FEEDS";

// MAIN FEED MEDIA (BACKGROUND IMAGES)
export const SET_MAIN_FEED_MEDIA_LIST = "SET_MAIN_FEED_MEDIA_LIST";

// MAIN FEED
export const SET_MAIN_FEED_FORM = "SET_MAIN_FEED_FORM";
export const SET_MAIN_FEEDS = "SET_MAIN_FEEDS";

// MAP
export const SET_CLIENT_PROPERTIES = "SET_CLIENT_PROPERTIES";

/** ******************************
CAMPAIGN CREATION
******************************** */

// step1, configuration

export const SET_CAMPAIGN_CONFIG = "SET_CAMPAIGN_CONFIG";
export const SET_CONFIG_STATUS = "SET_CONFIG_STATUS";

// ad stack
export const SET_AD_LIST = "SET_AD_LIST";
export const SET_SINGLE_AD = "SET_SINGLE_AD";
export const SET_AD_STATUS = "SET_AD_STATUS";

// Campaign General
export const SET_CAMPAIGNS = "SET_CAMPAIGNS";
