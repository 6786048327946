import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import withRouter from "@hooks/withRouter";
import FormGenerator from "@components/Global/Forms/formGenerator";
import * as FormConfig from "@components/Advertiser/services/formFields";
import LoadingPage from "@components/Global/General/loadingPage";

// Actions
import {
  initiateAdvertiserForm,
  updateAdvertiser,
  createAdvertiser,
  updatingAdvertiserForm,
  clearAdvertiserForm,
} from "../services/actions/advertiserActions";

class AdvertiserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formMode: "",
    };
    this.submitAction = this.submitAction.bind(this);
  }

  UNSAFE_componentWillMount() {
    const currentRoute = this.props.router.location.pathname;
    if (currentRoute === "advertisers/new") {
      this.props.initiateAdvertiserForm(null);
      this.setState({ formMode: "Create" });
    } else {
      this.props.initiateAdvertiserForm(this.props.router.params.id);
      this.setState({ formMode: "Edit" });
    }
  }

  componentWillUnmount() {
    this.props.clearAdvertiserForm();
  }

  submitAction() {
    if (!this.props.advertiserForm.formId) {
      this.props.createAdvertiser(this.props.advertiserForm, this.props.router);
    } else {
      this.props.updateAdvertiser(this.props.advertiserForm, this.props.router);
    }
  }

  render() {
    if (!this.props.advertiserForm) {
      return <LoadingPage pageName="Advertiser" />;
    }

    if (this.props.advertiserForm.state === "ERROR") {
      return <div> Please make sure Ad block is turned off </div>;
    }

    return (
      <FormGenerator
        formStyle={FormConfig.ADVERTISER_FORM_STYLE}
        formDataFields={FormConfig.ADVERTISER_FIELDS}
        currentForm={this.props.advertiserForm}
        updateFormAction={this.props.updatingAdvertiserForm}
        formId={this.props.advertiserForm.formId}
        formMode={this.state.formMode}
        exitRoute="/advertisers"
        createButtonName="Create"
        enableDelete={false}
        deleteAction={null}
        submitAction={this.submitAction}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    advertiserForm: state.advertisers.form,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      initiateAdvertiserForm,
      updateAdvertiser,
      createAdvertiser,
      updatingAdvertiserForm,
      clearAdvertiserForm,
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdvertiserForm));
