export const BIG_NOTICE_FREQUENCY_VALUE_MAP = {
  low: 1,
  regular: 2,
  high: 3,
  very_high: 4,
};

export const PROPERTY_ROLES = ["property_user", "non_vc_property_user"];

export const CAMPAIGN_TYPE_MAP = [
  { value: "advertising", label: "Advertising" },
  { value: "complimentary", label: "Complimentary" },
  { value: "internal", label: "Internal" },
  { value: "property", label: "Property Notices" },
  { value: "rsm_local", label: "RSM Local" },
  { value: "rsm_national", label: "RSM National" },
];

export const RSM_CAMPAIGN_TYPE_MAP = [
  { value: "rsm_local", label: "RSM Local" },
  { value: "rsm_national", label: "RSM National" },
];

export const PROPERTY_PAYMENT_METHODS = [
  { value: "cheque", label: "Cheque" },
  { value: "eft", label: "EFT" },
];

export const PROPERTY_SALES_TAX = [
  { value: "hst", label: "HST" },
  { value: "gst", label: "GST" },
  { value: "pst", label: "PST" },
  { value: "qst", label: "QST" },
];

export const MONETIZED_CAMPAIGN_TYPES = ["advertising", "rsm_local", "rsm_national"];
export const REPORTING_DISABLED_TYPES = ["internal", "property"];

export const LOOP_SECONDS = 230;
export const MILLISECONDS_PER_SECOND = 1000;
export const SECONDS_PER_MINUTE = 60;
export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * 60;
export const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24;
export const SECONDS_PER_WEEK = SECONDS_PER_DAY * 7;
// Subtract 1 second because campaigns end at 23:59:59.
export const LOOPS_PER_WEEK = (SECONDS_PER_WEEK - 1) / LOOP_SECONDS;

export const FULL_SCREEN_RATE = 1.3;

export const MAX_IMAGE_FILE_SIZE = 10 * 1000 * 1000; // 10 MB
