import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

/* Form Generator Widgets */
import Header from "@components/Interface/MainList/header";
import SubmitButtonForm from "../Widgets/views/submitButtonForm";
import TextBox from "../Widgets/views/textBox";
import ImageUploader from "../Widgets/views/imageUploader";
import CheckBox from "../Widgets/views/checkBox";
import DropDownWidget from "../Widgets/views/dropDown";

class FormGenerator extends Component {
  constructor(props) {
    super(props);
    this.validateForm = this.validateForm.bind(this);
    this.checkValid = this.checkValid.bind(this);
    this.filterNull = this.filterNull.bind(this);
  }

  filterNull(currList, checkForm) {
    for (let i = currList.length - 1; i >= 0; i -= 1) {
      const currInput = currList[i];
      if (!(currInput in checkForm)) {
        currList.splice(i, 1);
      }
    }
    return currList;
  }

  // VALIDATING FORM FIELDS
  checkValid() {
    // Remove Nulls from the current form so it only has valid data to check
    const checkForm = _.extend({}, this.props.currentForm);
    Object.keys(checkForm).forEach((key) => checkForm[key] === null && delete checkForm[key]);

    // TEXT VALIDATION: Must be longer than 0
    let textInputs = _.map(
      _.filter(this.props.formDataFields, { formType: "text", validate: true }),
      "fieldName"
    );

    // removing nulls from text input
    textInputs = this.filterNull(textInputs, checkForm);

    let validText = true;
    for (let i = 0; i < textInputs.length; i += 1) {
      const currKey = textInputs[i];
      const currValue = checkForm[currKey];
      const newValue = `${currValue}`;
      if (!newValue.trim().length) {
        validText = false;
        break;
      }
    }

    // DROPDOWN VALIDATION:
    // Boolean, must have a boolean
    // Others (Custom and Table), must be a string, longer than 1
    let tableDropDownInputs = _.map(
      _.filter(this.props.formDataFields, {
        formType: "dropDown",
        validate: true,
        dropDownType: "Table",
      }),
      "fieldName"
    );
    let customDropDownInputs = _.map(
      _.filter(this.props.formDataFields, {
        formType: "dropDown",
        validate: true,
        dropDownType: "Custom",
      }),
      "fieldName"
    );
    let boolDropDownInputs = _.map(
      _.filter(this.props.formDataFields, {
        formType: "dropDown",
        validate: true,
        dropDownType: "Boolean",
      }),
      "fieldName"
    );
    // removing null
    tableDropDownInputs = this.filterNull(tableDropDownInputs, checkForm);
    customDropDownInputs = this.filterNull(customDropDownInputs, checkForm);
    boolDropDownInputs = this.filterNull(boolDropDownInputs, checkForm);

    const validDropDownTable = tableDropDownInputs.every((v) => checkForm[v]);
    const validDropDownCustom = customDropDownInputs.every((v) => checkForm[v]);
    const validDropDownBool = boolDropDownInputs.every((v) => typeof checkForm[v] === "boolean");

    // MEDIA VALIDATION: Media cannot be null
    const mediaInputs = _.map(
      _.filter(this.props.formDataFields, {
        formType: "media",
        validate: true,
      }),
      "fieldName"
    );
    const validMedia = mediaInputs.every((v) => checkForm[v]);

    // DATE VALIDATION, if there are 2 dates, check that startDate != EndDate

    // TABLE VALIDATION, make sure atleast 1 is selected
    // var tableInput = _.filter(checkForm, {'formType':'table', 'validate':true});

    // If all fields are valid, submit the action

    if (validText && validMedia && validDropDownBool && validDropDownCustom && validDropDownTable) {
      this.props.submitAction();
    }
  }

  validateForm() {
    const validated = this.checkValid();
    if (validated) {
      this.props.submitAction();
    }
  }

  // Rendering Form Fields
  renderField(item) {
    if (item.formType === "header") {
      return (
        <div className="header-column">
          <hr />
          <div className="column-header">
            <h2>{item.title} </h2>
          </div>
        </div>
      );
    }
    if (item.formType === "small-header") {
      return (
        <div className="small-header-column">
          <h2>{item.title} </h2>
        </div>
      );
    }
    if (this.props.currentForm[item.fieldName] === null) {
      return null;
    }
    if (item.formType === "text") {
      return (
        <TextBox
          textValue={this.props.currentForm[item.fieldName]}
          updateForm={this.props.updateFormAction}
          formPropertyName={item.fieldName}
          description={item.description}
          placeHolder={item.placeHolder}
          inputType={item.formType}
          formColor={this.props.formStyle.formColor}
          formProps={this.props.currentForm}
          formWidth={item.formWidth}
          required={item.validate}
        />
      );
    }
    if (item.formType === "checkBox") {
      return (
        <CheckBox
          checkValue={this.props.currentForm[item.fieldName]}
          label={item.title}
          formPropertyName={item.fieldName}
          updateForm={this.props.updateFormAction}
          formProps={this.props.currentForm}
        />
      );
    }
    if (item.formType === "dropDown") {
      let newDataField = "";
      if (item.dropDownType === "Table") {
        newDataField = this.props.currentForm.DropDownData[item.dropDownTableName];
      } else {
        newDataField = item.options;
      }
      return (
        <DropDownWidget
          formValue={this.props.currentForm[item.fieldName]}
          formPropertyName={item.fieldName}
          currentForm={this.props.currentForm}
          required={item.validate}
          galleryColor={this.props.formStyle.formColor}
          formWidth={item.formWidth}
          updateForm={this.props.updateFormAction}
          multiSelect={item.multiSelect}
          title={item.title}
          options={newDataField}
          formType={item.dropDownType}
        />
      );
    }

    return null;
  }

  renderImages(item) {
    return (
      <div>
        <ImageUploader
          maxWidth={item.maxWidth}
          fileSizeText={item.fileSizeText}
          fileMaxSize={item.fileMaxSize}
          dimensions={item.dimensions}
          acceptedFiles={item.acceptedFiles}
          currMedia={this.props.currentForm[item.fieldName]}
          imageWidth={item.imageWidth}
          imageHeight={item.imageHeight}
          imageTitle={item.imageTitle}
          updateFormAction={this.props.updateFormAction}
          formPropertyName={item.fieldName}
          currentForm={this.props.currentForm}
          imageCategory={item.imageCategory}
          required={item.validate}
        />
      </div>
    );
  }

  render() {
    const {
      formStyle,
      formDataFields,
      formId,
      exitRoute,
      deleteAction,
      enableDelete,
      createButtonName,
    } = this.props;

    // Get Media Fields
    const mediaFields = _.filter(formDataFields, { formType: "media" });

    return (
      <div className="fillOut-form">
        <Header bgColor="#fff" title={formStyle.subject} titleColor="#000" buttonColor={null} />

        <div className="form-container">
          <div className="flex-center full-width">
            {_.map(mediaFields, this.renderImages.bind(this))}
          </div>

          {_.map(formDataFields, this.renderField.bind(this))}

          <SubmitButtonForm
            formId={formId}
            category={formStyle.subject}
            formColor={formStyle.formColor}
            exitRoute={exitRoute}
            enableDelete={enableDelete}
            createButtonName={createButtonName}
            deleteAction={deleteAction}
            createAction={this.validateForm}
            updateAction={this.validateForm}
          />
        </div>
      </div>
    );
  }
}

export default connect(null, null)(FormGenerator);
