import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { Line } from "rc-progress";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { REPORTING_DISABLED_TYPES } from "@components/constants";
import BasicTooltip from "@components/Global/Widgets/views/basicTooltip";
import SimpleImagePreview from "@components/Global/Widgets/views/simpleImagePreview";

class GeneralTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      currRow: "",
    };
    this.renderTableTitle = this.renderTableTitle.bind(this);
    this.renderTableData = this.renderTableData.bind(this);
    this.renderSingleItem = this.renderSingleItem.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.delete = this.delete.bind(this);
  }

  close() {
    this.setState({ showModal: false });
  }

  open(currRow) {
    this.setState({ showModal: true, currRow });
  }

  delete() {
    this.props.deleteAction(this.state.currRow);
    this.close();
  }

  renderTableTitle(tableFormat) {
    let extraCss = "";
    if (
      tableFormat.fieldType === "iconLink" ||
      tableFormat.fieldType === "iconLinkList" ||
      tableFormat.fieldType === "campaign-progress" ||
      tableFormat.fieldValue === "state" ||
      tableFormat.fieldValue === "status"
    ) {
      extraCss = "center-text";
    }
    return (
      <div
        className={`cell ${extraCss}`}
        key={uuidv4()}
        data-title="Name"
        style={{ width: tableFormat.columnSize }}
      >
        {tableFormat.columnTitle}
      </div>
    );
  }

  renderTableData(rowData) {
    return (
      <div key={uuidv4()} className="row">
        {this.props.tableFormat.map(this.renderCell.bind(this, rowData))}
      </div>
    );
  }

  renderCell(rowData, tableFormat) {
    let extraCss = "";
    if (
      tableFormat.fieldType === "iconLink" ||
      tableFormat.fieldType === "campaign-progress" ||
      tableFormat.fieldValue === "state" ||
      tableFormat.fieldValue === "status"
    ) {
      extraCss = "center-text";
    }

    return (
      <div key={uuidv4()} className={`cell ${extraCss} text-md-start text-center`}>
        {this.renderSingleItem(rowData, tableFormat)}
      </div>
    );
  }

  // RENDERING THE FIELD TYPE
  renderSingleItem(rowData, tableFormat) {
    const { t } = this.props;

    const { fieldType, fieldValue, extraCss } = tableFormat;

    if (fieldType === "text") {
      return <div className={`text ${extraCss}`}>{rowData[fieldValue]}</div>;
    }

    if (fieldType === "component") {
      return rowData[fieldValue];
    }

    if (fieldType === "iconLink") {
      return (
        <div className={`iconLink ${extraCss}`}>
          <i
            className={`fa fa-lg ${tableFormat.iconName}`}
            aria-hidden="true"
            onClick={tableFormat.linkAction.bind(this, rowData)}
          />
        </div>
      );
    }

    if (fieldType === "iconLinkList") {
      const startDay = moment(rowData.start_at).startOf("day");
      const currDay = moment();
      const reportingEnabled = !REPORTING_DISABLED_TYPES.includes(rowData.campaign_type);

      const contents = fieldValue.map((iconLink) => {
        if (
          iconLink.linkType === "analytics" &&
          !(reportingEnabled && startDay > moment("06-25-2018", "MM-DD-YYYY") && startDay < currDay)
        ) {
          return (
            <div key={uuidv4()} className="iconLink">
              <BasicTooltip message={`${iconLink.tooltip} not available`}>
                <i
                  className={`fa fa-lg ${iconLink.iconName} no-link`}
                  aria-hidden="true"
                  onClick={() => {}}
                />
              </BasicTooltip>
            </div>
          );
        }
        return (
          <div key={uuidv4()} className="iconLink">
            <BasicTooltip message={iconLink.tooltip}>
              <i
                className={`fa fa-lg ${iconLink.iconName}`}
                aria-hidden="true"
                onClick={() => iconLink.linkAction(rowData)}
              />
            </BasicTooltip>
          </div>
        );
      });

      return <div className={`iconLinkList ${extraCss}`}>{contents}</div>;
    }

    // textLink
    if (fieldType === "textLink") {
      let name = tableFormat.staticText;
      if (tableFormat.hasValue) {
        name = rowData[fieldValue];
      }
      return (
        <div
          className={`textLink ${extraCss}`}
          onClick={tableFormat.linkAction.bind(this, rowData)}
        >
          {name}
        </div>
      );
    }

    // image
    if (fieldType === "image") {
      return (
        <div className="image-preview center-text">
          <SimpleImagePreview
            imageTitle={null}
            imagePreview={rowData[fieldValue]}
            imageWidth={tableFormat.width}
            imageHeight={tableFormat.height}
          />
        </div>
      );
    }

    // padding
    if (fieldType === "padding") {
      return <div />;
    }

    // Progress Bar (Campaign Specific)
    if (fieldType === "campaign-progress") {
      // need full percent, current value, sentence

      const currentDay = moment().startOf("day");
      const endDay = moment(rowData.end_at).endOf("day");
      const startDay = moment(rowData.start_at).startOf("day");
      let daysDone = Math.abs(startDay.diff(currentDay, "days"));
      const dateRange = Math.abs(endDay.diff(startDay, "days")) + 1;

      let percentage = (daysDone / dateRange) * 100;

      if (currentDay < startDay) {
        daysDone = 0;
        percentage = 0;
      } else if (currentDay > endDay) {
        daysDone = dateRange;
        percentage = 100;
      }

      // set Color
      let strokeColor = "";

      switch (true) {
        case percentage < 16:
          strokeColor = "#FFA144";
          break;
        case percentage < 32:
          strokeColor = "#FFD230";
          break;
        case percentage < 48:
          strokeColor = "#B1E21D";
          break;
        case percentage < 64:
          strokeColor = "#2dd893";
          break;
        case percentage < 80:
          strokeColor = "#1679fd";
          break;
        case percentage < 100:
          strokeColor = "#6821BA";
          break;
        default:
          strokeColor = "#3030D3";
          break;
      }

      return (
        <div className="campaign-progress-bar">
          <p>
            <b>{daysDone}</b>/{dateRange} {t("keywords.dateRelated.days")}
          </p>
          <Line percent={percentage} strokeWidth="4" strokeColor={strokeColor} />
        </div>
      );
    }

    // button
    if (fieldType === "button") {
      return (
        <div>
          {rowData[tableFormat.buttonDetails.show] ? (
            <button
              type="button"
              className={`btn ${tableFormat.buttonDetails.style}`}
              onClick={tableFormat.buttonDetails.buttonAction.bind(this, rowData)}
            >
              {tableFormat.buttonDetails.label}
            </button>
          ) : null}
        </div>
      );
    }
    // boolean, shows a circle

    if (fieldType === "boolean") {
      if (rowData[fieldValue]) {
        return <div className="true-circle"> </div>;
      }
      return <div className="false-circle"> </div>;
    }

    // edit
    if (fieldType === "edit") {
      return (
        <div className="editBox">
          {tableFormat.editAction ? (
            <Link to={tableFormat.editAction.replace(":id", rowData.id)}>
              <Button variant="outline-success" size="sm me-1">
                {t("keywords.buttonActions.edit")}
              </Button>
            </Link>
          ) : null}
          {tableFormat.deleteAction ? (
            <Button variant="outline-danger" size="sm mt-0" onClick={this.open.bind(this, rowData)}>
              {t("keywords.buttonActions.delete")}
            </Button>
          ) : null}
        </div>
      );
    }

    // no valid type was passed in
    return null;
  }

  render() {
    const { tableData, tableFormat, t } = this.props;

    return (
      <div>
        {tableData.length === 0 ? (
          <div className="noscroll-table-wrapper">
            <div className="general-table">
              <div className="row header">{tableFormat.map(this.renderTableTitle.bind(this))}</div>
            </div>
            <div className="no-results">
              <i className="fa fa-search" aria-hidden="true" />
              {t("keywords.general.noResults")}
            </div>
          </div>
        ) : (
          <div className="noscroll-table-wrapper">
            <div className="general-table">
              {/* TABLE HEADER */}
              <div className="row header">{tableFormat.map(this.renderTableTitle.bind(this))}</div>

              {/* TABLE ROWS */}
              {tableData.map(this.renderTableData.bind(this))}
            </div>

            <Modal show={this.state.showModal} onHide={this.close}>
              <Modal.Header closeButton>
                <Modal.Title> {t("keywords.buttonActions.deleting")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("keywords.buttonActions.deleteMessage")}</Modal.Body>
              <Modal.Footer>
                <Button variant="outline-secondary" onClick={this.close}>
                  {t("keywords.buttonActions.cancel")}
                </Button>
                <Button variant="danger m-0" onClick={this.delete.bind(this)}>
                  {t("keywords.buttonActions.delete")}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, null)(withTranslation()(GeneralTable));
