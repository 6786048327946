import { differenceInSeconds, format, endOfDay, startOfDay, parse } from "date-fns";
import { enCA, frCA } from "date-fns/locale";
import i18n from "../i18n";
import { SECONDS_PER_WEEK, SECONDS_PER_DAY } from "../components/constants";

const LOCALE_MAP = {
  "en-CA": enCA,
  "fr-CA": frCA,
};

const API_DATETIME_FORMAT = "yyyy-MM-dd HH:mm:ss";
const STRING_FORMAT_OPTIONS = { year: "numeric", month: "long", day: "numeric" };

export function formDateAsApiData(dateString, modifier = null) {
  if (!dateString) return null;

  let apiDate = parseLocaleDate(dateString);

  if (modifier === "startOfDay") {
    apiDate = startOfDay(apiDate);
  }

  if (modifier === "endOfDay") {
    apiDate = endOfDay(apiDate);
  }

  return format(apiDate, API_DATETIME_FORMAT);
}

export function apiDateAsFormData(apiDateTime) {
  if (!apiDateTime) return "";

  const dateTime = parseApiDate(apiDateTime);
  return dateToString(dateTime);
}

export function dateToString(dateTime) {
  // date-fns.format() should be able to do this as well, but French formatters are unavailable
  return dateTime.toLocaleDateString(i18n.t("language.locale"), STRING_FORMAT_OPTIONS);
}

export function apiDurationInDays(startDate, endDate) {
  if (!startDate || !endDate) return null;

  const start = parseApiDate(startDate);
  const end = parseApiDate(endDate);

  return dateDurationInDays(start, end);
}

export function formDurationInWeeks(startDate, endDate) {
  if (!startDate || !endDate) return null;

  const start = parseLocaleDate(startDate);
  const end = parseLocaleDate(endDate);

  return dateDurationInWeeks(start, end);
}

export function formDurationInDays(startDate, endDate) {
  if (!startDate || !endDate) return null;

  const start = parseLocaleDate(startDate);
  const end = parseLocaleDate(endDate);

  return dateDurationInDays(start, end);
}

export function formDurationInSeconds(startDate, endDate) {
  if (!startDate || !endDate) return null;

  const start = parseLocaleDate(startDate);
  const end = parseLocaleDate(endDate);

  return dateDurationInSeconds(start, end);
}

export function minFormDate(formDates) {
  const dates = formDates.map((formDate) => parseLocaleDate(formDate).getTime());
  const minDate = Math.min(...dates);
  return dateToString(new Date(minDate));
}

export function maxFormDate(formDates) {
  const dates = formDates.map((formDate) => parseLocaleDate(formDate).getTime());
  const maxDate = Math.max(...dates);
  return dateToString(new Date(maxDate));
}

export function now() {
  return dateToString(new Date());
}

export function addDaysToCurrentDay(additionalDays) {
  const date = new Date();
  date.setDate(date.getDate() + additionalDays);
  return dateToString(date);
}

export function parseApiDate(apiDateString) {
  return parse(apiDateString, API_DATETIME_FORMAT, new Date());
}

export function parseLocaleDate(localeDateString) {
  return parse(localeDateString, i18n.t("language.dateFormat"), new Date(), {
    locale: LOCALE_MAP[i18n.t("language.locale")],
  });
}

function dateDurationInWeeks(startDate, endDate) {
  const diff = dateDurationInSeconds(startDate, endDate);
  return diff / SECONDS_PER_WEEK;
}

function dateDurationInDays(startDate, endDate) {
  const diff = dateDurationInSeconds(startDate, endDate);
  return diff / SECONDS_PER_DAY;
}

function dateDurationInSeconds(startDate, endDate) {
  return differenceInSeconds(endOfDay(endDate), startOfDay(startDate));
}
