// ALL ACTIONS FOR PROPERTIES
import _ from "lodash";
import moment from "moment";

import { SET_CAMPAIGNS } from "../../../../../App/actionTypes";
import Elev8ApiService from "../../../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../../../lib/services/Elev8Api/lib";

/** ******************************************
REDUCER
******************************************** */

export function setCampaigns(campaigns) {
  return {
    type: SET_CAMPAIGNS,
    payload: campaigns,
  };
}

/** ******************************************
INITIALIZATION
******************************************** */

export function fetchCampaigns(sparseFieldSets, queryParams) {
  return function (dispatch) {
    // TODO make the filter format more intuitive ie. nested objects
    Elev8ApiService.index(ApiTypes.campaign, sparseFieldSets, queryParams)
      .then((response) => {
        // add state to campaigns
        const today = moment();
        let campaignData = response;
        campaignData = _.sortBy(campaignData, (o) => {
          return new moment(o.attributes.start_at).format("YYYY-MM-DD");
        }).reverse();

        const convertedCampaignData = campaignData.map((singleItem) => {
          const campaign = singleItem.attributes;
          const owner = singleItem.owner();
          const client = singleItem.advertiser();
          const campaignGroup = singleItem.campaign_group();

          campaign.owner = owner && owner.attributes.first_name;
          campaign.owner_id = parseInt(owner && owner.attributes.id, 10);
          campaign.client = client && client.attributes.name;
          campaign.advertiser_id = parseInt(client && client.attributes.id, 10);
          campaign.campaignGroupId = campaignGroup && campaignGroup.attributes.id;
          campaign.campaignGroupName = campaignGroup && campaignGroup.attributes.name;
          campaign.tailoredStartDate = moment(campaign.start_at).format("MMMM DD YYYY");
          campaign.tailoredEndDate = moment(campaign.end_at).format("MMMM Do YYYY");
          campaign.progress = 0;
          if (campaign.status == "inactive") {
            campaign.state = "Draft";
          } else if (today.isAfter(campaign.end_at)) {
            campaign.state = "Complete";
            campaign.progress = 100;
          } else if (today.isBefore(campaign.start_at)) {
            campaign.state = "Pending";
          } else {
            campaign.state = "Active";
            // get date difference and percentage
            // current day/end
            const currentDay = moment();
            const startDay = moment(campaign.start_at);
            const endDay = moment(campaign.end_at);
            const daysDone = Math.abs(startDay.diff(currentDay, "days"));
            const dateRange = Math.abs(endDay.diff(startDay, "days")) + 1;
            const percentage = (daysDone / dateRange) * 100;
            campaign.progress = percentage;
          }

          return campaign;
        });

        dispatch(setCampaigns(convertedCampaignData));
      })
      .catch((e) => {
        console.log("Can't fetch campaign", e);
      });
  };
}
