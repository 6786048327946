import React, { Component } from "react";
import { connect } from "react-redux";
import Alert from "react-bootstrap/Alert";
import { withTranslation } from "react-i18next";

import { removeFlashMessage } from "../../../Interface/General/services/actions/applicationStateActions";

class FlashMessage extends Component {
  renderFlash = (flash) => {
    const handleDismiss = () => this.props.removeFlashMessage(flash);
    const { t } = this.props;

    return (
      <Alert
        key={flash.flashMessageId}
        className="m-2"
        variant={flash.type}
        onClose={handleDismiss}
        dismissible
      >
        {t(flash.message)}
      </Alert>
    );
  };

  render() {
    return (
      <div className="flash-message">
        {this.props.flashMessages.map((flash) => this.renderFlash(flash))}
      </div>
    );
  }
}

export default connect(null, { removeFlashMessage })(withTranslation()(FlashMessage));
