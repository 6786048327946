/* Preview Images with Title on the Bottom */

import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

class SimpleImagePreview extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { imageTitle, imagePreview, imageWidth, imageHeight } = this.props;

    return (
      <div>
        <div className="simple-image-preview" style={{ width: imageWidth, height: imageHeight }}>
          <img src={imagePreview} />
        </div>
        {this.props.imageTitle ? <h4 className="text-center"> {imageTitle} </h4> : null}
      </div>
    );
  }
}

export default connect(null, null)(SimpleImagePreview);
