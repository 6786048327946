import React, { Component } from "react";
import { connect } from "react-redux";
import Fuse from "fuse.js";

import Header from "@components/Interface/MainList/header";
import GeneralTable from "@components/Interface/MainList/generalTable";
import LoadingPage from "@components/Global/General/loadingPage";
import { fetchRssFeeds } from "../services/actions/rssFeedActions";

class RssFeedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
    };
    this.updateSearch = this.updateSearch.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.fetchRssFeeds();
  }

  updateSearch(event) {
    this.setState({ searchValue: event.target.value });
  }

  render() {
    if (!this.props.feeds) {
      return <LoadingPage pageName="Rss Feeds" />;
    }

    const tableFormat = [
      {
        columnTitle: "",
        columnSize: "2%",
        fieldType: "padding",
      },
      {
        columnTitle: "Title",
        columnSize: "20%",
        fieldType: "text",
        fieldValue: "name",
        isBold: false,
      },
      {
        columnTitle: "Icon (Legacy)",
        columnSize: "20%",
        fieldType: "image",
        fieldValue: "iconUrl",
        width: 50,
        height: 50,
      },
      {
        columnTitle: "Banner",
        columnSize: "20%",
        fieldType: "image",
        fieldValue: "bannerUrl",
        width: 100,
        height: 50,
      },
      {
        columnTitle: "Signature",
        columnSize: "20%",
        fieldType: "image",
        fieldValue: "signatureUrl",
        width: 100,
        height: 50,
      },
      {
        columnTitle: "Url",
        columnSize: "55%",
        fieldType: "text",
        fieldValue: "url",
        isBold: false,
      },
      {
        columnTitle: "",
        columnSize: "5%",
        fieldType: "edit",
        editAction: "/rss-feeds/edit/:id",
        deleteAction: null,
      },
    ];

    const options = {
      keys: ["name"],
      threshold: 0.1,
      ignoreLocation: true,
    };

    let searchResult = this.props.feeds;

    if (this.state.searchValue.trim() !== "") {
      const fuse = new Fuse(this.props.feeds, options);
      searchResult = fuse.search(this.state.searchValue);
      searchResult = searchResult.map((result) => result.item);
    }

    return (
      <div>
        <Header
          title="Rss Feeds"
          newRoute="/rss-feeds/new"
          count={searchResult.length}
          searchEnabled
          searchValue={this.state.searchValue}
          searchAction={this.updateSearch}
        />
        <GeneralTable tableData={searchResult} tableFormat={tableFormat} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    feeds: state.client.rssFeed.all,
  };
}

export default connect(mapStateToProps, { fetchRssFeeds })(RssFeedList);
