import {
  SET_FLASH_MESSAGE,
  REMOVE_FLASH_MESSAGE,
  SHOW_SAVING_MODAL,
} from "../../../../../App/actionTypes";

/** ****************
 Application State Reducer
 **************** */

const INITIAL_STATE = {
  flashMessages: [],
  currentlySaving: false,
};

function handleSetFlashMessage(state, payload) {
  const flashMessages = state.flashMessages.concat([payload]);

  return { ...state, flashMessages };
}

function handleRemoveFlashMessage(state, payload) {
  const flashMessages = state.flashMessages.filter((message) => {
    return message.flashMessageId !== payload.flashMessageId;
  });

  return { ...state, flashMessages };
}

function handleCurrentlySaving(state, payload) {
  return { ...state, currentlySaving: payload };
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_FLASH_MESSAGE:
      return handleSetFlashMessage(state, action.payload);
    case REMOVE_FLASH_MESSAGE:
      return handleRemoveFlashMessage(state, action.payload);
    case SHOW_SAVING_MODAL:
      return handleCurrentlySaving(state, action.payload);
    default:
      return state;
  }
}
