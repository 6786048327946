import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import PropertySelectionByTableFilters from "./PropertySelectionByTableFilters";
import CampaignPropertyTable from "./CampaignPropertyTable";

class PropertySelectionByTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryAttributes: [],
      filteredProperties: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.updateFilteredProperties();
  }

  isNumeric(filterValue) {
    return !isNaN(parseFloat(filterValue)) && isFinite(filterValue);
  }

  parseFilterValue(filterValue) {
    if (filterValue === "true" || filterValue === "yes") {
      return true;
    }

    if (filterValue === "false" || filterValue === "no") {
      return false;
    }

    if (this.isNumeric(filterValue)) {
      return Number(filterValue);
    }

    return filterValue;
  }

  updateFilteredProperties() {
    const filteredProperties = this.props.availableProperties.filter((property) => {
      for (const query of this.state.queryAttributes) {
        const attributes = property.attributes;
        const key = query[0];
        const operator = query[1];
        const value = this.parseFilterValue(query[2]);

        if (attributes[key] !== null) {
          switch (operator) {
            case ">":
              if (attributes[key] <= value) return false;
              break;
            case "<":
              if (attributes[key] >= value) return false;
              break;
            case "=":
              if (attributes[key] != value) return false;
              break;
            case "!=":
              if (attributes[key] == value) return false;
              break;
          }
        }
      }
      return true;
    });
    this.setState({ filteredProperties: filteredProperties });
  }

  setSelectedRows = (data) => {
    this.props.onSelectedRowsChange(data);
  };

  updateQueryAttributes = (queryAttributes) => {
    this.setState({ queryAttributes }, () => this.updateFilteredProperties());
  };

  render() {
    const {
      onSelectedRowsChange,
      selectedPropertyIds,
      availableProperties,
      startAt,
      endAt,
      loopSpotsPerProperty,
      baseRate,
      currentRateValue,
      fullScreen,
    } = this.props;
    return (
      <>
        <PropertySelectionByTableFilters
          properties={availableProperties}
          queryAttributes={this.state.queryAttributes}
          updateQueryAttributes={this.updateQueryAttributes}
        />
        <CampaignPropertyTable
          properties={this.state.filteredProperties}
          selectedRows={selectedPropertyIds}
          onSelectedRowsChange={onSelectedRowsChange}
          startAt={startAt}
          endAt={endAt}
          loopSpotsPerProperty={loopSpotsPerProperty}
          baseRate={baseRate}
          currentRateValue={currentRateValue}
          fullScreen={fullScreen}
        />
      </>
    );
  }
}

PropertySelectionByTable.defaultProps = {
  selectedPropertyIds: [],
  baseRate: null,
  fullScreen: "false",
};

PropertySelectionByTable.propTypes = {
  baseRate: PropTypes.string,
  currentRateValue: PropTypes.number.isRequired,
  fullScreen: PropTypes.oneOf(["true", "false"]),
  availableProperties: PropTypes.array.isRequired,
  selectedPropertyIds: PropTypes.object,
  onSelectedRowsChange: PropTypes.func.isRequired,
  startAt: PropTypes.string.isRequired,
  endAt: PropTypes.string.isRequired,
  loopSpotsPerProperty: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
};

export default withTranslation()(PropertySelectionByTable);
