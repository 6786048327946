import { JsonApiObject } from "../index";

export class CampaignReport extends JsonApiObject {
  constructor(apiObject, included) {
    super(apiObject, included);
    this.looker_url = apiObject.attributes.looker_url;
  }

  getLookerUrl() {
    return this.looker_url;
  }
}
