import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "@hookform/error-message";
import Button from "react-bootstrap/Button";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import PropertyWithAddressTableSelector from "../../../Global/Widgets/updatedWidgets/PropertyWithAddressTableSelector";
import ImageGalleryPicker from "../../../Global/Widgets/views/ImageGalleryPicker";

export default function ScreenDisplaySettings({
  properties,
  backgroundImages,
  uploadBackgroundImage,
  backgroundImageUploadStatus,
  deleteBackgroundImage,
  backgroundImagesQuickLookup,
}) {
  const { t } = useTranslation();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const landscapeDimensions = "1920 x 1080px";
  const portraitDimensions = "1080 x 1920px";
  const mCityDimensions = "1080 x 1096px";

  const selectedPropertyIds = useWatch({
    control,
    name: "property_ids",
  });

  const backgroundId = useWatch({
    control,
    name: "background_id",
  });

  const contentType = useWatch({
    control,
    name: "content_type",
  });

  function renderDisplayOptions() {
    return (
      <div className="flex align-items-center mb-3">
        <h5 className="mr-3">{t("noticeTerms.selectType")}</h5>
        <Controller
          name="content_type"
          control={control}
          render={({ field: { onChange } }) => (
            <div className="radio-group">
              {["text", "image"].map((text) => {
                const isSelected = contentType === text;
                /**
                 * @todo Update the radio group so that it can support localization
                 */
                const buttonDescription = `${text.charAt(0).toUpperCase()}${text.slice(1)}  Notice`;
                return (
                  <Button
                    value={text}
                    className="mr-2"
                    onClick={() => onChange(text)}
                    variant={`${isSelected ? "primary" : "outline-primary"}`}
                  >
                    {buttonDescription}
                  </Button>
                );
              })}
            </div>
          )}
        />
      </div>
    );
  }

  function renderDisplaySettings() {
    return (
      <div>
        {renderDisplayOptions()}
        {contentType === "text" ? (
          <div> {renderTextDisplaySettings()}</div>
        ) : (
          <div> {renderImageDisplaySettings()} </div>
        )}
      </div>
    );
  }

  function renderImageDisplaySettings() {
    return (
      <>
        <h6>{t("imageTerms.selectImage")}</h6>
        <Controller
          control={control}
          name="background_id"
          rules={{
            validate: {
              nullBackgroundId: (backgroundIdValue) =>
                (backgroundIdValue && contentType === "image") ||
                `*** ${t("imageTerms.missingImageError")}`,
              invalidBackgroundId: (backgroundIdValue) =>
                backgroundImagesQuickLookup[backgroundIdValue] ||
                `*** ${t("imageTerms.missingImageError")}`,
            },
          }}
          render={({ field: { onChange } }) => (
            <ImageGalleryPicker
              uploadImage={uploadBackgroundImage}
              imageUploadStatus={backgroundImageUploadStatus}
              imageIdSelected={backgroundId}
              onSelectImage={onChange}
              images={backgroundImages}
              deleteImage={deleteBackgroundImage}
              borderStyle={errors.background_id ? "error-border" : ""}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="background_id"
          message="Error"
          render={({ message }) => <b className="error">{message}</b>}
        />
        <p className="mt-3">
          <p>
            <b>{t("imageTerms.format")}: </b>
            PNG/JPG, {t("fileTerms.sizeInMB", { size: 10 })}
          </p>
          <b>{t("imageTerms.screenDimensions")}:</b> {t("imageTerms.landscape")}:{" "}
          {landscapeDimensions}, {t("imageTerms.portrait")}: {portraitDimensions}, M-City:{" "}
          {mCityDimensions}
        </p>
      </>
    );
  }

  function isValidTextContent(value) {
    return value.trim().length > 0 && contentType === "text";
  }

  function renderTextDisplaySettings() {
    const validateContent = (value) =>
      isValidTextContent(value) || `*** ${t("keywords.general.contentMissingError")}`;
    const validateTitle = (value) =>
      isValidTextContent(value) || `*** ${t("keywords.general.titleMissingError")}`;

    return (
      <div>
        <div className="mt-4 flex justify-content-between">
          <h6>{t("keywords.general.title")}</h6>
          <div>
            <b>{t("wordTerms.maxCharactersWithNumber", { maxCount: 115 })}</b>
          </div>
        </div>
        <input
          type="text"
          className={`form-control ${errors.title ? "is-invalid" : ""}`}
          maxLength={115}
          {...register("title", { validate: validateTitle })}
        />

        <ErrorMessage
          errors={errors}
          name="title"
          message="Error"
          render={({ message }) => <b className="error">{message}</b>}
        />

        <div className="mt-4 flex justify-content-between">
          <h6>{t("keywords.general.content")}</h6>
          <div>
            <b>{t("wordTerms.maxCharactersWithNumber", { maxCount: 800 })}</b>
          </div>
        </div>
        <textarea
          className={`form-control displaySettingsSection__content ${
            errors.content ? "is-invalid" : ""
          }`}
          maxLength={800}
          {...register("content", { validate: validateContent })}
        />

        <ErrorMessage
          errors={errors}
          name="content"
          message="Error"
          render={({ message }) => <b className="error">{message}</b>}
        />
      </div>
    );
  }

  function renderPropertySelection() {
    if (properties.length <= 1 || !selectedPropertyIds) return null;
    return (
      <div className="mt-4">
        <h6>{t("propertyTerms.selectPropertiesToDisplayNotice")}</h6>
        <Controller
          control={control}
          name="property_ids"
          rules={{
            required: true,
            validate: {
              property_ids: (propertyIds) =>
                Object.keys(propertyIds).length > 0 ||
                `*** ${t("propertyTerms.missingPropertyPleaseSelectAProperty")}`,
            },
          }}
          render={({ field: { onChange } }) => (
            <PropertyWithAddressTableSelector
              properties={properties}
              selectedPropertyIds={selectedPropertyIds}
              updatePropertySelection={onChange}
              tableContainerStyle={errors.property_ids ? "mainTable__error" : ""}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name="property_ids"
          message="Error"
          render={({ message }) => <b className="error">{message}</b>}
        />
      </div>
    );
  }

  return (
    <>
      <h4 className="mb-3"> {t("noticeTerms.createNotice")}</h4>
      {renderDisplaySettings()}
      {renderPropertySelection()}
    </>
  );
}

ScreenDisplaySettings.defaultProps = {
  properties: [],
  backgroundImages: [],
  backgroundImageUploadStatus: null,
};

ScreenDisplaySettings.propTypes = {
  properties: PropTypes.array,
  backgroundImages: PropTypes.array,
  backgroundImageUploadStatus: PropTypes.string,
  uploadBackgroundImage: PropTypes.func.isRequired,
  deleteBackgroundImage: PropTypes.func.isRequired,
};
