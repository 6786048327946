import { withTranslation } from "react-i18next";
import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert"

import vcLogo from "../../../../style/images/VC-logo-light.png";
import viLogo from "../../../../style/images/VI-logo-light.png";

class LoginContainer extends Component {
  render() {
    const { t, children } = this.props;

    return (
      <Container className="login">
        <Row className="justify-content-center">
          <Col md={5} className="p-5 login__image-section">
            <span className="login__description">{t("keywords.appRelated.welcome")}</span>
            <Alert key="login-alert" variant="warning">
              <h4>{t("keywords.appRelated.migrationTitle")}</h4>
              {t("keywords.appRelated.mainMigrationMessage")} <a href='https://elev8.verticalcity.com/'>{t("keywords.appRelated.newAppLinkText")}</a>.
              <div>
                {t("keywords.appRelated.supportMessage")} <a href="mailto:support@verticalcity.com">support@verticalcity.com</a>
              </div>
            </Alert>
            <hr />
            <div className="login__logos">
              <img src={vcLogo} className="login__logo" alt="Vertical City" />
              <img src={viLogo} className="login__logo" alt="Vertical Impression" />
            </div>
          </Col>
          <Col md={5} className="p-5 u-background-color-white">
            {children}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(LoginContainer);
