import React, { Component } from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import MainFeedPlayer from "./mainFeedPlayer";
import SidebarPlayer from "./sidebarPlayer";
import Logo from "../../../../style/images/VC-logo-dark.png";
import Bg1 from "../../../../style/images/bg1.jpg";
import Bg2 from "../../../../style/images/bg2.jpg";
import Bg3 from "../../../../style/images/bg3.jpg";
import Bg4 from "../../../../style/images/bg4.jpg";

class PropertyContentDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentContent: {
        campaigns: [],
        big_notices: [],
        feeds: [],
        ads: [],
        media: [],
      },
      currentLoop: [],
    };
  }

  // this method is a reproduction
  // of the screen functionality whereby
  // content is assigned a number of "slots"
  // see windows client MainPlayer.cs
  mockLoop() {
    const mockLoop = [];
    const bigNotices = this.bigNotices();
    const randomIndex = this.getRandomIndex;

    if (bigNotices.length === 1) {
      mockLoop.push({ big_notice: bigNotices[0] });
      mockLoop.push({ big_notice: bigNotices[0] });
    } else if (bigNotices.length > 1) {
      mockLoop.push({ big_notice: bigNotices.splice(randomIndex(bigNotices), 1)[0] });
      mockLoop.push({ big_notice: bigNotices.splice(randomIndex(bigNotices), 1)[0] });
    }

    this.campaigns().forEach((campaign) => {
      mockLoop.push({ campaign });
    });

    return _.shuffle(mockLoop);
  }

  campaigns() {
    return [...this.props.content.campaigns];
  }

  bigNotices() {
    return [...this.props.content.big_notices];
  }

  getRandomIndex(array) {
    return Math.floor(Math.random() * array.length);
  }

  randomAdFor(campaign) {
    // handle empty state? maybe?
    const campaignAds = this.props.content.ads.filter((ad) => ad.campaign_id === campaign.id);

    const randomAd = campaignAds[this.getRandomIndex(campaignAds)] || {};

    return randomAd;
  }

  componentDidMount() {
    this.playContent();
  }

  getDuration(content) {
    return (content.duration || 10) * 1000;
  }

  getCurrentMedia(content, type) {
    if (type === "campaign") {
      return content.media;
    }
    if (type === "big_notice") {
      return content.background;
    }
  }

  getContentType(content) {
    return Object.keys(content)[0];
  }

  componentWillUnmount() {
    clearTimeout(this.state.nextPlay);
  }

  playContent() {
    if (!this.hasMainFeedContent()) {
      return null;
    }
    const currentLoop = this.getLoop();
    const content = currentLoop.pop();

    const currentContent = this.formatContent(content);
    const contentType = this.getContentType(content);

    const nextPlay = setTimeout(this.playContent.bind(this), this.getDuration(currentContent));

    this.setState({
      currentContent,
      contentType,
      currentMedia: this.getCurrentMedia(currentContent, contentType),
      currentLoop,
      nextPlay,
    });
  }

  formatContent(content) {
    if (this.getContentType(content) == "campaign") {
      const ad = this.randomAdFor(content.campaign);
      ad.duration = content.campaign.ad_duration;
      return ad;
    }
    return Object.values(content)[0];
  }

  getLoop() {
    if (this.state.currentLoop === null || this.state.currentLoop.length === 0) {
      return this.mockLoop();
    }
    return this.state.currentLoop;
  }

  backgroundImageStyle() {
    let url;
    const hour = new Date().getHours();
    if (hour < 5 || hour > 22) url = Bg4;
    else if (hour < 9) url = Bg1;
    else if (hour < 18) url = Bg2;
    else url = Bg3;

    return {
      backgroundImage: `url(${url})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
  }

  logo() {
    if (this.props.content.properties[0].logo) {
      return this.props.content.properties[0].logo.url;
    }
    return "";
  }

  scale() {
    const container = document.getElementsByClassName("js-mainContent")[0];
    const padding = 48;
    const availableHeight = container.clientHeight - padding;
    const availableWidth = container.clientWidth - padding;

    // the numeric values below are hardcoded
    // in style/css/components/_tablet_wrapper.scss
    const scaleFactor = Math.min(availableWidth / 1368, availableHeight / 768);

    return { transform: `scale(${scaleFactor})` };
  }

  hasMainFeedContent() {
    const { content } = this.props;
    return content.big_notices.concat(content.campaigns).length > 0;
  }

  render() {
    const { t } = this.props;

    return (
      <div className="p-1">
        <div className="tablet_wrapper" style={this.scale()}>
          <div className="tablet_wrapper__content container h-100">
            <div className="row h-100">
              <div className="col-12 h-100">
                <div className="row h-90">
                  <div className="col-4 u-pad-none" style={this.backgroundImageStyle()}>
                    <SidebarPlayer notices={this.props.content.notices} logo={this.logo()} />
                  </div>
                  <div className="col-8 h-100 u-pad-none">
                    <MainFeedPlayer
                      type={this.state.contentType}
                      content={this.state.currentContent}
                      media={this.state.currentMedia}
                    />
                  </div>
                </div>

                <div className="row h-10 align-center" style={{ backgroundColor: "#fff" }}>
                  <div className="d-flex align-center align-items-center col-4 h-100">
                    <span className="h-65 align-middle mx-auto">
                      <img className="tablet_wrapper__visio_logo" src={Logo} />
                    </span>
                  </div>

                  <div className="col-8 h-100">
                    <div className="row h-100">
                      <div className="col-3 u-background-color-light-blue">
                        {t("keywords.weatherRelated.current")}
                      </div>
                      <div className="col-3 u-background-color-white">
                        {t("keywords.dateRelated.laterToday")}
                      </div>
                      <div className="col-3 u-background-color-light-blue">
                        {t("keywords.dateRelated.tomorrow")}
                      </div>
                      <div className="col-3 u-background-color-white">
                        {t("keywords.dateRelated.nextDay")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PropertyContentDemo);
