import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import withRouter from "@hooks/withRouter";
import PageTabs from "@components/Global/Widgets/pageTabs";
import LoadingPage from "@components/Global/General/loadingPage";
import {
  initiateEditPropertyForm,
  clearPropertyFormRelatedInfo,
} from "@components/Property/services/actions/propertyFormActions";
import PropertyContainer from "./propertyContainer";

class PropertyEdit extends Component {
  componentDidMount() {
    this.props.initiateEditPropertyForm(this.props.router.params.id);
  }

  componentWillUnmount() {
    this.props.clearPropertyFormRelatedInfo();
  }

  setOpenSection = (sectionClicked) => {
    const { router } = this.props;
    const { id } = router.params;
    if (sectionClicked === "property_form") {
      router.navigate(`/properties/edit/${id}/form`);
    } else {
      router.navigate(`/properties/edit/${id}/screen_list`);
    }
  };

  activeSection = () => {
    if (this.props.router.location.pathname.endsWith("form")) {
      return "property_form";
    }

    return "screen_list";
  };

  render() {
    if (!this.props.propertyForm) {
      return <LoadingPage pageName="Property" />;
    }
    return (
      <div>
        <PageTabs
          tabs={["property_form", "screen_list"]}
          tabClicked={this.setOpenSection}
          activeSection={this.activeSection()}
        />
        <PropertyContainer
          section={this.activeSection()}
          isPropertyManager={false}
          propertyForm={this.props.propertyForm}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      initiateEditPropertyForm,
      clearPropertyFormRelatedInfo,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    propertyForm: state.property.general.form,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PropertyEdit));
