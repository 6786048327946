import React, { Component } from "react";

class ScreenNotice extends Component {
  render() {
    if (this.props.notice == null) {
      return null;
    }

    return (
      <div className="screen_notice p-3">
        <span>
          {this.props.notice.icon != undefined && (
            <img src={this.props.notice.icon.url} className="screen_notice__inline_icon" />
          )}
          <h2 className="d-inline">{this.props.notice.title}</h2>
        </span>
        <p>{this.props.notice.body}</p>
      </div>
    );
  }
}

export default ScreenNotice;
