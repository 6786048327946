import React, { Component } from "react";
import PropTypes from "prop-types";
import Pill from "./pill";

class CampaignStatusPill extends Component {
  constructor(props) {
    super(props);
  }

  colorFromState(state) {
    switch (state) {
      case "Active":
        return "green";
      case "Pending":
        return "yellow";
      case "Complete":
        return "blue";
      default:
        return "disabled";
    }
  }

  render() {
    return <Pill text={this.props.state} type={this.colorFromState(this.props.state)} />;
  }
}

CampaignStatusPill.propTypes = {
  state: PropTypes.string.isRequired,
};

export default CampaignStatusPill;
