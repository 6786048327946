import {
  SET_RSS_FEED_FORM,
  FETCH_RSS_FEEDS,
  SET_RSS_FEED_DATA,
} from "../../../../../App/actionTypes";

const INITIAL_STATE = { all: null, single: null, tableData: null };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_RSS_FEED_FORM:
      // console.log("Setting rss form", action.payload)
      return { ...state, single: action.payload };
    case FETCH_RSS_FEEDS:
      // console.log("Fetching all feeds", action.payload)
      return { ...state, all: action.payload };
    case SET_RSS_FEED_DATA:
      return { ...state, tableData: action.payload };
  }
  return state;
}
