import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import BasicTable from "../../../Global/General/BasicTable";
import ToggleSlider from "../../../Global/Widgets/updatedWidgets/toggleSlider";
import CampaignProposalMarketBreakdownHelper from "../../../../utilities/CampaignProposalMarketBreakdownHelper";

export default function MarketBreakdownTable({ propertyData, grouping, showTableFilters }) {
  const { t } = useTranslation();
  const currencyFormatter = new Intl.NumberFormat(t("language.locale"), {
    style: "currency",
    currency: "CAD",
    maximumFractionDigits: 2,
  });
  const [showFilters, setShowFilters] = useState(showTableFilters);
  const [isCollapsed, collapseTable] = useState(false);

  const wholeNumberFormatter = new Intl.NumberFormat(t("language.locale"), {
    maximumFractionDigits: 0,
  });

  function calculateColumnSum(data, column) {
    return data.rows.reduce((sum, row) => row.values[column] + sum, 0);
  }

  function calculateTotalProperties(data, column) {
    return data.rows.reduce((sum, row) => row.values[column].length + sum, 0);
  }

  function calculateTotalCPM(data) {
    const totalCost = data.rows.reduce((sum, row) => row.values.cost + sum, 0);
    const totalImpressions = data.rows.reduce((sum, row) => row.values.servedImpressions + sum, 0);
    return CampaignProposalMarketBreakdownHelper.calculateCPM(totalCost, totalImpressions);
  }

  const columns = useMemo(
    () => [
      {
        Header: grouping,
        accessor: "groupingKey",
        Footer: "TOTAL",
      },
      {
        Header: t("propertyTerms.properties"),
        accessor: "propertyIds",
        Cell: (props) => wholeNumberFormatter.format(props.value.length),
        Footer: (data) => {
          return wholeNumberFormatter.format(calculateTotalProperties(data, "propertyIds"));
        },
      },
      {
        Header: t("propertyTerms.activeScreens"),
        accessor: "activeScreenCount",
        Cell: (props) => wholeNumberFormatter.format(props.value),
        Footer: (data) => {
          return wholeNumberFormatter.format(calculateColumnSum(data, "activeScreenCount"));
        },
      },
      {
        Header: t("keywords.campaignRelated.servedImpressions"),
        accessor: "servedImpressions",
        Cell: (props) => wholeNumberFormatter.format(props.value),
        Footer: (data) => {
          return wholeNumberFormatter.format(calculateColumnSum(data, "servedImpressions"));
        },
      },
      {
        Header: t("keywords.campaignRelated.circulation"),
        accessor: "circulation",
        Cell: (props) => wholeNumberFormatter.format(props.value),
        Footer: (data) => {
          return wholeNumberFormatter.format(calculateColumnSum(data, "circulation"));
        },
      },
      {
        Header: t("keywords.campaignRelated.cpm"),
        accessor: "cpm",
        Cell: (props) => currencyFormatter.format(props.value),
        Footer: (data) => {
          return currencyFormatter.format(calculateTotalCPM(data));
        },
      },
      {
        Header: t("keywords.campaignRelated.rateCard"),
        accessor: "rateCard",
        Cell: (props) => currencyFormatter.format(props.value),
        Footer: (data) => {
          return currencyFormatter.format(calculateColumnSum(data, "rateCard"));
        },
      },
      {
        Header: t("keywords.campaignRelated.cost"),
        accessor: "cost",
        Cell: (props) => currencyFormatter.format(props.value),
        Footer: (data) => {
          return currencyFormatter.format(calculateColumnSum(data, "cost"));
        },
      },
      {
        Header: t("keywords.campaignRelated.savings"),
        accessor: "savings",
        Cell: (props) => currencyFormatter.format(props.value),
        Footer: (data) => {
          return currencyFormatter.format(calculateColumnSum(data, "savings"));
        },
      },
    ],
    []
  );

  function changeTableState(collapsedState) {
    setShowFilters(!collapsedState);
    collapseTable(collapsedState);
  }

  function renderTableHeader() {
    const arrowDirection = isCollapsed ? "right" : "down";
    return (
      <div className="marketBreakdownTable__headerSection">
        <div className="marketBreakdownTable__title">{t("proposalTerms.breakdown")}</div>
        <button
          type="button"
          className={`marketBreakdownTable__toggleIcon fa fa-chevron-circle-${arrowDirection}`}
          onClick={() => changeTableState(!isCollapsed)}
        />
      </div>
    );
  }

  function renderTableFilters() {
    if (isCollapsed) return null;
    return (
      <div className="marketBreakdownTable__filterSection">
        <ToggleSlider
          variant="dark"
          size="sm"
          value={showFilters}
          onChange={() => setShowFilters(!showFilters)}
          label={t("filterTerms.columnFilters")}
        />
      </div>
    );
  }

  if (!propertyData) {
    return <div>{t("keywords.appRelated.loading")}</div>;
  }

  return (
    <div className="marketBreakdownTable">
      {renderTableHeader()}
      <BasicTable
        tableHeader={() => null}
        headerClassName="marketBreakdownTable__header"
        bodyClassName="marketBreakdownTable__body"
        footerClassName="marketBreakdownTable__footer"
        noResultsDescription={t("keywords.propertyRelated.noPropertiesSelected")}
        columns={columns}
        isCollapsed={isCollapsed}
        data={propertyData}
        showFilters={showFilters}
        showFooter
      />
      {renderTableFilters()}
    </div>
  );
}

MarketBreakdownTable.defaultProps = {
  propertyData: null,
};

MarketBreakdownTable.propTypes = {
  propertyData: PropTypes.arrayOf(PropTypes.object),
};
