// ALL ACTIONS FOR CAMPAIGN PROPERTIES (for AdEdit)

import { FETCH_PROPERTIES } from "@App/actionTypes";

import Elev8ApiService from "@lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "@lib/services/Elev8Api/lib";

/** *****************************************
INITIALIZE ACTIONS
******************************************* */

// Currently set to ALL
export default function fetchPropertiesForOldCampaign() {
  const sparseFieldSets = {
    include: [
      "municipality",
      "district",
      "country",
      "local_logic_location_info",
      "screen_installation_configs",
      "network",
    ],
    fields: {
      property: [
        "id",
        "name",
        "contact_name",
        "contact_email",
        "contact_phone",
        "property_type",
        "status",
        "content_restriction",
        "address_line1",
        "address_line2",
        "address_city",
        "address_province",
        "address_country",
        "address_postal_code",
        "screen_count",
        "latitude",
        "longitude",
        "municipality",
        "district",
        "country",
        "local_logic_location_info",
        "floors",
        "has_gym",
        "has_pool",
        "family_friendly",
        "number_of_units",
        "resident_parking",
        "visitor_parking",
        "weekly_price_cents",
        "screen_resolution",
        "rev_share_start_at",
        "revenue_share",
        "adult_only",
        "allows_cats",
        "allows_dogs",
        "amenities",
        "building_sqft",
        "install_date",
        "comments",
        "tester",
        "screen_installation_configs",
        "network",
      ],
      municipality: ["id", "name"],
      network: ["id", "name"],
      country: ["id", "name"],
      district: ["id", "name"],
      local_logic_location_info: ["id", "local_logic_fields"],
      screen_installation_config: ["id", "current_maintenance"],
    },
  };
  return (dispatch) => {
    Elev8ApiService.index(ApiTypes.property, sparseFieldSets).then((properties) => {
      const oldPropertyFormatList = properties.map((property) => {
        const propertyFields = property.attributes;
        propertyFields.municipality = property.municipality().attributes;
        propertyFields.district = property.district().attributes;
        propertyFields.country = property.country().attributes;
        propertyFields.networkName = property.networkName;
        const localLogicLocationInfo = property.local_logic_location_info();
        const localLogicFieldsValues = localLogicLocationInfo
          ? localLogicLocationInfo.attributes.local_logic_fields
          : {};
        const combinedPropertyData = {
          ...propertyFields,
          ...localLogicFieldsValues,
          localLogicKeys: Object.keys(localLogicFieldsValues),
          activeScreenCount: property.activeScreens.length,
        };
        return combinedPropertyData;
      });
      dispatch({
        type: FETCH_PROPERTIES,
        payload: oldPropertyFormatList,
      });
    });
  };
}
