export const USER_GROUP = {
  RSM: "Rogers Sports and Media",
  STAFF: "Vertical City/Impression staff",
};

export const ROLE_GROUP = {
  admin: USER_GROUP.STAFF,
  account_manager: USER_GROUP.STAFF,
  rsm_sales_associate: USER_GROUP.RSM,
};

export default class IdentityService {
  static isRsm(user) {
    const userRole = user.role;
    return ROLE_GROUP[userRole] === USER_GROUP.RSM;
  }

  static isVerticalStaff(user) {
    const userRole = user.role;
    return ROLE_GROUP[userRole] === USER_GROUP.STAFF;
  }
}
