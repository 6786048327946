import {
  // Login
  UNAUTH_USER,
  ERROR_MSG,
  NO_ERROR_MSG,
  VALID_PASSWORD,
  INVALID_PASSWORD,
} from "../../../App/actionTypes";
import {
  setPersonalForm,
  fetchPersonalForm,
} from "../../User/Personal/services/actions/personalActions";
import Elev8ApiService from "../../../lib/services/Elev8Api/Elev8ApiService";

// sending email to recover password
export function sendEmail(email) {
  return () => {
    Elev8ApiService.server().post("users/request_password_reset", { email });
  };
}

export function resetPassword(newPassword) {
  return (dispatch) => {
    Elev8ApiService.server()
      .post("users/reset_password", newPassword)
      .then(() => {
        dispatch({ type: VALID_PASSWORD }); // set Authentication to True
      })
      .catch(() => {
        dispatch({ type: INVALID_PASSWORD }); // set Authentication to True
      });
  };
}

export function loginUser(email, password) {
  return (dispatch) => {
    Elev8ApiService.auth(email, password)
      .then(() => {
        dispatch(fetchPersonalForm());
        dispatch({ type: NO_ERROR_MSG });
      })
      .catch(() => {
        dispatch({ type: ERROR_MSG });
      });
  };
}

// logout user
export function logoutUser() {
  return (dispatch) => {
    localStorage.removeItem("report_code");
    Elev8ApiService.unauth();
    dispatch(setPersonalForm(null));
    dispatch({ type: UNAUTH_USER });
  };
}

export function checkSession() {
  const userId = localStorage.getItem("userId");
  return (dispatch) => {
    Elev8ApiService.server()
      .get("/token_user")
      .then((response) => {
        if (response.data.data.id === userId) {
          Elev8ApiService.renewAuth();
        } else {
          throw new Error("Invalid Session");
        }
      })
      .catch(() => {
        dispatch(logoutUser());
      });
  };
}
