import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Alert from "react-bootstrap/Alert";
import { FormProvider, useForm, useWatch } from "react-hook-form";

import { unstableHistory } from "@App/history";
import { determineNoticeTitleByRole } from "@utilities/UserRoleHelper";
import FormStatus from "@utilities/FormStatus";
import PropertyDataHelper from "@components/Property/services/actions/PropertyDataHelper";
import BigNoticeDataHelper from "../services/actions/BigNoticeDataHelper";
import { BigNoticeSerializer } from "../services/actions/BigNoticeSerializer";
import ScreenDisplaySettings from "./ScreenDisplaySettings";
import ScreenSchedulingSettings from "./ScreenSchedulingSettings";
import BigNoticeFormTopBar from "./BigNoticeFormTopBar";
import BigNoticeScreenPreview from "./BigNoticeScreenPreview";

export default function UpdatedBigNoticeForm({ params, loggedInUser }) {
  const { t } = useTranslation();
  const { id } = params;
  const [properties, setProperties] = useState([]);
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [backgroundImagesQuickLookup, setQuickLookupBackgroundImages] = useState({});
  const [formStatus, setFormStatus] = useState(FormStatus.LOADING);
  const [backgroundImageUploadStatus, setBackgroundImageUploadStatus] = useState(FormStatus.EDIT);
  const [serverError, showServerError] = useState(false);

  const methods = useForm();
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
    clearErrors,
    register,
  } = methods;

  const propertyIds = useWatch({
    control,
    name: "property_ids",
  });

  const contentType = useWatch({
    control,
    name: "content_type",
  });

  const title = useWatch({
    control,
    name: "title",
  });

  const content = useWatch({
    control,
    name: "content",
  });

  const backgroundId = useWatch({
    control,
    name: "background_id",
  });

  const bigNoticeName = t(determineNoticeTitleByRole(loggedInUser));

  useEffect(() => {
    loadProperties();
    loadBigNotice();
  }, []);

  useEffect(() => {
    clearValidationErrorsBasedOffContentType();
  }, [contentType]);

  // When a user updates property ids, this method gets called to fetch
  // the correct images associated to the new set of property ids
  useEffect(() => {
    loadBackgroundImages();
  }, [propertyIds]);

  async function loadProperties() {
    const propertyData = await PropertyDataHelper.fetchNameAndAddress();
    if (propertyData.length === 1) {
      const key = propertyData[0].attributes.id;
      setValue("property_ids", { [key]: true });
    }
    setProperties(propertyData);
  }

  async function loadBigNotice() {
    let existingBigNotice;
    if (id) {
      existingBigNotice = await BigNoticeDataHelper.fetchBigNotice(id);
    }
    initializeForm(existingBigNotice);
  }

  async function loadBackgroundImages() {
    const propertyIdsIntArray = Object.keys(propertyIds || {});
    if (propertyIdsIntArray.length === 0) {
      setBackgroundImages([]);
      setValue("background_id", null);
    } else {
      const backgroundImageData = await BigNoticeDataHelper.oldFetchBigNoticeBackgrounds(
        propertyIdsIntArray
      );
      const backgroundImageHashMap = BigNoticeSerializer.backgroundImageHashmap(
        backgroundImageData.data
      );
      setBackgroundImages(backgroundImageData.data);
      setQuickLookupBackgroundImages(backgroundImageHashMap);
    }
  }

  // If a user is testing validation for an image layout we don't need to validate
  // the title and content since those are only required for text layouts. Vice versa.
  function clearValidationErrorsBasedOffContentType() {
    if (contentType === "image") {
      clearErrors("title");
      clearErrors("content");
    } else {
      clearErrors("background_id");
    }
  }

  function renderServerError() {
    if (!serverError) return null;
    return (
      <Alert variant="danger" onClose={() => showServerError(false)} dismissible>
        <b>{t("keywords.errorRelated.tryAgain")}</b>
      </Alert>
    );
  }

  function deleteNotice() {
    BigNoticeDataHelper.deleteNotice(id)
      .then(() => {
        unstableHistory.push("/bignotice");
      })
      .catch(() => {
        showServerError(true);
      });
  }

  function initializeForm(apiObject) {
    const formData = BigNoticeSerializer.formData(apiObject);
    setFormStatus(FormStatus.EDIT);
    reset(formData);
  }

  function updateBigNotice(apiObject) {
    BigNoticeDataHelper.updateNotice(apiObject, id)
      .then(() => {
        loadBigNotice(id);
      })
      .catch(() => {
        showServerError(true);
        setFormStatus(FormStatus.EDIT);
      });
  }

  function createBigNotice(apiObject) {
    BigNoticeDataHelper.createNotice(apiObject)
      .then((response) => {
        unstableHistory.push(`/bignotice/edit/${response.data.data.id}`);
      })
      .catch(() => {
        showServerError(true);
        setFormStatus(FormStatus.EDIT);
      });
  }

  function uploadBackgroundImage(file) {
    const mediaApiObject = BigNoticeSerializer.mediaApiObject(file);
    const propertyIdsIntArray = Object.keys(propertyIds || {});
    setBackgroundImageUploadStatus(FormStatus.SAVING);

    BigNoticeDataHelper.oldCreateBackgroundImage(mediaApiObject, propertyIdsIntArray)
      .then((response) => {
        setValue("background_id", response.data.id);
        setBackgroundImageUploadStatus(FormStatus.EDIT);
        return loadBackgroundImages();
      })
      .catch(() => {
        showServerError(true);
      });
  }

  function deleteBackgroundImage(imageId) {
    BigNoticeDataHelper.oldDeleteBackgroundImage(imageId)
      .then(() => {
        if (imageId === backgroundId) {
          setValue("background_id", null);
        }
        return loadBackgroundImages();
      })
      .catch(() => {
        showServerError(true);
        setFormStatus(FormStatus.EDIT);
      });
  }

  const onSubmit = (data) => {
    setFormStatus(FormStatus.SAVING);
    const apiObject = BigNoticeSerializer.apiObject(data);
    if (id) {
      updateBigNotice(apiObject);
    } else {
      createBigNotice(apiObject);
    }
  };

  function renderScreenPreview() {
    const backgroundImage = backgroundImagesQuickLookup[backgroundId];
    return (
      <div className="screenPreviewSection">
        {renderServerError()}
        <BigNoticeScreenPreview
          contentType={contentType}
          backgroundImage={backgroundImage}
          title={title}
          content={content}
        />
        <div className="publishedCheckbox">
          <label htmlFor="publishedCheckbox" className="flex align-items-center">
            <input
              type="checkbox"
              id="publishedCheckbox"
              className="mr-1"
              {...register("published", {})}
            />
            {t("noticeTerms.publishNotice")}
          </label>
        </div>
      </div>
    );
  }

  function formErrorsPresent() {
    return Object.keys(errors).length !== 0;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BigNoticeFormTopBar
          id={id}
          formStatus={formStatus}
          deleteNotice={deleteNotice}
          title={bigNoticeName}
          disableSave={formErrorsPresent}
        />
        <div className="BigNoticeFormContainer">
          <div className="displaySettingsSection">
            <ScreenDisplaySettings
              properties={properties}
              backgroundImages={backgroundImages}
              backgroundImagesQuickLookup={backgroundImagesQuickLookup}
              uploadBackgroundImage={uploadBackgroundImage}
              backgroundImageUploadStatus={backgroundImageUploadStatus}
              deleteBackgroundImage={deleteBackgroundImage}
            />
            <ScreenSchedulingSettings />
          </div>
          {renderScreenPreview()}
        </div>
      </form>
    </FormProvider>
  );
}

UpdatedBigNoticeForm.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  loggedInUser: PropTypes.shape({
    capabilities: PropTypes.object,
  }).isRequired,
};
