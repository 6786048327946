import {
  now,
  addDaysToCurrentDay,
  apiDateAsFormData,
  formDateAsApiData,
} from "../../../../../utilities/dateStringHelper";
import {
  selectedTableIdsFromApiObjectIds,
  apiObjectIdsFromSelectedTableIds,
} from "../../../../../utilities/TableSelectorHelper";
import TargetingSerializer from "../../../../../utilities/targetingSerializer";
import Elev8ApiService from "../../../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../../../lib/services/Elev8Api/lib";

const BigNoticeSerializer = {
  formData(obj = null) {
    return apiObjectToFormData(obj);
  },
  apiObject(data = null) {
    return formDataToApiObject(data);
  },
  mediaApiObject(data = null) {
    return mediaApiObject(data);
  },
  propertyApiObject(data = null) {
    return apiObjectIdsFromSelectedTableIds(data);
  },
  backgroundImageHashmap(data = null) {
    return backgroundImageHashMapFromApiObject(data);
  },
};

const BIG_NOTICE_FORM_DEFAULTS = {
  title: "",
  published: true,
  id: null,
  start_date: now(),
  end_date: addDaysToCurrentDay(7),
  targeting: TargetingSerializer.defaultTargeting(),
  background_id: null,
  content_type: "text",
  property_ids: {},
};

function apiObjectToFormData(obj) {
  if (!obj) return { ...BIG_NOTICE_FORM_DEFAULTS };
  const { attributes } = obj;
  return {
    ...attributes,
    start_date: apiDateAsFormData(attributes.start_date),
    end_date: apiDateAsFormData(attributes.end_date),
    content: attributes.content.body,
    targeting: TargetingSerializer.serialize(attributes.targeting),
    background_id: obj.relationships.background.id || null,
    property_ids: selectedTableIdsFromApiObjectIds(obj.relationships.properties),
  };
}

function mediaApiObject(file) {
  const media = {
    name: file.name,
    type: file.type.split("/")[0],
    category: "big_notice_background",
    url: file.preview,
    id: null,
    file,
  };
  return media;
}

// Using this hashmap will optimize the background_id to image object lookup speed
function backgroundImageHashMapFromApiObject(backgroundImages) {
  if (!backgroundImages || backgroundImages.length === 0) return {};
  const backgroundImagesMediaHashmap = backgroundImages.reduce(function (map, obj) {
    map[obj.id] = obj;
    return map;
  }, {});
  return backgroundImagesMediaHashmap;
}

function formDataToApiObject(formData) {
  const content = {
    body: formData.content,
  };
  const relationships = {};

  const attributes = {
    ...formData,
    start_date: formDateAsApiData(formData.start_date, "startOfDay"),
    end_date: formDateAsApiData(formData.end_date, "endOfDay"),
    targeting: TargetingSerializer.deserialize(formData.targeting),
    content,
  };

  if (formData.property_ids) {
    relationships.properties = {
      data: apiObjectIdsFromSelectedTableIds(formData.property_ids, ApiTypes.property.type),
    };
  }

  if (formData.background_id) {
    relationships.background = {
      data: { id: formData.background_id, type: ApiTypes.media.type },
    };
  }

  return Elev8ApiService.setToJsonApiFormat(
    ApiTypes.big_notice.type,
    formData.id,
    attributes,
    relationships
  );
}

export { BigNoticeSerializer, BIG_NOTICE_FORM_DEFAULTS };
