import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import BasicTable from "../../../../Global/General/BasicTable";
import { SelectColumnFilter } from "../../../../Global/General/BasicTableFilter";
import { generateCsv, downloadCsv } from "../../../../../utilities/csvHelpers";

class LocationList extends Component {
  locationCsv = () => {
    const csvKeys = [
      "attributes.id",
      "attributes.name",
      "attributes.property_type",
      "attributes.address_postal_code",
      "attributes.address_city",
      "attributes.address_province",
      "attributes.screen_count",
    ];
    const csvHeaders = [
      "Property ID",
      "Property Name",
      "Property Type",
      "Postal Code",
      "City",
      "Province",
      "Screen Count",
    ];
    return generateCsv(this.props.properties, csvKeys, csvHeaders, ",");
  };

  customHeader = (rows) => {
    const csv = this.locationCsv();
    return (
      <Row className="justify-content-between align-items-center">
        <Col className="reportingContent__title">
          Market Breakdown
          <Button className="m-2" variant="primary" onClick={() => downloadCsv(csv)}>
            Export CSV
          </Button>
        </Col>

        <Col className="calloutCard__subtitle" md="auto">
          Total properties: {rows?.length} | Total screens:{" "}
          {rows?.reduce((total, prop) => total + prop.values["attributes.screen_count"], 0)}
        </Col>
      </Row>
    );
  };

  columns() {
    return [
      {
        Header: "Property Name",
        accessor: "attributes.name",
      },
      {
        Header: "Property Type",
        accessor: "attributes.property_type",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Postal Code",
        accessor: "attributes.address_postal_code",
      },
      {
        Header: "City",
        accessor: "attributes.address_city",
      },
      {
        Header: "Province",
        accessor: "attributes.address_province",
      },
      {
        Header: "Screen Count",
        accessor: "attributes.screen_count",
        disableFilters: true,
      },
    ];
  }

  render() {
    return (
      <div className="locationList">
        <BasicTable
          columns={this.columns()}
          data={this.props.properties}
          tableHeader={this.customHeader}
          headerClassName="locationList__header"
          bodyClassName="locationList__body"
        />
      </div>
    );
  }
}

LocationList.propTypes = {
  properties: PropTypes.array.isRequired,
};

export default withTranslation()(LocationList);
