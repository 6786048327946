import Elev8ApiService from "../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../lib/services/Elev8Api/lib";

class CampaignReportDataHelper {
  static fetchData(reportCode, sparseFieldsets) {
    return new Promise((resolve, reject) => {
      Elev8ApiService.read(ApiTypes.campaign_report, reportCode, sparseFieldsets)
        .then((report) => {
          resolve(report);
        })
        .catch((e) => {
          console.log("Can't fetch analytics...", e);
          reject(e);
        });
    });
  }
}

export default CampaignReportDataHelper;
