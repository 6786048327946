/*
PANEL ICONS
*/

import _ from "lodash";

import { FETCH_PANEL_ICONS, SET_ICON_FORM } from "../../../../../App/actionTypes";

const INITIAL_STATE = {
  all: null,
  form: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    // SIDE PANEL ACTIONS
    case FETCH_PANEL_ICONS:
      return { ...state, all: action.payload };
    case SET_ICON_FORM: // fetch single panel icon
      // console.log("Setting single panel icon", action.payload);
      return { ...state, form: action.payload };
  }
  return state;
}
