import React from "react";
import PropTypes from "prop-types";
import { useWatch } from "react-hook-form";
import CampaignProposalMarketBreakdownHelper from "../../../../utilities/CampaignProposalMarketBreakdownHelper";
import MarketBreakdownTable from "./MarketBreakdownTable";

export default function CampaignProposalMarketBreakdown({
  elev8Properties,
  selectedCampaignProposalIndex,
}) {
  const grouping = "city";

  const start_at = useWatch({ name: fieldName("start_at") });
  const end_at = useWatch({ name: fieldName("end_at") });
  const property_ids = useWatch({ name: fieldName("property_ids") });
  const rate_type = useWatch({ name: fieldName("rate_type") });
  const loop_spots = useWatch({ name: fieldName("loop_spots") });
  const fullscreen = useWatch({ name: fieldName("fullscreen") });
  const rate_value_dollars = useWatch({ name: fieldName("rate_value_dollars") });

  function fieldName(attributeName) {
    return `campaign_proposals.${selectedCampaignProposalIndex}.${attributeName}`;
  }

  const campaignFieldsForBreakdown = {
    start_at,
    end_at,
    property_ids,
    rate_type,
    fullscreen,
    loop_spots,
    rate_value_dollars,
  };

  const summaryBreakdown = CampaignProposalMarketBreakdownHelper.createCampaignSummaryBreakdown(
    campaignFieldsForBreakdown,
    "city",
    elev8Properties
  );

  return (
    <MarketBreakdownTable propertyData={summaryBreakdown} grouping={grouping} showTableFilters />
  );
}

CampaignProposalMarketBreakdown.defaultProps = {
  selectedCampaignProposalIndex: null,
};

CampaignProposalMarketBreakdown.propTypes = {
  elev8Properties: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCampaignProposalIndex: PropTypes.number,
};
