import React, { Component } from "react";
import PropTypes from "prop-types";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BasicTable from "../../Global/General/BasicTable";
import StatusPill from "../../Global/General/StatusPill";

class CampaignGroupDeleteButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleDelete = () => {
    this.setState({ show: false });
    this.props.deleteAction();
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  renderStatusPill = ({ cell }) => {
    return <StatusPill status={cell.value} />;
  };

  columns() {
    return [
      {
        Header: "Flight Name",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "detailedStatus",
        Cell: this.renderStatusPill,
        disableFilters: true,
      },
      {
        Header: "Start",
        accessor: "start_at",
        disableFilters: true,
      },
      {
        Header: "End",
        accessor: "end_at",
        disableFilters: true,
      },
    ];
  }

  renderModal() {
    return (
      <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Warning: Are you sure you want to delete this Campaign?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>The following flights will be deleted:</b>
          <div className="mainTable mainTable__withBorder pt-3">
            <BasicTable
              tableHeader={() => null}
              noResultsDescription="No flights present"
              columns={this.columns()}
              data={this.props.campaignData}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.handleDelete}>
            Yes, I want to delete this Campaign
          </Button>
          <Button variant="outline-primary" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderModal()}
        <Button variant="outline-danger" size="sm" onClick={this.handleShow}>
          Delete
        </Button>
      </React.Fragment>
    );
  }
}

CampaignGroupDeleteButton.propTypes = {
  campaignData: PropTypes.array.isRequired,
  deleteAction: PropTypes.func.isRequired,
};

export default CampaignGroupDeleteButton;
