import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

class StatusPill extends Component {
  render() {
    const { t, status } = this.props;
    const translatedDescription = t(status.description);

    return <div className={`pill pill--${status.cssValue}`}>{translatedDescription}</div>;
  }
}

StatusPill.propTypes = {
  status: PropTypes.object.isRequired,
};

export default withTranslation()(StatusPill);
