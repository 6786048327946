// Preview Box for  Side Notice/Big Notice

import React, { Component } from "react";

// MAIN CLASS
class BigNoticePreview extends Component {
  constructor(props) {
    super(props);
  }

  backgroundImageStyle() {
    return {
      backgroundImage: `url("${this.props.imageUrl}")`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
  }

  render() {
    const { screenTitle, screenDescription } = this.props;
    const screenTitleName = screenTitle.length > 0 ? screenTitle : "";
    const hasText = !!(screenTitle || screenDescription);

    return (
      <div className="h-100 u-color-white">
        <div className="row h-100 align-items-center p-5 m-0" style={this.backgroundImageStyle()}>
          {hasText ? (
            <div className="col-12 align-center h-100 u-transparent-black p-4">
              <div>{screenTitleName}</div>
              <div className="pt-2">
                {screenDescription.length > 0 ? (
                  <div>
                    <p>{screenDescription}</p>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default BigNoticePreview;
