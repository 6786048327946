import React from "react";
import PropTypes from "prop-types";

export default function SummaryCallout({ label, value, info }) {
  return (
    <>
      <div className="summaryCallout__label">{label}:</div>
      <div>
        <div className="summaryCallout__value">{value}</div>
        {info && <div className="summaryCallout__info">{info}</div>}
      </div>
    </>
  );
}

SummaryCallout.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
};

SummaryCallout.defaultProps = {
  info: null,
};
