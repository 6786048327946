// Helper methods based on specific user roles

import { PROPERTY_ROLES } from "../components/constants";

export function isPropertyUser(role) {
  return PROPERTY_ROLES.includes(role);
}

export function determineNoticeTitleByRole(role, modifier) {
  if (role === "property_user") {
    return `pageNames.bigNotice${modifier || ""}`;
  }
  return `noticeTerms.notice${modifier || ""}`;
}
