// React
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropertyContentRow from "./propertyContentRow";
import { determineNoticeTitleByRole } from "../../../utilities/UserRoleHelper";

import { BIG_NOTICE_FREQUENCY_VALUE_MAP } from "../../constants";

// Actions

class PropertyContentLoop extends Component {
  bigNoticeSlotCountString(screens) {
    const slotCounts = screens
      .map((screen) => screen.big_notice_frequency)
      .map((frequency) => BIG_NOTICE_FREQUENCY_VALUE_MAP[frequency]);
    const maxSlotCount = Math.max(...slotCounts);
    const minSlotCount = Math.min(...slotCounts);

    return maxSlotCount === minSlotCount ? String(maxSlotCount) : `${minSlotCount}-${maxSlotCount}`;
  }

  render() {
    if (!this.props.content) {
      return null;
    }

    const { content, t, screens, userRole } = this.props;

    return (
      <div>
        <PropertyContentRow
          rowData={content.big_notices}
          type="Big Notices"
          slotName={t(determineNoticeTitleByRole(userRole, "_plural"))}
          slotCount={this.bigNoticeSlotCountString(screens)}
          fieldName="title"
        />
        <PropertyContentRow
          rowData={content.campaigns}
          type="Campaigns"
          slotName={t("pageNames.campaigns")}
          fieldName="name"
        />
        <PropertyContentRow
          rowData={content.feeds}
          type="RSS Feeds"
          slotName={t("pageNames.rssFeeds")}
          slotCount="2"
          fieldName="name"
        />
      </div>
    );
  }
}

export default withTranslation()(PropertyContentLoop);
