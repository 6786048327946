// React
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Elev8ApiService from "@lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "@lib/services/Elev8Api/lib";
import TextBox from "@components/Global/Widgets/views/textBox";
import Checkbox from "@components/Global/Widgets/updatedWidgets/checkBox";
import TextArea from "@components/Global/Widgets/updatedWidgets/textArea";
import { BIG_NOTICE_FREQUENCY_VALUE_MAP } from "@components/constants";

class ScreenFields extends Component {
  handleNameChange = (field, value) => this.props.handleChange(this.props.id, field, value);

  handleFrequencyChange = (selectOption) =>
    this.props.handleChange(this.props.id, "big_notice_frequency", selectOption.value);

  handleDurationChange = (selectOption) =>
    this.props.handleChange(this.props.id, "big_notice_duration", selectOption.value);

  handleVisionDisabledChange = (event) =>
    this.props.handleChange(this.props.id, "vision_disabled", event.target.checked);

  handleMaintenanceChange = (selectOption) =>
    this.props.handleChange(this.props.id, "current_maintenance", selectOption.value);

  handleScreenTypeChange = (selectOption) =>
    this.props.handleChange(this.props.id, "screen_type", selectOption.value);

  handleNotesChange = (event) =>
    this.props.handleChange(this.props.id, "notes", event.target.value);

  deleteScreen = () => {
    Elev8ApiService.delete(ApiTypes.screen_installation_config, this.props.id)
      .then(() => {
        // eslint-disable-next-line no-undef
        window.location.reload();
      })
      .catch(() => {
        // eslint-disable-next-line no-alert, no-undef
        alert(
          "Something went wrong, please ensure there's no devices configured for this location, or contact the dev team."
        );
      });
  };

  maintenanceOptions() {
    return [
      { label: "None", value: "no_maintenance" },
      { label: "Elevator In Progress", value: "elevator_in_progress" },
      { label: "Screen In Progress", value: "screen_in_progress" },
    ];
  }

  screenTypeOptions() {
    return [
      { label: "Lobby", value: "lobby" },
      { label: "Elevator", value: "elevator" },
    ];
  }

  bigNoticeFrequencyOptions() {
    const { t } = this.props;
    const options = [];

    Object.keys(BIG_NOTICE_FREQUENCY_VALUE_MAP).forEach((frequency) =>
      options.push({
        label: t("keywords.screenRelated.slotWithCount", {
          count: BIG_NOTICE_FREQUENCY_VALUE_MAP[frequency],
        }),
        value: String(frequency),
      })
    );

    return options;
  }

  bigNoticeDurationOptions() {
    // returns 10..20
    return [...Array(11).keys()].map((num) => ({ label: num + 10, value: num + 10 }));
  }

  render() {
    const {
      id,
      property_id,
      name,
      big_notice_frequency,
      big_notice_duration,
      current_maintenance,
      notes,
      screen_type,
      vision_disabled,
    } = this.props;

    const selectedBigNoticeFrequency = this.bigNoticeFrequencyOptions().find(
      (option) => option.value === big_notice_frequency
    );
    const selectedBigNoticeDuration = this.bigNoticeDurationOptions().find(
      (option) => option.value === big_notice_duration
    );
    const selectedCurrentMaintenance = this.maintenanceOptions().find(
      (option) => option.value === current_maintenance
    );
    const selectedScreenType = this.screenTypeOptions().find(
      (option) => option.value === screen_type
    );

    return (
      <Container>
        <Row>
          <Col className="px-3">
            <Row className="pt-3">
              <input type="hidden" name="id" value={id} onChange={this.handleNameChange} />
              <input
                type="hidden"
                name="property_id"
                value={property_id}
                onChange={this.handleChange}
              />

              <Col xs={6} sm={4} md={2}>
                <TextBox
                  textValue={name}
                  updateForm={this.handleNameChange}
                  formPropertyName="name"
                  description="Name"
                  placeHolder="Elevator 1"
                  inputType="text"
                  required
                />
              </Col>

              <Col xs={6} sm={4} md={2}>
                <div className="title">Big Notice Frequency</div>
                <Select
                  value={selectedBigNoticeFrequency}
                  isMulti={false}
                  onChange={this.handleFrequencyChange}
                  options={this.bigNoticeFrequencyOptions()}
                  name="big_notice_frequency"
                  isClearable={false}
                />
              </Col>

              <Col xs={6} sm={4} md={2}>
                <div className="title">Big Notice Duration</div>
                <Select
                  value={selectedBigNoticeDuration}
                  isMulti={false}
                  onChange={this.handleDurationChange}
                  options={this.bigNoticeDurationOptions()}
                  name="big_notice_duration"
                  isClearable={false}
                />
              </Col>

              <Col xs={6} sm={4} md={2}>
                <div className="title">Current Maintenance</div>
                <Select
                  value={selectedCurrentMaintenance}
                  isMulti={false}
                  onChange={this.handleMaintenanceChange}
                  options={this.maintenanceOptions()}
                  name="current_maintenance"
                  isClearable={false}
                />
              </Col>

              <Col xs={12} sm={8} md={3}>
                <div className="title">Notes</div>
                <TextArea value={notes} handleChange={this.handleNotesChange} />
              </Col>
            </Row>
            <Row className="pb-3 border-bottom">
              <Col xs={12} md={2}>
                <div className="title">Screen Type</div>
                <Select
                  value={selectedScreenType}
                  isMulti={false}
                  onChange={this.handleScreenTypeChange}
                  options={this.screenTypeOptions()}
                  name="screen_type"
                  isClearable={false}
                />
              </Col>
              <Col xs={12} md={1}>
                <div className="title">Vision Disabled</div>
                <Checkbox
                  type="checkbox"
                  name="vision_disabled"
                  value={vision_disabled}
                  handleChange={this.handleVisionDisabledChange}
                />
              </Col>
              <Col xs={12} md={1}>
                <Button size="sm" variant="outline-danger" onClick={this.deleteScreen}>
                  Delete Screen
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

ScreenFields.defaultProps = {
  notes: "",
  big_notice_duration: 0,
  big_notice_frequency: "",
};

ScreenFields.propTypes = {
  notes: PropTypes.string,
  big_notice_duration: PropTypes.number,
  big_notice_frequency: PropTypes.string,
  id: PropTypes.number.isRequired,
  property_id: PropTypes.number.isRequired,
};

export default withTranslation()(connect(null, null)(ScreenFields));
