// ALL ACTIONS FOR ADVERTISERS

import _ from "lodash";

import { setFormData } from "@components/Authentication/actions/settingActions";
import Elev8ApiService from "@lib/services/Elev8Api/Elev8ApiService";
import { FETCH_ADVERTISERS, SET_ADVERTISER_FORM } from "@App/actionTypes";
import { ADVERTISER_FIELDS } from "../formFields";

/** *****************************************
GLOBAL ACTIONS
******************************************* */
export function setAdvertiserForm(updatedForm) {
  return {
    type: SET_ADVERTISER_FORM,
    payload: updatedForm,
  };
}

export function clearAdvertiserForm() {
  return {
    type: SET_ADVERTISER_FORM,
    payload: null,
  };
}

export function fetchAdvertisers() {
  return (dispatch) => {
    Elev8ApiService.getOldEndpoint("advertisers")
      .then((response) => {
        const advertiserData = response.data;
        Elev8ApiService.getOldEndpoint("media?category=logo").then((mediaResponse) => {
          const mediaList = mediaResponse.data;
          advertiserData.forEach((item) => {
            let currImage = null;
            if (item.logo_id != null) {
              [currImage] = _.map(_.filter(mediaList, { id: item.logo_id }), "url");
            }
            item.area1 = `${item.contact_address_line1},${item.contact_city},${item.contact_country}`;
            item.url = currImage;
          });

          dispatch({
            type: FETCH_ADVERTISERS,
            payload: advertiserData,
          });
        });
      })
      .catch((e) => {
        console.log("Can't fetch advertisers", e);
      });
  };
}

/** *****************************************
INITIALIZING ADVERTISER FORM
******************************************* */

// initialize form for Advertisers
function createNewForm() {
  const newForm = [];
  for (let i = 0; i < ADVERTISER_FIELDS.length; i += 1) {
    const currField = ADVERTISER_FIELDS[i];
    if (currField.formType === "text") {
      newForm[currField.fieldName] = "";
    } else if (currField.formType === "media") {
      newForm[currField.fieldName] = null;
    }
  }
  newForm.formId = null;
  return newForm;
}

export function initiateAdvertiserForm(id) {
  return (dispatch) => {
    if (id == null) {
      // create form
      let newForm = {};
      newForm = createNewForm();
      newForm.state = "NEW";
      dispatch(setAdvertiserForm(newForm));
    } else {
      dispatch(initiateEditAdvertiserForm(id));
    }
  };
}

export function initiateEditAdvertiserForm(id) {
  return (dispatch) => {
    Elev8ApiService.getOldEndpoint(`advertisers/${id}`)
      .then((response) => {
        const newData = response.data;
        if (newData.logo_id) {
          Elev8ApiService.getOldEndpoint(`media/${newData.logo_id}`, {}).then((mediaResponse) => {
            const mediaData = mediaResponse.data;
            const newMedia = {
              name: mediaData.name,
              type: mediaData.type,
              category: mediaData.category,
              mediaId: mediaData.id,
              preview: mediaData.url,
            };
            newData.adLogo = newMedia;
            newData.formId = id;
            newData.state = "EDIT";
            dispatch(setAdvertiserForm(newData));
          });
        } else {
          newData.adLogo = null;
          newData.formId = id;
          dispatch(setAdvertiserForm(newData));
        }
      })
      .catch(() => {
        const advertiserForm = { state: "ERROR" };
        dispatch(setAdvertiserForm(advertiserForm));
      });
  };
}

/** ************************************
FORM ACTIONS
************************************* */

export function updatingAdvertiserForm(type, newData, oldData) {
  return (dispatch) => {
    const updatedForm = _.extend({}, oldData);
    switch (type) {
      case "newMediaError":
        updatedForm.currMedia = null;
        updatedForm.preview = "";
        break;
      case "clear":
        updatedForm.currMedia = null;
        updatedForm.preview = "";
        break;
      case "newMedia":
        updatedForm.currMedia = newData;
        updatedForm.preview = newData.file.preview;
        break;
      case "adLogo":
        updatedForm.logo_id = null;
        updatedForm[type] = newData;

        break;
      default:
        updatedForm[type] = newData;
        break;
    }
    dispatch(setAdvertiserForm(updatedForm));
  };
}

/** **************************************
DATABASE ACTIONS
**************************************** */

export function createAdvertiser(currentForm, router) {
  return (dispatch) => {
    Elev8ApiService.oldPost("advertisers", currentForm)
      .then((response) => {
        const advertiserId = response.data.id;
        if (currentForm.adLogo) {
          dispatch(fileUpload(currentForm.adLogo, advertiserId, router));
        } else {
          router.navigate(`/advertisers`);
        }
      })
      .catch(() => {
        currentForm.state = "ERROR";
        dispatch(setAdvertiserForm(currentForm));
      });
  };
}

export function fileUpload(mediaProps, adId, router) {
  return () => {
    const formData = setFormData(mediaProps.file, mediaProps.category, mediaProps.name);
    formData.append("advertiser_id", adId);
    Elev8ApiService.oldPost("media", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        const logoId = response.data.id;
        Elev8ApiService.oldPut(`advertisers/${adId}`, { logoId }).then(() => {
          router.navigate(`/advertisers/${adId}`);
        });
      })
      .catch(() => {
        console.log("Can't upload file");
      });
  };
}

// adLogo present but logoId null = new Logo, create it and associate it
// no Logo, update form to set logo to null

export function updateAdvertiser(currentForm, router) {
  return (dispatch) => {
    // You have no logo or new ad
    if (currentForm.logo_id == null && currentForm.adLogo == null) {
      dispatch(updateFormOnly(currentForm, router));
    } else if (currentForm.logo_id == null && currentForm.adLogo) {
      dispatch(updateNewLogo(currentForm, router)); // you have a new logo
    } else if (currentForm.logo_id) {
      dispatch(updateFormOnly(currentForm, router));
    } else {
      dispatch(updateDeleteLogo(currentForm, router)); // you deleted your logo
    }
  };
}

// User removed logo
export function updateDeleteLogo(currentForm, router) {
  return () => {
    currentForm.logo_id = null;
    Elev8ApiService.oldPut(`/advertisers/${currentForm.formId}`, currentForm)
      .then(() => {
        router.navigate(`/advertisers/${currentForm.formId}`);
      })
      .catch(() => {
        console.log("Can't delete old logo when updating ad form");
      });
  };
}

// User added new logo
export function updateNewLogo(currentForm, router) {
  return () => {
    const formData = setFormData(
      currentForm.adLogo.file,
      currentForm.adLogo.category,
      currentForm.adLogo.name,
      currentForm.id
    );
    formData.append("advertiser_id", currentForm.formId);
    Elev8ApiService.oldPost(`media/`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        const logoId = response.data.id;
        currentForm.logo_id = logoId;
        Elev8ApiService.oldPut(`advertisers/${currentForm.formId}`, currentForm).then(() => {
          router.navigate(`/advertisers/${currentForm.formId}`);
        });
      })
      .catch(() => {});
  };
}

export function updateFormOnly(currentForm, router) {
  return () => {
    Elev8ApiService.oldPut(`advertisers/${currentForm.formId}`, currentForm)
      .then(() => {
        router.navigate(`/advertisers/${currentForm.formId}`);
      })
      .catch((e) => {
        console.log("Can't update ad form only", e);
      });
  };
}
