import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import BasicTable from "../../../../Global/General/BasicTable";
import WarningMessageTooltip from "../../../../Global/Widgets/views/warningMessageTooltip";
import { SelectColumnFilter } from "../../../../Global/General/BasicTableFilter";

class CampaignPropertyTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPropertyDataModal: false,
      propertyData: null,
    };
  }

  handlePropertyDataClick = (propertyData) => {
    this.setState({ showPropertyDataModal: true, propertyData });
  };

  handlePropertyDataModalClose = () => {
    this.setState({ showPropertyDataModal: false, propertyData: null });
  };

  renderDeleteButton = ({ cell }) => {
    const { t } = this.props;
    const propertyId = cell.row.id;
    return (
      <div className="d-flex">
        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => this.props.handleRemoveSelectedProperty(propertyId)}
        >
          {t("keywords.buttonActions.delete")}
        </Button>
      </div>
    );
  };

  renderPropertyData = ({ row }) => {
    return (
      <Button
        variant="light"
        size="sm"
        value={row.id}
        onClick={() => this.handlePropertyDataClick(row.original)}
      >
        <i className="fa fa-file" />
      </Button>
    );
  };

  // eslint-disable-next-line consistent-return
  renderPropertyDataModal = () => {
    if (this.state.propertyData) {
      const { propertyData } = this.state;
      const propertyAttributes = propertyData.attributes;
      const fields = [];

      Object.keys(propertyAttributes).forEach((key) => {
        let value = String(propertyAttributes[key]);
        if (value === "") {
          value = "N/A";
        }

        fields.push(
          <p>
            {key}: {value}
          </p>
        );
      });

      return (
        <Modal
          show={this.state.showPropertyDataModal}
          onHide={this.handlePropertyDataModalClose}
          showCloseButton
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>{propertyData.name} Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ overflow: "auto", maxHeight: "400px" }}>
              <small>{fields}</small>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
  };

  renderContentRestrictions = ({ cell }) => {
    if (cell.value) {
      return <WarningMessageTooltip message={cell.value} place="bottom" />;
    }
    return null;
  };

  renderId = ({ row }) => {
    return row.id;
  };

  columns = () => {
    const { loopSpotsPerProperty, startAt, endAt, baseRate, currentRateValue, fullScreen } =
      this.props;

    const defaultColumns = [
      {
        Header: "ID",
        accessor: "blank",
        disableFilters: true,
        Cell: this.renderId,
      },
      {
        Header: "",
        accessor: "attributes.content_restriction",
        Cell: this.renderContentRestrictions,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Property Name",
        accessor: "attributes.name",
      },
      {
        Header: "Status",
        accessor: "attributes.status",
        Cell: (props) => {
          return <div className="text-capitalize">{props.cell.value}</div>;
        },
        Filter: SelectColumnFilter,
      },
      {
        Header: "Type",
        accessor: "attributes.property_type",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Market",
        accessor: (row) => {
          return row.market() && row.market().attributes.name;
        },
        Filter: SelectColumnFilter,
      },
      {
        Header: "Network",
        accessor: (row) => {
          return row.networkName;
        },
        Filter: SelectColumnFilter,
      },
      {
        Header: "Address",
        accessor: "attributes.address_line1",
      },
      {
        Header: "City",
        accessor: "attributes.address_city",
      },
      {
        Header: "Province",
        accessor: "attributes.address_province",
      },
      {
        Header: "Active Screens",
        accessor: (row) => {
          return row.activeScreens.length;
        },
      },
      {
        Header: "Served Impressions",
        accessor: (row) => {
          const propertyLoopSpots =
            typeof loopSpotsPerProperty === "number"
              ? loopSpotsPerProperty
              : loopSpotsPerProperty[row.attributes.id];
          return Math.round(row.getActiveBookedImpressions(startAt, endAt, propertyLoopSpots || 1));
        },
      },
      {
        Header: "Circulation",
        accessor: (row) => {
          return Math.round(row.getCirculation(this.props.startAt, this.props.endAt));
        },
      },
    ];

    if (baseRate) {
      defaultColumns.push({
        Header: "Price",
        accessor: (row) => {
          const propertyLoopSpots =
            typeof loopSpotsPerProperty === "number"
              ? loopSpotsPerProperty
              : loopSpotsPerProperty[row.attributes.id];
          const priceInfo = row.getCampaignCost(
            baseRate,
            currentRateValue,
            startAt,
            endAt,
            propertyLoopSpots,
            fullScreen
          );
          return `$${Math.round(priceInfo.price)}`;
        },
      });
    }

    const infoAndActionColumns = {
      Header: "",
      accessor: "property_data",
      Cell: this.renderPropertyData,
      disableFilters: true,
      disableSortBy: true,
    };

    defaultColumns.push(infoAndActionColumns);

    if (this.props.handleRemoveSelectedProperty) {
      defaultColumns.push({
        Header: "",
        accessor: "id",
        disableSortBy: true,
        disableFilters: true,
        Cell: this.renderDeleteButton,
      });
    }
    return defaultColumns;
  };

  render() {
    const { properties, selectedRows, onSelectedRowsChange, t } = this.props;
    return (
      <>
        {this.renderPropertyDataModal()}
        <div className="mainTable mainTable__withBorder campaignForm__table">
          <BasicTable
            tableHeader={() => null}
            noResultsDescription={t("propertyTerms.noPropertiesFound")}
            columns={this.columns()}
            data={properties}
            selectedRows={selectedRows}
            onSelectedRowsChange={onSelectedRowsChange}
          />
        </div>
      </>
    );
  }
}

CampaignPropertyTable.defaultProps = {
  properties: [],
  selectedRows: {},
  onSelectedRowsChange: null,
  handleRemoveSelectedProperty: null,
  currentRateValue: null,
};

CampaignPropertyTable.propTypes = {
  currentRateValue: PropTypes.number,
  startAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  endAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  loopSpotsPerProperty: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
  properties: PropTypes.array,
  selectedRows: PropTypes.object,
  onSelectedRowsChange: PropTypes.func,
  handleRemoveSelectedProperty: PropTypes.func,
};

export default withTranslation()(CampaignPropertyTable);
