import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class LoadingPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { pageName, t } = this.props;
    return (
      <div className="loading-Page">
        <div className="pageLoader" />
        <p>
          {t("keywords.appRelated.loading")}
          {pageName}
        </p>
      </div>
    );
  }
}

export default connect(null, null)(withTranslation()(LoadingPage));
