import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import Alert from "react-bootstrap/Alert";
import PropTypes from "prop-types";

import OldCampaignPropertySelectionContainer from "./OldCampaignPropertySelectionContainer";
import CampaignPropertyViewOnlyContainer from "./CampaignPropertyViewOnlyContainer";
import CampaignPropertyDataHelper from "../services/CampaignPropertyDataHelper";

class OldCampaignPropertyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyData: null,
    };
    this.fetchProperties();
  }

  fetchProperties = () => {
    CampaignPropertyDataHelper.fetchPropertyData()
      .then((propertyData) => {
        this.setState({ propertyData });
      })
      .catch(() => {
        this.showErrorAlert();
      });
  };

  handleRemoveSelectedProperty = (propertyIdToRemove) => {
    const currentCampaignPropertyIds = this.props.campaign.properties.map(
      (property) => property.attributes.id
    );
    const newPropertyIdSet = currentCampaignPropertyIds.filter(
      (item) => item !== propertyIdToRemove
    );
    this.props.updatePropertyIds(newPropertyIdSet);
  };

  renderInvalidPropertiesAlert = () => {
    const deactivatedProperties = this.props.campaign.properties.reduce((filtered, property) => {
      if (property.attributes.tester || property.attributes.status === "deactivated") {
        filtered.push(`${property.attributes.name}:${property.attributes.id}`);
      }
      return filtered;
    }, []);

    if (deactivatedProperties.length > 0) {
      return (
        <Alert variant="warning">
          <b>Your flight contains the following deactivated/tester properties:</b>{" "}
          {deactivatedProperties.join(", ")}
        </Alert>
      );
    }
    return null;
  };

  renderEditMode = () => (
    <OldCampaignPropertySelectionContainer
      toggleEditPropertyMode={this.props.toggleEditPropertyMode}
      availableProperties={this.state.propertyData}
      updatePropertyIds={this.props.updatePropertyIds}
      isUpdatingPropertyIds={this.props.isUpdatingPropertyIds}
      campaign={this.props.campaign}
      userEmail={this.props.userEmail}
    />
  );

  renderViewOnlyMode = () => (
    <CampaignPropertyViewOnlyContainer
      toggleEditPropertyMode={this.props.toggleEditPropertyMode}
      handleRemoveSelectedProperty={this.handleRemoveSelectedProperty}
      rawPropertyData={this.state.propertyData}
      campaign={this.props.campaign}
    />
  );

  render() {
    if (!this.state.propertyData) {
      return <>Loading properties...</>;
    }

    return (
      <>
        {this.props.editPropertyMode ? this.renderEditMode() : this.renderViewOnlyMode()}
        {this.renderInvalidPropertiesAlert()}
      </>
    );
  }
}

OldCampaignPropertyContainer.propTypes = {
  campaign: PropTypes.shape({
    properties: PropTypes.array,
    start_at: PropTypes.string.isRequired,
    end_at: PropTypes.string.isRequired,
    loop_spots_per_property: PropTypes.object.isRequired,
  }).isRequired,
  updatePropertyIds: PropTypes.func.isRequired,
  isUpdatingPropertyIds: PropTypes.bool.isRequired,
  userEmail: PropTypes.string.isRequired,
  editPropertyMode: PropTypes.bool.isRequired,
  toggleEditPropertyMode: PropTypes.func.isRequired,
};

export default withTranslation()(OldCampaignPropertyContainer);
