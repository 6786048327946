// ALL ACTIONS FOR PROPERTIES
import _ from "lodash";
import moment from "moment";

import { unstableHistory } from "@App/history";
import { SET_AD_LIST, SET_SINGLE_AD, SET_AD_STATUS } from "@App/actionTypes";
import TargetingSerializer from "@utilities/targetingSerializer";
import Elev8ApiService from "@lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "@lib/services/Elev8Api/lib";
import { setFormData } from "@components/Authentication/actions/settingActions";
import { fetchCampaignConfigurations } from "@components/Campaign/Creation/Configuration/services/configurationActions";

/** ******************************************
REDUCER
******************************************** */

export function setAdList(adList) {
  return {
    type: SET_AD_LIST,
    payload: adList,
  };
}

export function setSingleAd(ad) {
  return {
    type: SET_SINGLE_AD,
    payload: ad,
  };
}

/** ******************************************
LOADING
******************************************** */

export function clearAdList() {
  return {
    type: SET_AD_LIST,
    payload: null,
  };
}

export function clearAdForm() {
  return {
    type: SET_SINGLE_AD,
    payload: null,
  };
}

export function initiateSaveAds() {
  return {
    type: SET_AD_STATUS,
    payload: {
      opened: true,
      uploading: "inactive",
    },
  };
}

export function updateAds() {
  return {
    type: SET_AD_STATUS,
    payload: {
      opened: true,
      uploading: "saving",
    },
  };
}

export function closeAd() {
  return {
    type: SET_AD_STATUS,
    payload: {
      opened: false,
      uploading: "inactive",
    },
  };
}

export function adError() {
  return {
    type: SET_AD_STATUS,
    payload: {
      opened: true,
      uploading: "error",
    },
  };
}

function formatAd(currAd) {
  const start = moment(currAd.start_date, "YYYY-MM-DD HH:mm:ss");
  const end = moment(currAd.end_date, "YYYY-MM-DD HH:mm:ss");
  // --Weather list

  const targetingData = TargetingSerializer.serialize(currAd.targeting);

  const singleAd = _.merge(
    {
      id: currAd.id,
      campaign_id: currAd.campaign_id,
      adStart: start,
      adEnd: end,
      duration: currAd.duration,
      currMedia: {
        name: currAd.media.name,
        type: currAd.media.type,
        category: currAd.media.category,
        mediaId: currAd.media.id,
        preview: currAd.media.url,
      },

      selectedProperties: currAd.targeting.properties,
      published: currAd.published,
      title: currAd.title,
    },
    targetingData
  );
  return singleAd;
}

/** ******************************************
INITIALIZATION
******************************************** */

export function tailorAdList(data) {
  return (dispatch) => {
    const ads = data;
    const adList = [];
    for (let i = 0; i < ads.length; i += 1) {
      const currAd = formatAd(ads[i]);
      adList.push(currAd);
    }
    dispatch(setAdList(adList));
  };
}

export function initializeAdList(id) {
  return (dispatch) => {
    Elev8ApiService.getOldEndpoint(`ads/?campaign_id=${id}`)
      .then((response) => {
        dispatch(tailorAdList(response.data));
      })
      .catch((e) => {
        console.log("could not initializeAdList", e);
      });
  };
}

export function initiateSingleAd(id) {
  return (dispatch) => {
    Elev8ApiService.read(ApiTypes.campaign, id)
      .then((campaign) => {
        const campaignInfo = campaign.attributes;
        const properties = campaign.relationships.properties.map((p) => parseInt(p.id, 10));
        const defaultTargeting = TargetingSerializer.defaultTargeting();
        const newForm = _.merge(
          {
            selectedProperties: properties,
            title: "",
            id: null,
            adStart: moment(campaignInfo.start_at, "YYYY-MM-DD HH:mm:ss"),
            adEnd: moment(campaignInfo.end_at, "YYYY-MM-DD HH:mm:ss"),
            published: true,
            currMedia: null,
          },
          defaultTargeting
        );
        dispatch(initializeAdList(id));
        dispatch(setSingleAd(newForm));
      })
      .catch((e) => {
        console.log("Can't initiateSingleAd", e);
      });
  };
}

export function fetchSingleAd(id) {
  return (dispatch) => {
    const url = `ads/${id}`;
    Elev8ApiService.getOldEndpoint(url)
      .then((response) => {
        const newForm = formatAd(response.data);
        dispatch(setSingleAd(newForm));
        dispatch(fetchCampaignConfigurations(newForm.campaign_id));
        dispatch(initializeAdList(newForm.campaign_id));
      })
      .catch((e) => {
        console.log("Can't fetch Single Ad", e);
      });
  };
}

/** ***************
LOCAL UPDATES
***************** */

export function updatingLocalAdForm(type, newData, oldData) {
  return (dispatch) => {
    const updatedForm = _.extend({}, oldData);
    switch (type) {
      case "time":
        [updatedForm.adStart, updatedForm.adEnd] = newData;
        break;
      case "targeting":
        updatedForm.age = newData.age;
        updatedForm.periodsOfDay = newData.periodsOfDay;
        updatedForm.daysOfWeek = newData.daysOfWeek;
        updatedForm.gender = newData.gender;
        updatedForm.weather = newData.weather;
        updatedForm.glasses = newData.glasses;
        break;
      case "published":
        updatedForm.published = !updatedForm.published;
        break;
      default:
        updatedForm[type] = newData;
        break;
    }
    dispatch(setSingleAd(updatedForm));
  };
}

/** ***************
TO DATABASE
***************** */

export function deleteAd(ad, campaignId) {
  return (dispatch) => {
    dispatch(updateAds());
    Elev8ApiService.oldDelete(`ads/${ad.id}`)
      .then(() => {
        dispatch(closeAd());
        dispatch(initializeAdList(campaignId));
      })
      .catch(() => {
        dispatch(adError());
      });
  };
}

export function copyAd(ad, campaignId) {
  return (dispatch) => {
    dispatch(updateAds());
    Elev8ApiService.oldCopy(`ads/${ad.id}`)
      .then(() => {
        dispatch(closeAd());
        dispatch(initializeAdList(campaignId));
      })
      .catch(() => {
        dispatch(adError());
      });
  };
}

function packageAd(adProp, oldCampaign, mediaId) {
  const endDate = moment(adProp.adEnd, "MMMM Do YYYY h:mm:ss A");
  const startDate = moment(adProp.adStart, "MMMM Do YYYY h:mm:ss A");
  const deserializedTargeting = TargetingSerializer.deserialize(adProp);

  // Formatting Campaign
  const campaignTargeting = _.merge(
    {
      properties: adProp.selectedProperties,
      duration: oldCampaign.maxDuration,
    },
    deserializedTargeting
  );
  const adContent = {};

  let newType = adProp.currMedia.type;
  newType = newType.split("/");

  const campaignAd = {
    advertiser_id: oldCampaign.advertiser_id,
    campaign_id: oldCampaign.id,
    content: adContent,
    type: newType[0],
    start_date: startDate.format("YYYY-MM-DD HH:mm:ss"),
    end_date: endDate.format("YYYY-MM-DD HH:mm:ss"),
    published: adProp.published,
    targeting: campaignTargeting,
    media_id: mediaId,
    title: adProp.title,
    id: adProp.id,
  };
  return campaignAd;
}

export function saveAdtoDatabase(props, oldCampaign, mediaId) {
  return (dispatch) => {
    dispatch(updateAds());
    const campaignAd = packageAd(props, oldCampaign, mediaId);
    Elev8ApiService.oldPost("ads", campaignAd)
      .then(() => {
        dispatch(closeAd());
        unstableHistory.push(`/flight/edit/${oldCampaign.id}`);
      })
      .catch((e) => {
        console.log("Can't saveAdtoDatabase", e);
        dispatch(adError());
      });
  };
}
export function updateAdtoDatabase(props, oldCampaign, mediaId) {
  return (dispatch) => {
    dispatch(updateAds());
    const campaignAd = packageAd(props, oldCampaign, mediaId);
    Elev8ApiService.oldPut(`ads/${campaignAd.id}`, campaignAd)
      .then(() => {
        dispatch(closeAd());
        unstableHistory.push(`/flight/edit/${oldCampaign.id}`);
      })
      .catch((e) => {
        console.log("Can't updateAdtoDatabase", e);
        dispatch(adError());
      });
  };
}

// STEP 1
export function updateLocalAd(props, oldCampaign) {
  return (dispatch) => {
    if (props.currMedia.mediaId == null) {
      // if media id is null, that means it's NEW
      // Set new Media Props
      const fileData = setFormData(
        props.currMedia.file,
        "ad",
        props.currMedia.name,
        oldCampaign.advertiser_id
      );
      return Elev8ApiService.oldPost("media", fileData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          dispatch(updateAdtoDatabase(props, oldCampaign, response.data.id));
        })
        .catch(() => {
          dispatch(adError());
        });
    }
    dispatch(updateAdtoDatabase(props, oldCampaign, props.currMedia.mediaId));
  };
}

// STEP 1
export function createLocalAd(props, oldCampaign) {
  return (dispatch) => {
    dispatch(updateAds());
    // set media
    const fileData = setFormData(
      props.currMedia.file,
      "ad",
      props.currMedia.name,
      oldCampaign.advertiser_id
    );
    return Elev8ApiService.oldPost("media", fileData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        dispatch(saveAdtoDatabase(props, oldCampaign, response.data.id));
      })
      .catch(() => {
        dispatch(adError());
      });
  };
}
