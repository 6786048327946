// CONSTANTS FOR FORM

// SIZES FOR FORMS
export const FORMSIZE = {
  full: 900,
  half: 350,
  third: 500,
  fourth: 280,
  halfmore: 500,
};
