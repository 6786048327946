import { JsonApiObject } from "../index";
import CampaignPriceCalculator from "../../../../../utilities/CampaignPriceCalculator";
import {
  formDurationInSeconds,
  formDurationInWeeks,
} from "../../../../../utilities/dateStringHelper";
import { LOOP_SECONDS } from "../../../../../components/constants";
import i18n from "../../../../../i18n";

const PROPERTY_TYPE_LOCALIZATION = {
  Condo: "keywords.propertyRelated.type.condo",
  Commercial: "keywords.propertyRelated.type.commercial",
  "Luxury Rental": "keywords.propertyRelated.type.rental",
  Other: "keywords.propertyRelated.type.other",
};

// eslint-disable-next-line import/prefer-default-export
export class Property extends JsonApiObject {
  constructor(apiObject, included) {
    super(apiObject, included);
    this.activeScreens = this.getActiveScreens();
    this.networkName = this.getNetworkName();
    this.translatedPropertyType = i18n.t(this.getTranslatedPropertyType());
    this.presentableAddress = this.getPresentableAddress();
  }

  onVCNetwork() {
    return this.networkName === "Vertical City";
  }

  getActiveBookedImpressions(startDate, endDate, loopSpots) {
    if (!startDate || !endDate) return 0;

    const numberOfLoops = this.getNumberOfLoops(startDate, endDate);
    const screenCount = this.activeScreens.length;

    return numberOfLoops * loopSpots * screenCount;
  }

  getMarketName() {
    if (this.market()) {
      return this.market().attributes.name;
    }
    return null;
  }

  getCirculation(startDate, endDate) {
    const durationInWeeks = formDurationInWeeks(startDate, endDate);
    const circulationForDateRange = this.attributes.weekly_circulation_estimate * durationInWeeks;

    return circulationForDateRange;
  }

  getNumberOfLoops(startDate, endDate) {
    if (!startDate || !endDate) return 0;

    const durationInSeconds = formDurationInSeconds(startDate, endDate);
    return durationInSeconds / LOOP_SECONDS;
  }

  getScreenCount() {
    if (Object.prototype.hasOwnProperty.call(this.relationships, "screen_installation_configs")) {
      const screenInstallationConfigs = this.screen_installation_configs();
      return screenInstallationConfigs.length;
    }
    return 0;
  }

  fetchContentUrl() {
    if (this.screens().length === 0) {
      return null;
    }
    const validScreen = this.screens().find((screen) => screen.attributes.elev8_anywhere_url);
    return validScreen ? validScreen.attributes.elev8_anywhere_url : null;
  }

  getCampaignCost(baseRate, currentRateValue, startAt, endAt, loopSpots, fullScreen) {
    const { activeScreens } = this;
    const priceInfo = CampaignPriceCalculator.propertyPriceInfo(
      baseRate,
      currentRateValue,
      activeScreens.length,
      startAt,
      endAt,
      loopSpots,
      fullScreen
    );
    return priceInfo;
  }

  getNetworkName() {
    if (Object.prototype.hasOwnProperty.call(this.relationships, "network")) {
      return this.network().attributes.name;
    }
    return null;
  }

  getPresentableAddress() {
    const presentableAddress = [this.attributes.address_line1, this.attributes.address_city].join(
      ", "
    );
    return presentableAddress;
  }

  getTranslatedPropertyType() {
    const propertyType = this.attributes.property_type;
    if (!propertyType) return "keywords.errorRelated.notAvailable";
    return PROPERTY_TYPE_LOCALIZATION[propertyType] || "keywords.errorRelated.notAvailable";
  }

  getActiveScreens() {
    if (Object.prototype.hasOwnProperty.call(this.relationships, "screen_installation_configs")) {
      const screenInstallationConfigs = this.screen_installation_configs();
      const activeScreens = screenInstallationConfigs.filter(
        (screen) => screen.attributes.current_maintenance === "no_maintenance"
      );
      return activeScreens;
    }
    return [];
  }
}
