import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { Form, Row, Col } from "react-bootstrap";

import { formDurationInDays } from "../../../../utilities/dateStringHelper";
import { CAMPAIGN_TYPE_MAP } from "../../../constants";
import Checkbox from "../../../Global/Widgets/updatedWidgets/checkBox";
import Dropdown from "../../../Global/Widgets/updatedWidgets/dropDown";
import NumberInput from "../../../Global/Widgets/updatedWidgets/numberInput";
import SingleDatePicker from "../../../Global/Widgets/updatedWidgets/singleDatePicker";
import TextInput from "../../../Global/Widgets/textInput";

class CampaignDetails extends Component {
  // separate from handleFieldChange because NumberInput "event" object is not a true synthetic event and has no persist() method
  handleNumberChange = (event) => {
    this.props.onChange(event);
  };

  handleStatusChange = (event) => {
    let newStatus;
    if (event.target.checked) {
      newStatus = "active";
    } else {
      newStatus = "inactive";
    }
    this.props.onChange({ target: { name: "status", value: newStatus } });
  };

  handleFieldChange = (event) => {
    event.persist();

    this.props.onChange(event);
  };

  handleDropdownChange = (name, value) => {
    this.props.onChange({ target: { name, value } });
  };

  handleTimeChange = (name, time) => {
    this.props.onChange({ target: { name, value: time } });
  };

  formatCampaignDuration = () => {
    const { t, campaign } = this.props;
    const duration = Math.round(formDurationInDays(campaign.start_at, campaign.end_at));
    const unit = duration > 1 ? t("keywords.dateRelated.days") : t("keywords.dateRelated.day");
    return duration > 0 ? `${duration} ${unit}` : "Invalid date range";
  };

  render() {
    const { t, campaign, isActiveCampaign } = this.props;

    return (
      <>
        <Row>
          <Form.Group as={Col} lg="6">
            <TextInput
              label={t("keywords.general.name")}
              value={campaign.name}
              handleChange={this.handleFieldChange}
              required
              name="name"
              type="text"
            />
          </Form.Group>
          <Form.Group as={Col} lg="2">
            <SingleDatePicker
              label={t("keywords.dateRelated.start")}
              onChange={(time) => this.handleTimeChange("start_at", time)}
              date={campaign.start_at}
            />
          </Form.Group>
          <Form.Group as={Col} lg="2">
            <SingleDatePicker
              label={t("keywords.dateRelated.end")}
              onChange={(time) => this.handleTimeChange("end_at", time)}
              date={campaign.end_at}
              minDate={campaign.start_at}
            />
          </Form.Group>
          <Form.Group as={Col} lg="2">
            <TextInput
              label="Duration"
              value={this.formatCampaignDuration()}
              readOnly
              type="text"
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg="3">
            <Dropdown
              includeBlank
              label="Flight Type"
              value={campaign.campaign_type}
              handleChange={this.handleFieldChange}
              name="campaign_type"
              required
              options={CAMPAIGN_TYPE_MAP}
            />
          </Form.Group>
          <Form.Group as={Col} lg="3">
            <Dropdown
              includeBlank
              label="Ad Length"
              value={campaign.ad_duration}
              handleChange={this.handleFieldChange}
              required
              name="ad_duration"
              options={[
                { label: "5 seconds", value: 5 },
                { label: "10 seconds", value: 10 },
                { label: "15 seconds", value: 15 },
                { label: "20 seconds", value: 20 },
                { label: "25 seconds", value: 25 },
                { label: "30 seconds", value: 30 },
              ]}
            />
          </Form.Group>
          <Form.Group as={Col} lg="2">
            <Dropdown
              includeBlank
              label="Enable Video Ads"
              value={campaign.video_enabled}
              handleChange={this.handleFieldChange}
              name="video_enabled"
              required
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Form.Group>
          <Form.Group as={Col} lg="2">
            <Dropdown
              includeBlank
              label="Fullscreen"
              value={campaign.fullscreen}
              handleChange={this.handleFieldChange}
              name="fullscreen"
              required
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
            />
          </Form.Group>
          <Form.Group as={Col} lg="2">
            <TextInput
              label="RSM Flight #"
              value={campaign.rsm_flight_id || ""}
              handleChange={this.handleFieldChange}
              type="text"
              name="rsm_flight_id"
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <TextInput
              label="Comments"
              value={campaign.comments || ""}
              handleChange={this.handleFieldChange}
              type="text"
              name="comments"
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg="2">
            <TextInput
              label="Loop Spots"
              value={campaign.loop_spots_description}
              name="loop_spots"
              readOnly
              type="number"
            />
          </Form.Group>
          <Form.Group as={Col} lg="2">
            <TextInput
              label="Booked Impressions"
              value={String(campaign.booked_impressions)}
              handleChange={this.handleFieldChange}
              required
              name="booked_impressions"
              type="number"
            />
          </Form.Group>
          <Form.Group as={Col} lg="3">
            <NumberInput
              label="Contract Value"
              disabled={isActiveCampaign}
              value={campaign.standard_price_dollars}
              handleChange={this.handleNumberChange}
              prefix="$"
              name="standard_price_dollars"
            />
          </Form.Group>
          <Form.Group as={Col} lg="3">
            <NumberInput
              label="Rate Card Value (optional)"
              value={campaign.actual_value_dollars}
              handleChange={this.handleNumberChange}
              prefix="$"
              name="actual_value_dollars"
            />
          </Form.Group>
          <Form.Group as={Col} lg="2">
            <div className="pt-4">
              <Checkbox
                label="Published"
                value={campaign.status === "active"}
                handleChange={this.handleStatusChange}
                name="status"
              />
            </div>
          </Form.Group>
        </Row>
      </>
    );
  }
}

CampaignDetails.propTypes = {
  campaign: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(CampaignDetails);
