import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { withTranslation } from "react-i18next";

class TextArea extends Component {
  render() {
    const { name, label, required, value, rows, handleChange, t } = this.props;
    return (
      <React.Fragment>
        {label ? <Form.Label>{label}</Form.Label> : null}
        <Form.Control
          as="textarea"
          rows={rows}
          name={name}
          value={value}
          required={required}
          onChange={handleChange}
        />
        {required ? (
          <Form.Control.Feedback type="invalid">
            {t("keywords.errorRelated.required")}
          </Form.Control.Feedback>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withTranslation()(TextArea);
