import Fuse from "fuse.js";

export function filterDataBySearchValue(keys, dataToFilter, searchValue) {
  const options = {
    keys: keys,
    threshold: 0.1,
    ignoreLocation: true,
  };
  let filteredResult = dataToFilter;
  if (searchValue.trim() !== "") {
    const fuse = new Fuse(dataToFilter, options);
    filteredResult = fuse.search(searchValue);
    filteredResult = filteredResult.map((result) => {
      return result.item;
    });
  }
  return filteredResult;
}
