import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";

import withRouter from "@src/hooks/withRouter";
import darkLogo from "../../../../../style/images/VC-logo-dark.png";
import DropDownWidget from "../../../Global/Widgets/views/dropDown";
import Elev8ApiService from "../../../../lib/services/Elev8Api/Elev8ApiService";
import Dropdown from "../../../Global/Widgets/updatedWidgets/dropDown";
import Checkbox from "../../../Global/Widgets/updatedWidgets/checkBox";
import { addFlashMessage } from "../../../Interface/General/services/actions/applicationStateActions";

class RegisterScreen extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      properties: undefined,
      screenConfigs: [],
      selectedProperty: undefined,
      selectedConfig: undefined,
      showAllProperties: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.getPropertyAndConfigs();
  }

  getPropertyAndConfigs() {
    return Elev8ApiService.server()
      .get(`/screen/${this.macAddress()}/register`, {
        params: {
          show_all_screen_installation_config: this.state.showAllProperties,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState({ properties: response.data.data, screenConfigs: response.data.included });
        }
      });
  }

  toggleShowAllProperties = (event) => {
    this.setState({ showAllProperties: event.target.checked }, () => {
      this.getPropertyAndConfigs();
    });
  };

  propertySelected = (fieldName, propertyId) => {
    this.setState({ selectedProperty: propertyId, selectedConfig: undefined });
  };

  configSelected = (event) => {
    this.setState({ selectedConfig: event.target.value });
  };

  register = () => {
    Elev8ApiService.server()
      .post(`/screen/${this.macAddress()}/register`, {
        screen_installation_config_id: this.state.selectedConfig,
      })
      .then((response) => {
        if (response.status === 200) {
          this.props.addFlashMessage(
            "success",
            "Thank you for registering your screen with Vertical City"
          );

          window.location.replace("https://verticalcity.com");
        }
      })
      .catch(() => {
        this.props.addFlashMessage(
          "danger",
          "Something went wrong, please try again or contact support@verticalcity.com"
        );
      });
  };

  macAddress() {
    return this.props.router.params.mac_address;
  }

  screenConfigList() {
    if (!this.state.selectedProperty) {
      return [];
    }

    return this.state.screenConfigs
      .filter(
        (config) => config.attributes.property_id === parseInt(this.state.selectedProperty, 10)
      )
      .map((config) => {
        const attrs = config.attributes;
        return [attrs.name, attrs.id];
      });
  }

  propertySelectList() {
    return this.state.properties.map((property) => {
      const attrs = property.attributes;
      return {
        value: attrs.id,
        label: `${attrs.name} (${attrs.address_line1 + attrs.address_line2})`,
      };
    });
  }

  renderShowAllToggle() {
    return (
      <Form.Group as={Col} md="12">
        <Checkbox
          label="Replacing a previously registered device?"
          value={this.props.showAllProperties}
          handleChange={this.toggleShowAllProperties}
        />
      </Form.Group>
    );
  }

  renderPropertySelect() {
    if (this.state.properties === undefined) {
      return (
        <div className="loading-Page">
          <div className="pageLoader" />
        </div>
      );
    }

    return (
      <Form.Group as={Col} md="12">
        <p>Please select your property</p>
        <DropDownWidget
          formValue={this.state.selectedProperty}
          formPropertyName="property-id"
          updateForm={this.propertySelected}
          multiSelect={false}
          title=""
          options={this.propertySelectList()}
        />
      </Form.Group>
    );
  }

  renderConfigSelect() {
    if (!this.state.selectedProperty) {
      return null;
    }

    return (
      <Form.Group as={Col} md="12">
        <p>Please choose your screen location</p>
        <Dropdown
          value={this.state.selectedConfig}
          handleChange={this.configSelected}
          includeBlank
          required
          name="property-id"
          options={this.screenConfigList()}
        />
      </Form.Group>
    );
  }

  renderSubmit() {
    if (this.state.selectedProperty !== undefined && this.state.selectedConfig !== undefined) {
      return (
        <Form.Group as={Col} md="12">
          <button
            type="button"
            className="btn btn-primary btn-lg btn-block"
            onClick={this.register}
          >
            Register your screen
          </button>
        </Form.Group>
      );
    }

    return null;
  }

  render() {
    return (
      <Container className="registration-page-flex d-flex flex-column pt-5">
        <Row sm={12} md={12} lg={12}>
          <img alt="Vertical City Logo" className="mb-5" src={darkLogo} />

          {this.renderShowAllToggle()}

          {this.renderPropertySelect()}

          {this.renderConfigSelect()}

          {this.renderSubmit()}
        </Row>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addFlashMessage,
    },
    dispatch
  );
}

export default withRouter(connect(null, mapDispatchToProps)(withTranslation()(RegisterScreen)));
