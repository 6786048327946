// ALL ACTIONS FOR PROPERTIES

import { unstableHistory } from "@App/history";
import { setFormDataNoId } from "@components/Authentication/actions/settingActions";
import { SET_RSS_FEED_FORM, FETCH_RSS_FEEDS } from "@App/actionTypes";
import Elev8ApiService from "@lib/services/Elev8Api/Elev8ApiService";

/** *****************************************
GLOBAL ACTION
******************************************* */

export function fetchRssFeeds() {
  return function (dispatch) {
    Elev8ApiService.getOldEndpoint("feeds")
      .then((response) => {
        // Configuring Rss Feed images for banner and background
        const iconData = response.data;
        iconData.forEach(function (item) {
          item.iconUrl = item.icon && item.icon.url;
          item.bannerUrl = item.background && item.background.url;
          item.signatureUrl = item.signature && item.signature.url;
        });

        dispatch({
          type: FETCH_RSS_FEEDS,
          payload: iconData,
        });
      })
      .catch((e) => {
        console.log("Can't fetch feeds", e);
      });
  };
}

export function clearRssFeedForm() {
  return {
    type: SET_RSS_FEED_FORM,
    payload: null,
  };
}

export function setRssFeedForm(updatedForm) {
  return {
    type: SET_RSS_FEED_FORM,
    payload: updatedForm,
  };
}

export function updateMedia(currMedia, props, type) {
  return function () {
    if (currMedia && !currMedia.mediaId) {
      const mediaFormData = setFormDataNoId(currMedia.file, currMedia.category, currMedia.name);

      return Elev8ApiService.oldPost("media", mediaFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          props[type] = response.data.id;
          return Elev8ApiService.oldPut(`feeds/${props.formId}`, props);
        })
        .catch((e) => {
          console.log("Can't update feed image media", e);
        });
    }
  };
}

/** *****************************************
INITIALIZING RSS FEED
******************************************* */
const DEFAULT_RSS_TITLE_COLOR = "#013157";
const DEFAULT_RSS_DIVIDER_COLOR = "#dddddd";
export function initiateRssFeedForm(id) {
  return function (dispatch) {
    if (id == null) {
      const newForm = {
        name: "",
        url: "",
        title_color: DEFAULT_RSS_TITLE_COLOR,
        divider_color: DEFAULT_RSS_DIVIDER_COLOR,
        currIcon: null,
        currBanner: null,
        currSignature: null,
        formId: null,
      };
      dispatch(setRssFeedForm(newForm));
    } else {
      dispatch(initiateEditRssFeedForm(id));
    }
  };
}

export function initiateEditRssFeedForm(id) {
  return function (dispatch) {
    Elev8ApiService.getOldEndpoint(`feeds/${id}`)
      .then((response) => {
        const newData = response.data;
        const iconForm = newData.icon && {
          name: newData.icon.name,
          type: newData.icon.type,
          category: newData.icon.category,
          mediaId: newData.icon.id,
          preview: newData.icon.url,
        };
        const bannerForm = newData.background && {
          name: newData.background.name,
          type: newData.background.type,
          category: newData.background.category,
          mediaId: newData.background.id,
          preview: newData.background.url,
        };
        const signatureForm = newData.signature && {
          name: newData.signature.name,
          type: newData.signature.type,
          category: newData.signature.category,
          mediaId: newData.signature.id,
          preview: newData.signature.url,
        };
        const newForm = {
          name: newData.name,
          url: newData.url,
          title_color: newData.title_color,
          divider_color: newData.divider_color,
          currIcon: iconForm,
          currBanner: bannerForm,
          currSignature: signatureForm,
          formId: id,
          oldBannerId: (newData.background && newData.background.id) || null,
          oldIconId: (newData.icon && newData.icon.id) || null,
          oldSignatureId: (newData.signature && newData.signature.id) || null,
        };
        dispatch(setRssFeedForm(newForm));
      })
      .catch((e) => {
        console.log("Can't initiate edit rss feed form", e);
      });
  };
}

/** ************************************
FORM ACTIONS
************************************* */

export function updatingRssFeedForm(type, newData, oldData) {
  return function (dispatch) {
    const updatedForm = _.extend({}, oldData);
    switch (type) {
      default:
        updatedForm[type] = newData;
        break;
    }
    dispatch(setRssFeedForm(updatedForm));
  };
}

/** ************************************
DATABASE ACTIONS
************************************* */

export function createRssFeed(props) {
  return function () {
    const bannerFormData = setFormDataNoId(
      props.currBanner.file,
      props.currBanner.category,
      props.currBanner.name
    );
    const iconFormData = setFormDataNoId(
      props.currIcon.file,
      props.currIcon.category,
      props.currIcon.name
    );
    const signatureFormData = setFormDataNoId(
      props.currSignature.file,
      props.currSignature.category,
      props.currSignature.name
    );

    createMedia(bannerFormData, (bannerId) => {
      createMedia(iconFormData, (iconId) => {
        createMedia(signatureFormData, (signatureId) => {
          props.background_id = bannerId;
          props.icon_id = iconId;
          props.signature_id = signatureId;

          Elev8ApiService.oldPost("feeds", props)
            .then(() => {
              unstableHistory.push("/rss-feeds");
            })
            .catch((e) => {
              console.log("Can't create rss feed 1", e);
            });
        });
      });
    });
  };
}

function createMedia(mediaData, createdCallback) {
  return Elev8ApiService.oldPost("media", mediaData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((response) => {
      const mediaId = response.data.id;
      createdCallback(mediaId);
    })
    .catch((e) => {
      console.log("Can't create rss feed image", e);
    });
}

export function editRssFeed(props) {
  return function (dispatch) {
    Elev8ApiService.oldPut(`feeds/${props.formId}`, props)
      .then(() => {
        return Promise.all([
          dispatch(updateMedia(props.currBanner, props, "background_id")),
          dispatch(updateMedia(props.currIcon, props, "icon_id")),
          dispatch(updateMedia(props.currSignature, props, "signature_id")),
        ]).then(() => {
          unstableHistory.push("/rss-feeds");
        });
      })
      .catch((e) => {
        console.log("Can't edit rss feed", e);
      });
  };
}

export function deleteRssFeed(feedId) {
  return function (dispatch) {
    Elev8ApiService.oldDelete(`feeds/${feedId}`)
      .then(() => {
        dispatch(fetchFeeds());
        unstableHistory.push("/rss-feeds");
      })
      .catch((e) => {
        console.log("Can't delete rss feed", e);
      });
  };
}
