import Elev8ApiService from "../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../lib/services/Elev8Api/lib";
import { formDateAsApiData, apiDateAsFormData } from "../../../utilities/dateStringHelper";
import {
  convertStringArrayToIntArray,
  getCentsFromDollars,
  getDollarsFromCents,
  roundToDecimal,
} from "../../../utilities/mathHelpers";
import CampaignRates from "../../../utilities/CampaignRates";

const CampaignProposalSerializer = {
  formData(obj = null) {
    return apiObjectToFormData(obj);
  },
  apiObject(data = null) {
    return formDataToApiObject(data);
  },
};

const CAMPAIGN_PROPOSAL_FORM_DEFAULTS = {
  campaign_group_proposal_id: "",
  name: "",
  start_at: "",
  end_at: "",
  loop_spots: "1",
  campaign_type: "advertising",
  rate_type: "15_seconds",
  rate_value_dollars: CampaignRates.baseRateInDollarsFromRate("15_seconds"),
  discount: 0,
  fullscreen: "false",
  property_ids: [],
  target_screens: "0",
  booked_impressions: "0",
  standard_price_dollars: "0",
};

function apiObjectToFormData(obj) {
  if (!obj) return { ...CAMPAIGN_PROPOSAL_FORM_DEFAULTS };

  const { attributes } = obj;
  return {
    id: attributes.id,
    campaign_group_proposal_id: obj.relationships.campaign_group_proposal
      ? obj.relationships.campaign_group_proposal.id
      : "",
    name: attributes.name || "",
    start_at: apiDateAsFormData(attributes.start_at),
    end_at: apiDateAsFormData(attributes.end_at),
    loop_spots: String(attributes.loop_spots),
    campaign_type: attributes.campaign_type || "",
    rate_type: attributes.rate_type || "",
    rate_value_dollars: attributes.rate_value_cents
      ? getDollarsFromCents(attributes.rate_value_cents)
      : "0",
    discount: attributes.discount_percent
      ? roundToDecimal(attributes.discount_percent * 100, 2)
      : 0,
    fullscreen: String(attributes.fullscreen),
    property_ids: attributes.property_ids && attributes.property_ids.map(String),
    target_screens: String(attributes.target_screens),
    booked_impressions: String(attributes.booked_impressions),
    standard_price_dollars: attributes.standard_price_cents
      ? getDollarsFromCents(attributes.standard_price_cents)
      : "0",
  };
}

function formDataToApiObject(formData) {
  const relationships = {};
  const attributes = {
    id: formData.id,
    name: formData.name,
    start_at: formDateAsApiData(formData.start_at, "startOfDay"),
    end_at: formDateAsApiData(formData.end_at, "endOfDay"),
    loop_spots: parseInt(formData.loop_spots, 10),
    campaign_type: formData.campaign_type,
    rate_type: formData.rate_type,
    rate_value_cents: formData.rate_value_dollars
      ? getCentsFromDollars(formData.rate_value_dollars)
      : 0,
    discount_percent: formData.discount ? formData.discount / 100 : 0,
    fullscreen: formData.fullscreen === "true",
    property_ids: convertStringArrayToIntArray(formData.property_ids),
    target_screens: parseInt(formData.target_screens, 10),
    booked_impressions: parseInt(formData.booked_impressions, 10),
    standard_price_cents: formData.standard_price_dollars
      ? getCentsFromDollars(formData.standard_price_dollars)
      : 0,
  };

  if (formData.campaign_group_proposal_id) {
    relationships.campaign_group_proposal = Elev8ApiService.setToJsonApiFormat(
      ApiTypes.campaign_group_proposal.type,
      formData.campaign_group_proposal_id
    );
  }

  return Elev8ApiService.setToJsonApiFormat(
    ApiTypes.campaign_proposal.type,
    formData.id,
    attributes,
    relationships
  );
}

export { CampaignProposalSerializer, CAMPAIGN_PROPOSAL_FORM_DEFAULTS };
