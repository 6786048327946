const CreateMapPropertyPopup = function (options = { getServedImpressions: null }) {
  return (property) => {
    // TODO: add average income (needs api output)
    return `
      <div class="map-property-popup">
        <div class="map-property-popup__header detail-section-header">${property.name}</div>
        <div class="map-property-popup__info">
          ${infoSection("Address", property.address_line1)}
          ${servedImpressionsSection(options.getServedImpressions, property)}
          ${infoSection("Property Type", property.property_type)}
          ${infoSection("Screens", property.screen_count)}
        </div>
      </div>
    `;
  };
};

function servedImpressionsSection(getServedImpressions, property) {
  if (getServedImpressions != null) {
    return infoSection("Served Impressions", getServedImpressions(property));
  }
  return "";
}

function infoSection(header, content) {
  return `
    <div class="map-property-popup__info-header detail-header">
      ${header}
    </div>
    <div class="map-property-popup__info-content detail-content">
      ${content}
    </div>
  `;
}

export default CreateMapPropertyPopup;
