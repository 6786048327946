import { FULL_SCREEN_RATE } from "../components/constants";

const AD_DURATIONS = {
  "5_seconds": 5,
  "10_seconds": 10,
  "15_seconds": 15,
  "30_seconds": 30,
};

export const RATE_TYPES = {
  cpm: "CPM",
};

const RATE_OPTIONS = [
  {
    label: "5 seconds – $5.50",
    value: "5_seconds",
    baseRateDollars: 5.5,
    adDuration: AD_DURATIONS["5_seconds"],
    type: RATE_TYPES.cpm,
  },
  {
    label: "10 seconds – $8.00",
    value: "10_seconds",
    baseRateDollars: 8,
    adDuration: AD_DURATIONS["10_seconds"],
    type: RATE_TYPES.cpm,
  },
  {
    label: "15 seconds – $8.00",
    value: "15_seconds",
    baseRateDollars: 8,
    adDuration: AD_DURATIONS["15_seconds"],
    type: RATE_TYPES.cpm,
  },
  {
    label: "30 seconds – $12.00",
    value: "30_seconds",
    baseRateDollars: 12,
    adDuration: AD_DURATIONS["30_seconds"],
    type: RATE_TYPES.cpm,
  },
];

export default class CampaignRates {
  static allRateOptions() {
    return RATE_OPTIONS;
  }

  static groupedSelectOptions() {
    const campaignRatesByType = [
      { group: RATE_TYPES.cpm, options: this._groupByRateType(RATE_TYPES.cpm) },
    ];
    return campaignRatesByType;
  }

  static adDurationFromRate(rateValue) {
    const adDuration = this._getFieldValueFromRate(rateValue, "adDuration");
    return adDuration || null;
  }

  static baseRateInDollarsFromRate(rateValue, fullScreen) {
    const baseRateValueInDollars = this._getFieldValueFromRate(rateValue, "baseRateDollars");
    const fullScreenRateMultiplier = this._fullScreenRateMultiplier(fullScreen);
    const adjustedBaseRateValueInDollars = baseRateValueInDollars
      ? (fullScreenRateMultiplier * baseRateValueInDollars).toFixed(2)
      : null;
    return adjustedBaseRateValueInDollars;
  }

  static rateTypeFromRate(rateValue) {
    const rateType = this._getFieldValueFromRate(rateValue, "type");
    return rateType || null;
  }

  static _groupByRateType(rateType) {
    const groupedRateTypes = RATE_OPTIONS.filter(function (rateOptions) {
      return rateOptions.type === rateType;
    });
    return groupedRateTypes;
  }

  static _fullScreenRateMultiplier(fullScreen) {
    if (fullScreen === "true") return FULL_SCREEN_RATE;
    return 1;
  }

  static _getFieldValueFromRate(rateValue, field) {
    const selectedRate = RATE_OPTIONS.find((rateType) => rateType.value === rateValue);
    if (selectedRate) {
      return selectedRate[field];
    }
    return null;
  }
}
