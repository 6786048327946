import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { withTranslation } from "react-i18next";

import withRouter from "@hooks/withRouter";
import {
  initiateSideNoticeForm,
  updatingSideNoticeForm,
  createSideNotice,
  editSideNotice,
  deleteSideNotice,
  clearSideNoticeForm,
} from "@components/Client/SideNotice/services/actions/sideNoticeActions";
import { fetchFeedProperties } from "@components/Property/services/actions/propertyManagerActions";
import SubmitButtonForm from "@components/Global/Widgets/views/submitButtonForm";
import TextBox from "@components/Global/Widgets/views/textBox";
import Header from "@components/Interface/MainList/header";
import DateRangePicker from "@components/Global/Widgets/views/dateRangePicker";
import TableSelector from "@components/Global/Widgets/views/tableSelector";
import TargetingSelector from "@components/Global/Widgets/views/TargetingSelector/targetingSelector";
import LoadingPage from "@components/Global/General/loadingPage";

class SideNoticeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formMode: "",
      formColor: "#3f51b5",
    };
    this.updateTime = this.updateTime.bind(this);
    this.handleNoticeCheck = this.handleNoticeCheck.bind(this);
    this.selectRow = this.selectRow.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.checkValid = this.checkValid.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.deselectAll = this.deselectAll.bind(this);
    this.handleTargetingChange = this.handleTargetingChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.fetchSideNoticeInfo();
  }

  componentWillUnmount() {
    this.props.clearSideNoticeForm();
  }

  handleNoticeCheck() {
    this.props.updatingSideNoticeForm(
      "published",
      !this.props.sideNoticeForm.published,
      this.props.sideNoticeForm
    );
  }

  handleTargetingChange(targeting) {
    this.props.updatingSideNoticeForm("targeting", targeting, this.props.sideNoticeForm);
  }

  onDelete() {
    this.props.deleteSideNotice(this.props.router.params.id, this.props.loggedInUser);
  }

  updateTime(startDate, endDate) {
    const newDate = [startDate, endDate];
    this.props.updatingSideNoticeForm("time", newDate, this.props.sideNoticeForm);
  }

  checkValid() {
    const start = this.props.sideNoticeForm.startDate.format("MMMM Do YYYY h:mm a");
    const end = this.props.sideNoticeForm.endDate.format("MMMM Do YYYY h:mm a");
    if (
      this.props.sideNoticeForm.selectedProperties.length > 0 &&
      start !== end &&
      this.props.sideNoticeForm.body.trim() !== "" &&
      this.props.sideNoticeForm.title.trim() !== ""
    ) {
      return true;
    }
    return false;
  }

  submitForm() {
    const validated = this.checkValid();
    if (this.props.sideNoticeForm.formId && validated) {
      this.props.editSideNotice(this.props.sideNoticeForm);
    } else if (validated) {
      this.props.createSideNotice(this.props.sideNoticeForm);
    }
  }

  fetchSideNoticeInfo() {
    const { t } = this.props;
    const currentRoute = this.props.router.location.pathname;
    this.props.fetchFeedProperties(this.props.loggedInUser);
    if (currentRoute === "/sidenotice/new") {
      this.props.initiateSideNoticeForm(null);
      this.setState({ formMode: t("keywords.buttonActions.create") });
    } else {
      this.setState({ formMode: t("keywords.buttonActions.edit") });
      this.props.initiateSideNoticeForm(this.props.router.params.id);
    }
  }

  selectRow(row, isSelected) {
    if (isSelected) {
      // add a new property
      let currProperties = this.props.sideNoticeForm.selectedProperties;
      currProperties = [...currProperties, row.id];
      this.props.updatingSideNoticeForm(
        "selectedProperties",
        currProperties,
        this.props.sideNoticeForm
      );
    } else {
      let currProperties = this.props.sideNoticeForm.selectedProperties;
      // eslint-disable-next-line no-undef
      currProperties = _.without(currProperties, row.id);
      this.props.updatingSideNoticeForm(
        "selectedProperties",
        currProperties,
        this.props.sideNoticeForm
      );
    }
  }

  selectAll() {
    // get ids of all properties
    // eslint-disable-next-line no-undef
    const selectedProperties = _.map(this.props.properties, "id");
    this.props.updatingSideNoticeForm("selectAll", selectedProperties, this.props.sideNoticeForm);
  }

  deselectAll() {
    // eslint-disable-next-line no-undef
    const deselectedProperties = _.map(this.props.properties, "id");
    this.props.updatingSideNoticeForm(
      "deselectAll",
      deselectedProperties,
      this.props.sideNoticeForm
    );
  }

  render() {
    const { t } = this.props;
    const sideNoticeName = t("pageNames.sideNotice");
    const propertyTableFormat = [
      { field: "name", size: "40%", label: t("keywords.general.name") },
      { field: "area1", size: "60%", label: t("keywords.locationRelated.address") },
    ];

    if (!this.props.properties || !this.props.sideNoticeForm) {
      return <LoadingPage pageName={sideNoticeName} />;
    }

    return (
      <div>
        <Header title={sideNoticeName} />
        <div className="fillout-form">
          <div className="form-container">
            <h4>
              {this.state.formMode} {sideNoticeName}
            </h4>
            <hr />

            <DateRangePicker
              startDate={this.props.sideNoticeForm.startDate}
              endDate={this.props.sideNoticeForm.endDate}
              updateTime={this.updateTime}
              minDate={this.props.sideNoticeForm.minDate}
              maxDate={moment().add(5, "y")}
              showTime
              showDuration
              dateFormat="MMMM d, yyyy h:mm aa"
            />

            <TextBox
              textValue={this.props.sideNoticeForm.title}
              updateForm={this.props.updatingSideNoticeForm}
              formPropertyName="title"
              description={t("keywords.noticeRelated.title")}
              placeHolder={t("keywords.noticeRelated.title")}
              inputType="text"
              formWidth={240}
              formColor={this.state.formColor}
              formProps={this.props.sideNoticeForm}
              required
              maxLength={50}
              hideWordCount={false}
            />
            <div style={{ padding: "10px" }} />
            <TextBox
              textValue={this.props.sideNoticeForm.body}
              updateForm={this.props.updatingSideNoticeForm}
              formPropertyName="body"
              description={t("keywords.noticeRelated.content")}
              placeHolder={t("keywords.noticeRelated.content")}
              inputType="text"
              formWidth={500}
              formColor={this.state.formColor}
              formProps={this.props.sideNoticeForm}
              required
              maxLength={145}
              hideWordCount={false}
            />

            <hr />
            <h6> {t("keywords.dateRelated.targetTime")} </h6>
            <TargetingSelector
              targetingData={this.props.sideNoticeForm.targeting}
              onTargetingChange={this.handleTargetingChange}
              showDemographic={false}
            />

            <div className="header-column">
              <hr />
              <h6>{t("keywords.noticeRelated.propertyToNotice")}</h6>
            </div>

            <TableSelector
              tableData={this.props.properties}
              tableFormat={propertyTableFormat}
              tableType="checkbox"
              selected={this.props.sideNoticeForm.selectedProperties}
              onSelectAction={this.selectRow}
              selectAll={this.selectAll}
              deselectAll={this.deselectAll}
              tableColor={this.state.formColor}
              sortByField="name"
            />
            <hr />
            <div className="header-column">
              <div className="checkbox-style">
                <label className="check-container">
                  <h6>{t("noticeTerms.publishNotice")}</h6>
                  <input
                    type="checkbox"
                    checked={this.props.sideNoticeForm.published}
                    onChange={this.handleNoticeCheck}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
            <hr />

            <SubmitButtonForm
              formId={this.props.sideNoticeForm.formId}
              category={sideNoticeName}
              formColor={this.state.formColor}
              exitRoute="/sidenotice"
              createButtonName={t("keywords.buttonActions.create")}
              enableDelete
              deleteAction={this.onDelete}
              createAction={this.submitForm}
              updateAction={this.submitForm}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    properties: state.property.pM.all,
    sideNoticeForm: state.client.sideNotice.form,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createSideNotice,
      editSideNotice,
      deleteSideNotice,
      initiateSideNoticeForm,
      updatingSideNoticeForm,
      fetchFeedProperties,
      clearSideNoticeForm,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SideNoticeForm))
);
