/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */

import CampaignRates from "./CampaignRates";
import { formDurationInWeeks } from "./dateStringHelper";
import { LOOPS_PER_WEEK } from "../components/constants";

export default class CampaignPriceCalculator {
  static propertyPriceInfo = (
    baseRate,
    currentRateValue,
    screens,
    startAt,
    endAt,
    loopSpots,
    fullScreen
  ) => {
    if (!startAt || !endAt || !baseRate || !loopSpots || !currentRateValue)
      return { basePrice: 0, price: 0 };

    const baseRateValue = CampaignRates.baseRateInDollarsFromRate(baseRate, fullScreen);

    const weeks = formDurationInWeeks(startAt, endAt);

    const basePrice = this._calculatePropertyPrice(
      baseRateValue,
      screens,
      weeks,
      loopSpots
    );

    const price =
      baseRateValue === currentRateValue
        ? basePrice
        : this._calculatePropertyPrice(currentRateValue, screens, weeks, loopSpots);

    return {
      basePrice,
      price,
    };
  };

  static _calculatePropertyPrice = (rateValue, screens, weeks, loopSpots) => {
    // CPM Rate calculation
    const impressionCount = (screens * weeks * loopSpots * LOOPS_PER_WEEK) / 1000;
    const price = impressionCount * rateValue;

    return price;
  };

  static _calculateCostWithAddons(price, fullScreen) {
    if (fullScreen === "true") {
      return price + price * FULL_SCREEN_RATE;
    }
    return price;
  }
}
