import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class FilterDropdownItem extends Component {
  filterIsActive() {
    return (
      !!this.props.activeFilterValues &&
      this.props.activeFilterValues.indexOf(this.props.value) >= 0
    );
  }

  render() {
    let prefix = <span />;
    if (this.filterIsActive() === true) {
      prefix = <i className="fa fa-check" />;
    }

    return (
      <button className="filterControl__dropdown__item w-100" type="button">
        {prefix}
        <span className="filterControl__dropdown__item__label">{this.props.value}</span>
      </button>
    );
  }
}

FilterDropdownItem.propTypes = {
  activeFilterValues: PropTypes.array.isRequired,
};

export default withTranslation()(FilterDropdownItem);
