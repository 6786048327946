import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "react-bootstrap";

import withRouter from "@hooks/withRouter";
import StatusPill from "@components/Global/General/StatusPill";
import BasicTable from "@components/Global/General/BasicTable";
import LoadingPage from "@components/Global/General/loadingPage";
import { StatusTypes } from "@utilities/StatusTypes";

import CampaignGroupProposalDataHelper from "./services/CampaignGroupProposalDataHelper";

class ProposalListPage extends Component {
  static tableKeysToFilter = ["attributes.name"];

  constructor(props) {
    super(props);
    this.state = {
      proposals: null,
    };
    this.loadProposals();
  }

  loadProposals = () => {
    CampaignGroupProposalDataHelper.fetchProposals()
      .then((proposalData) => {
        this.setState({
          proposals: proposalData,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  renderEdit = (row) => {
    const { t } = this.props;

    return (
      <div className="d-flex">
        <Link to={`/campaign-proposal/edit/${row.id}`}>
          <Button variant="outline-success" size="sm mr-1">
            {t("keywords.buttonActions.edit")}
          </Button>
        </Link>
      </div>
    );
  };

  getStatusDetails = (status) => {
    if (!status || !(status in StatusTypes)) {
      return { cssValue: "disabled", description: "" };
    }

    return StatusTypes[status];
  };

  renderStatusPill = ({ cell }) => <StatusPill status={this.getStatusDetails(cell.value)} />;

  columns = () => {
    const { t } = this.props;

    if (this.state.proposals) {
      return [
        {
          Header: t("keywords.general.ID"),
          accessor: "attributes.id",
          disableFilters: true,
        },
        {
          Header: t("keywords.statusRelated.name"),
          accessor: "attributes.status",
          Cell: this.renderStatusPill,
        },
        {
          Header: t("proposalTerms.campaignGroupName"),
          accessor: "attributes.name",
        },
        {
          Header: t("keywords.userRelated.creator"),
          accessor: (row) =>
            row.owner() ? `${row.owner().attributes.name}` : t("keywords.errorRelated.NA"),
        },
        {
          Header: t("keywords.campaignRelated.viOwner"),
          accessor: (row) =>
            row.vi_owner() ? `${row.vi_owner().attributes.name}` : t("keywords.errorRelated.NA"),
        },
        {
          Header: t("advertiserTerms.advertiser"),
          accessor: (row) =>
            row.advertiser() ? row.advertiser().attributes.name : t("keywords.errorRelated.NA"),
        },
        {
          Header: t("proposalTerms.campaignProposals"),
          accessor: (row) => (row.campaign_proposals() ? row.campaign_proposals().length : 0),
        },
        {
          Header: "",
          accessor: "attributes.id",
          id: "actions",
          Cell: ({ row }) => this.renderEdit(row),
          disableSortBy: true,
          disableFilters: true,
        },
      ];
    }
    return [];
  };

  renderTableHeader = () => {
    const { t } = this.props;
    return (
      <Card className="mb-2 px-3">
        <Row className="justify-content-between h-100 align-items-center">
          <Col sm={12} md={7}>
            <h4>{t("proposalTerms.campaignGroupProposals")}</h4>
          </Col>
          <Col sm={12} md={2}>
            <Link to="/campaign-proposal/new">
              <Button variant="success" className="pull-right">
                {t("keywords.buttonActions.create")}
              </Button>
            </Link>
          </Col>
        </Row>
      </Card>
    );
  };

  render() {
    const { t } = this.props;

    if (!this.state.proposals) {
      return <LoadingPage pageName={t("proposalTerms.campaignGroupProposals")} />;
    }

    return (
      <div className="mainTable">
        {this.renderTableHeader()}
        <Card>
          <BasicTable
            tableHeader={() => null}
            initialSort={[{ id: "id", desc: true }]}
            headerClassName=""
            bodyClassName=""
            noResultsDescription={t("proposalTerms.noCampaignGroupProposals")}
            columns={this.columns()}
            data={this.state.proposals}
          />
        </Card>
      </div>
    );
  }
}

export default withRouter(withTranslation()(ProposalListPage));
