import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import _ from "lodash";

import { generateCsv, downloadCsv } from "@utilities/csvHelpers";
import { convertSnakeCaseToTitleCase } from "@utilities/stringHelpers";
import Elev8CampaignPropertiesContext from "@contexts/Elev8CampaignPropertiesContext";
import ProposalSection from "./ProposalSection";
import CampaignGroupProposalForm from "./CampaignGroupProposal/CampaignGroupProposalForm";
import CampaignGroupProposalSummary from "./CampaignGroupProposal/CampaignGroupProposalSummary";
import CampaignGroupProposalSummaryMarketBreakdown from "./CampaignGroupProposal/CampaignGroupProposalSummaryMarketBreakdown";

const PROPERTY_CSV_COLUMNS = [
  "name",
  "property.id",
  "property.name",
  "property.status",
  "property.property_type",
  "property.active_screens",
  "property.market_name",
  "property.address_line1",
  "property.address_line2",
  "property.address_city",
  "property.district",
  "property.address_province",
  "property.country",
  "property.address_postal_code",
  "property.latitude",
  "property.longitude",
  "property.floors",
  "property.number_of_units",
  "property.has_gym",
  "property.has_pool",
  "property.family_friendly",
  "property.resident_parking",
  "property.visitor_parking",
  "property.adult_only",
  "property.allows_cats",
  "property.allows_dogs",
  "property.amenities",
  "property.content_restriction",
  "property.impressions",
  "property.cost",
  "property.circulation",
];

const PROPERTY_CSV_HEADER = [
  "Flight",
  "Property ID",
  "Property Name",
  "Active?",
  "Type",
  "Active Screens",
  "Market",
  "Address",
  "Address 2",
  "City",
  "District",
  "Province",
  "Country",
  "Postal Code",
  "Latitude",
  "Longitude",
  "# of Floors",
  "# of Units (Condo & Rental Only)",
  "Gym?",
  "Pool?",
  "Family Friendly?",
  "Resident Parking?",
  "Visitor Parking?",
  "Adult Only?",
  "Cats?",
  "Dogs?",
  "Amenities",
  "Content Restrictions",
  "Impressions",
  "Cost",
  "Circulation",
];

function CampaignGroupProposalContainer({ readOnly, formStatus }) {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const elev8Properties = useContext(Elev8CampaignPropertiesContext);
  const [savingCsv, setSavingCsv] = useState(false);

  function elev8PropertiesById(propertyIds) {
    return propertyIds.reduce((propertyMap, propertyId) => {
      const property = elev8Properties.find(
        (elev8Property) => String(elev8Property.attributes.id) === propertyId
      );
      if (!property) return propertyMap;
      return { ...propertyMap, [propertyId]: property };
    }, {});
  }

  function selectedPropertyIds(campaignProposals) {
    const propertyIdsByCampaignProposal = campaignProposals.map(
      (campaignProposal) => campaignProposal.property_ids
    );
    return _.union(...propertyIdsByCampaignProposal);
  }

  function flattenedPropertyData(jsonApiProperty, campaignProposal, localLogicColumns) {
    const { attributes } = jsonApiProperty;
    attributes.active_screens = jsonApiProperty.activeScreens.length;
    attributes.market_name = jsonApiProperty.market() && jsonApiProperty.market().attributes.name;
    attributes.district = jsonApiProperty.district() && jsonApiProperty.district().attributes.name;
    attributes.country = jsonApiProperty.country() && jsonApiProperty.country().attributes.name;
    attributes.impressions = jsonApiProperty.getActiveBookedImpressions(
      campaignProposal.start_at,
      campaignProposal.end_at,
      campaignProposal.loop_spots
    );
    const numericCostInDollars = jsonApiProperty.getCampaignCost(
      campaignProposal.rate_type,
      campaignProposal.rate_value_dollars,
      campaignProposal.start_at,
      campaignProposal.end_at,
      campaignProposal.loop_spots,
      campaignProposal.fullscreen
    ).price;
    attributes.cost = `$${Math.round(numericCostInDollars)}`;
    attributes.circulation = Math.round(
      jsonApiProperty.getCirculation(campaignProposal.start_at, campaignProposal.end_at)
    );

    // Local Logic Fields
    localLogicColumns.forEach((localLogicColumn) => {
      const localLogicFields =
        jsonApiProperty.local_logic_location_info()?.attributes?.local_logic_fields;
      if (localLogicFields !== undefined && localLogicColumn in localLogicFields)
        attributes[localLogicColumn] =
          jsonApiProperty.local_logic_location_info()?.attributes?.local_logic_fields[
            localLogicColumn
          ];
      else attributes[localLogicColumn] = "";
    });

    return attributes;
  }

  function csvData() {
    const campaignProposals = getValues("campaign_proposals");
    const allSelectedPropertyIds = selectedPropertyIds(campaignProposals);
    const selectedPropertiesById = elev8PropertiesById(allSelectedPropertyIds);
    const localLogicFieldHeaders = constructLocalLogicFieldHeaders(
      Object.values(selectedPropertiesById)
    );
    const propertyDataByCampaign = [];

    PROPERTY_CSV_COLUMNS.push(...localLogicFieldHeaders.map((llfh) => `property.${llfh}`));
    PROPERTY_CSV_HEADER.push(
      ...localLogicFieldHeaders.map((llfh) => convertSnakeCaseToTitleCase(llfh))
    );

    campaignProposals.forEach((campaignProposal) => {
      const propertyData = campaignProposal.property_ids.map((propertyId) => ({
        name: campaignProposal.name,
        property: flattenedPropertyData(
          selectedPropertiesById[propertyId],
          campaignProposal,
          localLogicFieldHeaders
        ),
      }));
      propertyDataByCampaign.push(...propertyData);
    });
    return propertyDataByCampaign;
  }

  function constructLocalLogicFieldHeaders(properties) {
    const propertiesWithLocalLogicInfo = properties.filter(
      (propertyWithLli) =>
        propertyWithLli.local_logic_location_info()?.attributes?.local_logic_fields !== undefined
    );

    const allLocalLogicFields = propertiesWithLocalLogicInfo.map((property) =>
      Object.keys(property.local_logic_location_info()?.attributes?.local_logic_fields)
    );
    const flattenedLocalLogicFields = [].concat(...allLocalLogicFields);
    return [...new Set(flattenedLocalLogicFields)];
  }

  async function downloadPropertyList() {
    setSavingCsv(true);
    const data = csvData();
    const csvString = generateCsv(data, PROPERTY_CSV_COLUMNS, PROPERTY_CSV_HEADER, ",");

    downloadCsv(csvString, "proposal_property_list");

    setSavingCsv(false);
  }

  return (
    <ProposalSection
      title={t("campaignGroupTerms.campaignGroup")}
      actions={[
        {
          label: t("propertyTerms.exportPropertyList"),
          disabled: savingCsv,
          variant: "primary",
          onClick: downloadPropertyList,
        },
      ]}
    >
      <CampaignGroupProposalForm readOnly={readOnly} formStatus={formStatus} />
      <CampaignGroupProposalSummary />
      <CampaignGroupProposalSummaryMarketBreakdown />
    </ProposalSection>
  );
}

CampaignGroupProposalContainer.propTypes = {
  formStatus: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default CampaignGroupProposalContainer;
