import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";

import PublicComponent from "../../../App/routeHelpers/publicComponent";
import { REPORTING_ROUTE_LIST } from "../../../App/routeHelpers/publicRouteList";
import withRouter from "../../../hooks/withRouter";
import PageNotFound from "../../Global/General/pageNotFound";

import LoadingPage from "../../Global/General/loadingPage";
import CampaignReportDataHelper from "../services/CampaignReportDataHelper";
import CampaignReportingLayout from "./components/CampaignReportingLayout";
import ErrorPage from "../../Global/General/ErrorPage";

class ReportContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      report: null,
      cityFilters: [],
      propertyTypeFilters: [],
      loading: true,
      error: false,
    };

    this.fetchData();
  }

  setFilters = (filterName, newValue) => {
    this.setState({ [filterName]: newValue });
  };

  inSelectedCities = (property) => {
    return this.isSelectedCity(property.municipality());
  };

  ofSelectedType = (property) => {
    const selected = this.state.propertyTypeFilters;
    if (selected.length === 0) {
      return true;
    }

    return selected.includes(property.attributes.property_type);
  };

  isSelectedCity = (city) => {
    const selected = this.state.cityFilters;
    if (selected.length === 0) {
      return true;
    }
    return selected.includes(city.attributes.name);
  };

  uniquePropertyTypes = (report) => {
    if (!report) {
      return [];
    }

    return [
      ...new Set(
        report
          .campaign()
          .properties()
          .map((prop) => prop.attributes.property_type)
      ),
    ];
  };

  uniqueCities = (report) => {
    if (!report) {
      return [];
    }

    return [
      ...new Set(
        report
          .campaign()
          .municipalities()
          .map((city) => city.attributes.name)
      ),
    ];
  };

  campaignName = (report) => {
    if (!report) {
      return "Loading";
    }

    return report.campaign().attributes.name;
  };

  campaignId = (report) => {
    if (!report) {
      return "";
    }

    return report.campaign().attributes.id;
  };

  campaignGroupId = (report) => {
    if (!report) {
      return "";
    }

    return report.campaign().relationships.campaign_group.id;
  };

  filteredProperties() {
    if (!this.state.report) {
      return [];
    }

    const properties = this.state.report.campaign().properties();

    return properties.filter(this.inSelectedCities).filter(this.ofSelectedType);
  }

  filteredCities() {
    if (!this.state.report) {
      return [];
    }

    const cities = this.state.report.campaign().municipalities();

    return cities.filter(this.isSelectedCity);
  }

  fetchData() {
    const reportCode = this.props.router.params.report_code;

    const sparseFieldSets = {
      include: [
        "campaign",
        "campaign.campaign_group",
        "campaign.properties",
        "campaign.properties.municipality",
        "campaign.properties.network",
        "campaign.campaign_locations",
        "campaign.campaign_rendered_report",
      ],
      fields: {
        campaign_rendered_report: ["content_url"],
      },
    };

    CampaignReportDataHelper.fetchData(reportCode, sparseFieldSets)
      .then((report) => {
        this.setState({ report });
      })
      .catch(() => {
        this.setState({ error: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  renderPublicRoute = (routeItem) => {
    const { report } = this.state;

    let campaign = null;

    if (report) {
      campaign = report.campaign();
    }

    return (
      <Route path={routeItem.path} key={routeItem.key} element={
        <PublicComponent
          auth={this.props.auth}
          component={routeItem.component}
          noRedirect={routeItem.noRedirect}
          campaign={campaign}
          properties={this.filteredProperties()}
          cities={this.filteredCities()}
          setFilters={this.setFilters}
          report={this.state.report}
        />}
      />
    );
  };

  getPageName() {
    const currentUrl = this.props.router.location.pathname.split("/");
    const page = currentUrl[currentUrl.length - 1];
    return page;
  }

  renderRouteList() {
    if (this.state.loading) {
      return <LoadingPage />;
    }

    if (this.state.error) {
      return <ErrorPage />;
    }

    return (
      <Routes>
        {REPORTING_ROUTE_LIST.map(this.renderPublicRoute.bind(this))}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    );
  }

  render() {
    return (
      <CampaignReportingLayout
        properties={this.filteredProperties()}
        cities={this.filteredCities()}
        selectedCities={this.state.cityFilters}
        selectedPropertyTypes={this.state.propertyTypeFilters}
        uniquePropertyTypes={this.uniquePropertyTypes(this.state.report)}
        uniqueCities={this.uniqueCities(this.state.report)}
        setFilters={this.setFilters}
        campaignName={this.campaignName(this.state.report)}
        campaignId={this.campaignId(this.state.report)}
        campaignGroupId={this.campaignGroupId(this.state.report)}
        pageName={this.getPageName()}
      >
        {this.renderRouteList()}
      </CampaignReportingLayout>
    );
  }
}

export default withRouter(ReportContainer);
