import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withTranslation } from "react-i18next";
import Dropdown from "../../Global/Widgets/updatedWidgets/dropDown";
import AdvancedDropdown from "../../Global/Widgets/updatedWidgets/multiSelect";
import TextInput from "../../Global/Widgets/textInput";
import SingleDatePicker from "../../Global/Widgets/views/singleDatePicker";

class PropertyAdditionalSection extends Component {
  handleFormChange = (event) => {
    const target = {
      value: event.target.value,
      name: event.target.name,
    };
    this.props.handleChange(target);
  };

  handleTimeChange = (name, time) => {
    const target = {
      name,
      value: time,
    };
    this.props.handleChange(target);
  };

  renderLanguageSection = () => {
    const { t } = this.props;

    const languageOptions = [
      { value: "en", label: t("language.languages.english") },
      { value: "fr", label: t("language.languages.french") },
    ];

    return (
      <div className="form-section">
        <div className="form-section__title pt-4">{t("language.communicationLanguage")}</div>
        <Row>
          <Form.Group as={Col} md="12" controlId="Language">
            <AdvancedDropdown
              label={t("language.communicationLanguageDescription")}
              value={this.props.formFields.communication_language}
              handleChange={this.props.handleChange}
              name="communication_language"
              options={languageOptions}
              enableMultiselect={false}
              isClearable={false}
            />
          </Form.Group>
        </Row>
      </div>
    );
  };

  renderContentRestrictionSection = () => {
    const { t } = this.props;

    if (this.props.isPropertyManager) {
      return (
        <React.Fragment>
          {this.props.formFields.content_restriction.length > 0 ? (
            <div className="form-section">
              <div className="form-section__title pt-4">
                {t("keywords.propertyRelated.restrictionRelated.categories")}
              </div>
              <p>{this.props.formFields.content_restriction}</p>
            </div>
          ) : null}
        </React.Fragment>
      );
    }
    return (
      <div className="form-section">
        <div className="form-section__title pt-4">
          {t("keywords.propertyRelated.restrictionRelated.content")}
        </div>
        <Row>
          <Form.Group as={Col} sm="12" controlId="content_restriction">
            <TextInput
              label={t("keywords.propertyRelated.restrictionRelated.listOf")}
              value={this.props.formFields.content_restriction}
              handleChange={this.handleFormChange}
              type="text-area"
              name="content_restriction"
            />
          </Form.Group>
        </Row>
      </div>
    );
  };

  renderRssFeedSection = () => {
    const { t } = this.props;

    if (this.props.isPropertyManager) {
      return null;
    }
    const feedOptions = this.props.feeds.map((feed) => ({
      value: feed.id,
      label: feed.name,
    }));
    return (
      <div className="form-section">
        <div className="form-section__title pt-4">{t("keywords.rssFeedRelated.configuration")}</div>
        <Row>
          <Form.Group as={Col} md="12" controlId="rss-feed">
            <AdvancedDropdown
              label={t("keywords.rssFeedRelated.toShow")}
              value={this.props.formFields.chosenFeeds}
              handleChange={this.props.handleChange}
              name="chosenFeeds"
              options={feedOptions}
              enableMultiselect
              isClearable
            />
          </Form.Group>
        </Row>
      </div>
    );
  };

  renderPropertyStatusSection = () => {
    const { t } = this.props;

    if (this.props.isPropertyManager) {
      return null;
    }
    return (
      <div className="form-section">
        <div className="form-section__title pt-4">{t("keywords.propertyRelated.status")}</div>
        <Row>
          <Form.Group as={Col} sm={4} controlId="install-date">
            <SingleDatePicker
              label={t("keywords.propertyRelated.installDate.name")}
              updateTime={this.handleTimeChange.bind(this, "install_date")}
              date={this.props.formFields.install_date}
              showTimeSelect={true}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} sm={4} controlId="Status">
            <Dropdown
              label={t("keywords.statusRelated.name")}
              value={this.props.formFields.status}
              handleChange={this.handleFormChange}
              required
              name="status"
              options={this.renderStatusOption()}
            />
          </Form.Group>
        </Row>
      </div>
    );
  };

  renderStatusOption = () => {
    if (this.props.formFields.status === "pending") {
      return ["active", "pending"];
    }
    return ["active", "deactivated"];
  };

  render() {
    return (
      <React.Fragment>
        {this.renderLanguageSection()}
        {this.renderContentRestrictionSection()}
        {this.renderRssFeedSection()}
        {this.renderPropertyStatusSection()}
      </React.Fragment>
    );
  }
}

export default withTranslation()(PropertyAdditionalSection);
