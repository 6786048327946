import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// CUSTOM COMPONENTS
import { updatingLocalAdForm } from "../../services/actions/adStackActions";

import DateRangePicker from "../../../../../Global/Widgets/views/dateRangePicker";

// Custom Actions

class AdDateRange extends Component {
  constructor(props) {
    super(props);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  handleTimeChange(startDate, endDate) {
    const time = [startDate, endDate];
    // console.log("THE NEW TIME", startDate, endDate);
    this.props.updatingLocalAdForm("time", time, this.props.adInfo);
  }

  render() {
    const calendarMinDate = moment(this.props.campaign.start_at);
    const calendarMaxDate = moment(this.props.campaign.end_at);
    // add 1 more second to the end date.
    return (
      <div className="ad-step-form">
        <div style={{ padding: "10px" }} />
        <div className="title">Date Range </div>
        <DateRangePicker
          startDate={this.props.adInfo.adStart}
          endDate={this.props.adInfo.adEnd}
          minDate={calendarMinDate}
          maxDate={calendarMaxDate}
          showTime
          dateFormat="MMMM d, yyyy h:mm aa"
          updateTime={this.handleTimeChange}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updatingLocalAdForm }, dispatch);
}

function mapStateToProps(state) {
  return {
    campaign: state.campaign.creation.config.form,
    adInfo: state.campaign.creation.adStack.single,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdDateRange);
