// Campaign Reducer

import { SET_CAMPAIGNS } from "../../../../../App/actionTypes";

const INITIAL_STATE = { all: null, single: null };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CAMPAIGNS:
      return { ...state, all: action.payload };
  }
  return state;
}
