export const MAPDATA = "map-data";

// Cluster
export const CLUSTER_COLOR = "#a56af3";

// Markers
export const MARKER_SIZE = 0.08;
export const SELECTED_MARKER = "selected";
export const PENDING_MARKER = "pending";
export const DEFAULT_MARKER = "default";
