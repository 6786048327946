import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { datetimeAsApiData } from "../../../../utilities/dateHelper";
import Header from "../../../Interface/MainList/header";
import LoadingPage from "../../../Global/General/loadingPage";
import DateRangePicker from "../../../Global/Widgets/views/dateRangePicker";

import Elev8ApiService from "../../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../../lib/services/Elev8Api/lib";

class CampaignMonthlyRevenueReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().subtract(3, "months").startOf("day"),
      endDate: moment().endOf("day"),
      maxDate: moment().endOf("day"),
    };
  }

  updateDate = (startDate, currEndDate) => {
    const maxDate = moment(startDate).add(3, "months").endOf("day");
    let endDate = currEndDate;
    if (endDate > maxDate) {
      endDate = maxDate;
    }
    this.setState({ startDate, endDate, maxDate });
  };

  renderUploadToDrive = () => {
    return (
      <div onClick={() => this.uploadToDrive()}>
        <Button>Generate Report</Button>
      </div>
    );
  };

  uploadToDrive = () => {
    const serializedStartAt = datetimeAsApiData(this.state.startDate);
    const serializedEndAt = datetimeAsApiData(this.state.endDate);

    const serializedForm = Elev8ApiService.setToJsonApiFormat(
      ApiTypes.campaign_revenue.type,
      null,
      { start_at: serializedStartAt, end_at: serializedEndAt }
    );
    Elev8ApiService.create(ApiTypes.campaign_revenue, serializedForm).then((response) => {
      if (response.status === 201) {
        alert(
          "Your request has been registered. You will receive an email with a link to the report. It may take 20-30 minutes for the email to arrive."
        );
      } else if (response.status === 200) {
        if (response.data.data.message === "no campaigns") {
          alert(
            "No active campaigns were found with the given date range. Please try another range."
          );
        }
      } else {
        alert("Something went wrong, please try again");
      }
    });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingPage pageName="Campaign Revenue" />;
    }

    return (
      <React.Fragment>
        <Header title="Campaign Revenue" />
        <Row className="u-background-color-white" style={{ height: "88vh" }}>
          <Col xs={12} md={6} className="py-3 px-5">
            <h5 className="pb-2"> Monthly Revenue Report</h5>
            <p>Generate the monthly revenue of campaigns by selecting a date range below:</p>
            <hr />
            <DateRangePicker
              updateTime={this.updateDate}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              maxDate={this.state.maxDate}
              showDuration
              dateFormat="MMMM d, yyyy"
            />
            {this.renderUploadToDrive()}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withTranslation()(CampaignMonthlyRevenueReport);
