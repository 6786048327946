import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Alert, Button, Form } from "react-bootstrap";
import { isBefore, startOfDay, endOfDay } from "date-fns";

import withRouter from "@hooks/withRouter";
import { getDollarsFromCents } from "@utilities/mathHelpers";
import { ApiTypes } from "@lib/services/Elev8Api/lib";
import Elev8ApiService from "@lib/services/Elev8Api/Elev8ApiService";
import CampaignDataHelper from "@components/Campaign/services/CampaignDataHelper";
import LoadingPage from "@components/Global/General/loadingPage";
import ConfirmModal from "@components/Global/Widgets/views/ConfirmModal";
import { MONETIZED_CAMPAIGN_TYPES } from "@components/constants";
import { apiDateAsFormData, parseLocaleDate } from "@utilities/dateStringHelper";
import CampaignSerializer from "@utilities/campaignSerializer";
import OldCampaignPropertyContainer from "@components/Campaign/Creation/Property/views/OldCampaignPropertyContainer";
import CampaignDetails from "./components/CampaignDetails";
import CampaignAdList from "./components/CampaignAdList";

class CampaignEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: null,
      campaignUpdates: {},
      isActiveCampaign: true,
      navigateToRoute: "",
      savingAdId: null,
      showNavigateModal: false,
      showDeleteAdModal: false,
      failureAlertVisible: false,
      successAlertVisible: false,
      successMessage: null,
      savingCampaign: false,
      isUpdatingPropertyIds: false,
      editPropertyMode: false,
    };
    this.loadCampaign();
  }

  loadCampaign = () => {
    CampaignDataHelper.fetchCampaign(this.props.router.params.id)
      .then((campaign) => {
        const campaignFields = {
          ...campaign.attributes,
          start_at: apiDateAsFormData(campaign.attributes.start_at),
          end_at: apiDateAsFormData(campaign.attributes.end_at),
          actual_value_dollars: getDollarsFromCents(campaign.attributes.actual_value_cents),
          standard_price_dollars: getDollarsFromCents(campaign.attributes.standard_price_cents),
          ads: campaign.ads(),
          campaign_group: campaign.campaign_group(),
          properties: campaign.properties(),
          loop_spots_description: this.loopSpotsDescription(campaign.loopSpotsPerProperty),
          loop_spots_per_property: campaign.loopSpotsPerProperty,
        };
        this.setState({
          // Stored separately to inform which fields should be disabled
          isActiveCampaign: campaign.attributes.status === "active",
          campaign: campaignFields,
          originalCampaign: campaignFields,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  updatePropertyIds = (selectedPropertyIds) => {
    const payload = { id: this.state.campaign.id, property_ids: selectedPropertyIds };
    const serializedCampaignUpdates = CampaignSerializer.serializeUpdates(payload);
    this.setState({ isUpdatingPropertyIds: true });
    Elev8ApiService.partialUpdate(
      ApiTypes.campaign,
      serializedCampaignUpdates,
      this.state.campaign.id
    )
      .then((response) => {
        this.setState({ isUpdatingPropertyIds: false });
        if (response.status === 200) {
          this.showSuccessAlert(`Successfully added property ids`);
          this.reloadCampaignProperties();
          this.reloadCampaignAds();
          this.setState({ editPropertyMode: false });
        } else {
          this.showFailureAlert();
        }
      })
      .catch((e) => {
        console.log("Can't save property ids", e);
        this.showFailureAlert();
      });
  };

  reloadCampaignAds = () => {
    CampaignDataHelper.fetchCampaignAds(this.props.router.params.id).then((campaign) => {
      this.setState((prevState) => ({
        campaign: {
          ...prevState.campaign,
          ads: campaign.ads(),
        },
      }));
    });
  };

  reloadCampaignProperties = () => {
    CampaignDataHelper.fetchCampaignProperties(this.props.router.params.id).then((campaign) => {
      this.setState((prevState) => ({
        campaign: {
          ...prevState.campaign,
          properties: campaign.properties(),
        },
      }));
    });
  };

  handleCancelNavigate = () => {
    this.setState({
      navigateToRoute: "",
      showNavigateModal: false,
    });
  };

  handleConfirmNavigate = () => {
    this.props.router.navigate(this.state.navigateToRoute);
  };

  navigateTo = (route) => {
    if (!Object.keys(this.state.campaignUpdates).length && !this.state.editPropertyMode) {
      this.props.router.navigate(route);
    } else {
      this.setState({
        navigateToRoute: route,
        showNavigateModal: true,
      });
    }
  };

  formHasChanges = () => Object.keys(this.state.campaignUpdates).length;

  toggleEditPropertyMode = (value) => {
    this.setState({
      editPropertyMode: value,
    });
  };

  handleEditAd = (id) => {
    this.navigateTo(`/flight/ad/edit/${id}`);
  };

  clearAlerts = () => {
    this.setState({
      failureAlertVisible: false,
      successAlertVisible: false,
      successMessage: null,
    });
  };

  showFailureAlert = () => {
    this.setState(
      {
        failureAlertVisible: true,
      },
      () => {
        window.setTimeout(() => {
          this.setState({ failureAlertVisible: false });
        }, 2000);
      }
    );
  };

  showSuccessAlert = (message) => {
    this.setState(
      {
        failureAlertVisible: false,
        successAlertVisible: true,
        successMessage: message,
      },
      () => {
        window.setTimeout(() => {
          this.setState({ successAlertVisible: false });
        }, 2000);
      }
    );
  };

  handleCopyAd = (id) => {
    this.setState({
      savingAdId: id,
    });

    Elev8ApiService.oldCopy(`ads/${id}`)
      .then(() => {
        this.showSuccessAlert(`Successfully copied ad ${id}`);
        this.reloadCampaignAds();
      })
      .catch((error) => {
        this.showFailureAlert();
        throw error;
      })
      .finally(() => {
        this.setState({
          savingAdId: null,
        });
      });
  };

  handleDeleteAd = (id) => {
    this.setState({
      savingAdId: id,
      showDeleteAdModal: true,
    });
  };

  handleConfirmDeleteAd = () => {
    const adId = this.state.savingAdId;

    Elev8ApiService.oldDelete(`ads/${adId}}`)
      .then(() => {
        this.showSuccessAlert(`Successfully deleted ad ${adId}`);
        this.reloadCampaignAds();
      })
      .catch((error) => {
        this.showFailureAlert();
        throw error;
      })
      .finally(() => {
        this.setState({
          savingAdId: null,
          showDeleteAdModal: false,
        });
      });
  };

  handleCancelDeleteAd = () => {
    this.setState({
      savingAdId: null,
      showDeleteAdModal: false,
    });
  };

  validUpdatedFields = (keyWithUpdates, newValue) => {
    const currentUpdates = this.state.campaignUpdates;
    const initialCampaignValue = this.state.originalCampaign[keyWithUpdates];
    if (
      !currentUpdates.hasOwnProperty(keyWithUpdates) ||
      String(initialCampaignValue) !== String(newValue)
    ) {
      return { ...currentUpdates, [keyWithUpdates]: newValue };
    }
    return delete currentUpdates[keyWithUpdates];
  };

  handleChange = (event) => {
    const updatedFields = this.validUpdatedFields(event.target.name, event.target.value);
    this.setState((previousState) => ({
      campaign: { ...previousState.campaign, [event.target.name]: event.target.value },
      campaignUpdates: updatedFields,
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.clearAlerts();
    const formValid = event.currentTarget.checkValidity();
    const campaignValid = this.validCampaign(this.state.campaign);
    this.setState({ validated: true });
    if (formValid) {
      if (campaignValid) {
        this.setState({ savingCampaign: true });
        this.updateCampaign();
      } else {
        this.showFailureAlert();
      }
    }
  };

  // This can eventually be replaced with CampaignValidator.isValid when the old campaign flow is
  // removed and there's no longer a discrepancy on what's the responsibility of Campaign or Group
  validCampaign = (campaignData) => {
    if (
      isBefore(
        startOfDay(parseLocaleDate(campaignData.start_at)),
        endOfDay(parseLocaleDate(campaignData.end_at))
      ) &&
      campaignData.ad_duration &&
      !(
        campaignData.status === "active" &&
        MONETIZED_CAMPAIGN_TYPES.includes(campaignData.campaign_type) &&
        !campaignData.standard_price_dollars
      )
    ) {
      return true;
    }
    return false;
  };

  updateCampaign = () => {
    const { campaignUpdates } = this.state;
    campaignUpdates.id = this.state.campaign.id;
    const serializedCampaign = CampaignSerializer.serializeUpdates(campaignUpdates);
    Elev8ApiService.partialUpdate(ApiTypes.campaign, serializedCampaign, this.state.campaign.id)
      .then((response) => {
        this.setState({ savingCampaign: false });
        if (response.status === 200) {
          this.loadCampaign();
          this.showSuccessAlert("Successfully saved Flight details");
          this.setState((prevState) => ({
            campaignUpdates: {},
            isActiveCampaign: prevState.campaign.status === "active",
            validated: false,
          }));
        } else {
          this.showFailureAlert();
        }
      })
      .catch((e) => {
        console.log("Can't save Campaign(Flight) ", e);
        this.showFailureAlert();
      });
  };

  loopSpotsDescription = (loopSpotsPerProperty) => {
    const loopSpots = Object.values(loopSpotsPerProperty);
    if (!loopSpots.length) {
      return "None";
    }
    if (Math.min(...loopSpots) === Math.max(...loopSpots)) {
      return String(Math.min(...loopSpots));
    }

    return `${Math.min(...loopSpots)} - ${Math.max(...loopSpots)}`;
  };

  renderAdSection = () => {
    const campaignHasProperties = this.state.campaign.properties.length;
    return (
      <>
        <div className="campaignForm__sectionHeader">
          Ads
          <Link to={`/flight/ad/new/${this.state.campaign.id}`}>
            <Button disabled={!campaignHasProperties || this.formHasChanges()} variant="success">
              Create an Ad
            </Button>
          </Link>
        </div>
        {campaignHasProperties ? (
          <CampaignAdList
            ads={this.state.campaign.ads}
            onEditAd={this.handleEditAd}
            onCopyAd={this.handleCopyAd}
            onDeleteAd={this.handleDeleteAd}
            savingAdId={this.state.savingAdId}
          />
        ) : (
          <div className="campaignForm__tablePlaceholder basicTable__noResults">
            You Must Add Properties before you can create Ads
          </div>
        )}
      </>
    );
  };

  renderErrorMessage = () => {
    if (this.state.campaign) {
      if (
        isBefore(
          endOfDay(parseLocaleDate(this.state.campaign.end_at)),
          startOfDay(parseLocaleDate(this.state.campaign.start_at))
        )
      ) {
        return "Campaign date range is invalid";
      }
    }
    return "Something went wrong... please try again";
  };

  renderCampaignSaveButton = () => {
    const saveCampaignStatus = this.state.savingCampaign ? "Saving Flight..." : "Save Flight";
    return (
      <Button
        className="pull-right"
        variant="success"
        disabled={this.state.savingCampaign || !this.formHasChanges()}
        type="submit"
      >
        {this.state.savingCampaign ? (
          <span
            className="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
          />
        ) : null}
        <span>{saveCampaignStatus}</span>
      </Button>
    );
  };

  render() {
    if (!this.state.campaign) {
      return <LoadingPage pageName="Flight" />;
    }

    const campaignGroupId = this.state.campaign.campaign_group.attributes.id;

    return (
      <>
        <Alert
          variant="success"
          show={this.state.successAlertVisible}
          onClose={() => this.setState({ successAlertVisible: false })}
          dismissible
        >
          {this.state.successMessage}
        </Alert>
        <Alert
          variant="danger"
          show={this.state.failureAlertVisible}
          onClose={() => this.setState({ failureAlertVisible: false })}
          dismissible
        >
          {this.renderErrorMessage()}
        </Alert>
        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={this.handleSubmit}
          className="campaignForm"
        >
          <div className="campaignForm__sectionHeader">
            <div>Flight</div>
            <div>
              <Link to={`/campaign_group/edit/${campaignGroupId}`}>
                <Button
                  className="mr-1"
                  variant="outline-secondary"
                  disabled={this.state.savingCampaign}
                >
                  Go back to Campaign Group
                </Button>
              </Link>
              {this.renderCampaignSaveButton()}
            </div>
          </div>
          <CampaignDetails
            campaign={this.state.campaign}
            isActiveCampaign={this.state.isActiveCampaign}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
          />
          {this.renderAdSection()}
          <div className="pt-5" />
          <OldCampaignPropertyContainer
            campaign={this.state.campaign}
            updatePropertyIds={this.updatePropertyIds}
            isUpdatingPropertyIds={this.state.isUpdatingPropertyIds}
            userEmail={this.props.loggedInUser.email}
            editPropertyMode={this.state.editPropertyMode}
            toggleEditPropertyMode={this.toggleEditPropertyMode}
          />
        </Form>
        <ConfirmModal
          cancel={{
            label: "Cancel",
            variant: "outline-secondary",
            action: this.handleCancelNavigate,
          }}
          confirm={{
            label: "Leave",
            variant: "danger",
            action: this.handleConfirmNavigate,
          }}
          title="Unsaved Changes"
          show={this.state.showNavigateModal}
        >
          {this.state.editPropertyMode ? <p>You are currently editing properties</p> : null}
          {Object.keys(this.state.campaignUpdates).length ? (
            <p>You have unsaved Flight changes</p>
          ) : null}
          <p>Are you sure you want to leave this page?</p>
        </ConfirmModal>
        <ConfirmModal
          cancel={{
            label: "Cancel",
            variant: "outline-secondary",
            action: this.handleCancelDeleteAd,
          }}
          confirm={{
            label: "Delete",
            variant: "danger",
            action: this.handleConfirmDeleteAd,
          }}
          show={this.state.showDeleteAdModal}
        >
          <p>Are you sure you want to delete this ad?</p>
        </ConfirmModal>
      </>
    );
  }
}

export default withRouter(withTranslation()(CampaignEdit));
