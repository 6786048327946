import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import SingleDatePicker from "../../Global/Widgets/views/singleDatePicker";
import Form from "react-bootstrap/Form";
import ConfirmModal from "../../Global/Widgets/views/ConfirmModal";

class PropertyRevenueShareReportGenerator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startAt: moment().subtract(3, "months").startOf("day"),
      endAt: moment().endOf("day"),
      hiveStackFile: null,
      vistarFile: null,
      validated: false,
      showGenerateRevenueReportModal: false,
    };
  }

  handleSubmit() {
    event.preventDefault();
  }

  showConfirmModal = () => {
    this.setState({ showGenerateRevenueReportModal: true });
  };

  cancelConfirmModal = () => {
    this.setState({ showGenerateRevenueReportModal: false });
  };

  handleDateChange(dateFieldName, date) {
    this.setState({ [dateFieldName]: date });
  }

  onFileChange = (dataSet, event) => {
    this.setState({ [dataSet]: event.target.files[0] });
  };

  canSubmit = () => {
    if (this.state.startAt > this.state.endAt) {
      return false;
    }
    if (!this.state.vistarFile) {
      return false;
    }
    if (!this.state.hiveStackFile) {
      return false;
    }
    return true;
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingPage pageName="Campaign Revenue" />;
    }

    const { t } = this.props;

    return (
      <Container fluid>
        <ConfirmModal
          cancel={{
            label: "Cancel",
            variant: "outline-secondary",
            action: this.cancelConfirmModal,
          }}
          confirm={{
            label: "Yes, generate reports",
            variant: "success",
            action: this.handleConfirmModal,
          }}
          title={"Generate Property Revenue Reports"}
          show={this.state.showGenerateRevenueReportModal}
        >
          <div>
            <p>You are about to create Revenue Reports for all properties on Elev8.</p>
            <p> Confirm that you have included the correct data sets and correct date range.</p>
            <b>Are you ready to generate the reports?</b>
          </div>
        </ConfirmModal>
        <Form noValidate validated={this.state.validated} onSubmit={(e) => this.handleSubmit(e)}>
          <Row className="justify-content-md-center mt-4">
            <Col sm={12} md={10}>
              <Card>
                <Card.Header as="h2" className="p-3 text-center">
                  <span>Property Revenue Share Generator</span>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col sm={12}>
                      <h4>Add the desired data range for the Revenue Reports </h4>
                    </Col>
                  </Row>

                  <Row>
                    <Form.Group as={Col} sm={12} md={6}>
                      <SingleDatePicker
                        label={t("keywords.dateRelated.start")}
                        updateTime={(date) => this.handleDateChange("startAt", date)}
                        date={this.state.startAt}
                        dateFormat="MMMM d, yyyy"
                        maxDate={this.state.endAt}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={6}>
                      <SingleDatePicker
                        label={t("keywords.dateRelated.end")}
                        updateTime={(date) => this.handleDateChange("endAt", date)}
                        date={this.state.endAt}
                        dateFormat="MMMM d, yyyy"
                        minDate={this.state.startAt}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <h4>Upload the corresponding Vistar and Hivestack datasets</h4>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Form.Group controlId="formFile" className="mb-3" as={Col} sm={12} md={6}>
                      <h4 className="pt-1"> Vistar Dataset</h4>
                      <Form.Control
                        onChange={this.onFileChange.bind(this, "vistarFile")}
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      />
                      <span className="pt-2">
                        {" "}
                        Required columns: <b>Rev Share, Venue ID </b>
                      </span>
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mb-3" as={Col} sm={12} md={6}>
                      <h4 className="pt-1"> Hivestack Dataset</h4>
                      <Form.Control
                        type="file"
                        onChange={this.onFileChange.bind(this, "hiveStackFile")}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      />
                      <span className="pt-2">
                        {" "}
                        Required columns: <b> Rev Share, SITE_ID </b>
                      </span>
                    </Form.Group>
                  </Row>
                </Card.Body>
                <Button
                  variant="success"
                  size="lg"
                  type="submit"
                  onClick={this.showConfirmModal}
                  disabled={!this.canSubmit()}
                >
                  Generate Revenue Share Reports
                </Button>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default withTranslation()(PropertyRevenueShareReportGenerator);
