import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

class NumberInput extends Component {
  handleValueChange = (event) => {
    const eventBundle = {
      target: { type: "number", value: event.target.value, name: this.props.name },
    };
    this.props.handleChange(eventBundle);
  };

  renderPrefix = () => <InputGroup.Text>{this.props.prefix}</InputGroup.Text>;

  renderSuffix = () => <InputGroup.Text>{this.props.suffix}</InputGroup.Text>;

  render() {
    const {
      disabled,
      label,
      required,
      value,
      prefix,
      suffix,
      max,
      min,
      t,
      placeholder,
      name,
      readOnly,
    } = this.props;
    return (
      <>
        <Form.Label>{label}</Form.Label>

        <InputGroup>
          {prefix ? this.renderPrefix() : null}
          <Form.Control
            name={name}
            disabled={disabled}
            readOnly={readOnly}
            type="number"
            max={max}
            min={min}
            step="any"
            placeholder={placeholder}
            value={value}
            required={required}
            onChange={this.handleValueChange}
          />
          {suffix ? this.renderSuffix() : null}
          {required ? (
            <Form.Control.Feedback type="invalid">
              {t("keywords.errorRelated.required")}
            </Form.Control.Feedback>
          ) : null}
        </InputGroup>
      </>
    );
  }
}

export default withTranslation()(NumberInput);
