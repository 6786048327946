import React, { Component } from "react";
import PropTypes from "prop-types";

class TargetingDemographicSelector extends Component {
  constructor(props) {
    super(props);
    this.renderGender = this.renderGender.bind(this);
    this.renderAge = this.renderAge.bind(this);
    this.handleGenderClick = this.handleGenderClick.bind(this);
    this.handleAgeClick = this.handleAgeClick.bind(this);
  }

  render() {
    const genderLength = _.filter(this.props.gender, { present: true });
    const ageLength = _.filter(this.props.age, { present: true });

    return (
      <div className="ad-step-form">
        <div className="form-group">
          <div className="subtitle">
            <div className="title"> Gender</div>
            {genderLength.length <= 0 ? <div className="error"> *** Required </div> : null}
          </div>
          <div className="d-flex flex-wrap ad-icon-row">
            {this.props.gender.map(this.renderGender)}
          </div>
        </div>
        <div className="small-block" />
        <div className="form-group">
          <div className="subtitle">
            <div className="title"> Age</div>
            {ageLength.length <= 0 ? <div className="error"> *** Required </div> : null}
          </div>
          <div className="d-flex flex-wrap button-effects">
            {this.props.age.map(this.renderAge)}
          </div>
        </div>
      </div>
    );
  }

  // -- Private -- //

  handleGenderClick(clickedItem) {
    const gender = _.cloneDeep(this.props.gender);
    const age = _.cloneDeep(this.props.age);

    const updatedGender = gender.map((g) => {
      g.present = g.name === clickedItem.name;
      return g;
    });

    this.props.onDemographicChange(updatedGender, age);
  }

  handleAgeClick(clickedItem) {
    const gender = _.cloneDeep(this.props.gender);
    const age = _.cloneDeep(this.props.age);

    const item = _.find(age, (i) => i.name === clickedItem.name);
    item.present = !item.present;

    this.props.onDemographicChange(gender, age);
  }

  renderGender(gender) {
    let active = "";
    if (gender.present) {
      active = "active";
    }

    return (
      <div className="main-icon" key={gender.name}>
        <a
          className={`thumbnail ${gender.name} ${active}`}
          onClick={this.handleGenderClick.bind(this, gender)}
        />
        <p>{gender.name}</p>
      </div>
    );
  }

  renderAge(age) {
    let active = "";
    if (age.present) {
      active = "active";
    }

    return (
      <a
        className={`hvr-reveal ${active}`}
        onClick={this.handleAgeClick.bind(this, age)}
        key={age.name}
      >
        {age.name}
      </a>
    );
  }
}

TargetingDemographicSelector.propTypes = {
  gender: PropTypes.arrayOf(PropTypes.any).isRequired,
  age: PropTypes.arrayOf(PropTypes.any).isRequired,
  onDemographicChange: PropTypes.func.isRequired,
};

export default TargetingDemographicSelector;
