import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import useDeepCompareEffect from "../../../../utilities/hooks/useDeepCompareEffect";
import {
  getDollarsFromCents,
  getCentsFromDollars,
  roundToDecimal,
} from "../../../../utilities/mathHelpers";
import CampaignRates from "../../../../utilities/CampaignRates";
import TextInput from "../../../Global/Widgets/textInput";
import Dropdown from "../../../Global/Widgets/updatedWidgets/dropDown";
import NumberInput from "../../../Global/Widgets/updatedWidgets/numberInput";
import SingleDatePicker from "../../../Global/Widgets/updatedWidgets/singleDatePicker";
import IdentityService from "../../../User/Services/identityService";
import { UserContext } from "../../../../App/routeHelpers/privateComponent";

function CampaignProposalForm({ readOnly, elev8Properties, selectedCampaignProposalIndex }) {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useFormContext();

  const loggedInUser = useContext(UserContext);
  const watchRateType = useWatch({ name: fieldName("rate_type") });
  const watchStartAt = useWatch({ name: fieldName("start_at") });
  const watchEndAt = useWatch({ name: fieldName("end_at") });
  const watchPropertyIds = useWatch({ name: fieldName("property_ids") });
  const watchFullscreen = useWatch({ name: fieldName("fullscreen") });
  const watchRateValueDollars = useWatch({ name: fieldName("rate_value_dollars") });
  const watchLoopSpots = useWatch({ name: fieldName("loop_spots") });
  const missingAttribute = t("keywords.general.notSet");
  const priceFieldDisabled = !watchRateType;
  const selectedProperties = elev8Properties.filter((elev8Property) =>
    watchPropertyIds.includes(String(elev8Property.attributes.id))
  );
  const propertiesLoaded = !!elev8Properties.length;

  useDeepCompareEffect(
    function setStandardPriceDollars() {
      if (!propertiesLoaded) return;
      const campaignPriceDollars = selectedProperties.reduce((totalPrice, elev8Property) => {
        const propertyPriceInfo = elev8Property.getCampaignCost(
          watchRateType,
          watchRateValueDollars,
          watchStartAt,
          watchEndAt,
          watchLoopSpots,
          watchFullscreen
        );
        return totalPrice + propertyPriceInfo.price;
      }, 0);
      setFieldValue("standard_price_dollars", campaignPriceDollars);
    },
    [
      watchPropertyIds,
      watchRateType,
      watchRateValueDollars,
      watchStartAt,
      watchEndAt,
      watchLoopSpots,
      watchFullscreen,
      propertiesLoaded,
    ]
  );

  useDeepCompareEffect(
    function setBookedImpressions() {
      if (!propertiesLoaded) return;
      const bookedImpressions = selectedProperties.reduce((totalImpressions, elev8Property) => {
        const propertyBookedImpressions = elev8Property.getActiveBookedImpressions(
          watchStartAt,
          watchEndAt,
          watchLoopSpots
        );
        return totalImpressions + propertyBookedImpressions;
      }, 0);
      setFieldValue("booked_impressions", bookedImpressions);
    },
    [watchPropertyIds, watchStartAt, watchEndAt, watchLoopSpots, propertiesLoaded]
  );

  useDeepCompareEffect(
    function setTargetScreens() {
      if (!propertiesLoaded) return;
      const targetScreens = selectedProperties.reduce((totalScreens, elev8Property) => {
        const propertyTargetScreens = elev8Property.activeScreens.length;
        return totalScreens + propertyTargetScreens;
      }, 0);
      setFieldValue("target_screens", targetScreens);
    },
    [watchPropertyIds, propertiesLoaded]
  );

  function campaignTypeOptions() {
    const allOptions = t("keywords.campaignRelated.typeOptions", { returnObjects: true });
    if (IdentityService.isRsm(loggedInUser)) {
      return allOptions.filter((option) => option.value.includes("rsm"));
    }
    return allOptions;
  }

  function loopSpotOptions() {
    const allOptions = [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
    ];
    if (!IdentityService.isVerticalStaff(loggedInUser)) {
      return allOptions.slice(0, 2);
    }
    return allOptions;
  }

  function fieldName(attributeName) {
    return `campaign_proposals.${selectedCampaignProposalIndex}.${attributeName}`;
  }

  function getFieldValue(attributeName) {
    return getValues(fieldName(attributeName));
  }

  function setFieldValue(attributeName, newValue) {
    setValue(fieldName(attributeName), newValue);
  }

  function renderRateLabel() {
    const rateType = getFieldValue("rate_type");

    return rateType && t("keywords.campaignRelated.baseCPM");
  }

  function renderBaseRate() {
    const rateType = watchRateType;
    const fullScreen = getFieldValue("fullscreen");

    return rateType ? `${CampaignRates.baseRateInDollarsFromRate(rateType, fullScreen)}` : 0;
  }

  function renderAdDuration() {
    const rateType = watchRateType;
    return rateType
      ? `${CampaignRates.adDurationFromRate(rateType)} ${t("timeTerms.seconds")}`
      : missingAttribute;
  }

  function currentBaseRateCents() {
    const rateType = getFieldValue("rate_type");
    const fullScreen = getFieldValue("fullscreen");
    if (!rateType) return null;

    return getCentsFromDollars(CampaignRates.baseRateInDollarsFromRate(rateType, fullScreen));
  }

  function updateRateValue() {
    const discount = getFieldValue("discount") || 0;
    const baseRateCents = currentBaseRateCents();
    if (baseRateCents) {
      const rateValueCents = baseRateCents - (discount / 100) * baseRateCents;
      const rateValueDollars = getDollarsFromCents(rateValueCents);
      setFieldValue("rate_value_dollars", rateValueDollars);
    } else {
      setFieldValue("rate_value_dollars", 0);
    }
  }

  function updateDiscount() {
    const rateValueDollars = getFieldValue("rate_value_dollars") || "0";
    const baseRateCents = currentBaseRateCents();
    if (baseRateCents) {
      const rateValueCents = getCentsFromDollars(rateValueDollars);
      const discountInPercent = 100 - (rateValueCents / baseRateCents) * 100;
      const roundedDiscount = roundToDecimal(discountInPercent, 2);
      setFieldValue("discount", roundedDiscount);
    }
  }

  function handleRateTypeChange(event, handleChange) {
    handleChange(event);
    updateRateValue();
  }

  function handleRateValueChange(event, handleChange) {
    handleChange(event);
    updateDiscount();
  }

  function handleDiscountChange(event, handleChange) {
    handleChange(event);
    updateRateValue();
  }

  function handleFullscreenChange(event, handleChange) {
    handleChange(event);
    updateRateValue();
  }

  return (
    <div className="flexForm">
      <div className="flexForm__group">
        <Form.Group controlId={fieldName("name")} className="flexForm__field--xl">
          <Controller
            control={control}
            name={fieldName("name")}
            render={({ field: { onChange, value, name } }) => (
              <TextInput
                readOnly={readOnly}
                label={`${t("keywords.campaignRelated.campaign")} ${t("keywords.general.name")}`}
                value={value}
                handleChange={onChange}
                name={name}
                required
                type="text"
              />
            )}
          />
        </Form.Group>
        <Form.Group controlId={fieldName("start_at")} className="flexForm__field">
          <Controller
            control={control}
            name={fieldName("start_at")}
            render={({ field: { onChange, value } }) => (
              <SingleDatePicker
                readOnly={readOnly}
                label={t("keywords.dateRelated.start")}
                onChange={onChange}
                maxDate={getFieldValue("end_at")}
                date={value}
              />
            )}
          />
        </Form.Group>
        <Form.Group controlId={fieldName("end_at")} className="flexForm__field">
          <Controller
            control={control}
            name={fieldName("end_at")}
            render={({ field: { onChange, value } }) => (
              <SingleDatePicker
                readOnly={readOnly}
                label={t("keywords.dateRelated.end")}
                onChange={onChange}
                minDate={getFieldValue("start_at")}
                date={value}
              />
            )}
          />
        </Form.Group>
        <Form.Group controlId={fieldName("loop_spots")} className="flexForm__field--sm">
          <Controller
            control={control}
            name={fieldName("loop_spots")}
            render={({ field: { onChange, value, name } }) => (
              <Dropdown
                readOnly={readOnly}
                name={name}
                label={t("keywords.campaignRelated.loopSpots")}
                value={value}
                handleChange={onChange}
                options={loopSpotOptions()}
              />
            )}
          />
        </Form.Group>
        <Form.Group controlId={fieldName("campaign_type")} className="flexForm__field">
          <Controller
            control={control}
            name={fieldName("campaign_type")}
            render={({ field: { onChange, value, name } }) => (
              <Dropdown
                readOnly={readOnly}
                includeBlank
                name={name}
                label={t("keywords.campaignRelated.campaignType")}
                value={value}
                handleChange={onChange}
                options={campaignTypeOptions()}
              />
            )}
          />
        </Form.Group>
      </div>
      <div className="flexForm__group">
        <Form.Group controlId={fieldName("rate_type")} className="flexForm__field--lg">
          <Controller
            control={control}
            name={fieldName("rate_type")}
            render={({ field: { onChange, value, name } }) => (
              <Dropdown
                readOnly={readOnly}
                name={name}
                label={t("keywords.campaignRelated.rateType")}
                value={value}
                handleChange={(event) => handleRateTypeChange(event, onChange)}
                options={CampaignRates.allRateOptions()}
              />
            )}
          />
        </Form.Group>
        <Form.Group controlId={fieldName("ad_duration")} className="flexForm__field">
          <TextInput
            readOnly
            name={fieldName("ad_duration")}
            label={t("keywords.campaignRelated.adLength")}
            value={renderAdDuration()}
            type="text"
            disabled={priceFieldDisabled}
          />
        </Form.Group>
        <Form.Group controlId={fieldName("base_cpm")} className="flexForm__field">
          <NumberInput
            readOnly
            name={fieldName("base_cpm")}
            label={renderRateLabel()}
            value={renderBaseRate()}
            prefix="$"
          />
        </Form.Group>
        <Form.Group controlId={fieldName("discount")} className="flexForm__field">
          <Controller
            control={control}
            name={fieldName("discount")}
            render={({ field: { onChange, value, name } }) => (
              <NumberInput
                readOnly={readOnly}
                name={name}
                label={t("keywords.campaignRelated.discount")}
                value={roundToDecimal(value, 2)}
                handleChange={(event) => handleDiscountChange(event, onChange)}
                suffix="%"
                max={100}
                disabled={priceFieldDisabled}
              />
            )}
          />
        </Form.Group>
        <Form.Group controlId={fieldName("rate_value_dollars")} className="flexForm__field">
          <Controller
            control={control}
            name={fieldName("rate_value_dollars")}
            render={({ field: { onChange, value, name } }) => (
              <NumberInput
                readOnly={readOnly}
                name={name}
                label={t("keywords.campaignRelated.dealRate")}
                value={value}
                handleChange={(event) => handleRateValueChange(event, onChange)}
                min={0}
                prefix="$"
                disabled={priceFieldDisabled}
              />
            )}
          />
        </Form.Group>
        {IdentityService.isVerticalStaff(loggedInUser) && (
          <Form.Group controlId={fieldName("fullscreen")} className="flexForm__field--fixedSm">
            <Controller
              control={control}
              name={fieldName("fullscreen")}
              render={({ field: { onChange, value, name } }) => (
                <Dropdown
                  readOnly={readOnly}
                  name={name}
                  label={t("keywords.campaignRelated.fullscreen")}
                  value={value}
                  handleChange={(event) => handleFullscreenChange(event, onChange)}
                  options={[
                    { value: true, label: t("keywords.general.yes") },
                    { value: false, label: t("keywords.general.no") },
                  ]}
                />
              )}
            />
          </Form.Group>
        )}
      </div>
    </div>
  );
}

CampaignProposalForm.defaultProps = {
  selectedCampaignProposalIndex: null,
};

CampaignProposalForm.propTypes = {
  elev8Properties: PropTypes.arrayOf(PropTypes.object).isRequired,
  readOnly: PropTypes.bool.isRequired,
  selectedCampaignProposalIndex: PropTypes.number,
};

export default CampaignProposalForm;
