/* Submit Button Form */

import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Rodal from "rodal";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { withTranslation } from "react-i18next";

class SubmitButtonForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.delete = this.delete.bind(this);
  }

  close() {
    this.setState({ showModal: false });
  }

  open() {
    this.setState({ showModal: true });
  }

  delete() {
    this.props.deleteAction();
    this.close();
  }

  renderBackButton = () => {
    const { t } = this.props;

    return (
      <Link to={this.props.exitRoute}>
        <Button size="lg" variant="outline-primary">
          {t("keywords.buttonActions.cancel")}
        </Button>
      </Link>
    );
  };

  renderDeleteButton = () => {
    const { t } = this.props;

    const canDelete = this.props.enableDelete && this.props.formId;
    if (!canDelete) {
      return null;
    }

    return (
      <Button size="lg" variant="danger" onClick={this.open}>
        {t("keywords.buttonActions.delete")}
      </Button>
    );
  };

  renderPrimaryAction = () => {
    const { t } = this.props;

    const shouldUpdate = !!this.props.formId;

    if (shouldUpdate) {
      return (
        <Button type="submit" size="lg" variant="success" onClick={this.props.updateAction}>
          {t("keywords.buttonActions.update")}
        </Button>
      );
    }

    if (this.props.createAction) {
      return (
        <Button type="submit" size="lg" variant="success" onClick={this.props.createAction}>
          {this.props.createButtonName}
        </Button>
      );
    }

    return null;
  };

  renderModalArea = () => {
    const { t } = this.props;

    return (
      <Rodal visible={this.state.showModal} onClose={this.close} height={200}>
        <h3>
          {" "}
          {t("keywords.buttonActions.deleting")} {this.props.category}
        </h3>
        <hr />
        {t("keywords.buttonActions.deleteMessage")}
        <hr />
        <div className="flex-this">
          <Button variant="danger" onClick={this.delete}>
            {t("keywords.buttonActions.delete")}
          </Button>
          <Button onClick={this.close}>{t("keywords.buttonActions.cancel")}</Button>
        </div>
      </Rodal>
    );
  };

  render() {
    return (
      <Container fluid className={`px-0 py-4 ${this.props.className}`}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {this.renderBackButton()}
          <div>
            {this.renderDeleteButton()}
            {this.renderPrimaryAction()}
          </div>
        </div>
        {this.renderModalArea()}
      </Container>
    );
  }
}

export default connect(null, null)(withTranslation()(SubmitButtonForm));
