import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import moment from "moment";
import {
  AGE_REFERENCE,
  DAYS_OF_WEEK_REFERENCE,
  GENDER_REFERENCE,
  WEATHER_REFERENCE,
} from "@components/Campaign/Creation/AdStack/services/actions/adInfoRef";
import BasicTable from "@components/Global/General/BasicTable";
import { SelectColumnFilter } from "@components/Global/General/BasicTableFilter";
import ImagePreviewWidget from "@components/Global/Widgets/views/imagePreviewWidget";
import Pill from "@components/Global/General/pill";
import ProgressBar from "@components/Global/Widgets/views/progressBar";

class CampaignAdList extends Component {
  flattenedAds = () =>
    this.props.ads.map((ad) => ({
      ...ad.attributes,
      media: ad.media() && ad.media().attributes,
      status: [ad.attributes.start_date, ad.attributes.end_date, ad.attributes.published],
      progress: [ad.attributes.start_date, ad.attributes.end_date, ad.attributes.published],
    }));

  columns = () => [
    {
      Header: "Status",
      accessor: "status",
      disableFilters: true,
      Cell: (props) => this.renderPill(props.value),
    },
    {
      Header: "ID",
      accessor: "id",
      disableFilters: true,
    },
    {
      Header: "Ad Name",
      accessor: "title",
      disableFilters: true,
    },
    {
      Header: "Type",
      accessor: "media.type",
      Filter: SelectColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Ad",
      accessor: "media",
      disableSortBy: true,
      disableFilters: true,
      Cell: this.renderMediaPreview,
    },
    {
      Header: "Progress",
      accessor: "progress",
      disableSortBy: true,
      disableFilters: true,
      Cell: (props) => this.renderProgress(props.value),
    },
    {
      Header: "Gender",
      accessor: "targeting.gender",
      disableSortBy: true,
      disableFilters: true,
      Cell: (props) => this.renderTargetIcons(props.value, GENDER_REFERENCE),
    },
    {
      Header: "Age",
      accessor: "targeting.age",
      disableSortBy: true,
      disableFilters: true,
      Cell: (props) =>
        this.renderTargetDescription(props.value, AGE_REFERENCE, "description", true),
    },
    {
      Header: "Days",
      accessor: "targeting.days",
      disableSortBy: true,
      disableFilters: true,
      Cell: (props) => this.renderTargetDescription(props.value, DAYS_OF_WEEK_REFERENCE, "abbr"),
    },
    {
      Header: "Weather",
      accessor: "targeting.weather",
      disableSortBy: true,
      disableFilters: true,
      Cell: (props) => this.renderTargetIcons(props.value, WEATHER_REFERENCE),
    },
    {
      Header: "# Properties",
      accessor: "targeting.properties",
      disableSortBy: true,
      disableFilters: true,
      Cell: this.renderCount,
    },
    {
      Header: "# Screens",
      accessor: "screen_count",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: "",
      accessor: "id",
      id: "actions",
      disableSortBy: true,
      disableFilters: true,
      Cell: this.renderActions,
    },
  ];

  adStatus = (adDetails) => {
    const adStart = adDetails[0];
    const adEnd = adDetails[1];
    const published = adDetails[2];
    const today = moment();

    if (!published) {
      return "Inactive";
    }
    if (today.isBefore(adStart)) {
      return "Pending";
    }
    if (today.isAfter(adEnd)) {
      return "Complete";
    }
    return "Active";
  };

  pillColour = (adStatus) => {
    switch (adStatus) {
      case "Inactive":
        return "disabled";
      case "Pending":
        return "yellow";
      case "Complete":
        return "blue";
      default:
        return "green";
    }
  };

  renderPill = (statusArray) => {
    const statusText = this.adStatus(statusArray);
    const pillColour = this.pillColour(statusText);
    return <Pill text={statusText} type={pillColour} />;
  };

  renderMediaPreview = ({ cell }) => {
    const media = cell.value;
    if (!media) return null;

    const width = 69;
    const height = 50;
    return (
      <ImagePreviewWidget
        filePreview={media.url}
        fileType={media.type}
        autoPlayVideo={false}
        width={width}
        height={height}
      />
    );
  };

  renderProgress = (progressArray) => (
    <ProgressBar
      start={progressArray[0]}
      end={progressArray[1]}
      statusOnly={false}
      published={progressArray[2]}
      className="ad-progress"
    />
  );

  renderTargetDescription = (cells, targetReference, field, column) => {
    const columnFormat = column ? "" : "d-flex justify-content-around";
    const targetDescription = Object.keys(targetReference).map((key) => {
      if (cells.includes(key)) {
        return <p key={targetReference[key][field]}>{targetReference[key][field]}</p>;
      }
      return null;
    });
    return <div className={columnFormat}>{targetDescription}</div>;
  };

  renderTargetIcons = (cells, targetReference) => {
    const targetIcon = Object.keys(targetReference).map((key) => {
      if (!cells.includes(key)) return null;
      const condensedTargetDescription = targetReference[key].description?.split(" ")?.join("");
      const condensedTargetCssValue = targetReference[key].cssValue?.split(" ")?.join("");
      const targetKey = `${condensedTargetDescription}${condensedTargetCssValue}`;
      return (
        <div
          key={targetKey}
          className={`basicTable__icon-size icons ${targetReference[key].cssValue}`}
        />
      );
    });
    return <div className="d-flex justify-content-around">{targetIcon}</div>;
  };

  renderCount = ({ cell }) => <div>{cell.value.length}</div>;

  renderActions = ({ cell }) => {
    const { t, onEditAd, onCopyAd, onDeleteAd, savingAdId } = this.props;
    const adId = cell.value;
    const savingThisAd = savingAdId === adId;

    return (
      <div className="d-flex">
        <Button
          disabled={savingThisAd}
          variant="outline-success"
          size="sm mr-1"
          onClick={() => onEditAd(adId)}
        >
          {t("keywords.buttonActions.edit")}
        </Button>
        <Button
          disabled={savingThisAd}
          variant="outline-secondary"
          size="sm mr-1"
          onClick={() => onCopyAd(adId)}
        >
          {t("keywords.buttonActions.copy")}
        </Button>
        <Button
          disabled={savingThisAd}
          variant="outline-danger"
          size="sm"
          onClick={() => onDeleteAd(adId)}
        >
          {t("keywords.buttonActions.delete")}
        </Button>
      </div>
    );
  };

  render() {
    const ads = this.flattenedAds();

    return (
      <div className="mainTable mainTable__withBorder campaignForm__table">
        <BasicTable
          tableHeader={() => null}
          noResultsDescription="No Ads present"
          columns={this.columns()}
          data={ads}
        />
      </div>
    );
  }
}

CampaignAdList.defaultProps = {
  savingAdId: 0,
};

CampaignAdList.propTypes = {
  ads: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.object,
      media: PropTypes.func,
    })
  ).isRequired,
  onEditAd: PropTypes.func.isRequired,
  onCopyAd: PropTypes.func.isRequired,
  onDeleteAd: PropTypes.func.isRequired,
  savingAdId: PropTypes.number,
};

export default withTranslation()(CampaignAdList);
