// React hook friendly version of the GalleryPicker
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import { useFormContext } from "react-hook-form";
import ConfirmModal from "./ConfirmModal";
import { MAX_IMAGE_FILE_SIZE } from "../../../constants";
import FormStatus from "../../../../utilities/FormStatus";

export default function ImageGalleryPicker({
  onSelectImage,
  imageIdSelected,
  deleteImage,
  uploadImage,
  imageUploadStatus,
  images,
  borderStyle,
}) {
  const { watch } = useFormContext();
  const { t } = useTranslation();
  const fileRef = useRef();
  const [showImageDeleteModal, toggleImageDeleteModal] = useState(false);
  const [mediaIdToDelete, setMediaToDelete] = useState(null);
  const propertyIds = watch("property_ids");
  const imagesSortedByIdDesc = images.sort((a, b) => b.id - a.id);

  function handleChange(e) {
    const [file] = e.target.files;
    const maxFileSizeDescription = t("fileTerms.sizeInMB", { size: 10 });
    if (file.size > MAX_IMAGE_FILE_SIZE) {
      alert(t("imageTerms.imageTooBig", { maxSize: maxFileSizeDescription }));
    } else {
      uploadImage(file);
    }
  }

  if (!propertyIds || Object.keys(propertyIds).length === 0) return emptyGallery();

  function emptyGallery() {
    return (
      <div className="gallery-picker error-border">
        <div className="gallery-picker__empty">
          <i className="fa fa-building mb-2" />
          <b>{t("propertyTerms.missingPropertyPleaseSelectAProperty")}</b>
        </div>
      </div>
    );
  }

  function openModal(media) {
    toggleImageDeleteModal(true);
    setMediaToDelete(media.id);
  }

  function resetMediaDeleteSelection() {
    toggleImageDeleteModal(false);
    setMediaToDelete(null);
  }

  function deleteMedia() {
    deleteImage(mediaIdToDelete);
    resetMediaDeleteSelection();
  }

  function handleSelectImage(image) {
    onSelectImage(image.id);
  }

  function renderImage(image) {
    const isSelected = parseInt(imageIdSelected, 10) === parseInt(image.id, 10);
    return (
      <>
        <button
          type="button"
          className={`gallery-picker__image ${isSelected ? "gallery-picker__image--selected" : ""}`}
          onClick={() => handleSelectImage(image)}
        >
          <img data-tip data-for={`image-${image.id}`} alt={image.name} src={image.url} />
        </button>

        <button type="button" onClick={() => openModal(image)}>
          <i className="simple-image-delete__icon fa fa-times-circle" />
        </button>

        <ReactTooltip
          effect="solid"
          id={`image-${image.id}`}
          border
          type="light"
          className="tool-tip"
          getContent={() => (
            <div className="tool-tip__container">
              <div className="text-left">
                <div className="tool-tip__description">{image.name}</div>
              </div>
            </div>
          )}
        />
      </>
    );
  }

  function renderImageUpload() {
    return (
      <div className="gallery-picker__image-uploader">
        <div className="m-3">
          <button
            type="button"
            onClick={() => fileRef.current.click()}
            className="simple-image-uploader"
          >
            <i className="fa fa-3x fa-cloud-upload" aria-hidden="true"></i>
            {imageUploadStatus === FormStatus.SAVING ? (
              <div>
                <b>{t("keywords.statusRelated.uploading")}...</b>
                <Spinner animation="border" variant="primary" size="sm" />
              </div>
            ) : (
              <b>{t("imageTerms.uploadImage")}</b>
            )}
          </button>
          <input
            ref={fileRef}
            onChange={handleChange}
            multiple={false}
            type="file"
            accept="image/png, image/jpg"
            hidden
          />
        </div>
      </div>
    );
  }

  function renderImageDeleteConfirmationModal() {
    return (
      <ConfirmModal
        title={t("keywords.buttonActions.deleting")}
        show={showImageDeleteModal}
        confirm={{
          label: t("keywords.buttonActions.delete"),
          variant: "danger",
          action: deleteMedia,
        }}
        cancel={{
          label: t("keywords.buttonActions.cancel"),
          variant: "outline-secondary",
          action: resetMediaDeleteSelection,
        }}
      >
        {t("keywords.buttonActions.deleteMessage")}
      </ConfirmModal>
    );
  }

  return (
    <div className={`gallery-picker ${borderStyle}`}>
      <div className="d-flex flex-wrap justify-content-start">
        {renderImageUpload()}
        {renderImageDeleteConfirmationModal()}
        {imagesSortedByIdDesc.map((image) => (
          <div>{renderImage(image)}</div>
        ))}
      </div>
    </div>
  );
}

ImageGalleryPicker.defaultProps = {
  imageIdSelected: null,
  images: [],
};

ImageGalleryPicker.propTypes = {
  imageIdSelected: PropTypes.string,
  images: PropTypes.array,
  deleteImage: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  imageUploadStatus: PropTypes.string.isRequired,
  onSelectImage: PropTypes.func.isRequired,
};
