// ALL ACTIONS FOR PROPERTIES FOR PROPERTY MANAGERS

import _ from "lodash";
import {
  // Properties
  SET_FEED_PROPERTIES,
} from "../../../../App/actionTypes";
import Elev8ApiService from "../../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../../lib/services/Elev8Api/lib";

/** ***********************************************************
PROPERTY ACTIONS
************************************************************ */

export function setFeedProperties(properties) {
  return {
    type: SET_FEED_PROPERTIES,
    payload: properties,
  };
}

// properties that a user is a member or a manager of

export function fetchFeedProperties(userData) {
  const sparseFieldSets = {
    include: ["screen_installation_configs"],
    fields: {
      property: [
        "id",
        "name",
        "address_line1",
        "address_city",
        "address_country",
        "screen_installation_configs",
      ],
      screen_installation_config: ["id", "big_notice_frequency"],
    },
  };
  return function (dispatch) {
    Elev8ApiService.index(ApiTypes.property, sparseFieldSets)
      .then((properties) => {
        let propertyData = properties.map((property) => {
          const item = property.attributes;
          item.screen_installation_configs = property.screen_installation_configs;
          item.area1 = `${item.address_line1},${item.address_city},${item.address_country}`;
          return item;
        });
        dispatch(setFeedProperties(propertyData.filter(Boolean)));
      })
      .catch((e) => {
        console.log("Can't fetch feed Properties", e);
      });
  };
}
