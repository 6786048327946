import Elev8ApiService from "../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../lib/services/Elev8Api/lib";

class CampaignProposalDataHelper {
  static deleteProposal(id) {
    return new Promise((resolve, reject) => {
      Elev8ApiService.delete(ApiTypes.campaign_proposal, id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default CampaignProposalDataHelper;
