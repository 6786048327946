import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import Fuse from "fuse.js";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import moment from "moment";

import withRouter from "@hooks/withRouter";
import Header from "@components/Interface/MainList/header";
import GeneralTable from "@components/Interface/MainList/generalTable";
import LoadingPage from "@components/Global/General/loadingPage";
import CampaignPill from "@components/Global/General/campaignPill";
import { clearAdList } from "@components/Campaign/Creation/AdStack/services/actions/adStackActions";
import { fetchCampaigns } from "@components/Campaign/GeneralView/services/actions/campaignActions";

class CampaignList extends Component {
  static sparseFieldSets = {
    include: ["campaign_group", "owner", "advertiser"],
    fields: {
      advertiser: ["id", "name"],
      campaign_group: ["id", "name"],
      user: ["id", "first_name"],
      campaign: [
        "id",
        "status",
        "name",
        "campaign_type",
        "start_at",
        "end_at",
        "report_code",
        "advertiser",
        "owner",
        "campaign_group",
      ],
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      publishedView: true,
      searchValue: "",
      activeMyFilter: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.fetchDataForRender();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.publishedView !== prevState.publishedView) {
      this.fetchDataForRender();
    }
  }

  filterMyCampaigns = () => {
    this.setState((prevState) => ({ activeMyFilter: !prevState.activeMyFilter }));
  };

  goToCampaignGroup = (row) => {
    this.props.router.navigate(`/campaign_group/edit/${row.campaignGroupId}`);
  };

  goToCampaign = (row) => {
    this.props.router.navigate(`/flight/edit/${row.id}`);
  };

  goToClient = (row) => {
    this.props.router.navigate(`/advertisers/${row.advertiser_id}`);
  };

  goToCampaignReport = (row) => {
    window.open(`/campaign_reporting/${row.report_code}/report`);
  };

  switchView = () => {
    this.setState((prevState) => ({ publishedView: !prevState.publishedView }));
  };

  updateSearch = (event) => {
    this.setState({ searchValue: event.target.value });
  };

  fetchDataForRender() {
    if (this.state.publishedView) {
      this.props.fetchCampaigns(CampaignList.sparseFieldSets, {
        "filter[status]": "active",
        "filter[end_at][gt]": moment().subtract(1, "years").format("YYYY-MM-DD HH:mm:ss"),
      });
    } else {
      this.props.fetchCampaigns(CampaignList.sparseFieldSets, {
        "filter[status]": "inactive",
        "filter[end_at][gt]": moment().subtract(1, "years").format("YYYY-MM-DD HH:mm:ss"),
      });
    }
    this.props.clearAdList();
  }

  render() {
    if (!this.props.campaigns) {
      return <LoadingPage pageName="Campaigns" />;
    }

    const ICON_LINKS = [
      {
        linkType: "analytics",
        iconName: "fa-area-chart",
        linkAction: this.goToCampaignReport,
        tooltip: "Report",
      },
    ];

    const PUBLISHED_TABLE = [
      {
        columnTitle: "",
        columnSize: "2%",
        fieldType: "padding",
      },
      {
        columnTitle: "Status",
        columnSize: "5%",
        fieldType: "component",
        fieldValue: "statusPill",
      },
      {
        columnTitle: "Flight",
        columnSize: "5%",
        fieldType: "textLink",
        fieldValue: "id",
        hasValue: true,
        linkAction: this.goToCampaign,
        extraCss: "text-link",
      },
      {
        columnTitle: "Campaign",
        columnSize: "5%",
        fieldType: "textLink",
        hasValue: true,
        fieldValue: "campaignGroupId",
        linkAction: this.goToCampaignGroup,
        extraCss: "text-link",
      },
      {
        columnTitle: "Name",
        columnSize: "12%",
        fieldType: "text",
        fieldValue: "name",
        extraCss: "u-word-break",
      },
      {
        columnTitle: "Type",
        columnSize: "5%",
        fieldType: "text",
        fieldValue: "campaign_type",
        extraCss: "text-capitalize",
      },
      {
        columnTitle: "Client",
        columnSize: "10%",
        fieldType: "textLink",
        hasValue: true,
        fieldValue: "client",
        linkAction: this.goToClient,
      },
      {
        columnTitle: "Owned by",
        columnSize: "10%",
        fieldType: "text",
        fieldValue: "owner",
      },
      {
        columnTitle: "Start",
        columnSize: "5%",
        fieldType: "text",
        fieldValue: "tailoredStartDate",
      },
      {
        columnTitle: "End",
        columnSize: "5%",
        fieldType: "text",
        fieldValue: "tailoredEndDate",
      },
      {
        columnTitle: "Progress",
        columnSize: "10%",
        fieldType: "campaign-progress",
        fieldValue: "tailoredEndDate",
      },
      {
        columnTitle: "Links",
        columnSize: "14%",
        fieldType: "iconLinkList",
        fieldValue: ICON_LINKS,
      },
      {
        columnTitle: "",
        columnSize: "8%",
        fieldType: "edit",
        editAction: "/flight/edit/:id", // :id will get replaced in the General Table component
      },
      {
        columnTitle: "",
        columnSize: "30%",
        fieldType: "padding",
      },
    ];

    const DRAFT_TABLE = [
      {
        columnTitle: "",
        columnSize: "2%",
        fieldType: "padding",
      },
      {
        columnTitle: "Status",
        columnSize: "10%",
        fieldType: "component",
        fieldValue: "statusPill",
      },
      {
        columnTitle: "Flight",
        columnSize: "5%",
        fieldType: "textLink",
        fieldValue: "id",
        hasValue: true,
        linkAction: this.goToCampaign,
        extraCss: "text-link",
      },
      {
        columnTitle: "Campaign",
        columnSize: "5%",
        fieldType: "textLink",
        hasValue: true,
        fieldValue: "campaignGroupId",
        linkAction: this.goToCampaignGroup,
        extraCss: "text-link",
      },
      {
        columnTitle: "Name",
        columnSize: "12%",
        fieldType: "text",
        fieldValue: "name",
        extraCss: "u-word-break",
      },
      {
        columnTitle: "Type",
        columnSize: "5%",
        fieldType: "text",
        fieldValue: "campaign_type",
        extraCss: "text-capitalize",
      },
      {
        columnTitle: "Client",
        columnSize: "15%",
        fieldType: "textLink",
        hasValue: true,
        fieldValue: "client",
        linkAction: this.goToClient,
      },
      {
        columnTitle: "Owned by",
        columnSize: "15%",
        fieldType: "text",
        fieldValue: "owner",
      },
      {
        columnTitle: "Start",
        columnSize: "5%",
        fieldType: "text",
        fieldValue: "tailoredStartDate",
      },
      {
        columnTitle: "End",
        columnSize: "5%",
        fieldType: "text",
        fieldValue: "tailoredEndDate",
      },
      {
        columnTitle: "Links",
        columnSize: "10%",
        fieldType: "iconLinkList",
        fieldValue: ICON_LINKS,
      },
      {
        columnTitle: "",
        columnSize: "8%",
        fieldType: "edit",
        editAction: "/flight/edit/:id", // :id will get replaced in the General Table component
      },
      {
        columnTitle: "",
        columnSize: "30%",
        fieldType: "padding",
      },
    ];
    const refTable = this.state.publishedView ? PUBLISHED_TABLE : DRAFT_TABLE;

    // filter out campaign status
    let refData = this.props.campaigns;
    if (this.state.publishedView) {
      refData = refData.filter((e) => !e.state.includes("Draft"));
    } else {
      refData = _.filter(refData, { state: "Draft" });
    }

    const options = {
      keys: ["name", "client", "creator"],
      threshold: 0.1,
      ignoreLocation: true,
    };

    let searchResult = refData;

    if (this.state.searchValue.trim() !== "") {
      const fuse = new Fuse(refData, options); // "list" is the item array
      searchResult = fuse.search(this.state.searchValue);
      searchResult = searchResult.map((result) => result.item);
    }

    // only show your campaigns
    if (this.state.activeMyFilter) {
      searchResult = _.filter(searchResult, { owner_id: this.props.loggedInUser.id });
    }

    searchResult = searchResult.map((rowData) => {
      rowData.statusPill = <CampaignPill state={rowData.state} />;
      return rowData;
    });

    return (
      <div className="campaign-list">
        <Header
          count={searchResult.length}
          title={this.state.publishedView ? "Live Flights" : "Draft Flights"}
          searchValue={this.state.searchValue}
          searchAction={this.updateSearch}
        >
          <Row className="align-items-center pb-3 pb-md-0">
            <Col className="order-md-last" xs="auto">
              <Button
                size="sm"
                variant={this.state.activeMyFilter ? "primary" : "outline-primary"}
                onClick={this.filterMyCampaigns}
              >
                My Flights
              </Button>
            </Col>
            <Col className="d-flex">
              <ToggleButtonGroup
                type="checkbox"
                value={this.state.publishedView}
                onChange={this.switchView.bind(this, true)}
              >
                <ToggleButton id="published" size="sm" variant="outline-primary" value>
                  Published
                </ToggleButton>
                <ToggleButton id="drafts" size="sm" variant="outline-primary" value={false}>
                  Drafts
                </ToggleButton>
              </ToggleButtonGroup>
            </Col>
          </Row>
        </Header>
        <GeneralTable
          tableData={searchResult}
          tableFormat={refTable}
          deleteAction={this.deleteAction}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    campaigns: state.campaign.general.all,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchCampaigns, clearAdList }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignList));
