/** **********************************
ACCOUNT INFORMATION
*********************************** */

import { unstableHistory } from "@App/history";

import { SET_ALL_USERS, SET_USER_FORM, SET_PASSWORD_STATUS } from "@App/actionTypes";
import {
  addFlashMessage,
  currentlySaving,
} from "@components/Interface/General/services/actions/applicationStateActions";
import { logoutUser } from "@components/Authentication/actions/sessionActions";

import Elev8ApiService from "@lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "@lib/services/Elev8Api/lib";

/** *************************
REDUCER ACTIONS
************************* */

export function setAllUsers(users) {
  return {
    type: SET_ALL_USERS,
    payload: users,
  };
}

export function setUserForm(newForm) {
  return {
    type: SET_USER_FORM,
    payload: newForm,
  };
}

export function clearUserForm() {
  return {
    type: SET_USER_FORM,
    payload: null,
  };
}

export function setPasswordStatus(status) {
  return {
    type: SET_PASSWORD_STATUS,
    payload: status,
  };
}

/** **********************************
INITIATION
*********************************** */
export function fetchAllUsers() {
  return function (dispatch) {
    Elev8ApiService.server()
      .get(`/users`)
      .then((response) => {
        if (response.status === 200) {
          const formattedUserData = [];
          const userList = response.data.data;
          userList.forEach((user) => {
            const userDetails = user.attributes;
            userDetails.fullName = `${userDetails.first_name} ${userDetails.last_name}`;
            formattedUserData.push(userDetails);
          });
          dispatch(setAllUsers(formattedUserData));
        }
      });
  };
}

/** ************************************
FORM ACTIONS
************************************* */

export function fetchUserForm(userId) {
  return function (dispatch) {
    Elev8ApiService.server()
      .get(`/users/${userId}`)
      .then((response) => {
        if (response.status === 200) {
          const userData = response.data.data.attributes;
          dispatch(setUserForm(userData));
          dispatch(setPasswordStatus(""));
        } else {
          dispatch(logoutUser());
        }
      });
  };
}

export function initiateUserForm(currentUserRole) {
  return function (dispatch) {
    // creating user form
    const userForm = {
      first_name: "",
      last_name: "",
      email: "",
      preferences: {},
      property_role: "",
      role: currentUserRole,
      id: null,
      phone: "",
      address_line_1: "",
      address_line_2: "",
      address_postal_code: "",
      address_city: "",
      address_province: "",
      address_country: "",
      company: "",
      locale: "en",
      properties: {
        resident: [],
        manager: [],
        member: [],
      },
    };
    dispatch(setUserForm(userForm));
  };
}

export function updateUserForm(type, newData, oldData) {
  return function (dispatch) {
    const updatedForm = _.extend({}, oldData);
    switch (type) {
      case "pmProperties":
        updatedForm.properties.manager = newData;
        break;
      case "newRole": // if property_role is member, deselect manager properties vice versa
        if (newData == "member") {
          updatedForm.properties.manager = [];
        }
        if (newData == "manager") {
          updatedForm.properties.member = [];
        }
        updatedForm.property_role = newData;
        break;
      case "phone":
        // strip all letters
        // //console.log("Stripping contact phone");
        var updatedPhoneNumber = newData.replace(/[^0-9\-]/g, "");
        updatedForm[type] = updatedPhoneNumber;
        break;
      case "setAddress":
        updatedForm.address_line_1 = newData.address_line1;
        updatedForm.address_postal_code = newData.address_postal_code;
        updatedForm.address_city = newData.address_city;
        updatedForm.address_province = newData.address_province;
        updatedForm.address_country = newData.address_country;
        break;
      case "clearAddress":
        updatedForm.address_line_1 = "";
        updatedForm.address_postal_code = "";
        updatedForm.address_city = "";
        updatedForm.address_province = "";
        updatedForm.address_country = "";
        break;
      case "memberProperties":
        updatedForm.properties.member = newData;
        break;
      case "residentProperties":
        updatedForm.properties.resident = newData;
        break;
      default:
        updatedForm[type] = newData;
        break;
    }
    dispatch(setUserForm(updatedForm));
  };
}

/** *******************************
TO DATABASE
********************************* */

export function createNewUser(userForm) {
  const jsonApiFormattedForm = Elev8ApiService.setToJsonApiFormat(
    ApiTypes.user.type,
    userForm.id,
    userForm
  );

  return function (dispatch) {
    saveWithNotification(
      "keywords.userRelated.formActions.created",
      () => {
        return postUserForm(jsonApiFormattedForm);
      },
      dispatch
    );
  };
}

export function removeUser(userForm) {
  userForm.role = "no_access";
  return function (dispatch) {
    const jsonApiFormattedForm = Elev8ApiService.setToJsonApiFormat(
      ApiTypes.user.type,
      userForm.id,
      userForm
    );
    saveWithNotification(
      "keywords.userRelated.formActions.updated",
      () => {
        return putUserForm(jsonApiFormattedForm, userForm.id);
      },
      dispatch
    );
  };
}

function postUserForm(userForm) {
  return Elev8ApiService.server()
    .post(`/users`, userForm)
    .then(() => {
      unstableHistory.push("/users");
    });
}

export function editUser(userForm, password, current_password) {
  return function (dispatch) {
    if (password != "" && current_password != "") {
      userForm.password = password;
      userForm.current_password = current_password;
    }

    const jsonApiFormattedForm = Elev8ApiService.setToJsonApiFormat(
      ApiTypes.user.type,
      userForm.id,
      userForm
    );

    saveWithNotification(
      "keywords.userRelated.formActions.updated",
      () => {
        return putUserForm(jsonApiFormattedForm, userForm.id);
      },
      dispatch
    );
  };
}

function putUserForm(userForm, userId) {
  return Elev8ApiService.server()
    .put(`/users/${userId}`, userForm)
    .then(() => {
      unstableHistory.push("/users");
    });
}

function saveWithNotification(message, handleSave, dispatch) {
  currentlySaving(true)(dispatch);

  return handleSave()
    .then(() => {
      addFlashMessage("success", message)(dispatch);
    })
    .catch(() => {
      addFlashMessage("danger", "keywords.userRelated.formError")(dispatch);
    })
    .finally(() => {
      currentlySaving(false)(dispatch);
    });
}
