import axios from "axios";

import queryString from "query-string";
import { store } from "../../../createStore";
import { UNAUTH_USER } from "../../../App/actionTypes";
import { JsonApiObjectFactory, UriCreator } from "./lib";

const { API_URL } = process.env;

class Elev8ApiService {
  static index(apiType, sparseFieldsets, params) {
    const baseUrl = apiType.endpoint;
    const endPoint = UriCreator.createUri(baseUrl, sparseFieldsets);
    return Elev8ApiService.server()
      .get(endPoint, {
        params,
        paramsSerializer: (urlParams) =>
          queryString.stringify(urlParams, { arrayFormat: "bracket", encode: true }),
      })
      .then((response) => {
        return response.data.data.map((singleItem) =>
          JsonApiObjectFactory.createJsonApiObject(
            singleItem,
            response.data.included,
            apiType.apiType
          )
        );
      });
  }

  static read(apiType, id, sparseFieldsets) {
    const baseUrl = `${apiType.endpoint}/${id}`;
    const endpoint = UriCreator.createUri(baseUrl, sparseFieldsets);
    return Elev8ApiService.getEndpoint(endpoint);
  }

  static create(apiType, form) {
    return Elev8ApiService.server()
      .post(apiType.endpoint, form)
      .then((response) => {
        // TODO: Update all users to expect a JsonApiObject response instead
        return response;
      });
  }

  // TODO: DO NOT USE – migrate from this to partialUpdate
  static update(apiType, form, id) {
    return Elev8ApiService.server()
      .put(`${apiType.endpoint}/${id}`, form)
      .then((response) => {
        return response;
      });
  }

  static partialUpdate(apiType, form, id, options = null) {
    const baseUrl = `${apiType.endpoint}/${id}`;
    const endpoint = UriCreator.createUri(baseUrl, options);
    return Elev8ApiService.server()
      .patch(endpoint, form)
      .then((response) => {
        // TODO: Update all users to expect a JsonApiObject response instead
        return response;
      });
  }

  static copy(apiType, id) {
    return Elev8ApiService.server()
      .put(`${apiType.endpoint}/${id}/copy`)
      .then((response) => {
        return response;
      });
  }

  static complete(apiType, id, options = null) {
    const baseUrl = `${apiType.endpoint}/${id}/complete`;
    const endpoint = UriCreator.createUri(baseUrl, options);
    return Elev8ApiService.server()
      .post(endpoint)
      .then((response) => {
        return response;
      });
  }

  static submit(apiType, id, options = null) {
    const baseUrl = `${apiType.endpoint}/${id}/submit`;
    const endpoint = UriCreator.createUri(baseUrl, options);
    return Elev8ApiService.server()
      .patch(endpoint)
      .then((response) => {
        // TODO: Update all users to expect a JsonApiObject response instead
        return response;
      });
  }

  static delete(apiType, id) {
    return Elev8ApiService.server()
      .delete(`${apiType.endpoint}/${id}`)
      .then((response) => {
        return response;
      });
  }

  static getEndpoint(endpoint) {
    return Elev8ApiService.server()
      .get(endpoint)
      .then((response) => {
        const item = response.data.data;
        return JsonApiObjectFactory.createJsonApiObject(item, response.data.included, item.type);
      });
  }

  static getOldEndpoint(endpoint) {
    return Elev8ApiService.server()
      .get(`${API_URL}/${endpoint}`)
      .then((response) => {
        return response;
      });
  }

  static oldPut(endpoint, params, options = {}) {
    return Elev8ApiService.server()
      .put(`${API_URL}/${endpoint}`, params, options)
      .then((response) => {
        return response;
      });
  }

  static oldPost(endpoint, params, options = {}) {
    return Elev8ApiService.server()
      .post(`${API_URL}/${endpoint}`, params, options)
      .then((response) => {
        return response;
      });
  }

  static oldCopy(endpoint) {
    return Elev8ApiService.server()
      .put(`${API_URL}/${endpoint}/copy`)
      .then((response) => {
        return response;
      });
  }

  static oldDelete(endpoint) {
    return Elev8ApiService.server()
      .delete(`${API_URL}/${endpoint}`)
      .then((response) => {
        return response;
      });
  }

  static auth(email, password) {
    return Elev8ApiService.server()
      .post("/access_tokens/authenticate", { email, password })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("userId", JSON.stringify(response.data.data.attributes.user_id));
          Elev8ApiService._setAccessToken(response.data.data.attributes.key);
        }
      });
  }

  static unauth() {
    Elev8ApiService.server()
      .post("/access_tokens/expire")
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          return;
        }
        throw e;
      })
      .finally(() => {
        Elev8ApiService._accessToken = null;
        localStorage.removeItem("userId");
        localStorage.removeItem("accessToken");
        store.dispatch({ type: UNAUTH_USER });
      });
  }

  static renewAuth() {
    if (Elev8ApiService._getAccessToken()) {
      Elev8ApiService.server()
        .post("/access_tokens/renew")
        .catch(() => {
          Elev8ApiService.unauth();
        });
    }
  }

  static server() {
    if (!Elev8ApiService._serverInstance) {
      Elev8ApiService._serverInstance = axios.create({
        baseURL: `${API_URL}/api`,
        timeout: 30000,

        transformRequest: [
          function (data, headers) {
            headers["X-API-AUTHENTICATION"] = Elev8ApiService._getAccessToken();

            if (headers["Content-Type"] == undefined) {
              headers["Content-Type"] = "application/json";
            }

            if (headers["Content-Type"] == "application/json") {
              return Elev8ApiService._serializeData(data);
            }

            return data;
          },
        ],

        transformResponse: [
          function (data) {
            return Elev8ApiService._deserializeData(data);
          },
        ],
      });
    }

    return Elev8ApiService._serverInstance;
  }

  static setToJsonApiFormat(type, id, attributes, relationships) {
    return {
      data: {
        type,
        id,
        attributes: {
          ...attributes,
        },
        relationships: {
          ...relationships,
        },
      },
    };
  }

  // -- Private -- //
  static _setAccessToken(accessToken) {
    Elev8ApiService._accessToken = accessToken;
    localStorage.setItem("accessToken", accessToken);
  }

  static _getAccessToken() {
    if (!Elev8ApiService._accessToken) {
      Elev8ApiService._accessToken = localStorage.getItem("accessToken");
    }

    return Elev8ApiService._accessToken;
  }

  static _serializeData(data) {
    if (data) {
      return JSON.stringify(data);
    }
    return data;
  }

  static _deserializeData(data) {
    if (data) {
      return JSON.parse(data);
    }

    return data;
  }
}

export default Elev8ApiService;
