/*

GENERAL USER INFO

INVOLVES LOCAL CHANGES TO CURRENT FORM
Personal info stays same no matter what
*/

import { SET_ALL_USERS, SET_USER_FORM } from "../../../../../App/actionTypes";

const INITIAL_STATE = { all: null, form: null };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_ALL_USERS:
      return { ...state, all: action.payload };
    case SET_USER_FORM:
      return { ...state, form: action.payload };
  }
  return state;
}
