import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withTranslation } from "react-i18next";

class DashboardPanel extends Component {
  render() {
    const { type, iconUrl, createUrl, description, css, t } = this.props;

    return (
      <Card>
        <Card.Body className="p-0">
          <Link to={createUrl}>
            <Container fluid className="h-100">
              <Row className="h-100 align-items-stretch">
                <Col xs={12} md={4} xl={3} className={`d-flex p-3 justify-content-center ${css}`}>
                  <img src={iconUrl} style={{ objectFit: "contain" }} />
                </Col>
                <Col
                  xs={12}
                  md={8}
                  xl={9}
                  className="d-flex align-items-center p-2 p-sm-4 u-text-force-static"
                >
                  <div className="d-block w-100">
                    <Card.Title className="d-none d-sm-block">{t(type)}</Card.Title>
                    <Card.Text className="d-block d-sm-none text-center u-text-small">
                      {t(type)}
                    </Card.Text>
                    <Card.Text className="d-none d-sm-block">{t(description)}</Card.Text>
                  </div>
                </Col>
              </Row>
            </Container>
          </Link>
        </Card.Body>
      </Card>
    );
  }
}

export default withTranslation()(DashboardPanel);
