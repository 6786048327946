import Elev8ApiService from "@lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "@lib/services/Elev8Api/lib";

class CampaignPropertyDataHelper {
  static fetchPropertyData() {
    const propertySparseFieldSets = {
      include: [
        "network",
        "market",
        "local_logic_location_info",
        "screen_installation_configs",
        "municipality",
        "district",
        "country",
      ],
      fields: {
        property: [
          "id",
          "name",
          "property_type",
          "status",
          "content_restriction",
          "address_line1",
          "address_line2",
          "address_city",
          "address_province",
          "address_country",
          "address_postal_code",
          "screen_count",
          "latitude",
          "longitude",
          "market",
          "local_logic_location_info",
          "floors",
          "has_gym",
          "has_pool",
          "family_friendly",
          "number_of_units",
          "resident_parking",
          "visitor_parking",
          "weekly_price_cents",
          "screen_resolution",
          "adult_only",
          "allows_cats",
          "allows_dogs",
          "amenities",
          "building_sqft",
          "weekly_circulation_estimate",
          "comments",
          "tester",
          "screen_installation_configs",
          "network",
          "municipality",
          "country",
          "district",
        ],
        municipality: ["id", "name"],
        market: ["id", "name"],
        country: ["id", "name"],
        district: ["id", "name"],
        local_logic_location_info: ["id", "local_logic_fields"],
        screen_installation_config: ["id", "current_maintenance"],
      },
    };

    return new Promise((resolve, reject) => {
      Elev8ApiService.index(ApiTypes.property, propertySparseFieldSets)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          console.log("Can't fetch campaign properties...", e);
          reject(e);
        });
    });
  }
}

export default CampaignPropertyDataHelper;
