import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function NonVCPropertyContent({ contentUrl }) {
  const { t } = useTranslation();

  return (
    <div className="non-vc-property-content">
      <iframe
        className="non-vc-property-content__iframe"
        title="non-vc-iframe"
        src={contentUrl}
        sandbox
      />
      <h3 className="non-vc-property-content__title">{t("keywords.screenRelated.liveView")} </h3>
    </div>
  );
}

NonVCPropertyContent.propTypes = {
  contentUrl: PropTypes.string.isRequired,
};
