import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";

import SummaryCallout from "../../SummaryCallout";
import SummarySection from "../../SummarySection";
import SummaryItem from "../../SummaryItem";
import { formDurationInDays } from "../../../../utilities/dateStringHelper";
import CampaignRates from "../../../../utilities/CampaignRates";

export default function CampaignProposalSummary({ selectedCampaignProposalIndex }) {
  const { t, i18n } = useTranslation();
  const missingDateString = t("keywords.general.notSet");
  const missingAttribute = t("keywords.general.missingAttribute");

  const currencyFormatter = new Intl.NumberFormat(t("language.locale"), {
    style: "currency",
    currency: "CAD",
    maximumFractionDigits: 2,
  });

  const percentageFormatter = new Intl.NumberFormat(t("language.locale"), {
    style: "percent",
    maximumFractionDigits: 2,
  });

  const campaignProposalName = useWatch({ name: fieldName("name") });
  const campaignProposalStartAt = useWatch({ name: fieldName("start_at") });
  const campaignProposalEndAt = useWatch({ name: fieldName("end_at") });
  const campaignProposalPropertyIds = useWatch({ name: fieldName("property_ids") });
  const campaignProposalRateType = useWatch({ name: fieldName("rate_type") });
  const campaignProposalFullScreen = useWatch({ name: fieldName("fullscreen") });
  const campaignProposalDiscount = useWatch({ name: fieldName("discount") });
  const campaignProposalCurrentRate = useWatch({ name: fieldName("rate_value_dollars") });
  const campaignProposalStandardPriceDollars = useWatch({
    name: fieldName("standard_price_dollars"),
  });
  const campaignProposalTargetScreens = useWatch({ name: fieldName("target_screens") });

  function fieldName(attributeName) {
    return `campaign_proposals.${selectedCampaignProposalIndex}.${attributeName}`;
  }

  function renderSummaryTitle() {
    return `${campaignProposalName || "Flight"} ${t("keywords.general.summary")}`;
  }

  function renderDateRange() {
    if (!campaignProposalStartAt && !campaignProposalEndAt) return missingDateString;
    const startDate = campaignProposalStartAt || missingDateString;
    const endDate = campaignProposalEndAt || missingDateString;
    return `${startDate} – ${endDate}`;
  }

  function renderDuration() {
    const duration = Math.round(
      formDurationInDays(campaignProposalStartAt, campaignProposalEndAt) || 0
    );
    return i18n.format(t("keywords.dateRelated.daysWithCount", { count: duration }), "lowercase");
  }

  function renderPropertyCount() {
    return String(campaignProposalPropertyIds.length);
  }

  function renderScreenCount() {
    return String(campaignProposalTargetScreens);
  }

  function renderAdLength() {
    if (!campaignProposalRateType) return `${missingAttribute}`;
    const adLength = CampaignRates.adDurationFromRate(campaignProposalRateType);
    return `${adLength}${t("timeTerms.secondsAbbr")}`;
  }

  // We only want to add the CPM rate type to the rate labels
  function renderCPMRateType() {
    if (!campaignProposalRateType) return "";
    const rateType = CampaignRates.rateTypeFromRate(campaignProposalRateType);
    if (!rateType || rateType !== "CPM") return "";
    return "CPM";
  }

  // We only want to add the CPM rate type to the rate labels
  function renderRateCardLabel() {
    return `${t("keywords.campaignRelated.rateCard")} ${renderCPMRateType()}`.trimEnd();
  }

  function renderRateCard() {
    if (!campaignProposalRateType) return `${missingAttribute}`;
    const rateCard = CampaignRates.baseRateInDollarsFromRate(
      campaignProposalRateType,
      campaignProposalFullScreen
    );
    if (!rateCard) return `${missingAttribute}`;
    return `${t("keywords.currencyRelated.dollarValue", { value: rateCard })}`;
  }

  // We only want to add the CPM rate type to the rate labels
  function renderCurrentRateLabel() {
    return `${t("keywords.campaignRelated.currentRate")} ${renderCPMRateType()}`.trimEnd();
  }

  function renderCurrentRate() {
    if (!campaignProposalRateType) return `${missingAttribute}`;
    return `${t("keywords.currencyRelated.dollarValue", { value: campaignProposalCurrentRate })}`;
  }

  function renderFullScreen() {
    if (!campaignProposalFullScreen || campaignProposalFullScreen === "false")
      return `${t("keywords.general.no")}`;
    return `${t("keywords.general.yes")}`;
  }

  function renderDiscount() {
    const discountValue = campaignProposalDiscount;
    if (!discountValue || parseFloat(discountValue) === 0) return "";
    return `${t("keywords.campaignRelated.discount")}: ${percentageFormatter.format(
      discountValue / 100
    )}`;
  }

  function renderFullPrice() {
    return currencyFormatter.format(campaignProposalStandardPriceDollars);
  }

  return (
    <>
      <SummarySection
        title={renderSummaryTitle()}
        callout={
          <SummaryCallout
            label={t("keywords.campaignRelated.campaignCost")}
            value={renderFullPrice()}
            info={renderDiscount()}
          />
        }
      >
        <SummaryItem label={t("keywords.dateRelated.dates")} value={renderDateRange()} />
        <SummaryItem label={t("keywords.dateRelated.duration")} value={renderDuration()} />
        <SummaryItem label={t("keywords.screenRelated.screens")} value={renderScreenCount()} />
        <SummaryItem label={t("propertyTerms.properties")} value={renderPropertyCount()} />
        <SummaryItem label={t("keywords.campaignRelated.adLength")} value={renderAdLength()} />
        <SummaryItem label={renderRateCardLabel()} value={renderRateCard()} />
        <SummaryItem label={renderCurrentRateLabel()} value={renderCurrentRate()} />
        <SummaryItem label={t("keywords.campaignRelated.fullscreen")} value={renderFullScreen()} />
      </SummarySection>
    </>
  );
}

CampaignProposalSummary.defaultProps = {
  selectedCampaignProposalIndex: null,
};

CampaignProposalSummary.propTypes = {
  selectedCampaignProposalIndex: PropTypes.number,
};
