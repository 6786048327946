import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import NavDropdown from "react-bootstrap/NavDropdown";

class FilterDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  onToggle(isOpen, event, metadata) {
    const currentState = this.state.isOpen;

    if (event.currentTarget && event.currentTarget.hasAttribute("data-close-dropdown")) {
      this.state.isOpen = false;
    } else if (metadata?.source === "select") {
      this.state.isOpen = true;
    } else {
      this.state.isOpen = isOpen;
    }

    if (currentState !== this.state.isOpen) {
      this.forceUpdate();
    }
  }

  getClassName = () => {
    if (this.props.disabled) {
      return "disabled";
    }
    return "";
  };

  render() {
    const { children } = this.props;
    return (
      <NavDropdown
        show={this.state.isOpen}
        // taking out the bind breaks the FilterDropdown on mobile view
        // eslint-disable-next-line react/jsx-no-bind
        onToggle={this.onToggle.bind(this)}
        title={this.props.title}
        className={this.getClassName()}
      >
        {children}
      </NavDropdown>
    );
  }
}

export default withTranslation()(FilterDropdown);
