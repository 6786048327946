import { SET_AD_LIST, SET_SINGLE_AD, SET_AD_STATUS } from "../../../../../../App/actionTypes";

const INITIAL_STATE = {
  all: null,
  single: null,
  demo: null,
  status: {
    uploading: "inactive",
    opened: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AD_LIST:
      return { ...state, all: action.payload };
    case SET_SINGLE_AD:
      return { ...state, single: action.payload };
    case SET_AD_STATUS:
      return { ...state, status: action.payload };
    default:
      return state;
  }
};
