/*

ADVERTISER LIST

*/

import React, { Component } from "react";
import { connect } from "react-redux";
import Fuse from "fuse.js";

import withRouter from "@hooks/withRouter";
import Header from "@components/Interface/MainList/header";
import GeneralTable from "@components/Interface/MainList/generalTable";
import { fetchAdvertisers } from "@components/Advertiser/services/actions/advertiserActions";
import LoadingPage from "@components/Global/General/loadingPage";

class AdvertiserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
    };
    this.updateSearch = this.updateSearch.bind(this);
    this.goToClient = this.goToClient.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAdvertisers();
  }

  goToClient(advertiser) {
    this.props.router.navigate(`/advertisers/${advertiser.id}`);
  }

  updateSearch(event) {
    this.setState({ searchValue: event.target.value });
  }

  render() {
    if (!this.props.advertisers) {
      return <LoadingPage pageName="Advertisers" />;
    }

    const editTableForm = [
      {
        columnTitle: "",
        columnSize: "2%",
        fieldType: "padding",
      },
      {
        columnTitle: "Company",
        columnSize: "20%",
        fieldType: "textLink",
        hasValue: true,
        fieldValue: "name",
        linkAction: this.goToClient,
        extraCss: "",
      },
      {
        columnTitle: "Client",
        columnSize: "20%",
        fieldType: "text",
        fieldValue: "contact_name",
        extraCss: "",
      },
      {
        columnTitle: "Phone",
        columnSize: "15%",
        fieldType: "text",
        fieldValue: "contact_phone",
        extraCss: "",
      },
      {
        columnTitle: "Email",
        columnSize: "15%",
        fieldType: "text",
        fieldValue: "contact_email",
        extraCss: "",
      },
      {
        columnTitle: "Location",
        columnSize: "35%",
        fieldType: "text",
        fieldValue: "area1",
        extraCss: "",
      },

      {
        columnTitle: "",
        columnSize: "5%",
        fieldType: "edit",
        editAction: "/advertisers/edit/:id", // :id will get replaced in the General Table component
        deleteAction: null,
      },
    ];

    const tableFormat = editTableForm;

    const options = {
      keys: ["contact_name", "name", "contact_phone", "contact_email", "area1"],
      threshold: 0.1,
      ignoreLocation: true,
    };

    let searchResult = this.props.advertisers;

    if (this.state.searchValue.trim() !== "") {
      const fuse = new Fuse(searchResult, options); // "list" is the item array
      searchResult = fuse.search(this.state.searchValue);
      searchResult = searchResult.map((result) => result.item);
    }

    console.log(this.props);

    return (
      <div>
        <Header
          count={searchResult.length}
          title="Advertisers"
          newRoute="/advertisers/new"
          searchEnabled
          searchValue={this.state.searchValue}
          searchAction={this.updateSearch}
        />

        <GeneralTable tableData={searchResult} tableFormat={tableFormat} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    advertisers: state.advertisers.all,
  };
}

export default withRouter(connect(mapStateToProps, { fetchAdvertisers })(AdvertiserList));
