import React, { useState, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

function PropertyStep({ adInfo, adProperties, canSelectProperties, updatingLocalAdForm }) {
  const sortProperties = (properties) =>
    _.sortBy(properties, ["address_province", "municipality", "name"]);

  const sortedProperties = sortProperties(adProperties);
  const propertiesSelectedByAd = adProperties.filter((property) =>
    adInfo.selectedProperties.includes(property.id)
  );

  const [rowData] = useState(canSelectProperties ? sortedProperties : propertiesSelectedByAd);
  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 50,
      filter: true,
      floatingFilter: true,
      suppressHeaderMenuButton: true,
      sortable: true,
    }),
    []
  );
  const columnDefs = useMemo(
    () => [
      {
        field: "name",
        headerName: "Property",
        checkboxSelection: canSelectProperties,
        headerCheckboxSelection: canSelectProperties,
        lockPosition: true,
        maxWidth: 1200,
      },
      { field: "activeScreenCount", headerName: "Screens", maxWidth: 100 },
      { field: "property_type", headerName: "Type", maxWidth: 200 },
      { field: "municipality.name", headerName: "City", maxWidth: 300 },
      { field: "address_province", headerName: "Province", maxWidth: 250 },
      { field: "networkName", headerName: "Network", maxWidth: 200 },
      { field: "district.name", headerName: "District", maxWidth: 300 },
    ],
    [canSelectProperties]
  );

  const onFirstDataRendered = useCallback(
    (event) => {
      sizeColumnsByDefault(event.api);
      applySelectedProperties(event.api);
    },
    [applySelectedProperties]
  );

  const sizeColumnsByDefault = (gridApi) => {
    gridApi.sizeColumnsToFit();
  };

  const applySelectedProperties = useCallback(
    (gridApi) => {
      const selected = [];
      const deselected = [];
      gridApi.forEachNode((node) => {
        if (adInfo.selectedProperties.includes(node.data.id)) {
          selected.push(node);
        } else {
          deselected.push(node);
        }
      });
      gridApi.setNodesSelected({ nodes: selected, newValue: true });
      gridApi.setNodesSelected({ nodes: deselected, newValue: false });
    },
    [adInfo.selectedProperties]
  );

  const updateSelectedProperties = useCallback(
    (event) => {
      updatingLocalAdForm(
        "selectedProperties",
        event.api.getSelectedRows().map((row) => row.id),
        adInfo
      );
    },
    [adInfo, updatingLocalAdForm]
  );

  return (
    <div
      className="ad-step-form"
      style={{
        width: "100%",
        height: "500px",
        display: "flex",
        flexDirection: "column",
        padding: "8px 0",
      }}
    >
      <div className="header">Target Location</div>
      <div style={{ width: "100%", height: "100%" }} className="ag-theme-alpine">
        <AgGridReact
          rowData={rowData}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowSelection={canSelectProperties ? "multiple" : "none"}
          rowMultiSelectWithClick
          onFirstDataRendered={onFirstDataRendered}
          onSelectionChanged={updateSelectedProperties}
        />
      </div>
      {canSelectProperties && adInfo.selectedProperties.length === 0 && (
        <div className="error">** Make sure to select at least 1 property</div>
      )}
    </div>
  );
}

export default connect(null, null)(PropertyStep);
