import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withRouter from "@hooks/withRouter";
import { unstableHistory } from "@App/history";
import LoadingPage from "@components/Global/General/loadingPage";
import { isPropertyUser } from "@utilities/UserRoleHelper";

// Form
import PropertyContainer from "./propertyContainer";

// Actions
import {
  initiateEditPropertyForm,
  clearPropertyFormRelatedInfo,
} from "../services/actions/propertyFormActions";

class PmPropertyEdit extends Component {
  componentDidMount() {
    this.props.initiateEditPropertyForm(this.props.router.params.id);
  }

  componentWillUnmount() {
    this.props.clearPropertyFormRelatedInfo();
  }

  render() {
    if (
      isPropertyUser(this.props.loggedInUser.role) &&
      this.props.loggedInUser.properties.manager.length === 0
    ) {
      unstableHistory.push("/dashboard");
    }
    if (!this.props.propertyForm) {
      return <LoadingPage pageName="Property" />;
    }
    return <PropertyContainer isPropertyManager {...this.props} />;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      initiateEditPropertyForm,
      clearPropertyFormRelatedInfo,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    propertyForm: state.property.general.form,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PmPropertyEdit));
