import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import english from "./locales/en/common.json";
import french from "./locales/fr/common.json";

const detectionOptions = {
  order: ["cookie", "navigator", "localStorage", "querystring", "htmlTag"],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: ["en"],
    load: "current",
    detection: detectionOptions,
    resources: {
      fr: {
        translation: french,
      },
      en: {
        translation: english,
      },
    },
    interpolation: {
      escapeValue: false,
      format(value, format) {
        if (format === "uppercase") return value.toUpperCase();
        if (format === "lowercase") return value.toLowerCase();
        if (format === "capitalize") return value.charAt(0).toUpperCase() + value.slice(1);
        return value;
      },
    },
    nsSeparator: false,
  });

export default i18n;
