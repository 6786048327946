import React, { Component } from "react";
import PropTypes from "prop-types";

class Pill extends Component {
  render() {
    return (
      <div className={`pill pill--${this.props.type} ${this.props.extraClass}`}>
        {this.props.text}
      </div>
    );
  }
}

Pill.propTypes = {
  type: PropTypes.oneOf(["blue", "disabled", "green", "yellow"]).isRequired,
  text: PropTypes.string.isRequired,
  extraClass: PropTypes.string,
};

Pill.defaultProps = {
  extraClass: "",
};

export default Pill;
