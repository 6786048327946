import { JsonApiObjectFactory } from "../index";

export class JsonApiObject {
  constructor(apiObject, included) {
    this.attributes = apiObject.attributes;
    this.relationships = this._extractRelationships(apiObject.relationships);
    this.included = included;

    Object.entries(this.relationships).forEach(([relation, values]) => {
      this[relation] = () => this.getIncluded(values);
    });
  }

  getIncluded = (values) => {
    if (!this.included || values === null) {
      return null;
    }

    if (values instanceof Array) {
      const includedObjects = values
        .map((objectReference) =>
          this.included.find(
            (item) => item.id === objectReference.id && item.type === objectReference.type
          )
        )
        .filter(Boolean);
      return includedObjects.map((includedObject) =>
        JsonApiObjectFactory.createJsonApiObject(includedObject, this.included)
      );
    }

    if (!values.id || !values.type) return null;
    const includedObject = this.included.find(
      (item) => item.id === values.id && item.type === values.type
    );
    return JsonApiObjectFactory.createJsonApiObject(includedObject, this.included);
  };

  _extractRelationships(relationships) {
    if (relationships == null) {
      return {};
    }

    const relationshipMap = {};

    Object.keys(relationships).forEach((relationshipName) => {
      const originalRelationshipValue = relationships[relationshipName];

      if (Array.isArray(originalRelationshipValue)) {
        relationshipMap[relationshipName] = originalRelationshipValue.map(
          (item) => item.data || {}
        );
      } else {
        relationshipMap[relationshipName] = originalRelationshipValue.data || {};
      }
    });

    return relationshipMap;
  }
}
