import * as MAPSETTINGS from "./MapSettings";

const CLUSTER_PROPERTIES = {
  source: MAPSETTINGS.MAPDATA,
  filter: ["has", "point_count"],
};

export function setSourceLayer(source) {
  const sourceLayer = {
    type: "geojson",
    cluster: true,
    clusterProperties: {
      sum: [
        ["+", ["accumulated"], ["get", "sum"]],
        ["get", source],
      ],
    },
    data: {
      type: "FeatureCollection",
      features: [],
    },
  };
  return sourceLayer;
}

export const CLUSTER_LAYER = {
  ...CLUSTER_PROPERTIES,
  id: "cluster-layer",
  type: "circle",
  paint: {
    "circle-color": MAPSETTINGS.CLUSTER_COLOR,
    "circle-radius": ["step", ["get", "sum"], 25, 50, 30, 100, 35],
  },
};

export const CLUSTER_COUNT_LAYER = {
  ...CLUSTER_PROPERTIES,
  id: "cluster-count-layer",
  type: "symbol",
  layout: {
    "text-field": "{sum}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 12,
  },
  paint: {
    "text-color": "#fff",
  },
};

export const MARKER_LAYER = {
  id: "marker-layer",
  type: "symbol",
  source: MAPSETTINGS.MAPDATA,
  layout: {
    "icon-image": MAPSETTINGS.DEFAULT_MARKER,
    "icon-size": MAPSETTINGS.MARKER_SIZE,
  },
};
