class MapService {
  static convertPropertiesToGeoJsonFormat = (properties) => {
    const geoJsonFormat = {
      type: "FeatureCollection",
      features: [],
    };
    if (properties.length == 0) {
      return geoJsonFormat;
    }
    properties.forEach(function (item) {
      const geoJsonItem = {
        type: "feature",
        properties: item.attributes,
        geometry: {
          type: "Point",
          coordinates: [item.attributes.longitude, item.attributes.latitude],
        },
      };
      geoJsonFormat.features.push(geoJsonItem);
    });
    return geoJsonFormat;
  };
}

export default MapService;
