import { Actions, Resources } from "../../components/User/Services/permissionService";

import NotFound from "../../components/Global/General/pageNotFound";
import Dashboard from "../../components/Dashboard/views/mainDashboard";
import Advertisers from "../../components/Advertiser/views/advertiserList";
import AdvertiserForm from "../../components/Advertiser/views/advertiserForm";
import AdvertiserSingle from "../../components/Advertiser/views/advertiserSingle";

import PropertyList from "../../components/Property/views/propertyList";
import PropertyEdit from "../../components/Property/views/propertyEdit";
import PropertySingle from "../../components/Property/views/propertySingle";

import AccountSettings from "../../components/User/Personal/views/accountSettings";

import UserList from "../../components/User/General/views/userList";
import UserForm from "../../components/User/General/views/userForm";

import ScreenHealthDashboard from "../../components/Tech/views/ScreenHealthDashboard";

import PropertyRevenueShareReportGenerator from "../../components/PropertyRevenueShare/views/PropertyRevenueShareReportGenerator";

// Property Manager Related
import RssFeeds from "../../components/Client/RssFeed/views/rssFeedList";
import RssFeedForm from "../../components/Client/RssFeed/views/rssFeedForm";
import SideNotice from "../../components/Client/SideNotice/views/sideNoticeList";
import SideNoticeForm from "../../components/Client/SideNotice/views/sideNoticeForm";
import MainFeedList from "../../components/Client/MainFeed/views/mainFeedList";
import BigNoticeFormContainer from "../../components/Client/MainFeed/views/BigNoticeFormContainer";
import PmPropertyEdit from "../../components/Property/views/pmPropertyEdit";

// Campaign Related
import CampaignList from "../../components/Campaign/GeneralView/views/campaignList";
import CampaignMonthlyRevenueReport from "../../components/Campaign/Revenue/views/monthlyRevenueReport";

import CampaignGroupList from "../../components/CampaignGroup/views/CampaignGroupList";
import CampaignGroupEdit from "../../components/CampaignGroup/views/CampaignGroupEdit";
import CampaignEdit from "../../components/Campaign/views/CampaignEdit";
import AdEdit from "../../components/Campaign/views/AdEdit";

import ProposalPage from "../../components/Proposal/ProposalPage";
import ProposalListPage from "../../components/Proposal/ProposalListPage";

import PropertyContentContainer from "../../components/Property/views/PropertyContentContainer";

const PRIVATE_ROUTE_LIST = [
  {
    routes: [
      {
        path: "*",
        component: NotFound,
        key: "not-found",
      },
      {
        path: "/dashboard",
        component: Dashboard,
      },
      {
        path: "/account-settings",
        component: AccountSettings,
      },
    ],
  },
  {
    resource: Resources.ADVERTISERS,
    routes: [
      {
        path: "/advertisers",
        component: Advertisers,
        action: Actions.LIST,
      },
      {
        path: "/advertisers/new",
        component: AdvertiserForm,
        key: "add-advertiser",
        action: Actions.CREATE,
      },
      {
        path: "/advertisers/edit/:id",
        component: AdvertiserForm,
        key: "edit-advertiser",
        action: Actions.UPDATE,
      },
      {
        path: "/advertisers/:id",
        component: AdvertiserSingle,
        type: "Advertisers",
        action: Actions.READ,
      },
    ],
  },
  {
    resource: Resources.PROPERTY_CONTRACT_DETAILS,
    routes: [
      {
        path: "/properties/edit/:id/*",
        component: PropertyEdit,
        key: "edit-property",
        action: Actions.UPDATE,
      },
    ],
  },
  {
    resource: Resources.PROPERTIES,
    routes: [
      {
        path: "/properties",
        component: PropertyList,
        action: Actions.LIST,
      },
      {
        path: "/my_property/edit/:id/*",
        component: PmPropertyEdit,
        key: "edit-property",
        action: Actions.UPDATE,
      },
      {
        path: "/properties/:id",
        component: PropertySingle,
        action: Actions.READ,
      },
      {
        path: "/property_content/:id",
        component: PropertyContentContainer,
        action: Actions.READ,
      },
    ],
  },
  {
    resource: Resources.PROPERTY_REVENUE,
    routes: [
      {
        path: "/property-revenue-share-report-generator",
        component: PropertyRevenueShareReportGenerator,
        action: Actions.UPDATE,
      },
    ],
  },
  {
    resource: Resources.USERS,
    routes: [
      {
        path: "/users",
        component: UserList,
        action: Actions.LIST,
      },
      {
        path: "/user/new",
        component: UserForm,
        action: Actions.CREATE,
      },
      {
        path: "/user/edit/:id",
        component: UserForm,
        key: "edit-user",
        action: Actions.UPDATE,
      },
    ],
  },
  {
    resource: Resources.FEEDS,
    routes: [
      {
        path: "/rss-feeds",
        component: RssFeeds,
        action: Actions.LIST,
      },
      {
        path: "/rss-feeds/new",
        component: RssFeedForm,
        key: "create-rss-feed",
        action: Actions.CREATE,
      },
      {
        path: "/rss-feeds/edit/:id",
        component: RssFeedForm,
        key: "edit-rss-feed",
        action: Actions.UPDATE,
      },
    ],
  },
  {
    resource: Resources.BIG_NOTICES,
    routes: [
      {
        path: "/bignotice",
        component: MainFeedList,
        action: Actions.LIST,
      },
      {
        path: "/bignotice/new",
        component: BigNoticeFormContainer,
        key: "create-bignotice",
        action: Actions.CREATE,
      },
      {
        path: "/bignotice/edit/:id",
        component: BigNoticeFormContainer,
        key: "edit-bignotice",
        action: Actions.UPDATE,
      },
    ],
  },
  {
    resource: Resources.CAMPAIGNS,
    routes: [
      // Old "campaign" routes temporarily maintained so URLs already provided to users still function
      // Internal navigation in these components all uses the new "flight" routes
      {
        path: "/campaigns",
        component: CampaignList,
        action: Actions.UPDATE,
      },
      {
        path: "/campaign/monthly_revenue_report",
        component: CampaignMonthlyRevenueReport,
        key: "campaign-monthly-revenue-report",
        action: Actions.UPDATE,
      },
      {
        path: "/campaign_groups",
        component: CampaignGroupList,
        action: Actions.UPDATE,
      },
      {
        path: "/campaign_group/edit/:id",
        component: CampaignGroupEdit,
        action: Actions.UPDATE,
      },
      {
        path: "/flights",
        component: CampaignList,
        action: Actions.UPDATE,
      },
      {
        path: "/flight/edit/:id",
        component: CampaignEdit,
        action: Actions.UPDATE,
      },
      {
        path: "/flight/ad/edit/:id",
        component: AdEdit,
        key: "edit-ad",
        action: Actions.UPDATE,
      },
      {
        path: "/flight/ad/new/:id",
        component: AdEdit,
        key: "new-ad",
        action: Actions.UPDATE,
      },
    ],
  },
  {
    resource: Resources.CAMPAIGN_GROUP_PROPOSALS,
    routes: [
      {
        path: "/campaign-proposal/edit/:id",
        component: ProposalPage,
        key: "campaign-proposal",
        action: Actions.UPDATE,
      },
      {
        path: "/campaign-proposal/new",
        component: ProposalPage,
        key: "campaign-proposal-new",
        action: Actions.UPDATE,
      },
      {
        path: "/campaign-proposals",
        component: ProposalListPage,
        key: "campaign-proposal-list",
        action: Actions.UPDATE,
      },
    ],
  },
  {
    resource: Resources.NOTICES,
    routes: [
      {
        path: "/sidenotice",
        component: SideNotice,
        action: Actions.LIST,
      },
      {
        path: "/sidenotice/new",
        component: SideNoticeForm,
        key: "create-sidenotice",
        action: Actions.CREATE,
      },
      {
        path: "/sidenotice/edit/:id",
        component: SideNoticeForm,
        key: "edit-sidenotice",
        action: Actions.UPDATE,
      },
    ],
  },
  {
    resource: Resources.SCREENS,
    routes: [
      {
        path: "/admin/screen_health_dashboard",
        component: ScreenHealthDashboard,
        key: "screen-health-dashboard",
        action: Actions.LIST,
      },
    ],
  },
];

export default PRIVATE_ROUTE_LIST;
