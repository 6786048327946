const REPORT = {
  name: "pageNames.report",
  icon: "fa-tachometer",
  url: "report",
};

const LOCATION = {
  name: "pageNames.location",
  icon: "fa-map-marker",
  url: "location",
};

const itemList = () => [REPORT, LOCATION];

export default itemList;
