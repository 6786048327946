import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { withTranslation } from "react-i18next";

class TargetingTimeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAMIsActive: true,
      selectPMIsActive: true,
    };

    this.handleDayClick = this.handleDayClick.bind(this);
    this.handlePeriodClick = this.handlePeriodClick.bind(this);
    this.handleAMClick = this.handleAMClick.bind(this);
    this.handlePMClick = this.handlePMClick.bind(this);

    this.renderToggle = this.renderToggle.bind(this);
    this.renderDayOfWeek = this.renderDayOfWeek.bind(this);
    this.renderTime = this.renderTime.bind(this);
  }

  toggleAMSelectState = () => {
    this.setState({ selectAMIsActive: !this.state.selectAMIsActive });
  };

  togglePMSelectState = () => {
    this.setState({ selectPMIsActive: !this.state.selectPMIsActive });
  };

  renderTimeSelection = (timeType) => {
    const { t } = this.props;

    const timeList = _.filter(this.props.periodsOfDay, { type: timeType });
    const timeAction = timeType === "AM" ? this.handleAMClick : this.handlePMClick;
    const timeState = timeType === "AM" ? this.state.selectAMIsActive : this.state.selectPMIsActive;
    return (
      <Row className="g-0">
        {timeList.map(this.renderTime)}
        <Col sm={4} md="auto" className="me-1 pt-1">
          <Button size="sm" variant="outline-primary" onClick={timeAction} key={timeType}>
            {timeState
              ? `${t("keywords.buttonActions.deselectAll")} ${timeType}`
              : `${t("keywords.buttonActions.selectAll")} ${timeType}`}
          </Button>
        </Col>
      </Row>
    );
  };

  updateButtonToggleState(timeType, periodsOfDay) {
    const selectedPeriods = _.filter(periodsOfDay, { type: timeType, present: true });
    const timeState = timeType === "AM" ? "selectAMIsActive" : "selectPMIsActive";
    if (selectedPeriods.length === 12) {
      this.setState({ [timeState]: true });
    } else if (selectedPeriods.length === 0) {
      this.setState({ [timeState]: false });
    }
  }

  handleDayClick(clickedItem) {
    const daysOfWeek = _.cloneDeep(this.props.daysOfWeek);
    const periodsOfDay = _.cloneDeep(this.props.periodsOfDay);

    const item = _.find(daysOfWeek, (i) => i.value === clickedItem.value);
    item.present = !item.present;

    this.props.onTimeChange(daysOfWeek, periodsOfDay);
  }

  handlePeriodClick(clickedItem) {
    const daysOfWeek = _.cloneDeep(this.props.daysOfWeek);
    const periodsOfDay = _.cloneDeep(this.props.periodsOfDay);

    const item = _.find(periodsOfDay, (i) => i.value === clickedItem.value);
    item.present = !item.present;

    // check if either all of AM/PM is selected/deselected and updated the toggle button
    this.updateButtonToggleState(clickedItem.type, periodsOfDay);

    this.props.onTimeChange(daysOfWeek, periodsOfDay);
  }

  handleAMClick() {
    const daysOfWeek = _.cloneDeep(this.props.daysOfWeek);
    const periodsOfDay = _.cloneDeep(this.props.periodsOfDay);

    this.toggleTimeGroup(_.filter(periodsOfDay, { type: "AM" }));

    this.props.onTimeChange(daysOfWeek, periodsOfDay);
    this.toggleAMSelectState();
  }

  handlePMClick() {
    const daysOfWeek = _.cloneDeep(this.props.daysOfWeek);
    const periodsOfDay = _.cloneDeep(this.props.periodsOfDay);

    this.toggleTimeGroup(_.filter(periodsOfDay, { type: "PM" }));

    this.props.onTimeChange(daysOfWeek, periodsOfDay);
    this.togglePMSelectState();
  }

  toggleTimeGroup(group) {
    const shouldDisableAll = _.some(group, "present");

    group.forEach((item) => {
      item.present = !shouldDisableAll;
    });
  }

  renderTime(item) {
    return this.renderToggle(item, this.handlePeriodClick);
  }

  renderDayOfWeek(item) {
    return this.renderToggle(item, this.handleDayClick);
  }

  renderToggle(item, changeHandler) {
    const { t } = this.props;
    const variant = item.present ? "primary" : "outline-primary";
    return (
      <Col sm={4} md="auto" key={item.value} className="me-1 pt-1">
        <Button size="sm" variant={variant} onClick={changeHandler.bind(this, item)}>
          {t(item.description)}
          {item.type}
        </Button>
      </Col>
    );
  }

  render() {
    const AMTimeTargetSection = this.renderTimeSelection("AM");
    const PMTimeTargetSection = this.renderTimeSelection("PM");
    const { t, errors } = this.props;

    const timeEachDayError = (errors && errors.type === "periodsOfDay") || false;
    const daysOfWeekError = (errors && errors.type === "daysOfWeek") || false;

    return (
      <div>
        <div className={`form-group ${timeEachDayError ? "error-border" : ""}`}>
          <div className="subtitle">
            <h6>{t("keywords.dateRelated.timeEachDay")}</h6>
          </div>
          {AMTimeTargetSection}
          <hr />
          {PMTimeTargetSection}
        </div>

        <div className={`form-group ${daysOfWeekError ? "error-border" : ""}`}>
          <div className="subtitle">
            <h6>{t("keywords.dateRelated.daysOfWeek.name")}</h6>
          </div>
          <Row className="g-0">{this.props.daysOfWeek.map(this.renderDayOfWeek)}</Row>
        </div>
      </div>
    );
  }
}

TargetingTimeSelector.defaultProps = {
  errors: null,
};

TargetingTimeSelector.propTypes = {
  daysOfWeek: PropTypes.arrayOf(PropTypes.any).isRequired,
  periodsOfDay: PropTypes.arrayOf(PropTypes.any).isRequired,
  onTimeChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default withTranslation()(TargetingTimeSelector);
