import Elev8ApiService from "../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../lib/services/Elev8Api/lib";

const AdvertiserSerializer = {
  formData(obj = null) {
    return apiObjectToFormData(obj);
  },
  apiObject(data = null) {
    return formDataToApiObject(data);
  },
};

const ADVERTISER_FORM_DEFAULTS = {
  name: "",
  contact_name: "",
  contact_email: "",
  contact_phone: "",
  contact_address_line1: "",
  contact_address_line2: "",
  contact_postal_code: "",
  contact_city: "",
  contact_province: "",
  contact_country: "Canada",
  comments: "",
};

function apiObjectToFormData(obj) {
  if (!obj) return { ...ADVERTISER_FORM_DEFAULTS };

  const { attributes } = obj;
  return {
    id: attributes.id,
    name: attributes.name,
    contact_name: attributes.contact_name,
    contact_email: attributes.contact_email,
    contact_phone: attributes.contact_phone,
    contact_address_line1: attributes.contact_address_line1,
    contact_address_line2: attributes.contact_address_line2,
    contact_postal_code: attributes.contact_postal_code,
    contact_city: attributes.contact_city,
    contact_province: attributes.contact_province,
    contact_country: attributes.contact_country,
    comments: attributes.comments,
  };
}

function formDataToApiObject(formData) {
  const providedAttributes = Object.fromEntries(
    Object.entries(formData).filter(([_, value]) => !!value)
  );
  const relationships = {};
  const attributes = {
    name: providedAttributes.name,
    contact_name: providedAttributes.contact_name,
    contact_email: providedAttributes.contact_email,
    contact_phone: providedAttributes.contact_phone,
    contact_address_line1: providedAttributes.contact_address_line1,
    contact_address_line2: providedAttributes.contact_address_line2,
    contact_postal_code: providedAttributes.contact_postal_code,
    contact_city: providedAttributes.contact_city,
    contact_province: providedAttributes.contact_province,
    contact_country: providedAttributes.contact_country,
    comments: providedAttributes.comments,
  };

  return Elev8ApiService.setToJsonApiFormat(
    ApiTypes.advertiser.type,
    formData.id,
    attributes,
    relationships
  );
}

export default AdvertiserSerializer;
