import Dropzone from "react-dropzone";
import Rodal from "rodal";

import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";

import uploadIcon from "../../../../../style/images/upload-2.png";

class SimpleImageUploader extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      showErrorModal: false,
    };
  }

  handleDrop = (files) => {
    const file = files[0];

    if (!file || file.size > this.props.fileMaxSize) {
      this.setState({
        showErrorModal: true,
      });
    } else {
      this.props.onFileUpload(file);
    }
  };

  handleClose = () => {
    this.setState({ showErrorModal: false });
  };

  render() {
    const { file, preview, t } = this.props;
    const dropzoneRef = createRef();

    return (
      <>
        <Dropzone
          className="simple-image-uploader"
          activeClassName="simple-image-uploader--active"
          rejectClassName="simple-image-uploader--rejectFile"
          onDrop={this.handleDrop}
          accept={{
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
          }}
          ref={dropzoneRef}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <i className="simple-image-uploader__icon fa fa-plus-circle" />
              {file ? (
                <img alt="preview" src={preview} />
              ) : (
                <>
                  <img
                    alt="upload"
                    src={uploadIcon}
                    style={{
                      width: "48px",
                      height: "48px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                      marginTop: "20%",
                    }}
                  />
                  <p className="u-text-small text-center">
                    {t("keywords.fileRelated.imageRelated.upload")}
                  </p>
                </>
              )}
            </div>
          )}
        </Dropzone>
        <Rodal visible={this.state.showErrorModal} onClose={this.handleClose} showCloseButton>
          <h4>{t("keywords.errorRelated.error")}</h4>
          <hr />
          <p>
            <strong>{t("keywords.fileRelated.acceptedTypes")}</strong> .png, .jpg, .jpeg
          </p>
          <p>
            <strong>{t("keywords.fileRelated.maxSize")}:</strong> {this.props.fileSizeText}
          </p>
        </Rodal>
      </>
    );
  }
}

export default withTranslation()(SimpleImageUploader);
