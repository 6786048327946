// React stuff
import _ from "lodash";
import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import withRouter from "@hooks/withRouter";

// Custom
import { Row, Col, Container, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import {
  initiateUserForm,
  fetchUserForm,
  editUser,
  updateUserForm,
  createNewUser,
  clearUserForm,
} from "@components/User/General/services/actions/generalUserActions";
import { fetchPropertiesForUserForm } from "@components/Property/services/actions/propertyActions";
import { addFlashMessage } from "@components/Interface/General/services/actions/applicationStateActions";

import { FORMSIZE } from "@components/Global/Widgets/formConstants";
import { isPropertyUser } from "@utilities/UserRoleHelper";
import Header from "@components/Interface/MainList/header";

// Templates
import SubmitButtonForm from "@components/Global/Widgets/views/submitButtonForm";
import TextBox from "@components/Global/Widgets/views/textBox";
import DropDownWidget from "@components/Global/Widgets/views/dropDown";
import TableSelector from "@components/Global/Widgets/views/tableSelector";
import CollapsibleCard from "@components/Global/Widgets/views/collapsibleCard";
import BasicLayout from "@components/Global/Layouts/basic_layout";
import { unstableHistory } from "@App/history";
import { LANGUAGE_OPTIONS } from "@locales/reference";

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formMode: "",
      password: "",
      current_password: "",
      formColor: "rgb(49, 50, 80)",
      titleColor: "rgb(49, 50, 80)",
      headerColor: "#fff",
      buttonColor: "red",
      filterManagerAssociated: false,
      filterMemberAssociated: false,
    };
    this.deleteAction = this.deleteAction.bind(this);
    this.submitAction = this.submitAction.bind(this);
    this.checkValid = this.checkValid.bind(this);
    this.selectResidentSingle = this.selectResidentSingle.bind(this);
    this.selectResidentAll = this.selectResidentAll.bind(this);
    this.deselectResidentAll = this.deselectResidentAll.bind(this);
    this.selectPMSingle = this.selectPMSingle.bind(this);
    this.selectPMAll = this.selectPMAll.bind(this);
    this.deselectPMAll = this.deselectPMAll.bind(this);
    this.selectMemberSingle = this.selectMemberSingle.bind(this);
    this.selectMemberAll = this.selectMemberAll.bind(this);
    this.deselectMemberAll = this.deselectMemberAll.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.updateCurrentPassword = this.updateCurrentPassword.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (
      isPropertyUser(this.props.loggedInUser.role) &&
      this.props.loggedInUser.properties.manager.length === 0
    ) {
      unstableHistory.push("/dashboard");
    }

    if (this.props.loggedInUser.role === "admin") {
      this.setState({
        filterManagerAssociated: true,
        filterMemberAssociated: true,
      });
    }

    const currentRoute = this.props.router.location.pathname;
    if (currentRoute === "/user/new") {
      this.props.initiateUserForm(this.props.loggedInUser.role);
      this.setState({ formMode: "Create" });
      this.state.passwordRequired = false;
    } else {
      this.setState({ formMode: "Edit" });
      this.props.fetchUserForm(this.props.router.params.id);
      this.state.passwordRequired = false;
    }

    this.props.fetchPropertiesForUserForm();
  }

  componentWillUnmount() {
    this.props.clearUserForm();
  }

  getPropertyTableConfiguration = () => {
    const { t } = this.props;

    const propertyTable = [
      {
        field: "name",
        size: "20%",
        label: t("keywords.propertyRelated.name"),
        type: "Link",
        linkAction: this.moreInfo,
      },
      { field: "networkName", size: "20%", label: t("networkTerms.network"), type: "" },
      { field: "area1", size: "20%", label: t("keywords.locationRelated.name"), type: "" },
    ];
    if (this.isPropertyUserLoggedIn()) {
      return propertyTable;
    }
    propertyTable.push(
      { field: "contact_name", size: "15%", label: t("keywords.userRelated.owner"), type: "" },
      { field: "property_type", size: "10%", label: t("keywords.general.type"), type: "" }
    );
    return propertyTable;
  };

  updateCurrentPassword(newData) {
    this.setState({ current_password: newData });
  }

  checkValid() {
    const isValidContactDetails = this.validContactDetails();
    const isValidRole = this.validRole();
    const isValidPropertyConfiguration = this.validProperties();
    const isValidPropertyAssociation = this.validPropertyAssociation();

    if (!isValidContactDetails) {
      this.props.addFlashMessage("danger", "keywords.userRelated.contact.missing");
    }

    if (!isValidRole) {
      this.props.addFlashMessage("danger", "keywords.userRelated.role.missing");
    }

    if (!isValidPropertyConfiguration) {
      this.props.addFlashMessage("danger", "userForm.propertyToUserError");
    }

    if (!isValidPropertyAssociation) {
      this.props.addFlashMessage("danger", "userForm.atleast1PropertyError");
    }

    return (
      isValidContactDetails &&
      isValidPropertyConfiguration &&
      isValidRole &&
      isValidPropertyAssociation
    );
  }

  validProperties() {
    const { member, manager } = this.props.userForm.properties;
    const sameProperty = manager.filter((n) => member.includes(n));

    return sameProperty.length === 0;
  }

  validPropertyAssociation() {
    const noPropertiesPresent = Object.values(this.props.userForm.properties).every(
      (o) => o.length === 0
    );
    if (isPropertyUser(this.props.userForm.role) && noPropertiesPresent) {
      return false;
    }
    return true;
  }

  validContactDetails() {
    return this.props.userForm.first_name.trim() !== "" && this.props.userForm.email.trim() !== "";
  }

  validRole() {
    return !!this.props.userForm.role;
  }

  deleteAction() {
    this.props.deleteUser(this.props.userForm.id);
  }

  submitAction() {
    // check validation
    const validated = this.checkValid();

    // editing user
    if (this.props.userForm.id && validated) {
      this.props.editUser(this.props.userForm, this.state.password, this.state.current_password);

      // creating user
    } else if (validated) {
      this.props.createNewUser(this.props.userForm);
    }
  }

  selectResidentSingle(row, isSelected) {
    if (isSelected) {
      let currProperties = this.props.userForm.properties.resident;
      currProperties = [...currProperties, row.id];
      this.props.updateUserForm("residentProperties", currProperties, this.props.userForm);
    } else {
      let currProperties = this.props.userForm.properties.resident;
      currProperties = _.without(currProperties, row.id);
      // remove a property
      this.props.updateUserForm("residentProperties", currProperties, this.props.userForm);
    }
  }

  selectMemberAll() {
    // get ids of all properties
    const selectedProperties = _.map(this.props.properties, "id");
    this.props.updateUserForm("memberProperties", selectedProperties, this.props.userForm);
  }

  deselectMemberAll() {
    this.props.updateUserForm("memberProperties", [], this.props.userForm);
  }

  selectMemberSingle(row, isSelected) {
    if (isSelected) {
      // add a new property
      let currProperties = this.props.userForm.properties.member;
      currProperties = [...currProperties, row.id];
      this.props.updateUserForm("memberProperties", currProperties, this.props.userForm);
    } else {
      let currProperties = this.props.userForm.properties.member;
      currProperties = _.without(currProperties, row.id);
      // remove a property
      this.props.updateUserForm("memberProperties", currProperties, this.props.userForm);
    }
  }

  selectResidentAll() {
    // get ids of all properties
    const selectedProperties = _.map(this.props.properties, "id");
    this.props.updateUserForm("residentProperties", selectedProperties, this.props.userForm);
  }

  deselectResidentAll() {
    this.props.updateUserForm("residentProperties", [], this.props.userForm);
  }

  selectPMSingle(row, isSelected) {
    if (isSelected) {
      // add a new property
      let currProperties = this.props.userForm.properties.manager;
      currProperties = [...currProperties, row.id];

      this.props.updateUserForm("pmProperties", currProperties, this.props.userForm);
    } else {
      let currProperties = this.props.userForm.properties.manager;
      currProperties = _.without(currProperties, row.id);
      // remove a property
      this.props.updateUserForm("pmProperties", currProperties, this.props.userForm);
    }
  }

  toggleFilterManagerProperties() {
    this.setState((prevState) => ({ filterManagerAssociated: !prevState.filterManagerAssociated }));
  }

  toggleFilterMemberProperties() {
    this.setState((prevState) => ({ filterMemberAssociated: !prevState.filterMemberAssociated }));
  }

  filterManagerProperties(properties) {
    if (this.state.filterManagerAssociated) {
      return properties.filter((property) =>
        this.props.userForm.properties.manager.some(
          (managerPropertyId) => managerPropertyId === property.id
        )
      );
    }
    return properties;
  }

  filterMemberProperties(properties) {
    if (this.state.filterMemberAssociated) {
      return properties.filter((property) =>
        this.props.userForm.properties.member.some(
          (memberPropertyId) => memberPropertyId === property.id
        )
      );
    }
    return properties;
  }

  allAvailableProperties() {
    let properties = null;

    if (this.props.loggedInUser.role === "admin") {
      properties = this.props.properties;
    } else {
      properties = this.props.properties.filter(
        (p) => this.props.loggedInUser.properties.manager.indexOf(p.id) !== -1
      );
    }

    return properties;
  }

  selectPMAll() {
    // get ids of all properties
    const selectedProperties = _.map(this.props.properties, "id");
    this.props.updateUserForm("pmProperties", selectedProperties, this.props.userForm);
  }

  deselectPMAll() {
    this.props.updateUserForm("pmProperties", [], this.props.userForm);
  }

  updatePassword(newData) {
    this.setState({ password: newData });
  }

  isPropertyUserLoggedIn() {
    return isPropertyUser(this.props.loggedInUser.role);
  }

  renderContactInfo() {
    const border = this.validContactDetails() ? null : "danger";
    const { t } = this.props;

    return (
      <CollapsibleCard
        header={t("keywords.userRelated.contact.name")}
        border={border}
        defaultExpanded
      >
        <Container fluid>
          <Row>
            <Col xs="12" md="6" lg="3">
              <TextBox
                textValue={this.props.userForm.first_name}
                updateForm={this.props.updateUserForm}
                formPropertyName="first_name"
                description={t("keywords.userRelated.firstName.name")}
                placeHolder={t("keywords.userRelated.firstName.name")}
                inputType="text"
                formColor={this.state.formColor}
                formProps={this.props.userForm}
                formWidth={FORMSIZE.fourth}
                maxLength={50}
                required
                hideWordCount
              />
            </Col>
            <Col xs="12" md="6" lg="3">
              <TextBox
                textValue={this.props.userForm.last_name}
                updateForm={this.props.updateUserForm}
                formPropertyName="last_name"
                description={t("keywords.userRelated.lastName.name")}
                placeHolder={t("keywords.userRelated.lastName.name")}
                inputType="text"
                formColor={this.state.formColor}
                formProps={this.props.userForm}
                formWidth={FORMSIZE.fourth}
                maxLength={50}
                hideWordCount
              />
            </Col>
            <Col xs="12" md="6" lg="3">
              <TextBox
                textValue={this.props.userForm.email}
                updateForm={this.props.updateUserForm}
                formPropertyName="email"
                description={t("keywords.userRelated.email.name")}
                placeHolder={t("keywords.userRelated.email.name")}
                inputType="email"
                formColor={this.state.formColor}
                formProps={this.props.userForm}
                formWidth={FORMSIZE.fourth}
                maxLength={50}
                required
                hideWordCount
              />
            </Col>
            <Col xs="12" md="6" lg="3">
              <TextBox
                textValue={this.props.userForm.phone}
                updateForm={this.props.updateUserForm}
                formPropertyName="phone"
                description={t("keywords.userRelated.phone.name")}
                placeHolder={t("keywords.userRelated.phone.name")}
                inputType="tel"
                formColor={this.state.formColor}
                formProps={this.props.userForm}
                formWidth={FORMSIZE.fourth}
                maxLength={50}
                hideWordCount
              />
            </Col>
          </Row>
        </Container>
      </CollapsibleCard>
    );
  }

  renderPermissions() {
    if (isPropertyUser(this.props.loggedInUser.role)) {
      return null;
    }

    const { t } = this.props;

    const roleOptions = [
      {
        value: "admin",
        label: t("keywords.userRelated.userTypes.adminDefinition"),
      },
      {
        value: "account_manager",
        label: t("keywords.userRelated.userTypes.staffDefinition"),
      },
      {
        value: "non_vc_property_user",
        label: t("keywords.userRelated.userTypes.nonVCPropertyUser"),
      },
      {
        value: "property_user",
        label: t("keywords.userRelated.userTypes.vcPropertyUser"),
      },
      {
        value: "rsm_sales_associate",
        label: t("keywords.userRelated.userTypes.rsmSalesAssociate"),
      },
      {
        value: "no_access",
        label: t("keywords.userRelated.userTypes.noAccess"),
      },
    ];

    const border = this.props.userForm.role ? "" : "danger";

    return (
      <CollapsibleCard header={t("keywords.userRelated.role.name")} border={border} defaultExpanded>
        <DropDownWidget
          formValue={this.props.userForm.role || ""}
          formPropertyName="role"
          currentForm={this.props.userForm}
          galleryColor={this.state.formColor}
          formWidth={FORMSIZE.fourth}
          updateForm={this.props.updateUserForm}
          multiSelect={false}
          title={t("keywords.userRelated.role.name")}
          options={roleOptions}
          formType="Custom"
          isClearable={false}
          required
        />
      </CollapsibleCard>
    );
  }

  renderPropertySelectors() {
    const { t } = this.props;

    const propertyTable = this.getPropertyTableConfiguration();
    const isValidPropertyConfig = this.validProperties();
    const border = isValidPropertyConfig ? null : "danger";
    let errorMessage = null;
    if (!isValidPropertyConfig) {
      errorMessage = <div className="error">{t("userForm.propertyToUserError")}</div>;
    }

    const availableManagerProperties = this.filterManagerProperties(this.allAvailableProperties());
    const availableMemberProperties = this.filterMemberProperties(this.allAvailableProperties());

    return (
      <>
        <CollapsibleCard
          header={t("userForm.propertyManagerAt")}
          subheader={t("userForm.propertyManagerDescription")}
          border={border}
          defaultExpanded
        >
          {errorMessage}
          <ToggleButtonGroup
            type="checkbox"
            value={!this.state.filterManagerAssociated}
            onChange={this.toggleFilterManagerProperties.bind(this, true)}
          >
            <ToggleButton id="show-all-pm" size="sm" variant="outline-primary" value>
              Show all
            </ToggleButton>
            <ToggleButton id="show-assigned-pm" size="sm" variant="outline-primary" value={false}>
              Show assigned
            </ToggleButton>
          </ToggleButtonGroup>
          <TableSelector
            tableData={availableManagerProperties}
            tableFormat={propertyTable}
            tableType="checkbox"
            selected={this.props.userForm.properties.manager}
            onSelectAction={this.selectPMSingle}
            selectAll={this.selectPMAll}
            deselectAll={this.deselectPMAll}
            tableColor={this.state.formColor}
          />
        </CollapsibleCard>

        <CollapsibleCard
          header={t("userForm.boardMemberAt")}
          subheader={t("userForm.boardMemberDescription")}
          border={border}
          defaultExpanded
        >
          {errorMessage}
          <ToggleButtonGroup
            type="checkbox"
            value={!this.state.filterMemberAssociated}
            onChange={this.toggleFilterMemberProperties.bind(this, true)}
          >
            <ToggleButton id="show-all-bm" size="sm" variant="outline-primary" value>
              Show all
            </ToggleButton>
            <ToggleButton id="show-assigned-bm" size="sm" variant="outline-primary" value={false}>
              Show assigned
            </ToggleButton>
          </ToggleButtonGroup>
          <TableSelector
            tableData={availableMemberProperties}
            tableFormat={propertyTable}
            tableType="checkbox"
            selected={this.props.userForm.properties.member}
            onSelectAction={this.selectMemberSingle}
            selectAll={this.selectMemberAll}
            deselectAll={this.deselectMemberAll}
            tableColor={this.state.formColor}
          />
        </CollapsibleCard>
      </>
    );
  }

  renderUserLanguage = () => {
    const { t } = this.props;
    const options = LANGUAGE_OPTIONS.map((option) => ({
      value: option.value,
      label: t(option.label),
    }));
    return (
      <CollapsibleCard header={t("language.siteLanguage")} border defaultExpanded>
        <Container fluid>
          <Row>
            <Col xs="12">
              <DropDownWidget
                formValue={this.props.userForm.locale}
                formPropertyName="locale"
                currentForm={this.props.userForm}
                formWidth={FORMSIZE.fourth}
                updateForm={this.props.updateUserForm}
                title={t("language.siteLanguage")}
                options={options}
                formType="Custom"
                isClearable={false}
                required
              />
            </Col>
          </Row>
        </Container>
      </CollapsibleCard>
    );
  };

  render() {
    if (!this.props.userForm || !this.props.properties) {
      return null;
    }
    const { t } = this.props;
    const propertyUser = isPropertyUser(this.props.userForm.role);

    return (
      <BasicLayout>
        <BasicLayout.MainContent>
          <Header
            bgColor={this.state.headerColor}
            title={`${this.state.formMode} ${t("keywords.userRelated.user.name")}`}
            titleColor={this.state.titleColor}
            buttonColor={this.state.buttonColor}
          />
          <div className="w-100 p-4">
            {this.renderContactInfo()}
            {this.renderPermissions()}
            {propertyUser ? this.renderPropertySelectors() : null}
            {this.renderUserLanguage()}
          </div>
        </BasicLayout.MainContent>
        <BasicLayout.Footer>
          <SubmitButtonForm
            className="px-4"
            formId={this.props.userForm.id}
            category={t("pageNames.user")}
            formColor={this.state.formColor}
            exitRoute="/users"
            enableDelete={false}
            deleteAction={null}
            createButtonName={t("keywords.buttonActions.create")}
            createAction={this.submitAction}
            updateAction={this.submitAction}
          />
        </BasicLayout.Footer>
      </BasicLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    userForm: state.user.general.form,
    properties: state.property.general.all,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createNewUser,
      fetchUserForm,
      editUser,
      updateUserForm,
      initiateUserForm,
      fetchPropertiesForUserForm,
      addFlashMessage,
      clearUserForm,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserForm))
);
