import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import PropertyContentLoop from "@components/Global/Widgets/propertyContentLoop";
import { unstableHistory } from "@App/history";
import PermissionService, { Actions, Resources } from "@components/User/Services/permissionService";

class DashboardSchedulePanel extends Component {
  renderPropertyFilterDropdown = () => {
    if (!this.props.selectedProperty) {
      return null;
    }
    if (this.props.properties.length > 1) {
      return <div className="pt-2">{this.renderSelection()}</div>;
    }
    return <h4 className="pt-2">{this.props.selectedProperty.label}</h4>;
  };

  renderSelection = () => {
    return (
      <Select
        value={this.props.selectedProperty}
        options={this.props.properties}
        onChange={this.props.changeProperty}
        clearable={false}
      />
    );
  };

  renderScreenPreviewButton = () => {
    const { t, loggedInUser } = this.props;

    if (!PermissionService.can(Actions.LIST, Resources.PROPERTIES, loggedInUser)) {
      return null;
    }
    return (
      <Card.Footer>
        <Button
          variant="primary"
          size="lg"
          block
          onClick={this.goToScreen}
          className="d-flex justify-content-center align-items-center"
        >
          {t("keywords.screenRelated.viewScreenContent")}
          <i className="fa fa-play-circle-o fa-2x" aria-hidden="true" />
        </Button>
      </Card.Footer>
    );
  };

  goToScreen = () => {
    unstableHistory.push(`/property_content/${this.props.selectedProperty.id}`);
  };

  render() {
    const { content, t, selectedProperty } = this.props;
    return (
      <Card className="dashboard-schedule-panel">
        <Card.Header className="pt-4">
          <h3>{t("dashboard.panel.schedule.title")}</h3>
          {this.renderPropertyFilterDropdown()}
        </Card.Header>
        <Card.Body className="dashboard-schedule-panel__content p-4">
          <Card.Text>
            {selectedProperty ? (
              <PropertyContentLoop
                propertyID={selectedProperty.id}
                screens={selectedProperty.screens}
                content={content}
                userRole={this.props.loggedInUser.role}
              />
            ) : null}
          </Card.Text>
        </Card.Body>
        {selectedProperty ? <div>{this.renderScreenPreviewButton()}</div> : null}
      </Card>
    );
  }
}

export default withTranslation()(DashboardSchedulePanel);
