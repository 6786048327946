import i18n from "../../../i18n";
import {
  AUTH_USER,
  UNAUTH_USER,
  ERROR_MSG,
  NO_ERROR_MSG,
  VALID_PASSWORD,
  INVALID_PASSWORD,
} from "../../../App/actionTypes";

const INITIAL_STATE = { authenticated: false, errorMessage: "", passwordSent: "" };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, authenticated: true };
    case UNAUTH_USER:
      return { ...state, authenticated: false };
    case ERROR_MSG:
      return { ...state, errorMessage: i18n.t("loginRelatedPages.invalidLogin") };
    case NO_ERROR_MSG:
      return { ...state, errorMessage: "" };
    case VALID_PASSWORD:
      return { ...state, passwordSent: "valid" };
    case INVALID_PASSWORD:
      return { ...state, passwordSent: "invalid" };
  }
  return state;
}
