/*

ADVERTISER REDUCER

*/

import {
  FETCH_ADVERTISERS,
  SET_ADVERTISER_FORM,
  SET_CLIENT_PROPERTIES,
} from "../../../../App/actionTypes";

const INITIAL_STATE = { all: null, single: null, form: null, properties: null };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ADVERTISERS:
      return { ...state, all: action.payload };
    case SET_ADVERTISER_FORM:
      return { ...state, form: action.payload };
    case SET_CLIENT_PROPERTIES:
      return { ...state, properties: action.payload };
  }
  return state;
}
