// Replacement for useEffect that uses deep comparison instead of referential equality

import { useEffect, useMemo, useRef } from "react";
import _ from "lodash";

export default function useDeepCompareEffect(callback, dependencies) {
  return useEffect(callback, useDeepCompareMemoize(dependencies));
}

function useDeepCompareMemoize(value) {
  const ref = useRef(value);
  const signalRef = useRef(0);

  if (!_.isEqual(value, ref.current)) {
    ref.current = _.cloneDeep(value);
    signalRef.current += 1;
  }

  return useMemo(() => ref.current, [signalRef.current]);
}
