// React
import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";

// Form
import ScreenList from "./screenList";
import PropertyForm from "./propertyForm";

class PropertyContainer extends Component {
  render() {
    return (
      <Routes>
        <Route
          path="screen_list"
          key="edit-screen-list"
          element={
            <ScreenList
              initialScreens={this.props.propertyForm.screens}
              propertyId={this.props.propertyForm.id}
              {...this.props}
            />
          }
        />
        <Route
          path="form"
          key="property-edit-form"
          element={
            <PropertyForm
              isPropertyManager={this.props.isPropertyManager}
              propertyForm={this.props.propertyForm}
              {...this.props}
            />
          }
        />
      </Routes>
    );
  }
}
export default PropertyContainer;
