import Login from "../../components/Login/views/login";
import ForgotPassword from "../../components/Login/views/forgotPassword";
import ResetPassword from "../../components/Login/views/resetPassword";

import RegisterScreen from "../../components/Client/Other/views/RegisterScreen";

import LocationPage from "../../components/CampaignReporting/views/LocationPage";
import CampaignReportingRenderedReportPage from "../../components/CampaignReporting/views/RenderedReportPage";

const PUBLIC_ROUTE_LIST = [
  {
    path: "/",
    component: Login,
    key: "login-entry",
  },
  {
    path: "/login",
    component: Login,
    key: "login",
  },
  {
    path: "/reset_password",
    component: ResetPassword,
    key: "reset-password",
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    key: "forgot-password",
  },
  {
    path: "/:mac_address/select_config",
    component: RegisterScreen,
    key: "register_screen",
    noRedirect: true,
  },
];

// the following path values are relative; appended to /campaign_reporting/:report_code/ via ReportContainer
export const REPORTING_ROUTE_LIST = [
  {
    path: "report",
    component: CampaignReportingRenderedReportPage,
    key: "campaign-reporting-new",
    type: "public",
    noRedirect: true,
  },
  {
    path: "location",
    component: LocationPage,
    key: "campaign-location",
    type: "public",
    noRedirect: true,
  },
];

export default PUBLIC_ROUTE_LIST;
