import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { withTranslation } from "react-i18next";

class Checkbox extends Component {
  render() {
    const { name, label, required, value, handleChange, type, t } = this.props;
    return (
      <React.Fragment>
        <Form.Check type={type} name={name} label={label} onChange={handleChange} checked={value} />
        {required ? (
          <Form.Control.Feedback type="invalid">
            {t("keywords.errorRelated.required")}
          </Form.Control.Feedback>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withTranslation()(Checkbox);
