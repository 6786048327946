/*

PM SPECIFIC RELATED PROPERTY INFO

*/

import { SET_FEED_PROPERTIES } from "../../../../App/actionTypes";

const INITIAL_STATE = { all: null };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_FEED_PROPERTIES:
      // console.log("Setting feed properties", action.payload);
      return { ...state, all: action.payload };
  }
  return state;
}
