/*

PERSONAL REDUCER

*/

import { SET_PERSONAL_INFO, SET_PASSWORD_STATUS } from "../../../../../App/actionTypes";

const INITIAL_STATE = { info: null, form: null, passwordStatus: "" };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_PERSONAL_INFO:
      return { ...state, info: action.payload };
    case SET_PASSWORD_STATUS:
      return { ...state, passwordStatus: action.payload };
  }
  return state;
}
