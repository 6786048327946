// React
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class PageTabs extends Component {
  static contextTypes = {
    tabs: PropTypes.array,
    tabClicked: PropTypes.func,
    activeSection: PropTypes.string,
  };

  activeClass(sectionName) {
    if (sectionName === this.props.activeSection) {
      return "page-tabs__tab--active";
    }
    return "";
  }

  calculatedColumns() {
    // this will work for up to 4 tabs
    // after that we might need a case or something
    return `col-${12 / this.props.tabs.length}`;
  }

  titleCase(string) {
    return string.replace("_", " ").replace(/^(.)|\s(.)/g, ($1) => $1.toUpperCase());
  }

  renderTab(sectionName) {
    return (
      <div
        className={`page-tabs__tab ${this.calculatedColumns()} ${this.activeClass(sectionName)}`}
        key={sectionName}
        value={sectionName}
        onClick={() => this.props.tabClicked(sectionName)}
      >
        {this.titleCase(sectionName)}
      </div>
    );
  }

  render() {
    return <div className="page-tabs">{this.props.tabs.map((tab) => this.renderTab(tab))}</div>;
  }
}

export default connect(null, null)(PageTabs);
