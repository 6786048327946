import moment from "moment";

const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export function datetimeAsApiData(time, modifier = null) {
  if (!time) return undefined;

  let apiTime = moment(time);

  if (modifier === "startOfDay") {
    apiTime = apiTime.startOf();
  }

  if (modifier === "endOfDay") {
    apiTime = apiTime.endOf();
  }

  return apiTime.format(DATETIME_FORMAT);
}

export function datetimeAsFormData(time) {
  if (!time) return "";

  const serializedTime = moment(time, DATETIME_FORMAT);
  return serializedTime;
}

export function durationInDays(start, end) {
  if (!start || !end) return 0;

  const startDate = typeof start === "string" ? moment(start) : start;
  const endDate = typeof end === "string" ? moment(end) : end;

  return endDate.diff(startDate, "days");
}

export function now() {
  return moment();
}
