import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

class TextInput extends Component {
  getWordCountDescription = () => {
    const { t, maxLength, value, hideWordCount } = this.props;
    const valueLength = value !== undefined ? value.length : 0;

    return maxLength && !hideWordCount
      ? `${t("keywords.general.charactersLeft")} ${maxLength - valueLength}`
      : "";
  };

  showWordCountAlert = () => {
    const { maxLength, value } = this.props;
    if (maxLength && maxLength - value.length < 5) {
      return "error";
    }
    return "";
  };

  render() {
    const {
      name,
      label,
      required,
      type,
      value,
      handleChange,
      testId,
      maxLength,
      readOnly,
      titleStyle,
      t,
    } = this.props;

    const wordCountDescription = this.getWordCountDescription();
    const wordCountAlert = this.showWordCountAlert();

    return (
      <>
        {label ? <Form.Label className={titleStyle}>{label}</Form.Label> : null}
        <Form.Control
          name={name}
          type={type}
          placeholder={label}
          value={value}
          data-test-id={testId}
          required={required}
          onChange={handleChange}
          maxLength={maxLength}
          readOnly={readOnly}
        />
        {required ? (
          <Form.Control.Feedback type="invalid">
            {t("keywords.errorRelated.required")}
          </Form.Control.Feedback>
        ) : null}
        <div className={`title text-right ${wordCountAlert}`}>{wordCountDescription}</div>
      </>
    );
  }
}

TextInput.propTypes = {
  handleChange: PropTypes.func,
  maxLength: PropTypes.number,
  hideWordCount: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
};

TextInput.defaultProps = {
  handleChange: () => null,
  maxLength: null,
  hideWordCount: null,
  name: "",
  label: "",
  required: false,
  readOnly: false,
};

export default withTranslation()(TextInput);
