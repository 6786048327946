export const Resources = {
  PROPERTIES: "properties",
  ADS: "ads",
  ADVERTISERS: "advertisers",
  USERS: "users",
  FEEDS: "feeds",
  BIG_NOTICES: "big_notices",
  NOTICES: "notices",
  CAMPAIGNS: "campaigns",
  CAMPAIGN_GROUPS: "campaign_groups",
  CAMPAIGN_GROUP_PROPOSALS: "campaign_group_proposals",
  SCREENS: "screens",
  PROPERTY_CONTRACT_DETAILS: "property_contract_details",
  PROPERTY_REVENUE: "property_revenue",
};

export const Actions = {
  LIST: "list",
  READ: "read",
  UPDATE: "update",
  CREATE: "create",
  DELETE: "delete",
};

class PermissionService {
  static can = (requiredAction, resourceToAccess, user) => {
    if (!this._canAccess(requiredAction, resourceToAccess, user)) {
      return false;
    }
    return true;
  };

  static _canAccess(requiredAction, resourceToAccess, user) {
    if (!user) {
      return false;
    }

    if (!user.capabilities[resourceToAccess]) {
      return false;
    }

    if (!this._hasSubmemberAccess(resourceToAccess, user)) {
      return false;
    }

    if (!this._hasAction(requiredAction, user.capabilities[resourceToAccess])) {
      return false;
    }

    return true;
  }

  static _hasSubmemberAccess(resourceToAccess, user) {
    if (resourceToAccess == Resources.USERS && this._isBoardMember(user)) {
      return false;
    }
    if (resourceToAccess == Resources.PROPERTIES && this._isBoardMember(user)) {
      return false;
    }
    return true;
  }

  static _isBoardMember(user) {
    return user.properties.member.length > 0 && user.properties.manager.length == 0;
  }

  static _hasAction(requiredAction, userActions) {
    if (userActions.includes(requiredAction)) {
      return true;
    }
    return false;
  }
}

export default PermissionService;
