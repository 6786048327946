import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { withTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

class DropDown extends Component {
  renderField = (field) => {
    if (field.hasOwnProperty("group")) {
      return (
        <optgroup key={uuidv4()} label={field.group}>
          {field.options.map(this.renderOptions.bind(this))}
        </optgroup>
      );
    } else {
      return this.renderOptions(field);
    }
  };

  renderOptions = (option) => {
    let label;
    let value;

    if (Array.isArray(option)) {
      label = option[0];
      value = option[1];
    } else if (typeof option === "object") {
      label = option.label;
      value = option.value;
    } else {
      label = option;
      value = option;
    }

    return (
      <option value={value} key={value}>
        {label}
      </option>
    );
  };

  includeBlank() {
    if (this.props.includeBlank == true) {
      return <option value="">{this.props.blankValueName}</option>;
    }

    return null;
  }

  render() {
    const { name, label, readOnly, required, value, handleChange, options, t } = this.props;

    return (
      <React.Fragment>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="select"
          // Workaround for React-Bootstrap bug where readOnly is still selectable
          disabled={readOnly}
          onChange={handleChange}
          value={value}
          name={name}
          required={required}
        >
          {this.includeBlank()}
          {options.map(this.renderField)}
        </Form.Control>
        {required ? (
          <Form.Control.Feedback type="invalid">
            {t("keywords.errorRelated.required")}
          </Form.Control.Feedback>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withTranslation()(DropDown);
