/* global FormData */

import Elev8ApiService from "../../../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../../../lib/services/Elev8Api/lib";

class BigNoticeDataHelper {
  static fetchBigNotice(id) {
    return new Promise((resolve, reject) => {
      Elev8ApiService.read(ApiTypes.big_notice, id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateNotice(form, id) {
    return new Promise((resolve, reject) => {
      Elev8ApiService.update(ApiTypes.big_notice, form, id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static createNotice(form) {
    return new Promise((resolve, reject) => {
      Elev8ApiService.create(ApiTypes.big_notice, form)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static deleteNotice(id) {
    return new Promise((resolve, reject) => {
      Elev8ApiService.delete(ApiTypes.big_notice, id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * @todo Update oldFetchBigNoticeBackgrounds, oldDeleteBackgroundImage, oldCreateBackgroundImage to use
   * the new Elev8 Api.
   * Old methods extracted from the file MainFeedMediaActions
   * * Methods have been altered so that they can be used without relying on Redux
   * */

  static oldFetchBigNoticeBackgrounds(propertyIds) {
    const idQueryParams = propertyIds.map((id) => `big_notice_background_for[]=${id}`).join("&");
    return new Promise((resolve) => {
      Elev8ApiService.getOldEndpoint(`media/?${idQueryParams}`).then((response) => {
        resolve(response);
      });
    });
  }

  static oldDeleteBackgroundImage(id) {
    return new Promise((resolve) => {
      Elev8ApiService.oldDelete(`media/${id}/property_background`).then((response) => {
        resolve(response);
      });
    });
  }

  static oldCreateBackgroundImage(media, propertyIds) {
    const formData = new FormData();

    /**
     * @todo Consolidate formData set up to be in one place
     */

    const mediaKeys = Object.keys(media);

    mediaKeys.forEach((key) => {
      formData.append(key, media[key]);
    });

    propertyIds.forEach((propId) => {
      formData.append("property_ids[]", parseInt(propId, 10));
    });

    return new Promise((resolve) => {
      Elev8ApiService.oldPost("media", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        resolve(response);
      });
    });
  }
}

export default BigNoticeDataHelper;
