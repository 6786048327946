import {
  Route,
  unstable_HistoryRouter as UnstableHistoryRouter,
  Routes,
  Navigate,
} from "react-router-dom";
import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import i18n from "@src/i18n";
import { unstableHistory } from "@App/history";
import PermissionService from "@components/User/Services/permissionService";
import PUBLIC_ROUTE_LIST from "@App/routeHelpers/publicRouteList";
import PRIVATE_ROUTE_LIST from "@App/routeHelpers/privateRouteList";
import { PrivateComponent } from "@App/routeHelpers/privateComponent";
import PublicComponent from "@App/routeHelpers/publicComponent";
import ApplicationLayout from "@components/Global/Layouts/application-layout";
import Navbar from "@components/Interface/Navbar/views/navbar";
import SideBar from "@components/Interface/SideBar/views/sidebar";
import SidebarItemList from "@components/Interface/SideBar/services/sideBarItemList";
import BrandText from "@components/Global/General/brandText";
import FlashMessage from "@components/Global/Widgets/views/flashMessage";
import SavingNotificationModal from "@components/Global/Widgets/views/savingNotificationModal";
import ReportContainer from "@components/CampaignReporting/views/ReportContainer";

class Elev8Router extends Component {
  constructor(props) {
    super(props);
    if (process.env.SEGMENT_ENABLED === "true") {
      unstableHistory.listen((location) => {
        if (location === "/" || location === "/login") {
          return;
        }

        // eslint-disable-next-line no-undef
        window.analytics.page(
          {},
          {
            Intercom: {
              hideDefaultLauncher: true,
              custom_launcher_selector: "#js-intercomSelector",
            },
          }
        );
      });
    }

    this.state = {
      sideBarExpanded: false,
    };
  }

  onExternalPage(pathname) {
    return this.externalPageList().includes(pathname);
  }

  canAccess = (resource, routeItem) => {
    // we are using loose equality to check both null and undefined
    // eslint-disable-next-line eqeqeq
    if (resource == undefined) {
      return true;
    }
    return PermissionService.can(routeItem.action, resource, this.props.loggedInUser);
  };

  toggleSideBar = (newState) => {
    // we are using loose equality to check both null and undefined
    // eslint-disable-next-line eqeqeq
    if (newState != undefined) {
      this.setState({ sideBarExpanded: newState });
    } else {
      this.setState((prevState) => ({ sideBarExpanded: !prevState.sideBarExpanded }));
    }
  };

  checkRoutePermissions = (routeGroup) => {
    const { resource } = routeGroup;
    return routeGroup.routes.map(this.renderPrivateRoute.bind(this, resource));
  };

  externalPageList() {
    return ["/", "/login", "/forgot-password", "/reset_password"];
  }

  notEmbedded() {
    // eslint-disable-next-line no-undef, no-restricted-globals
    return queryString.parse(location.search).embedded !== "true";
  }

  isReportingRoute() {
    return unstableHistory.location.pathname.includes("/campaign_reporting/");
  }

  showNavigation() {
    return this.notEmbedded() && !this.onExternalPage(unstableHistory.location.pathname);
  }

  canIdentifyWithSegment(user) {
    if (
      // we are using loose equality to check both null and undefined
      // eslint-disable-next-line eqeqeq
      unstableHistory.location != undefined &&
      (unstableHistory.location.pathname === "/" || unstableHistory.location.pathname === "/login")
    ) {
      return false;
    }

    // we are using loose equality to check both null and undefined
    // eslint-disable-next-line eqeqeq
    return process.env.SEGMENT_ENABLED === "true" && user != undefined;
  }

  identifyWithSegment(user) {
    // we are using loose equality to check both null and undefined
    // eslint-disable-next-line eqeqeq
    if (user == undefined) {
      return;
    }

    // eslint-disable-next-line no-undef
    window.analytics.identify(
      user.id,
      {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        phone: user.phone,
        company: user.company,
        role: user.role,
        locale: user.locale,
        propertyId: Object.values(user.properties).flat().pop,
      },
      {
        Intercom: {
          hideDefaultLauncher: true,
          custom_launcher_selector: "#js-intercomSelector",
        },
      }
    );
  }

  renderPrivateRoute(resource, routeItem) {
    if (!this.canAccess(resource, routeItem)) {
      if (!this.props.auth) {
        return (
          <Route
            path={routeItem.path}
            key={routeItem.key}
            element={<Navigate to="/login" replace />}
          />
        );
      }
      return null;
    }
    return (
      <Route
        path={routeItem.path}
        key={routeItem.key}
        element={
          <PrivateComponent
            auth={this.props.auth}
            component={routeItem.component}
            user={this.props.loggedInUser}
          />
        }
      />
    );
  }

  renderPublicRoute = (routeItem) => (
    <Route
      path={routeItem.path}
      key={routeItem.key}
      element={
        <PublicComponent
          auth={this.props.auth}
          component={routeItem.component}
          noRedirect={routeItem.noRedirect}
        />
      }
    />
  );

  renderPoweredBy() {
    if (this.notEmbedded()) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="u-pad-xsmall d-flex justify-content-end align-items-center">
            <span className="u-text-small u-text-black pr-2">
              {i18n.t("keywords.general.poweredBy")}
            </span>
            <BrandText useExternalLink size="sm" whiteBackground useVCity={false} />
          </div>
        </div>
      </div>
    );
  }

  renderFlashMessages() {
    return <FlashMessage flashMessages={this.props.flashMessages} />;
  }

  renderSavingDialog() {
    if (!this.props.currentlySaving) {
      return null;
    }

    return <SavingNotificationModal />;
  }

  renderReportPage() {
    return (
      <Routes>
        <Route path="/campaign_reporting/:report_code/*" element={<ReportContainer />} />
      </Routes>
    );
  }

  renderElev8Page() {
    return (
      <ApplicationLayout>
        <ApplicationLayout.Header>
          <Navbar
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
            showNavigation={this.showNavigation()}
            auth={this.props.auth}
            loggedInUser={this.props.loggedInUser}
            toggleSideBar={this.toggleSideBar}
          />
        </ApplicationLayout.Header>
        <ApplicationLayout.Navigation>
          <SideBar
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...this.props}
            toggleSideBar={this.toggleSideBar}
            sideBarItemList={SidebarItemList}
            sideBarExpanded={this.state.sideBarExpanded}
            loggedInUser={this.props.loggedInUser}
          />
        </ApplicationLayout.Navigation>
        <ApplicationLayout.MainContent>
          <div className={`main-window ${!this.props.auth ? "guest" : ""}`}>
            <Routes>
              {PUBLIC_ROUTE_LIST.map(this.renderPublicRoute.bind(this))}
              {PRIVATE_ROUTE_LIST.map(this.checkRoutePermissions.bind(this))}
            </Routes>
            {this.renderPoweredBy()}
          </div>
        </ApplicationLayout.MainContent>
      </ApplicationLayout>
    );
  }

  render() {
    if (this.canIdentifyWithSegment(this.props.loggedInUser)) {
      this.identifyWithSegment(this.props.loggedInUser);
    }

    return (
      <UnstableHistoryRouter history={unstableHistory}>
        <>
          {this.renderFlashMessages()}
          {this.renderSavingDialog()}
          {this.isReportingRoute() ? this.renderReportPage() : this.renderElev8Page()}
        </>
      </UnstableHistoryRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedInUser: state.user.personal.info,
    flashMessages: state.application.flashMessages,
    currentlySaving: state.application.currentlySaving,
  };
}

export default connect(mapStateToProps, null)(Elev8Router);
