import React, { Component } from "react";
import { fromUnixTime, getUnixTime } from "date-fns";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import withRouter from "@hooks/withRouter";
import { MaintenanceStatus } from "@components/Tech/services/ScreenHealthService";
import ReactDatePickerInput from "@components/Global/Widgets/views/reactDatePickerInput";
import { unstableHistory } from "@App/history";

const SeverityMapping = {
  NoError: { label: "None", value: "none" },
  High: { label: "High", value: "high" },
  Medium: { label: "Medium", value: "medium" },
  Low: { label: "Low", value: "low" },
};

const DefaultFilters = {
  minSeverity: SeverityMapping.NoError.value,
  cities: [],
  searchString: "",
  date: null,
};

export function globalFilterFn(rows, ids, filterValue) {
  return rows.filter(
    (row) =>
      matchesSearchString(row, ids, filterValue) &&
      matchesErrorStartAts(row, ids, filterValue) &&
      matchesSeverity(row, ids, filterValue)
  );
}

class ScreenHealthFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: DefaultFilters,
    };
  }

  componentDidMount() {
    const savedFilters = this.savedFilters();
    const filters = { ...DefaultFilters, ...savedFilters };

    this.setState({ filters }, () => {
      this.notifyFilterChange();
    });
  }

  savedFilters = () => {
    const queryParamFilters = queryString.parse(this.props.router.location.search).filters;

    if (queryParamFilters) {
      try {
        return JSON.parse(queryParamFilters);
      } catch (err) {
        console.error(err);
      }
    }
    return {};
  };

  updateFilters = (updatedFilters) => {
    const saveFiltersToURL = () => {
      const savableFilters = _.omit(this.state.filters, "date");
      const urlEncodedFilters = encodeURIComponent(JSON.stringify(savableFilters));
      unstableHistory.replace({ search: `?filters=${urlEncodedFilters}` });
    };

    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, ...updatedFilters } }),
      () => {
        saveFiltersToURL();
        this.notifyFilterChange();
      }
    );
  };

  notifyFilterChange = () => {
    const filterSet = {};
    const { cities, date, searchString, minSeverity } = this.state.filters;
    const fullCityList = this.props.cities;

    filterSet.global = { searchString, date, minSeverity };
    filterSet.city = cities.length !== 0 ? cities : fullCityList;

    this.props.onFilterChange(filterSet);
  };

  renderGlobalSearchFilter = () => (
    <>
      <div className="title">&nbsp;</div>
      <InputGroup>
        <InputGroup.Text id="global-search-filter">
          <i className="fa fa-search" />
        </InputGroup.Text>
        <FormControl
          placeholder="Search for..."
          aria-describedby="global-search-filter"
          onChange={(e) => this.updateFilters({ searchString: e.target.value || "" })}
          value={this.state.filters.searchString}
        />
      </InputGroup>
    </>
  );

  renderSeverityFilterSelect = () => {
    const severityFilterOptions = Object.values(SeverityMapping);

    return (
      <>
        <div className="title">Min Severity</div>
        <Select
          value={SeverityMapping[this.state.filters.minSeverity]}
          onChange={(option) =>
            this.updateFilters({
              minSeverity: option ? option.value : DefaultFilters.severity,
            })
          }
          options={severityFilterOptions}
          name="severity-filter"
          clearable={false}
        />
      </>
    );
  };

  renderCitiesFilterSelect = () => {
    const citiesFilterOptions = this.props.cities.map((city) => ({ value: city, label: city }));

    return (
      <>
        <div className="title">City</div>
        <Select
          value={citiesFilterOptions.filter((city) =>
            this.state.filters.cities.includes(city.value)
          )}
          onChange={(options) => {
            this.updateFilters({ cities: options.map((o) => o.value) });
          }}
          options={citiesFilterOptions}
          name="cities-filter"
          isMulti
        />
      </>
    );
  };

  renderDateFilter = () => {
    const { date } = this.state.filters;
    return (
      <>
        <div className="title">Error began after</div>
        <DatePicker
          customInput={<ReactDatePickerInput />}
          selected={date ? fromUnixTime(date) : null}
          onChange={(option) =>
            this.updateFilters({
              date: option ? getUnixTime(new Date(option)) : null,
            })
          }
          popperPlacement="auto-start"
          dateFormat="MMMM d, yyyy"
          isClearable
        />
      </>
    );
  };

  render() {
    return (
      <Container fluid className="p-2">
        <Row>
          <Col xs={12} lg={6} xl={3}>
            {this.renderCitiesFilterSelect()}
          </Col>
          <Col xs={12} lg={6} xl={6}>
            {this.renderGlobalSearchFilter()}
          </Col>
          <Col xs={12} lg={6} xl={1}>
            {this.renderSeverityFilterSelect()}
          </Col>
          <Col xs={12} lg={6} xl={2}>
            {this.renderDateFilter()}
          </Col>
        </Row>
      </Container>
    );
  }
}

function undergoingMaintenance(maintenanceStatus) {
  return [MaintenanceStatus.Elevator, MaintenanceStatus.Screen].includes(maintenanceStatus);
}

function matchesSeverity(row, ids, filterValue) {
  const { minSeverity } = filterValue;
  const allDataForRow = row.original;

  if (minSeverity === SeverityMapping.NoError.value) {
    return true;
  }

  if (undergoingMaintenance(allDataForRow.maintenanceStatus)) {
    return false;
  }

  const { noSyncError, noUpdateError, playError, noEventError } = allDataForRow;

  const hasHighSeverity = noSyncError.startAt && noUpdateError.startAt;
  const hasMediumSeverity = noSyncError.startAt || noUpdateError.startAt || playError.startAt;
  const hasLowSeverity = noEventError.startAt && !allDataForRow.visionDisabled;

  return filterMatchesSeverityLevel(minSeverity, {
    hasHighSeverity,
    hasMediumSeverity,
    hasLowSeverity,
  });
}

function filterMatchesSeverityLevel(minSeverity, errorLevels) {
  if (minSeverity === SeverityMapping.Low.value) {
    return (
      errorLevels.hasLowSeverity || errorLevels.hasMediumSeverity || errorLevels.hasHighSeverity
    );
  }

  if (minSeverity === SeverityMapping.Medium.value) {
    return errorLevels.hasMediumSeverity || errorLevels.hasHighSeverity;
  }

  if (minSeverity === SeverityMapping.High.value) {
    return errorLevels.hasHighSeverity;
  }

  return false;
}

function matchesSearchString(row, ids, filterValue) {
  return ids.some((id) => {
    const rowValue = row.values[id];
    const filterSearchString = filterValue.searchString;

    return String(rowValue).toLowerCase().includes(String(filterSearchString).toLowerCase());
  });
}

function matchesErrorStartAts(row, ids, filterValue) {
  const { date } = filterValue;
  const allDataForRow = row.original;

  if (date == null) {
    return true;
  }

  if (undergoingMaintenance(allDataForRow.maintenanceStatus)) {
    return false;
  }

  const { noSyncError, noUpdateError, playError, noEventError } = allDataForRow;

  return (
    noSyncError.startAt >= date ||
    noUpdateError.startAt >= date ||
    playError.startAt >= date ||
    (!allDataForRow.visionDisabled && noEventError.startAt >= date)
  );
}

export default withTranslation()(withRouter(ScreenHealthFilters));
