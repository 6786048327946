import Elev8ApiService from "../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../lib/services/Elev8Api/lib";
import { CampaignProposalSerializer } from "./CampaignProposalSerializer";
import { NEW_OBJECT_VALUE, relationshipIdAsString } from "../../../utilities/FormDataHelper";

const CampaignGroupProposalSerializer = {
  formData(obj = null) {
    return apiObjectToFormData(obj);
  },
  apiObject(data = null) {
    return formDataToApiObject(data);
  },
};

const CAMPAIGN_GROUP_PROPOSAL_FORM_DEFAULTS = {
  name: "",
  advertiser_id: "",
  vi_owner_id: "",
  campaign_group_id: "",
  status: "new",
  isConvertible: false,
  campaign_proposals: [],
};

function apiObjectToFormData(obj) {
  if (!obj) return { ...CAMPAIGN_GROUP_PROPOSAL_FORM_DEFAULTS };

  const { attributes } = obj;
  return {
    id: attributes.id || null,
    name: attributes.name,
    advertiser_id: relationshipIdAsString(obj.relationships.advertiser),
    vi_owner_id: relationshipIdAsString(obj.relationships.vi_owner),
    campaign_group_id: relationshipIdAsString(obj.relationships.campaign_group),
    status: obj.attributes.status || CAMPAIGN_GROUP_PROPOSAL_FORM_DEFAULTS.status,
    isConvertible: obj.attributes["convertible?"],
    campaign_proposals: obj
      .campaign_proposals()
      .map((proposalData) => CampaignProposalSerializer.formData(proposalData)),
  };
}

function formDataToApiObject(formData) {
  const relationships = {};
  const advertiserId = formData.advertiser_id !== NEW_OBJECT_VALUE ? formData.advertiser_id : null;
  const attributes = {
    name: formData.name,
    vi_owner_id: formData.vi_owner_id,
    advertiser_id: advertiserId,
  };
  if (formData.vi_owner_id) {
    relationships.vi_owner = Elev8ApiService.setToJsonApiFormat(
      ApiTypes.user.type,
      formData.vi_owner_id
    );
  }
  if (advertiserId) {
    relationships.advertiser = Elev8ApiService.setToJsonApiFormat(
      ApiTypes.advertiser.type,
      advertiserId
    );
  }
  if (formData.campaign_proposals.length) {
    relationships.campaign_proposals = formData.campaign_proposals
      .map((campaignProposalForm) => CampaignProposalSerializer.apiObject(campaignProposalForm))
      .filter(Boolean);
  }
  return Elev8ApiService.setToJsonApiFormat(
    ApiTypes.campaign_group_proposal.type,
    formData.id,
    attributes,
    relationships
  );
}

export { CampaignGroupProposalSerializer, CAMPAIGN_GROUP_PROPOSAL_FORM_DEFAULTS };
