import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withTranslation } from "react-i18next";
import TextInput from "@components/Global/Widgets/textInput";
import NumberInput from "@components/Global/Widgets/updatedWidgets/numberInput";

class PropertyAddressSection extends Component {
  handleFormChange = (event) => {
    const target = {
      value: event.target.value,
      name: event.target.name,
    };
    this.props.handleChange(target);
  };

  renderUserView = () => {
    if (this.props.isPropertyManager) {
      return this.renderPropertyManagerView();
    }

    return this.renderAdminView();
  };

  renderPropertyManagerView = () => {
    const { t } = this.props;

    return (
      <Row>
        <Col>
          <p>
            {this.props.formFields.translatedPropertyType}, {this.props.formFields.address_line1},{" "}
            {this.props.formFields.address_city}, {this.props.formFields.address_country}
          </p>
          <p>
            {this.props.formFields.floors} {t("keywords.propertyRelated.floors.name")},{" "}
            {this.props.formFields.number_of_units} {t("keywords.propertyRelated.units.name")},{" "}
            {this.props.formFields.screen_count} {t("keywords.screenRelated.screens")}
          </p>
        </Col>
      </Row>
    );
  };

  getMarketName = () =>
    this.props.formFields.market && this.props.formFields.market.name
      ? this.props.formFields.market.name
      : "N/A";

  renderAdminView = () => {
    const { t } = this.props;

    return (
      <>
        <Row>
          <Form.Group as={Col} md="3">
            <TextInput
              label={t("keywords.locationRelated.addressLine1.name")}
              value={this.props.formFields.address_line1}
              handleChange={this.handleFormChange}
              required
              type="text"
              name="address_line1"
            />
          </Form.Group>
          <Form.Group as={Col} md="3">
            <TextInput
              label={t("keywords.locationRelated.addressLine2.name")}
              value={this.props.formFields.address_line2}
              handleChange={this.handleFormChange}
              required={false}
              type="text"
              name="address_line2"
            />
          </Form.Group>
          <Form.Group as={Col} md="2">
            <TextInput
              label={t("keywords.locationRelated.city.name")}
              value={this.props.formFields.address_city}
              handleChange={this.handleFormChange}
              required
              type="text"
              name="address_city"
            />
          </Form.Group>
          <Form.Group as={Col} md="2">
            <TextInput
              label={t("keywords.locationRelated.provinceOrState.name")}
              value={this.props.formFields.address_province}
              handleChange={this.handleFormChange}
              required
              type="text"
              name="address_province"
            />
          </Form.Group>
          <Form.Group as={Col} md="2">
            <TextInput
              label={t("keywords.locationRelated.country.name")}
              value={this.props.formFields.address_country}
              handleChange={this.handleFormChange}
              required
              type="text"
              name="address_country"
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md="3">
            <TextInput
              label={t("keywords.locationRelated.postalOrZipCode.name")}
              value={this.props.formFields.address_postal_code}
              handleChange={this.handleFormChange}
              required
              type="text"
              name="address_postal_code"
            />
          </Form.Group>
          <Form.Group as={Col} md="3">
            <NumberInput
              label={t("keywords.locationRelated.latitude.name")}
              value={this.props.formFields.latitude}
              handleChange={this.handleFormChange}
              required
              type="number"
              name="latitude"
            />
          </Form.Group>

          <Form.Group as={Col} md="3">
            <NumberInput
              label={t("keywords.locationRelated.longitude.name")}
              value={this.props.formFields.longitude}
              handleChange={this.handleFormChange}
              required
              type="number"
              name="longitude"
            />
          </Form.Group>
          <Form.Group as={Col} md="3">
            <TextInput
              label={t("keywords.propertyRelated.areaPropertyResides")}
              value={this.props.formFields.chosenArea}
              name="chosenArea"
              type="text"
              required
              readOnly
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="3">
            <TextInput
              label={t("keywords.locationRelated.market.name")}
              value={this.getMarketName()}
              type="text"
              name="market"
              readOnly
            />
          </Form.Group>
        </Row>
      </>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <div className="pt-3 form-section">
        <div className="form-section__title">
          {this.props.isPropertyManager ? (
            <span>{t("keywords.general.information")}</span>
          ) : (
            <span>{t("keywords.locationRelated.address")}</span>
          )}
        </div>
        {this.renderUserView()}
      </div>
    );
  }
}

export default withTranslation()(PropertyAddressSection);
