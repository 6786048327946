import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { withTranslation } from "react-i18next";

import LoginContainer from "./loginContainer";
import { resetPassword } from "../../Authentication/actions/sessionActions";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPassword: "",
      newPassword: "",
      error: "",
    };
    this.updateConfirm = this.updateConfirm.bind(this);
    this.updatedPassword = this.updatedPassword.bind(this);
  }

  handleResetPassword(event) {
    this.preventRefreshAfterSubmit(event);
    const { t } = this.props;
    const { newPassword } = this.state;

    if (newPassword !== this.state.confirmPassword) {
      this.setState({ error: `** ${t("keywords.appRelated.password.noMatch")}` });
    } else if (newPassword.length < 8 || this.state.confirmPassword.length < 8) {
      this.setState({
        error: `** ${t("keywords.appRelated.password.requirements")}`,
      });
    } else {
      const parsed = queryString.parse(location.search);
      this.setState({ error: "" });
      const newLogin = {
        key: parsed.key,
        password: newPassword,
      };
      this.props.resetPassword(newLogin);
    }
  }

  updatedPassword(e) {
    const trimmed = e.target.value.trim();
    this.setState({ newPassword: trimmed });
  }

  updateConfirm(e) {
    const trimmed = e.target.value.trim();
    this.setState({ confirmPassword: trimmed });
  }

  preventRefreshAfterSubmit(event) {
    event.preventDefault();
  }

  // Show error message for incorrect Login
  renderAlert() {
    const { t } = this.props;

    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong> {t("keywords.errorRelated.oops")} </strong> {this.props.errorMessage}
        </div>
      );
    }

    return null;
  }

  render() {
    const { t } = this.props;

    return (
      <LoginContainer>
        <form onSubmit={this.handleResetPassword.bind(this)}>
          <h3>{t("keywords.appRelated.password.new")} </h3>
          <div className="sign-container">
            <hr />
            {this.props.passwordResult === "" ? (
              <div>
                <p>{t("keywords.appRelated.password.enterNewPassword")} </p>
                <p>{t("keywords.appRelated.password.requirements")}</p>
                <div style={{ padding: "6px" }} />
                <div className="form-group">
                  <p>
                    <label htmlFor="password" className="purple">
                      {t("keywords.appRelated.password.new")}
                    </label>
                  </p>
                  <input
                    className="input-material"
                    type="password"
                    value={this.state.newPassword}
                    onChange={this.updatedPassword}
                    required
                    id="password"
                  />
                </div>
                <div className="form-group py-3">
                  <p>
                    <label htmlFor="confirm-password" className="purple">
                      {t("keywords.appRelated.password.retype")}
                    </label>
                  </p>
                  <input
                    type="password"
                    value={this.state.confirmPassword}
                    required
                    onChange={this.updateConfirm}
                    className="input-material"
                    id="confirm-password"
                  />
                </div>
                <div className="error"> {this.state.error} </div>
                {this.renderAlert()} <br />
                <div className="small-block" />
                <div className="button-logins">
                  <button type="submit" action="submit" className="btn btn-primary">
                    {t("keywords.appRelated.password.update")}
                  </button>
                  <Link to="/login" className="forgot-password">
                    {t("loginRelatedPages.returnToLogin")}
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                {this.props.passwordResult === "valid" ? (
                  <div>
                    <b>{t("loginRelatedPages.activatedNewPassword")}</b>
                    <div className="small-block" />
                    <Link to="/login">
                      <div className="forgot-password">
                        <b> {t("loginRelatedPages.returnToLogin")}</b>
                      </div>
                    </Link>
                  </div>
                ) : (
                  <div className="error">
                    {t("loginRelatedPages.invalidPasswordLink")}
                    <div className="small-block" />
                    <div className="flex-this flex-end">
                      <Link to="/login">
                        <div className="forgot-password">
                          <b> {t("loginRelatedPages.returnToLogin")}</b>
                        </div>
                      </Link>
                      <Link to="/login" className="forgot-password">
                        {t("loginRelatedPages.returnToLogin")}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </form>
      </LoginContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    passwordResult: state.auth.passwordSent,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ resetPassword }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ResetPassword));
