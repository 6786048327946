import { isAfter, isBefore } from "date-fns";
import { JsonApiObject } from "../index";
import { apiDateAsFormData, parseApiDate } from "../../../../../utilities/dateStringHelper";
import { StatusTypes } from "../../../../../utilities/StatusTypes";

// eslint-disable-next-line import/prefer-default-export
export class Campaign extends JsonApiObject {
  constructor(apiObject, included) {
    super(apiObject, included);
    // eslint-disable-next-line no-underscore-dangle
    this.loopSpotsPerProperty = this._extractLoopsPerProperty();
  }

  _extractLoopsPerProperty() {
    const loopSpotsPerProperty = {};
    if (this.included === undefined || !this.campaign_locations) {
      // to support the format of the old campaign endpoint
      return {};
    }
    this.campaign_locations().forEach((location) => {
      loopSpotsPerProperty[location.attributes.property_id] = location.attributes.loop_spots;
    });
    return loopSpotsPerProperty;
  }

  getLoopSpotsForProperty(propertyId) {
    return this.loopSpotsPerProperty[propertyId];
  }

  getStatus() {
    const today = new Date();
    const startDate = parseApiDate(this.attributes.start_at);
    const endDate = parseApiDate(this.attributes.end_at);
    if (this.attributes.status === "inactive") {
      return StatusTypes.draft;
    }
    if (isAfter(today, endDate)) {
      return StatusTypes.complete;
    }
    if (isBefore(today, startDate)) {
      return StatusTypes.pending;
    }
    return StatusTypes.active;
  }

  getTotalBookedImpressions() {
    let bookedImpressions = 0;

    this.properties().forEach((property) => {
      const startDate = apiDateAsFormData(this.attributes.start_at);
      const endDate = apiDateAsFormData(this.attributes.end_at);
      bookedImpressions += property.getActiveBookedImpressions(
        startDate,
        endDate,
        this.getLoopSpotsForProperty(property.attributes.id)
      );
    });

    return bookedImpressions;
  }

  getTotalScreenCount() {
    return this.properties()
      .map((property) => property.attributes.screen_count)
      .reduce((prev, next) => prev + next, 0);
  }

  getTotalActiveScreenCount() {
    return this.properties()
      .map((property) => property.activeScreens.length)
      .reduce((prev, next) => prev + next, 0);
  }
}
