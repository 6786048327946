import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { unstableHistory } from "@App/history";

class RsmDashboard extends Component {
  goToProposals = () => {
    unstableHistory.push("/campaign-proposals");
  };

  goToSettings = () => {
    unstableHistory.push("/account-settings");
  };

  goToProperties = () => {
    unstableHistory.push("/properties");
  };

  render() {
    return (
      <>
        <Row>
          <Col>
            <Card className="text-center">
              <Card.Header as="h2">Welcome, Rogers Sports Media!</Card.Header>

              <Card.Body>
                <Card.Text as="h3">Quick Links:</Card.Text>

                <Card.Text>
                  <Button variant="primary  mb-3 mt-3" onClick={this.goToProposals}>
                    View your latest proposals
                  </Button>
                </Card.Text>
                <Card.Text>
                  <Button variant="primary mb-3" onClick={this.goToProperties}>
                    Property network
                  </Button>
                </Card.Text>
                <Card.Text>
                  <Button variant="primary  mb-3" onClick={this.goToSettings}>
                    Account settings
                  </Button>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default withTranslation()(RsmDashboard);
