import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import withRouter from "@hooks/withRouter";
import SubmitButtonForm from "@components/Global/Widgets/views/submitButtonForm";
import TextBox from "@components/Global/Widgets/views/textBox";
import ImageUploader from "@components/Global/Widgets/views/imageUploader";
import Header from "@components/Interface/MainList/header";
import LoadingPage from "@components/Global/General/loadingPage";
import ColorPicker from "@components/Global/Widgets/views/colorPicker";
import {
  initiateEditRssFeedForm,
  editRssFeed,
  createRssFeed,
  deleteRssFeed,
  initiateRssFeedForm,
  updatingRssFeedForm,
  clearRssFeedForm,
} from "../services/actions/rssFeedActions";

const fileMaxSize = 5 * 100 * 1000; // 10MB

class RssFeedForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newForm: false,
      subject: "Rss Feeds",
      formColor: "rgb(76, 96, 232)",
    };
    this.deleteAction = this.deleteAction.bind(this);
    this.submitAction = this.submitAction.bind(this);
    this.checkValid = this.checkValid.bind(this);
  }

  UNSAFE_componentWillMount() {
    const currentRoute = this.props.router.location.pathname;
    if (currentRoute === "/rss-feed/new") {
      this.props.initiateRssFeedForm(null);
      this.setState({ newForm: true });
    } else {
      this.setState({ newForm: false });
      this.props.initiateRssFeedForm(this.props.router.params.id);
    }
  }

  componentWillUnmount() {
    this.props.clearRssFeedForm();
  }

  checkValid() {
    if (
      this.props.feedForm.name.length > 0 &&
      this.props.feedForm.url.length > 0 &&
      this.props.feedForm.currIcon &&
      this.props.feedForm.currBanner &&
      this.props.feedForm.currSignature
    ) {
      return true;
    }
    return false;
  }

  deleteAction() {
    this.props.deleteRssFeed(
      this.props.feedForm.formId,
      this.props.feedForm.currBanner.mediaId,
      this.props.feedForm.currIcon.mediaId
    );
  }

  submitAction() {
    const validated = this.checkValid();
    if (this.props.feedForm.formId && validated) {
      this.props.editRssFeed(this.props.feedForm);
    } else if (validated) {
      this.props.createRssFeed(this.props.feedForm);
    }
  }

  render() {
    if (!this.props.feedForm) {
      return <LoadingPage pageName="Rss Feed" />;
    }
    const { t } = this.props;
    const fileSizeText = t("fileTerms.sizeInMB", { size: 5 });

    return (
      <div>
        <Header title={this.state.subject} />
        <div className="fillOut-form">
          <div className="form-container">
            <div className="flex-center">
              <ImageUploader
                maxWidth={250}
                fileSizeText={fileSizeText}
                fileMaxSize={fileMaxSize}
                dimensions="150x150 px"
                acceptedFiles="image/*"
                currMedia={this.props.feedForm.currIcon}
                imageWidth={100}
                imageHeight={100}
                imageTitle="Upload Icon (Legacy Windows Client)"
                updateFormAction={this.props.updatingRssFeedForm}
                formPropertyName="currIcon"
                currentForm={this.props.feedForm}
                imageCategory="feed_icon"
                required
                newForm={this.state.newForm}
              />

              <ImageUploader
                maxWidth={400}
                fileSizeText={fileSizeText}
                fileMaxSize={fileMaxSize}
                dimensions="960x260 px"
                acceptedFiles="image/*"
                currMedia={this.props.feedForm.currBanner}
                imageWidth={258}
                imageHeight={100}
                imageTitle="Upload Background"
                updateFormAction={this.props.updatingRssFeedForm}
                formPropertyName="currBanner"
                currentForm={this.props.feedForm}
                imageCategory="feed_background"
                required
                newForm={this.state.newForm}
              />

              <ImageUploader
                maxWidth={400}
                fileSizeText={fileSizeText}
                fileMaxSize={fileMaxSize}
                dimensions="variable (TBD)"
                acceptedFiles="image/*"
                currMedia={this.props.feedForm.currSignature}
                imageWidth={300}
                imageHeight={80}
                imageTitle="Upload Signature"
                updateFormAction={this.props.updatingRssFeedForm}
                formPropertyName="currSignature"
                currentForm={this.props.feedForm}
                imageCategory="feed_signature"
                required
                newForm={this.state.newForm}
              />
            </div>

            <TextBox
              textValue={this.props.feedForm.name}
              updateForm={this.props.updatingRssFeedForm}
              formPropertyName="name"
              description="Name"
              placeHolder="Rss Feed Name"
              inputType="text"
              formWidth={550}
              formColor={this.state.formColor}
              formProps={this.props.feedForm}
              required
              maxLength={255}
            />

            <TextBox
              textValue={this.props.feedForm.url}
              updateForm={this.props.updatingRssFeedForm}
              formPropertyName="url"
              description="URL"
              placeHolder="RSS Feed URL"
              inputType="text"
              formWidth={550}
              formColor={this.state.formColor}
              formProps={this.props.feedForm}
              required
              maxLength={255}
            />

            <div className="color-picker-group">
              <ColorPicker
                value={this.props.feedForm.title_color}
                updateForm={this.props.updatingRssFeedForm}
                formPropertyName="title_color"
                currentForm={this.props.feedForm}
                description="Title Color"
              />

              <ColorPicker
                value={this.props.feedForm.divider_color}
                updateForm={this.props.updatingRssFeedForm}
                formPropertyName="divider_color"
                currentForm={this.props.feedForm}
                description="Divider Color"
              />
            </div>

            <SubmitButtonForm
              formId={this.props.feedForm.formId}
              category={this.state.subject}
              formColor={this.state.formColor}
              exitRoute="/rss-feeds"
              enableDelete={false}
              deleteAction={false}
              createButtonName="Create"
              createAction={this.submitAction}
              updateAction={this.submitAction}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    feedForm: state.client.rssFeed.single,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editRssFeed,
      createRssFeed,
      deleteRssFeed,
      initiateEditRssFeedForm,
      initiateRssFeedForm,
      updatingRssFeedForm,
      clearRssFeedForm,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RssFeedForm))
);
