import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PermissionService, { Resources, Actions } from "@components/User/Services/permissionService";
import { unstableHistory } from "@App/history";
import { determineNoticeTitleByRole } from "@utilities/UserRoleHelper";

class Sidebar extends Component {
  selectItem = () => {
    // sidebar should collapse on mobile view
    if (window.innerWidth <= 576) {
      this.props.toggleSideBar(false);
    }
  };

  renderSidebarItem = (item) => {
    const { t } = this.props;
    const sideBarName = t(item.name);
    const textColor =
      unstableHistory.location.pathname === this.urlForItem(item)
        ? this.props.activeTextColorClass
        : this.props.textColorClass;
    const selectedStatus =
      unstableHistory.location.pathname === this.urlForItem(item) ? "selected" : "unselected";

    if (item.externalLink) {
      return (
        <a
          className="sidebar__item"
          key={item.url}
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className={`fa ${item.icon}`}> </i>
          <span>{sideBarName}</span>
        </a>
      );
    }

    return (
      <Link
        to={this.urlForItem(item)}
        key={item.url}
        onClick={this.selectItem}
        className={`sidebar__item sidebar__item__${selectedStatus} ${textColor} ${
          this.props.sideBarExpanded ? "sidebar__item--expanded" : ""
        }`}
      >
        <i className={`fa ${item.icon}`}></i>
        <span>{sideBarName}</span>
      </Link>
    );
  };

  getRequiredAction = (resource) => {
    const resourcesWithUpdateActionRequired = [
      Resources.CAMPAIGNS,
      Resources.ADVERTISERS,
      Resources.FEEDS,
    ];
    const resourcesWithDeleteActionRequired = [Resources.USERS];
    if (resourcesWithUpdateActionRequired.includes(resource)) {
      return Actions.UPDATE;
    }
    if (resourcesWithDeleteActionRequired.includes(resource)) {
      return Actions.DELETE;
    }
    return Actions.LIST;
  };

  canAccess = (item) => {
    if (item.resource === undefined) {
      return true;
    }
    const requiredAction = this.getRequiredAction(item.resource);
    return PermissionService.can(requiredAction, item.resource, this.props.loggedInUser);
  };

  updateSidebarItemConfigurations(item) {
    const { t } = this.props;
    const role = this.props.loggedInUser.role;

    if (role === "rsm_sales_associate" && item.name === "pageNames.howToUse") {
      item.url = "https://www.verticalimpression.com/apps";
      return item;
    }
    if (item.resource === Resources.BIG_NOTICES) {
      item.name = t(determineNoticeTitleByRole(role, "_plural"));
      return item;
    } else {
      return item;
    }
  }

  checkSidebarPermission = (item) => {
    if (!this.canAccess(item)) {
      return null;
    }

    // TODO: redesign how the sidebar items are being evaluated and rendered so we don't
    // need to hack this role check here for a different "How To Use" link
    item = this.updateSidebarItemConfigurations(item);

    return this.renderSidebarItem(item);
  };

  urlForItem(item) {
    if (this.props.resourceId === undefined) {
      return `/${item.url}`;
    }

    return `/campaign_reporting/${this.props.resourceId}/${item.url}`;
  }

  render() {
    const { loggedInUser, sideBarExpanded, sideBarItemList } = this.props;
    if (!loggedInUser) {
      return null;
    }

    const sidebarClassName = `sidebar ${this.props.sideBarClasses} ${
      sideBarExpanded ? "sidebar--expanded" : ""
    }`;

    return (
      <div className={sidebarClassName}>
        <div className="sidebar__main-content">
          {sideBarItemList.map(this.checkSidebarPermission.bind(this))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Sidebar);
