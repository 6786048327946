import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VCPropertyContent from "./VCPropertyContent";
import NonVCPropertyContent from "./NonVCPropertyContent";
import PropertyDataHelper from "../services/actions/PropertyDataHelper";

export default function PropertyContentContainer() {
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;
  const [property, setProperty] = useState(null);

  useEffect(() => {
    fetchPropertyInfo();
  }, []);

  async function fetchPropertyInfo() {
    const propertyData = await PropertyDataHelper.fetchPropertyScreenContentInfo(id);
    setProperty(propertyData);
  }

  function emptyContentView() {
    return <h3 className="mt-3 text-center">{t("keywords.screenRelated.noPreviewAvailable")}</h3>;
  }

  function PropertyContentView() {
    if (!property) {
      return emptyContentView();
    }
    if (property.onVCNetwork()) {
      return <VCPropertyContent propertyId={id} />;
    }
    const contentUrl = property.fetchContentUrl();
    if (contentUrl) {
      return <NonVCPropertyContent contentUrl={contentUrl} />;
    }
    return emptyContentView();
  }

  return <PropertyContentView />;
}
