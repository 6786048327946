import React, { useEffect, useState } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Form from "react-bootstrap/Form";

import TextInput from "../../Global/Widgets/textInput";
import Dropdown from "../../Global/Widgets/updatedWidgets/dropDown";

import AdvertiserDataHelper from "../services/AdvertiserDataHelper";
import { NEW_OBJECT_VALUE } from "../../../utilities/FormDataHelper";
import UserDataHelper from "../../../utilities/UserDataHelper";
import UserSerializer from "../../../utilities/UserSerializer";
import {
  apiObjectSelectorOptions,
  objectSelectorOptions,
} from "../../../utilities/dropDownFormatter";

import FormStatus from "../../../utilities/FormStatus";
import NewAdvertiserForm from "./CampaignGroupProposalForm/NewAdvertiserForm";

export default function CampaignGroupProposalForm({ readOnly, formStatus }) {
  const [advertisers, setAdvertisers] = useState([]);
  const [viOwners, setViOwners] = useState([]);
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const advertiserId = useWatch({ name: "advertiser_id" });

  useEffect(() => {
    loadAdvertisers();
    loadViOwners();
  }, []);

  async function loadAdvertisers() {
    const advertiserData = await AdvertiserDataHelper.fetchAdvertisers();
    setAdvertisers(advertiserData);
  }

  async function handleSaveAdvertiser(newAdvertiserId) {
    await loadAdvertisers();
    setValue("advertiser_id", newAdvertiserId);
  }

  async function loadViOwners() {
    const accountManagerData = await UserDataHelper.fetchAccountManagers();
    const accountManagerFormData = accountManagerData.map((user) =>
      UserSerializer.deserialize(user)
    );
    setViOwners(accountManagerFormData);
  }

  function advertiserOptions() {
    const advertiserList = apiObjectSelectorOptions(advertisers);
    const newAdvertiserOption = {
      label: `*** ${t("advertiserTerms.addNewAdvertiser")}`,
      value: NEW_OBJECT_VALUE,
    };
    return [newAdvertiserOption, ...advertiserList];
  }

  if (!advertisers || !viOwners || formStatus === FormStatus.LOADING) {
    return <div>{t("keywords.appRelated.loading")}</div>;
  }

  return (
    <>
      <div className="flexForm">
        <div className="flexForm__group">
          <Form.Group className="flexForm__field--xl">
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value, name } }) => (
                <TextInput
                  readOnly={readOnly}
                  label={t("proposalTerms.proposalName")}
                  value={value}
                  handleChange={onChange}
                  name={name}
                  required
                  type="text"
                />
              )}
            />
          </Form.Group>
          <Form.Group className="flexForm__field">
            <Controller
              control={control}
              name="advertiser_id"
              render={({ field: { onChange, value, name } }) => (
                <Dropdown
                  readOnly={readOnly}
                  value={value}
                  handleChange={onChange}
                  name={name}
                  includeBlank
                  label={t("advertiserTerms.advertiser")}
                  options={advertiserOptions()}
                />
              )}
            />
          </Form.Group>
          <Form.Group className="flexForm__field">
            <Controller
              control={control}
              name="vi_owner_id"
              render={({ field: { onChange, value, name } }) => (
                <Dropdown
                  readOnly={readOnly}
                  value={value}
                  handleChange={onChange}
                  name={name}
                  includeBlank
                  label={t("keywords.campaignRelated.viOwner")}
                  options={objectSelectorOptions(viOwners, "name")}
                />
              )}
            />
          </Form.Group>
        </div>
      </div>
      <NewAdvertiserForm show={advertiserId === NEW_OBJECT_VALUE} onSave={handleSaveAdvertiser} />
    </>
  );
}

CampaignGroupProposalForm.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  formStatus: PropTypes.string.isRequired,
};
