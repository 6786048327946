// React
import React, { Component } from "react";

import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class PropertyContentRow extends Component {
  createLinkName = (cellData) => {
    const objectName = cellData[this.props.fieldName];
    if (!objectName && this.props.type === "Big Notices") {
      const imageName = `${cellData.background.name}`;
      return imageName;
    }
    return objectName;
  };

  slotCount(rowData) {
    const { slotCount } = this.props;
    if (slotCount) {
      return rowData.length > 0 ? slotCount : 0;
    }
    return rowData.length;
  }

  linkPath(cellData) {
    const { type } = this.props;
    if (type === "RSS Feeds") {
      return `/rss-feeds/edit/${cellData.id}`;
    }
    if (type === "Big Notices") {
      return `/bignotice/edit/${cellData.id}`;
    }
    return `/campaign/ads/${cellData.id}`;
  }

  canClick() {
    const { type } = this.props;
    if (type === "Big Notices") {
      return true;
    }
    return false;
  }

  renderCell(cellData) {
    const linkName = this.createLinkName(cellData);
    return (
      <li key={`${this.props.type}-${cellData.id}`} className="p-1">
        <Link
          to={this.linkPath(cellData)}
          className={this.canClick(cellData) ? "" : "u-text-force-static"}
        >
          {linkName}
        </Link>
      </li>
    );
  }

  render() {
    const { rowData, slotName, t } = this.props;
    const slotCount = this.slotCount(rowData);
    const slotText = t("keywords.screenRelated.slot", { count: parseInt(slotCount, 10) });
    const slotTitle = t("dashboard.panel.schedule.slotTitle", {
      name: slotName,
      number: slotCount,
      slots: slotText,
    });

    return (
      <div className="property-content-row">
        <span className="property-content-row__title">{slotTitle}</span>
        <ul className="u-pad-left-small">{rowData.map(this.renderCell.bind(this))}</ul>
      </div>
    );
  }
}

export default withTranslation()(PropertyContentRow);
