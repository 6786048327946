import _ from "lodash";
import { useTranslation } from "react-i18next";
import React, { useContext, useState, useCallback } from "react";
import PropTypes from "prop-types";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Spinner from "react-bootstrap/Spinner";
import CampaignPropertyTable from "../CampaignPropertyTable";
import PropertySelectionById from "../PropertySelectionById";
import PropertySelectionByTable from "../PropertySelectionByTable";
import { idArrayFromString } from "../../../../../../utilities/stringHelpers";
import { UserContext } from "../../../../../../App/routeHelpers/privateComponent";

export default function CampaignPropertySelectionContainer({
  selectedCampaignPropertyIds,
  elev8Properties,
  startAt,
  endAt,
  baseRate,
  currentRateValue,
  fullScreen,
  loopSpotsPerProperty,
  updatePropertySelection,
  readOnly,
}) {
  const { t } = useTranslation();
  const [tableView, togglePropertySelectionMethod] = useState(false);
  const [rawPropertiesByBulkIds, setRawPropertyByBulkIdsString] = useState(
    selectedCampaignPropertyIds.toString()
  );
  const loggedInUser = useContext(UserContext);
  const availablePropertyIds = elev8Properties.map((a) => a.attributes.id);
  const propertiesLoaded = !!elev8Properties.length;

  const updatePropertyTableSelection = useCallback((ids) => {
    const selectedPropertyIds = Object.keys(_.omit(ids, "undefined"));
    setRawPropertyByBulkIdsString(selectedPropertyIds.toString());
    updatePropertySelection(selectedPropertyIds);
  }, []);

  function convertIdsInTableFormat(selectedIds) {
    const idsInTableFormat = {};
    for (const id of selectedIds) {
      idsInTableFormat[id] = true;
    }
    return idsInTableFormat;
  }

  function changePropertySelectionMethod(tableView) {
    togglePropertySelectionMethod(tableView);
  }

  function updateBulkIdSelection(inputtedString) {
    const extractedIds = idArrayFromString(inputtedString);
    let validSelectedPropertyIds = extractedIds.filter((id) =>
      availablePropertyIds.includes(parseInt(id, 10))
    );
    validSelectedPropertyIds = [...new Set(validSelectedPropertyIds)];
    updatePropertySelection(validSelectedPropertyIds);
    setRawPropertyByBulkIdsString(inputtedString);
  }

  function renderLoading() {
    return (
      <>
        {t("propertyTerms.loadingProperties")}...{" "}
        <Spinner animation="border" role="status" variant="primary" size="sm" />
      </>
    );
  }

  function renderSelectionOptions() {
    return (
      <ToggleButtonGroup
        type="radio"
        name="options"
        value={tableView}
        onChange={changePropertySelectionMethod}
      >
        <ToggleButton id="by-id" variant="outline-primary" value={false}>
          {t("propertySelectionTerms.selectByIds")}
        </ToggleButton>
        <ToggleButton id="from-list" variant="outline-primary" value>
          {t("propertySelectionTerms.selectFromList")}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }

  function renderSelectionSectionTop() {
    return (
      <div className="propertySelectionSection">
        <div className="propertySelectionSection__header">
          {propertiesLoaded && t("propertyTerms.properties")} {!propertiesLoaded && renderLoading()}
        </div>
        {propertiesLoaded && renderSelectionOptions()}
      </div>
    );
  }

  function renderSelectionTypes() {
    return tableView ? (
      <PropertySelectionByTable
        availableProperties={elev8Properties}
        selectedPropertyIds={convertIdsInTableFormat(selectedCampaignPropertyIds)}
        onSelectedRowsChange={updatePropertyTableSelection}
        startAt={startAt}
        endAt={endAt}
        baseRate={baseRate}
        currentRateValue={currentRateValue}
        fullScreen={fullScreen}
        loopSpotsPerProperty={loopSpotsPerProperty}
        canSelectFromMap={false}
      />
    ) : (
      <div>
        <PropertySelectionById
          updateBulkIdSelection={updateBulkIdSelection}
          validIds={selectedCampaignPropertyIds}
          rawPropertiesByBulkIds={rawPropertiesByBulkIds}
          availableProperties={elev8Properties}
          userEmail={loggedInUser.email}
        />
      </div>
    );
  }

  if (readOnly) {
    const selectedProperties = elev8Properties.filter((property) =>
      selectedCampaignPropertyIds.includes(String(property.attributes.id))
    );
    return (
      <CampaignPropertyTable
        properties={selectedProperties}
        startAt={startAt}
        endAt={endAt}
        loopSpotsPerProperty={loopSpotsPerProperty}
      />
    );
  }

  return (
    <React.Fragment>
      {renderSelectionSectionTop()}
      {propertiesLoaded && renderSelectionTypes()}
    </React.Fragment>
  );
}

CampaignPropertySelectionContainer.defaultProps = {
  elev8Properties: [],
  selectedCampaignPropertyIds: [],
  readOnly: false,
  baseRate: null,
  fullScreen: "false",
};

CampaignPropertySelectionContainer.propTypes = {
  selectedCampaignPropertyIds: PropTypes.array,
  startAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  endAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  baseRate: PropTypes.string,
  currentRateValue: PropTypes.number.isRequired,
  fullScreen: PropTypes.oneOf(["true", "false"]),
  loopSpotsPerProperty: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
  elev8Properties: PropTypes.array,
  updatePropertySelection: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};
