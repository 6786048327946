import _ from "lodash";

import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import FilterDropdown from "./FilterControls/FilterDropdown";
import FilterDropdownContent from "./FilterControls/FilterDropdownContent";

class FilterControls extends Component {
  static defaultProps = {
    setFilters: () => {
      console.warn("FilterControls: props.setFilters prop required");
    },
    selectedCities: [],
    selectedPropertyTypes: [],
  };

  getActiveFilters() {
    let activeFilters = [];
    activeFilters = activeFilters.concat(this.props.selectedCities);
    activeFilters = activeFilters.concat(this.props.selectedPropertyTypes);
    return activeFilters;
  }

  toggleCity = (city) => {
    let { selectedCities } = this.props;
    if (selectedCities.indexOf(city) >= 0) {
      selectedCities = _.without(selectedCities, city);
    } else {
      selectedCities.push(city);
    }
    this.props.setFilters("cityFilters", selectedCities);
  };

  allCities = () => {
    this.props.setFilters("cityFilters", []);
  };

  toggleProperty = (propertyType) => {
    const { selectedPropertyTypes } = this.props;
    if (selectedPropertyTypes.indexOf(propertyType) >= 0) {
      _.pull(selectedPropertyTypes, propertyType);
    } else {
      selectedPropertyTypes.push(propertyType);
    }
    this.props.setFilters("propertyTypeFilters", selectedPropertyTypes);
  };

  allProperties = () => {
    this.props.setFilters("propertyTypeFilters", []);
  };

  removeFilter = (filter) => {
    const { selectedPropertyTypes, selectedCities } = this.props;
    _.pull(selectedPropertyTypes, filter);
    _.pull(selectedCities, filter);
    this.props.setFilters("cityFilters", selectedCities);
    this.props.setFilters("propertyTypeFilters", selectedPropertyTypes);
  };

  clearFilters() {
    this.props.setFilters("propertyTypeFilters", []);
    this.props.setFilters("cityFilters", []);
  }

  renderActiveFilters() {
    const activeFilters = this.getActiveFilters();

    return (
      <React.Fragment>
        {activeFilters.map((filter) => (
          <button
            type="button"
            className="filterControl__filterButton"
            onClick={() => this.removeFilter(filter)}
            key={filter}
          >
            <i className="fa fa-times" /> {filter}
          </button>
        ))}

        {activeFilters.length ? (
          ""
        ) : (
          <small>{!this.props.disabledFilters ? <em> No filters applied </em> : null}</small>
        )}
      </React.Fragment>
    );
  }

  render() {
    const activeFilters = this.getActiveFilters();

    let clearAllButton = null;
    if (activeFilters.length > 0) {
      clearAllButton = (
        <Nav.Link onClick={this.clearFilters.bind(this)}>
          <b>Clear all</b>
        </Nav.Link>
      );
    }

    return (
      <React.Fragment>
        <Row className="align-items-center vertical-impression-navbar__filter" g={0}>
          <Col
            xs={{ span: 11, order: 1 }}
            lg={{ span: 12, order: 2 }}
            className="d-flex flex-wrap justify-content-start justify-content-lg-center"
          >
            {this.renderActiveFilters()}
          </Col>
          <Col xs={{ span: 1, order: 2 }} lg={{ span: 0, order: 3 }}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ border: "none" }}>
              <i className="fa fa-ellipsis-v" style={{ color: "white" }} />
            </Navbar.Toggle>
          </Col>
          <Col xs={{ span: 12, order: 3 }} lg={{ order: 1 }}>
            <Navbar.Collapse className="filterControl" id="basic-navbar-nav">
              <Nav className="w-100 justify-content-center">
                <FilterDropdown
                  title="Filter by market"
                  id="nav-dropdown"
                  disabled={this.props.disabledFilters}
                >
                  <FilterDropdownContent
                    filterValues={this.props.uniqueCities}
                    activeFilterValues={this.props.selectedCities}
                    activateAllFilters={this.allCities}
                    toggleFilter={this.toggleCity}
                  />
                </FilterDropdown>
                <FilterDropdown
                  title="Filter by property"
                  id="nav-dropdown"
                  disabled={this.props.disabledFilters}
                >
                  <FilterDropdownContent
                    filterValues={this.props.uniquePropertyTypes}
                    activeFilterValues={this.props.selectedPropertyTypes}
                    activateAllFilters={this.allProperties}
                    toggleFilter={this.toggleProperty}
                    hideAutocomplete
                  />
                </FilterDropdown>
                {clearAllButton}
              </Nav>
            </Navbar.Collapse>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

FilterControls.propTypes = {
  uniquePropertyTypes: PropTypes.array.isRequired,
  uniqueCities: PropTypes.array.isRequired,
  setFilters: PropTypes.func,
  selectedPropertyTypes: PropTypes.array,
  selectedCities: PropTypes.array,
};

export default withTranslation()(FilterControls);
