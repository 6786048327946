import React, { Component } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

import { withTranslation } from "react-i18next";
import TargetingTimeSelector from "./targetingTimeSelector";
import TargetingDemographicSelector from "./targetingDemographicSelector";
import TargetingWeatherSelector from "./targetingWeatherSelector";

class TargetingSelector extends Component {
  constructor(props) {
    super(props);

    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleDemographicChange = this.handleDemographicChange.bind(this);
    this.handleWeatherChange = this.handleWeatherChange.bind(this);
  }

  // -- Private -- //

  handleTimeChange(daysOfWeek, periodsOfDay) {
    const newTargeting = _.cloneDeep(this.props.targetingData);
    newTargeting.daysOfWeek = daysOfWeek;
    newTargeting.periodsOfDay = periodsOfDay;

    this.props.onTargetingChange(newTargeting);
  }

  handleDemographicChange(gender, age) {
    const newTargeting = _.cloneDeep(this.props.targetingData);
    newTargeting.gender = gender;
    newTargeting.age = age;

    this.props.onTargetingChange(newTargeting);
  }

  handleWeatherChange(weather) {
    const newTargeting = _.cloneDeep(this.props.targetingData);
    newTargeting.weather = weather;

    this.props.onTargetingChange(newTargeting);
  }

  renderTimeSelector() {
    if (this.props.showTime) {
      return (
        <div>
          <TargetingTimeSelector
            daysOfWeek={this.props.targetingData.daysOfWeek}
            periodsOfDay={this.props.targetingData.periodsOfDay}
            onTimeChange={this.handleTimeChange}
            errors={this.props.errors}
          />
        </div>
      );
    }
    return null;
  }

  renderDemographicSelector() {
    const { t } = this.props;

    if (this.props.showDemographic) {
      return (
        <div>
          <h4> {t("keywords.targetRelated.preferredAudience")}</h4>
          <TargetingDemographicSelector
            gender={this.props.targetingData.gender}
            age={this.props.targetingData.age}
            onDemographicChange={this.handleDemographicChange}
          />
        </div>
      );
    }
    return null;
  }

  renderWeatherSelector() {
    if (this.props.hideWeatherSelector) return null;

    const { t } = this.props;

    if (this.props.showWeather) {
      return (
        <div>
          <h6> {t("keywords.targetRelated.additionalTargets")}</h6>
          <TargetingWeatherSelector
            weather={this.props.targetingData.weather}
            onWeatherChange={this.handleWeatherChange}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    if (!this.props.targetingData) return null;
    return (
      <div className="overflow-none">
        {this.renderTimeSelector()}
        {this.renderDemographicSelector()}
        {this.renderWeatherSelector()}
      </div>
    );
  }
}

TargetingSelector.defaultProps = {
  showTime: true,
  showDemographic: true,
  showWeather: true,
};

TargetingSelector.propTypes = {
  targetingData: PropTypes.shape({
    daysOfWeek: PropTypes.arrayOf(PropTypes.any),
    periodsOfDay: PropTypes.arrayOf(PropTypes.any),
    gender: PropTypes.arrayOf(PropTypes.any),
    age: PropTypes.arrayOf(PropTypes.any),
    weather: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,

  showTime: PropTypes.bool.isRequired,
  showDemographic: PropTypes.bool.isRequired,
  showWeather: PropTypes.bool.isRequired,

  onTargetingChange: PropTypes.func.isRequired,
};

export default withTranslation()(TargetingSelector);
