import React from "react";
import Rodal from "rodal";
import { withTranslation } from "react-i18next";
import Spinner from "./spinner";

function SavingNotificationModal(props) {
  const { className, t } = props;

  function noop() {}

  return (
    <Rodal
      className={className}
      showCloseButton={false}
      closeOnEsc={false}
      closeMaskOnClick={false}
      visible
      onClose={noop}
    >
      <strong> {t("keywords.appRelated.saveChanges")} </strong>
      <hr />
      <div>
        <p>{t("keywords.appRelated.dontExit")}</p>
        <Spinner />
      </div>
    </Rodal>
  );
}

export default withTranslation()(SavingNotificationModal);
