import React, { Component } from "react";
import { unstableHistory } from "@App/history";

class BrandText extends Component {
  getLogo = () => {
    const theme = this.props.whiteBackground ? "dark" : "light";
    const logo = this.props.useVCity ? "vc" : "vi";
    return `${logo}-${theme}`;
  };

  goToLink = () => {
    if (this.props.useExternalLink) {
      if (this.props.useVCity) {
        window.open("https://www.verticalcity.com/", "_blank");
      } else {
        window.open("https://www.verticalimpression.com/", "_blank");
      }
    } else {
      unstableHistory.push("/");
    }
  };

  brandText() {
    const logo = this.getLogo();
    return (
      <div className="flex">
        <div className={`logo logo--${logo} logo--${this.props.size}`} />
        {this.renderEnvTag()}
      </div>
    );
  }

  renderEnvTag() {
    if (process.env.NODE_ENV === "production") {
      return null;
    }

    const envName =
      process.env.NODE_ENV.charAt(0).toUpperCase() + process.env.NODE_ENV.substring(1);

    return <strong className="u-color-orange-lighter">&nbsp;-&nbsp;{envName}</strong>;
  }

  render() {
    return (
      <a onClick={this.goToLink} className="u-cursor-pointer">
        {this.brandText()}
      </a>
    );
  }
}

export default BrandText;
