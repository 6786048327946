/* Main Filter Container */

import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Rodal from "rodal";
import SavingNotificationModal from "@components/Global/Widgets/views/savingNotificationModal";

class AdSavingModal extends Component {
  noop = () => {};

  render() {
    const { status, saveChanges, cancel, title } = this.props;
    if (status.opened && status.uploading === "saving") {
      return <SavingNotificationModal />;
    }

    return (
      <Rodal
        visible={status.opened}
        className="config-modal"
        showCloseButton={false}
        onClose={this.noop}
      >
        <h4> {title} </h4>
        <hr />

        <div>
          {status.uploading === "inactive" ? (
            <div>
              <p> Do you want to save these configurations?</p>
              <p> Changes may affect other components of the Campaign </p>

              <div className="modal-buttons">
                <div className="save" onClick={saveChanges}>
                  Save Changes
                </div>
                <div className="cancel" onClick={cancel}>
                  Cancel
                </div>
              </div>
            </div>
          ) : (
            <div>
              {status.uploading === "error" ? (
                <div>
                  <p> Something Went Wrong... </p>
                  <p>Please try again. </p>

                  <div className="modal-buttons">
                    <div className="save" onClick={saveChanges}>
                      Save Changes
                    </div>
                    <div className="cancel" onClick={cancel}>
                      Cancel
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <p> Changes Have Been Saved</p>
                  <div className="modal-buttons">
                    <div className="cancel" onClick={cancel}>
                      Ok
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Rodal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      /* filteredProperties:propertyListingsSelector(state) */
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(AdSavingModal);
