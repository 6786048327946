import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import FormStatus from "../../../../utilities/FormStatus";
import TopBar from "../../../Proposal/ProposalTopBar/TopBar";
import ConfirmModal from "../../../Global/Widgets/views/ConfirmModal";

export default function BigNoticeFormTopBar({ formStatus, id, deleteNotice, title, disableSave }) {
  const { t } = useTranslation();
  const [showModal, toggleModal] = useState(false);

  function renderDeleteNoticeConfirmationModal() {
    return (
      <ConfirmModal
        title={t("keywords.buttonActions.deleting")}
        show={showModal}
        confirm={{
          label: t("keywords.buttonActions.delete"),
          variant: "danger",
          action: onClickDeleteNotice,
        }}
        cancel={{
          label: t("keywords.buttonActions.cancel"),
          variant: "outline-secondary",
          action: () => toggleModal(false),
        }}
      >
        {t("keywords.buttonActions.deleteMessage")}
      </ConfirmModal>
    );
  }

  function onClickDeleteNotice() {
    deleteNotice();
    toggleModal(false);
  }

  const saveButton = (
    <Button type="submit" variant="success" key="notice-save" disabled={disableSave}>
      {formStatus === FormStatus.SAVING
        ? `${t("keywords.buttonActions.saving")}...`
        : t("keywords.buttonActions.save")}
    </Button>
  );

  const deleteButton = (
    <Button
      type="button"
      disabled={formStatus === FormStatus.SAVING}
      variant="danger"
      key="notice-delete"
      onClick={() => toggleModal(true)}
    >
      {t("keywords.buttonActions.delete")}
    </Button>
  );

  const actionButtons = [];
  if (id) {
    actionButtons.push(saveButton);
    actionButtons.push(deleteButton);
  } else {
    actionButtons.push(saveButton);
  }

  return (
    <>
      <TopBar
        title={title}
        navigation={{
          path: "/bignotice",
          label: t("noticeTerms.goBack"),
        }}
        actions={actionButtons}
      />
      {renderDeleteNoticeConfirmationModal()}
    </>
  );
}

BigNoticeFormTopBar.defaultProps = {
  id: null,
  disableSave: false,
};

BigNoticeFormTopBar.propTypes = {
  formStatus: PropTypes.oneOf(Object.values(FormStatus)).isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  deleteNotice: PropTypes.func.isRequired,
  disableSave: PropTypes.bool,
};
