const UserSerializer = {
  deserialize(data) {
    return deserializingData(data);
  },
};
export default UserSerializer;

function deserializingData(data = null) {
  const attributes = data ? data.attributes : {};
  return attributes;
}
