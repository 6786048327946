// React
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Form from "react-bootstrap/Form";
import { withTranslation } from "react-i18next";

// Actions
import { fetchRssFeeds } from "@components/Client/RssFeed/services/actions/rssFeedActions";
import SubmitButtonForm from "@components/Global/Widgets/views/submitButtonForm";
import { addFlashMessage } from "@components/Interface/General/services/actions/applicationStateActions";
import { updateProperty, updatePropertyImage } from "../services/actions/propertyFormActions";

// custom made components
import AddressSection from "./addressSection";
import CharacteristicsSection from "./characteristicsSection";
import ContactSection from "./contactSection";
import AdditionalSection from "./additionalSection";
import ImageSection from "./ImageSection";
import BillingSection from "./BillingSection";
import UsersSection from "./usersSection";

class PropertyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        name: "",
        address_line1: "",
        address_line2: "",
        address_postal_code: "",
        address_city: "",
        address_province: "",
        address_country: "",
        amenities: "",
        contact_phone: "",
        contact_name: "",
        contact_email: "",
        latitude: 0,
        longitude: 0,
        number_of_units: 0,
        resident_parking: "",
        visitor_parking: "",
        building_sqft: 0,
        floors: 0,
        property_type: "Condo",
        chosenArea: "Downtown, Edmonton, Canada",
        market: null,
        has_gym: "",
        has_pool: "",
        adult_only: "",
        family_friendly: "",
        screen_count: 0,
        allows_dogs: "",
        allows_cats: "",
        status: "pending",
        propertyImage: "",
        propertyLogo: "",
        chosenFeeds: [],
        content_restriction: "",
        communication_language: "",
        revenue_share: "",
        install_date: null,
        rev_share_start_at: null,
      },
      validated: false,
    };
    this.submitAction = this.submitAction.bind(this);
  }

  componentDidMount() {
    this.props.fetchRssFeeds();
    this.setPropertyFormInfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.propertyForm !== this.props.propertyForm) {
      this.setPropertyFormInfo();
    }
  }

  setPropertyFormInfo() {
    const updatedPropertyForm = this.props.propertyForm;
    // tailor rss data for multiselect component
    const chosenFeeds = this.props.propertyForm.feeds.map((feed) => ({
      value: feed.id,
      label: feed.name,
    }));
    updatedPropertyForm.chosenFeeds = chosenFeeds;
    // tailor area data for dropdown component
    updatedPropertyForm.chosenArea = `${this.props.propertyForm.district.name}, ${this.props.propertyForm.municipality.name}, ${this.props.propertyForm.country.name}`;
    this.setState({ formFields: updatedPropertyForm });
  }

  throwErrorNotification = (event, message) => {
    event.stopPropagation();
    this.props.addFlashMessage("danger", message);
  };

  checkValidity = (event) => {
    if (this.state.formFields.status === "active" && this.state.formFields.install_date == null) {
      this.throwErrorNotification(event, "keywords.propertyRelated.installDate.activeStatusError");
      return false;
    }
    if (
      this.state.formFields.rev_share_start_at != null &&
      this.state.formFields.install_date != null &&
      this.state.formFields.install_date > this.state.formFields.rev_share_start_at
    ) {
      this.throwErrorNotification(
        event,
        "keywords.propertyRelated.installDate.invalidStartDateError"
      );
      return false;
    }
    if (this.state.formFields.revenue_share < 0 || this.state.formFields.revenue_share > 99) {
      this.throwErrorNotification("keywords.propertyRelated.revenueRelated.rangeError");
      return false;
    }
    if (this.checkOtherFields() === false) {
      this.throwErrorNotification(event, "keywords.errorRelated.incompleteForm");
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const valid = this.checkValidity(event);
    if (valid) {
      this.submitAction();
    }
    this.setState({ validated: true });
  };

  handleChange = (target) => {
    this.setState((prevState) => ({
      formFields: { ...prevState.formFields, [target.name]: target.value },
    }));
  };

  handleImageChange = (name, media) => {
    this.props.updatePropertyImage(name, media);
  };

  submitAction() {
    const formToSubmit = this.state.formFields;
    // add image ids and location to form
    formToSubmit.photo_id = this.props.propertyPhoto ? this.props.propertyPhoto.mediaId : null;
    formToSubmit.logo_id = this.props.propertyLogo ? this.props.propertyLogo.mediaId : null;
    this.props.updateProperty(formToSubmit);
  }

  checkOtherFields() {
    if (this.props.isPropertyManager) {
      return true;
    }
    if (this.props.chosenArea === []) {
      return false;
    }

    return true;
  }

  render() {
    if (!this.state.formFields || !this.props.feeds) {
      return null;
    }
    const { isPropertyManager, propertyPhoto, propertyLogo } = this.props;
    return (
      <div className="main-form">
        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={(e) => this.handleSubmit(e)}
          className="main-form__container"
        >
          <ImageSection
            handleImageChange={this.handleImageChange}
            photo={propertyPhoto}
            logo={propertyLogo}
            isPropertyManager={isPropertyManager}
          />
          <ContactSection
            formFields={this.state.formFields}
            handleChange={this.handleChange}
            isPropertyManager={isPropertyManager}
          />
          <AddressSection
            isPropertyManager={isPropertyManager}
            handleChange={this.handleChange}
            formFields={this.state.formFields}
          />
          <CharacteristicsSection
            isPropertyManager={isPropertyManager}
            handleChange={this.handleChange}
            formFields={this.state.formFields}
          />
          <AdditionalSection
            isPropertyManager={isPropertyManager}
            handleChange={this.handleChange}
            formFields={this.state.formFields}
            feeds={this.props.feeds}
          />
          <BillingSection
            isPropertyManager={isPropertyManager}
            handleChange={this.handleChange}
            formFields={this.state.formFields}
          />
          <UsersSection users={this.props.propertyForm.users} />
          <SubmitButtonForm
            formId={this.props.propertyForm.id}
            exitRoute="/dashboard"
            formColor="rgb(49, 50, 80)"
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    feeds: state.client.rssFeed.all,
    propertyLogo: state.property.general.logo,
    propertyPhoto: state.property.general.photo,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateProperty,
      updatePropertyImage,
      fetchRssFeeds,
      addFlashMessage,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertyForm));
