import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

export default function CampaignProposalLabel({ campaignProposalIndex }) {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const watchCampaignProposalName = useWatch({
    name: `campaign_proposals.${campaignProposalIndex}.name`,
  });
  const [campaignProposalName, setCampaignProposalName] = useState("");

  // We can't simply watch the name of the campaign proposal, because it doesn't
  // recalculate immediately on a changed campaignProposalIndex prop.
  useEffect(() => {
    setCampaignProposalName(getValues(`campaign_proposals.${campaignProposalIndex}.name`));
  }, [campaignProposalIndex, watchCampaignProposalName, getValues]);

  if (campaignProposalIndex == null) return t("proposalTerms.noCampaignSelected");
  return <>{campaignProposalName || `Flight ${campaignProposalIndex + 1}`}</>;
}

CampaignProposalLabel.defaultProps = {
  campaignProposalIndex: null,
};

CampaignProposalLabel.propTypes = {
  campaignProposalIndex: PropTypes.number,
};
