import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import withRouter from "../../../../../hooks/withRouter";
import SideBarItemList from "./SideBarItemList";
import Elev8Sidebar from "../../../../Interface/SideBar/views/sidebar";

class Sidebar extends Component {
  render() {
    // router is coming from withRouter
    const { router, sideBarExpanded, toggleSideBar } = this.props;

    return (
      <Elev8Sidebar
        sideBarExpanded={sideBarExpanded}
        toggleSideBar={toggleSideBar}
        sideBarItemList={SideBarItemList()}
        loggedInUser
        sideBarClasses="sidebar--light sidebar--extra-expanded-height"
        textColorClass="u-color-primary-blue"
        activeTextColorClass="u-color-purple"
        resourceId={router.params.report_code}
      />
    );
  }
}

const translatedSidebar = withTranslation()(Sidebar);

export default withRouter(translatedSidebar);
