import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import Sidebar from "./Sidebar/Sidebar";
import ApplicationLayout from "../../../Global/Layouts/application-layout";
import TopBar from "./TopBar/TopBar";

const PAGES_WITH_NO_FILTERING = ["creative", "report"];

class CampaignReportingLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sideBarExpanded: false,
    };
  }

  toggleSideBar = (newState) => {
    if (newState !== undefined) {
      this.setState({ sideBarExpanded: newState });
    } else {
      this.setState((prevState) => ({ sideBarExpanded: !prevState.sideBarExpanded }));
    }
  };

  disableFilters = () => {
    if (PAGES_WITH_NO_FILTERING.includes(this.props.pageName)) {
      return true;
    }

    return false;
  };

  render() {
    return (
      <ApplicationLayout wrapperClass="reportingContent">
        <ApplicationLayout.Header>
          <TopBar
            toggleSideBar={this.toggleSideBar}
            selectedCities={this.props.selectedCities}
            selectedPropertyTypes={this.props.selectedPropertyTypes}
            uniquePropertyTypes={this.props.uniquePropertyTypes}
            uniqueCities={this.props.uniqueCities}
            setFilters={this.props.setFilters}
            campaignName={this.props.campaignName}
            campaignId={this.props.campaignId}
            campaignGroupId={this.props.campaignGroupId}
            disabledFilters={this.disableFilters()}
          />
        </ApplicationLayout.Header>

        <ApplicationLayout.Navigation>
          <Sidebar
            sideBarExpanded={this.state.sideBarExpanded}
            toggleSideBar={this.toggleSideBar}
          />
        </ApplicationLayout.Navigation>

        <ApplicationLayout.MainContent>
          {this.props.children}
        </ApplicationLayout.MainContent>
      </ApplicationLayout>
    );
  }
}

export default withTranslation()(CampaignReportingLayout);
