const CampaignTypeOptions = {
  advertising: {
    locale: "keywords.campaignRelated.types.advertising",
    requiresPayment: true,
  },
  complimentary: {
    locale: "keywords.campaignRelated.types.complimentary",
    requiresPayment: false,
  },
  internal: {
    locale: "keywords.campaignRelated.types.internal",
    requiresPayment: false,
  },
  property: {
    locale: "keywords.campaignRelated.types.property",
    requiresPayment: false,
  },
  rsm_local: {
    locale: "keywords.campaignRelated.types.rsm_local",
    requiresPayment: true,
  },
  rsm_national: {
    locale: "keywords.campaignRelated.types.rsm_national",
    requiresPayment: true,
  },
};

export default class CampaignTypes {
  static campaignTypeLocale(campaignType) {
    if (!CampaignTypeOptions.hasOwnProperty(campaignType)) {
      return "keywords.general.missingAttribute";
    }
    return CampaignTypeOptions[campaignType].locale;
  }

  static requiresPayment(campaignType) {
    if (!CampaignTypeOptions.hasOwnProperty(campaignType)) {
      return false;
    }
    return CampaignTypeOptions[campaignType].requiresPayment;
  }
}
