import React, { Component } from "react";
import PropTypes from "prop-types";

class SearchBar extends Component {
  constructor(props) {
    super(props);
  }

  handleChange = (event) => {
    this.props.searchAction(event.target.value);
  };

  render() {
    const { searchText } = this.props;
    return (
      <div className="search-bar px-1">
        <i className="fa fa-search" />
        <input
          type="text"
          placeholder="Search for..."
          onChange={this.handleChange}
          value={searchText}
        />
      </div>
    );
  }
}

SearchBar.propTypes = {
  searchText: PropTypes.string.isRequired,
  searchAction: PropTypes.func.isRequired,
};

export default SearchBar;
