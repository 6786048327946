import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class PageNotFound extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return <div>{t("keywords.errorRelated.404")}</div>;
  }
}

export default connect(null, null)(withTranslation()(PageNotFound));
