// ALL ACTIONS FOR PROPERTIES

import moment from "moment";
import i18n from "../../../../i18n";

import {
  // Properties
  FETCH_PROPERTIES,
  SET_FEED_PROPERTIES,
} from "../../../../App/actionTypes";
import Elev8ApiService from "../../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../../lib/services/Elev8Api/lib/private/ApiTypes";

/** ***********************************************************
PROPERTY ACTIONS
************************************************************ */

export function getPropertyType(type) {
  switch (type) {
    case "Condo":
      return i18n.t("keywords.propertyRelated.type.condo");
    case "Commercial":
      return i18n.t("keywords.propertyRelated.type.commercial");
    case "Other":
      return i18n.t("keywords.propertyRelated.type.other");
    default:
      return i18n.t("keywords.propertyRelated.type.rental");
  }
}

/* eslint-disable func-names, no-undef, no-param-reassign */
export function setPropertyFormat(data) {
  return function (dispatch) {
    let flattenedResult = data.map((property) => {
      if (!("municipality_id" in property)) {
        property.municipality_id = property.municipality.id;
      }

      property.area1 = `${property.address_line1}, ${property.address_city}, ${property.address_country}`;
      property.translatedPropertyType = getPropertyType(property.property_type);

      return _.mapValues(property, function (value) {
        return _.isObject(value) ? value.name : value;
      });
    });

    flattenedResult = _.sortBy(flattenedResult, function (property) {
      // eslint-disable-next-line new-cap
      return new moment(property.created_at).format("YYYY-MM-DD");
    }).reverse();

    dispatch({
      type: FETCH_PROPERTIES,
      payload: flattenedResult,
    });
  };
}

export function fetchProperties() {
  return function (dispatch) {
    Elev8ApiService.getOldEndpoint("properties")
      .then((response) => {
        dispatch(setPropertyFormat(response.data));
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log("Can't fetchProperties", e);
      });
  };
}
/* eslint-enable func-names, no-undef, no-param-reassign */

export function fetchPropertiesForUserForm() {
  return (dispatch) => {
    const sparseFieldSets = {
      include: ["network"],
      fields: {
        property: [
          "id",
          "name",
          "address_line1",
          "address_city",
          "address_province",
          "address_country",
          "contact_name",
          "property_type",
          "network",
        ],
      },
      network: ["id", "name"],
    };

    Elev8ApiService.index(ApiTypes.property, sparseFieldSets).then((properties) => {
      const finalizedPropertyFormat = properties.map((propertyResult) => {
        const property = propertyResult.attributes;

        property.area1 = `${property.address_line1}, ${property.address_city}, ${property.address_country}`;
        property.translatedPropertyType = getPropertyType(property.property_type);
        property.networkName = propertyResult.networkName;

        return property;
      });

      dispatch({
        type: FETCH_PROPERTIES,
        payload: finalizedPropertyFormat,
      });
    });
  };
}

export function setFeedProperties(properties) {
  return {
    type: SET_FEED_PROPERTIES,
    payload: properties,
  };
}
