import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import "../style/css/custom.scss";
import { I18nextProvider } from "react-i18next";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { AUTH_USER } from "./App/actionTypes";
import i18n from "./i18n";
import { store } from "./createStore";
import Elev8ApiService from "./lib/services/Elev8Api/Elev8ApiService";

// eslint-disable-next-line no-undef
const userId = localStorage.getItem("userId");

if (userId) {
  store.dispatch({ type: AUTH_USER });
}

Elev8ApiService.renewAuth();

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY,
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ["production"],
  plugins: [new BugsnagPluginReact(React)],
  onError(event) {
    if (userId != null) {
      /* eslint no-param-reassign: ["error", { "props": false }] */
      event.user = {
        id: JSON.parse(userId),
      };
    }
  },
});

// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = Bugsnag.getPlugin("react");

function AppProviders({ children }) {
  return (
    <ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <ReduxProvider store={store}>{children}</ReduxProvider>
      </I18nextProvider>
    </ErrorBoundary>
  );
}

export default AppProviders;
