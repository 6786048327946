import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

export default function PropertySelectionByTableFilters({
  properties,
  updateQueryAttributes,
  queryAttributes,
}) {
  const { t } = useTranslation();
  const showFilterDescription = "filterTerms.showAdvancedFilters";
  const hideFilterDescription = "filterTerms.hideAdvancedFilters";
  const [filterFields, setFilterFields] = useState([]);
  const [queryBuilderOperator, setQueryBuilderOperator] = useState("=");
  const [queryBuilderKey, setQueryBuilderKey] = useState("");
  const [queryBuilderValue, setQueryBuilderValue] = useState("");
  const [advancedFilters, setAdvancedFilterState] = useState(false);

  const boolFields = [
    "building_type_condo",
    "building_type_luxury_rental",
    "building_type_commercial",
    "facilities_gym",
    "facilities_pool",
    "pet_friendly_dogs",
    "pet_friendly_cats",
  ];

  useEffect(() => {
    loadFilterFields();
  }, []);

  function settingQueryBuilderValue(e) {
    if (boolFields.indexOf(e.target.name) !== -1) {
      setQueryBuilderValue(e.target.checked);
    } else {
      setQueryBuilderValue(e.target.value);
    }
  }

  function toggleFilter() {
    setAdvancedFilterState(!advancedFilters);
  }

  function settingQueryBuilderOperator(e) {
    setQueryBuilderOperator(e.target.value);
  }

  function settingQueryBuilderKey(e) {
    setQueryBuilderKey(e.target.value);
  }

  function canAddQuery() {
    return (
      queryBuilderKey.length > 0 && queryBuilderOperator.length > 0 && queryBuilderValue.length > 0
    );
  }

  function addQuery() {
    queryAttributes.push([queryBuilderKey, queryBuilderOperator, queryBuilderValue]);
    updateQueryAttributes(queryAttributes);
  }

  function removeQuery(event) {
    queryAttributes.splice(event.target.value, 1);
    updateQueryAttributes(queryAttributes);
  }

  function loadFilterFields() {
    properties.some((property) => {
      let fields = Object.keys(property.attributes);

      const localLogicLocationInfo = property.local_logic_location_info();
      if (localLogicLocationInfo) {
        const localLogicFieldsValues = localLogicLocationInfo.attributes.local_logic_fields;

        fields = fields.concat(Object.keys(localLogicFieldsValues));
        setFilterFields(fields);
        return true;
      }
    });
  }

  return (
    <>
      <Button variant="outline-dark btn-sm mb-2" onClick={toggleFilter}>
        {t(`${advancedFilters ? hideFilterDescription : showFilterDescription}`)}
      </Button>
      {advancedFilters ? (
        <Card>
          <Card.Body>
            <div className="p-2 mt-3">
              {queryAttributes.map((query, index) => (
                <Row className="m-2">
                  <Col>
                    <Form.Control type="text" value={query[0]} readOnly />
                  </Col>
                  <Col className="col-sm-1">
                    <Form.Control type="text" value={query[1]} readOnly />
                  </Col>
                  <Col>
                    <Form.Control type="text" value={query[2]} readOnly />
                  </Col>
                  <Col className="col-sm-1">
                    <Button variant="danger" value={index} onClick={removeQuery}>
                      {t("keywords.buttonActions.remove")}
                    </Button>
                  </Col>
                </Row>
              ))}
              <Row className="m-2">
                <Col>
                  <Form.Control
                    name="queryBuilderKey"
                    as="select"
                    onChange={settingQueryBuilderKey}
                  >
                    <option>{t("filterTerms.selectAField")}</option>
                    {filterFields.map((field) => (
                      <option value={field} key={field}>
                        {field}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col className="col-sm-1">
                  <Form.Control
                    name="queryBuilderOperator"
                    as="select"
                    onChange={settingQueryBuilderOperator}
                  >
                    <option value="=">=</option>
                    <option value="!=">!=</option>
                    <option value=">">&gt;</option>
                    <option value="<">&lt;</option>
                  </Form.Control>
                </Col>
                <Col>
                  <Form.Control
                    name="queryBuilderValue"
                    type="text"
                    placeholder={t("filterTerms.fieldValue")}
                    onChange={settingQueryBuilderValue}
                  />
                </Col>
                <Col className="col-sm-1">
                  <Button variant="primary" onClick={addQuery} disabled={!canAddQuery()}>
                    {t("keywords.buttonActions.add")}
                  </Button>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
      ) : null}
    </>
  );
}

PropertySelectionByTableFilters.propTypes = {
  properties: PropTypes.array.isRequired,
  updateQueryAttributes: PropTypes.func.isRequired,
  queryAttributes: PropTypes.array,
};
