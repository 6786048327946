import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";

import ReportingContent from "./components/ReportingContent";
import CampaignLocationMapSection from "./components/location/LocationMapSection";
import LocationList from "./components/location/LocationList";

class LocationPage extends Component {
  render() {
    return (
      <ReportingContent>
        <Row className="px-md-5">
          <Col lg className="reportingContent__col">
            <CampaignLocationMapSection properties={this.props.properties} />
          </Col>
        </Row>
        <Row className="px-md-5 d-none d-md-block">
          <Col lg className="reportingContent__col">
            <LocationList properties={this.props.properties} />
          </Col>
        </Row>
      </ReportingContent>
    );
  }
}

LocationPage.propTypes = {
  properties: PropTypes.array.isRequired,
};

export default withTranslation()(LocationPage);
