export function objectSelectorOptions(data, labelValue = "name") {
  return data.map((item) => ({
    value: item.id,
    label: item[labelValue],
  }));
}

export function apiObjectSelectorOptions(apiObjects, labelValue = "name") {
  if (!apiObjects) return [];

  return apiObjects.map(({ attributes }) => ({
    value: attributes.id,
    label: attributes[labelValue],
  }));
}
