import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useWatch } from "react-hook-form";

import { Button } from "react-bootstrap";

import ProposalSection from "../ProposalSection";

function CampaignProposalSelector({
  dirtyCampaignProposals,
  onAddCampaignProposal,
  onSelectCampaignProposal,
  readOnly,
  selectedCampaignProposalIndex,
}) {
  const { t } = useTranslation();

  const campaignProposals = useWatch({ name: "campaign_proposals", defaultValue: [] });

  useEffect(() => {
    if (selectedCampaignProposalIndexOutOfBounds()) {
      onSelectCampaignProposal(0);
    }
  }, [selectedCampaignProposalIndex, campaignProposals]);

  function selectedCampaignProposalIndexOutOfBounds() {
    if (selectedCampaignProposalIndex == null) {
      return !!campaignProposals.length;
    }
    return selectedCampaignProposalIndex >= campaignProposals.length;
  }

  function handleAddCampaignProposal() {
    onAddCampaignProposal(campaignProposals.length);
  }

  function proposalIsDirty(proposalIndex) {
    return dirtyCampaignProposals === true || !!dirtyCampaignProposals[proposalIndex];
  }

  function proposalButtonLabel(campaignProposal, index) {
    let text;
    if (campaignProposal.name) {
      text = campaignProposal.name;
    } else {
      text = `${t("keywords.campaignRelated.campaign")} ${index + 1}`;
    }

    const unsavedGlyph = "\u25CF";

    return (
      <>
        <span className="u-truncated">{text}</span>
        {proposalIsDirty(index) && (
          <span className="campaignProposalsSelector__selectButton--unsaved">{unsavedGlyph}</span>
        )}
      </>
    );
  }

  function selectedButtonVariant(campaignProposalIndex) {
    if (selectedCampaignProposalIndex === campaignProposalIndex) {
      return "dark";
    }
    return "light";
  }

  function actions() {
    if (readOnly) return [];

    return [
      {
        label: t("proposalTerms.createCampaignProposal"),
        variant: "primary",
        onClick: handleAddCampaignProposal,
      },
    ];
  }

  function renderSelector() {
    if (!campaignProposals.length) {
      return <>{t("proposalTerms.noCampaignsCreated")}</>;
    }
    return (
      <>
        {campaignProposals.map((campaignProposal, index) => (
          <Button
            key={uuidv4()}
            variant={selectedButtonVariant(index)}
            onClick={() => onSelectCampaignProposal(index)}
            className="campaignProposalsSelector__selectButton"
          >
            {proposalButtonLabel(campaignProposal, index)}
          </Button>
        ))}
      </>
    );
  }

  return (
    <ProposalSection title={t("pageNames.campaigns")} actions={actions()}>
      <div className="d-flex flex-wrap campaignProposalsSelector">{renderSelector()}</div>
    </ProposalSection>
  );
}

CampaignProposalSelector.defaultProps = {
  dirtyCampaignProposals: [],
  selectedCampaignProposalIndex: null,
};

CampaignProposalSelector.propTypes = {
  dirtyCampaignProposals: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  selectedCampaignProposalIndex: PropTypes.number,
  onAddCampaignProposal: PropTypes.func.isRequired,
  onSelectCampaignProposal: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default CampaignProposalSelector;
