import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import _ from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

import { idArrayFromString } from "@utilities/stringHelpers";
import PropertySelectionById from "./PropertySelectionById";
import PropertySelectionByTable from "./PropertySelectionByTable";

class OldCampaignPropertySelectionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableView: false,
      selectedPropertiesByTable: {},
      selectedPropertiesByBulkIds: [],
      rawPropertiesByBulkIds: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.setupPropertyIdFormats();
  }

  onSavePropertyIds = () => {
    this.props.updatePropertyIds(this.state.selectedPropertiesByBulkIds);
  };

  setupPropertyIdFormats = () => {
    const selectedPropertiesByTable = {};
    this.props.campaign.properties.forEach((property) => {
      selectedPropertiesByTable[property.attributes.id] = true;
    });
    const selectedIdsString = this.props.campaign.properties
      .map((property) => property.attributes.id)
      .toString();
    this.updateBulkIdSelection(selectedIdsString);
    this.setState({ selectedPropertiesByTable });
  };

  changePropertySelectionMethod = (tableView) => {
    if (tableView) {
      this.setState((prevState) => ({
        rawPropertiesByBulkIds: prevState.selectedPropertiesByBulkIds.toString(),
      }));
    }
    this.setState({ tableView });
  };

  updateBulkIdSelection = (inputtedString) => {
    const extractedIds = idArrayFromString(inputtedString);
    const availablePropertyIds = this.props.availableProperties.map((a) => a.attributes.id);
    let validIds = extractedIds.filter((id) => availablePropertyIds.includes(parseInt(id, 10)));
    validIds = [...new Set(validIds)];

    const selectedPropertiesByTable = {};
    validIds.forEach((id) => {
      selectedPropertiesByTable[id] = true;
    });
    this.setState({
      selectedPropertiesByBulkIds: validIds,
      rawPropertiesByBulkIds: inputtedString,
      selectedPropertiesByTable,
    });
  };

  updatePropertyTableSelection = (selectedIds) => {
    this.setState({ selectedPropertiesByTable: selectedIds });
    const selectedPropertyIds = Object.keys(_.omit(selectedIds, "undefined"));
    this.updateBulkIdSelection(selectedPropertyIds.toString());
  };

  exitEditMode = () => {
    this.props.toggleEditPropertyMode(false);
  };

  renderHeader = () => {
    const disableState = this.props.isUpdatingPropertyIds;
    const updatingPropertyDescription = disableState ? "Saving Properties" : "Save Properties";
    return (
      <Col className="d-flex justify-content-between align-items-center">
        <h3>Properties</h3>
        <div>
          <Button variant="success" disabled={disableState} onClick={this.onSavePropertyIds}>
            {this.props.isUpdatingPropertyIds ? (
              <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {updatingPropertyDescription}
          </Button>
          <Button
            variant="outline-primary"
            className="ml-2"
            disabled={disableState}
            onClick={this.exitEditMode}
          >
            Exit Edit Mode
          </Button>
        </div>
      </Col>
    );
  };

  renderSelectionOptions = () => (
    <Col>
      <ToggleButtonGroup
        type="radio"
        name="options"
        value={this.state.tableView}
        onChange={this.changePropertySelectionMethod}
      >
        <ToggleButton id="by-id" variant="outline-primary" value={false}>
          Select by Id
        </ToggleButton>
        <ToggleButton id="from-list" variant="outline-primary" value>
          Select from List
        </ToggleButton>
      </ToggleButtonGroup>
    </Col>
  );

  render() {
    return (
      <>
        <Row g={0}>{this.renderHeader()}</Row>
        <Row className="py-3">{this.renderSelectionOptions()}</Row>
        {this.state.tableView ? (
          <PropertySelectionByTable
            availableProperties={this.props.availableProperties}
            selectedPropertyIds={this.state.selectedPropertiesByTable}
            onSelectedRowsChange={this.updatePropertyTableSelection}
            startAt={this.props.campaign.start_at}
            endAt={this.props.campaign.end_at}
            loopSpotsPerProperty={this.props.campaign.loop_spots_per_property}
          />
        ) : (
          <div>
            <PropertySelectionById
              updateBulkIdSelection={this.updateBulkIdSelection}
              validIds={this.state.selectedPropertiesByBulkIds}
              rawPropertiesByBulkIds={this.state.rawPropertiesByBulkIds}
              availableProperties={this.props.availableProperties}
              userEmail={this.props.userEmail}
            />
          </div>
        )}
      </>
    );
  }
}

OldCampaignPropertySelectionContainer.propTypes = {
  campaign: PropTypes.shape({
    properties: PropTypes.array,
    start_at: PropTypes.string.isRequired,
    end_at: PropTypes.string.isRequired,
    loop_spots_per_property: PropTypes.object.isRequired,
  }).isRequired,
  toggleEditPropertyMode: PropTypes.func.isRequired,
  availableProperties: PropTypes.array.isRequired,
  updatePropertyIds: PropTypes.func.isRequired,
  isUpdatingPropertyIds: PropTypes.bool.isRequired,
};

export default withTranslation()(OldCampaignPropertySelectionContainer);
