/*

Date Range Picker

*/

import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { enCA, frCA } from "date-fns/locale";
import ReactDatePickerInput from "./reactDatePickerInput";

registerLocale("fr-CA", frCA);
registerLocale("en-CA", enCA);

class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }

  handleChangeStart(date) {
    const newDate = moment(date);
    const startDate = this.props.showTime ? newDate : newDate.startOf("day");
    let { endDate } = this.props;
    if (newDate > endDate) {
      endDate = moment(newDate).endOf("day");
    }
    this.props.updateTime(startDate, endDate);
  }

  handleChangeEnd(date) {
    const newDate = moment(date);

    const endDate = this.props.showTime ? newDate : newDate.endOf("day");
    this.props.updateTime(this.props.startDate, endDate);
  }

  render() {
    const { startDate, endDate, showDuration, dateFormat, showTime, minDate, maxDate, t } =
      this.props;
    const error =
      startDate === endDate || startDate > endDate ? t("keywords.dateRelated.badRange") : "";

    /** Currently there's no min and max for time range.
    Awaiting for this PR to be merged which fixes this issue:
    https://github.com/Hacker0x01/react-datepicker/pull/1718
    */

    const calendarMinDate = minDate ? moment(minDate).toDate() : null;
    const calendarMaxDate = maxDate ? moment(maxDate).toDate() : null;
    let numberOfDays = 0;
    if (endDate !== undefined && startDate !== undefined) {
      numberOfDays = endDate.diff(startDate, "days") + 1;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    return (
      <div className="form-group">
        <DatePicker
          customInput={<ReactDatePickerInput label={t("keywords.dateRelated.startDate")} />}
          locale={t("language.locale")}
          selected={start}
          timeCaption={t("keywords.dateRelated.time")}
          minDate={calendarMinDate}
          maxDate={calendarMaxDate}
          selectsStart
          showTimeSelect={showTime}
          dateFormat={dateFormat}
          startDate={start}
          endDate={end}
          onChange={this.handleChangeStart}
          popperPlacement="bottom"
          popperModifiers={{
            flip: {
              behavior: ["bottom"], // don't allow it to flip to be above
            },
            preventOverflow: {
              enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
            },
            hide: {
              enabled: false, // turn off since needs preventOverflow to be enabled
            },
          }}
        />
        <DatePicker
          customInput={<ReactDatePickerInput label={t("keywords.dateRelated.endDate")} />}
          selected={end}
          locale={t("language.locale")}
          timeCaption={t("keywords.dateRelated.time")}
          selectsEnd
          minDate={start}
          maxDate={calendarMaxDate}
          showTimeSelect={showTime}
          dateFormat={dateFormat}
          startDate={start}
          endDate={end}
          onChange={this.handleChangeEnd}
          popperPlacement="bottom"
          popperModifiers={{
            flip: {
              behavior: ["bottom"], // don't allow it to flip to be above
            },
            preventOverflow: {
              enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
            },
            hide: {
              enabled: false, // turn off since needs preventOverflow to be enabled
            },
          }}
        />
        {showDuration ? (
          <div className="duration-text">
            {t("keywords.dateRelated.duration")}: {numberOfDays}{" "}
            {`${numberOfDays > 1 ? t("keywords.dateRelated.day") : t("keywords.dateRelated.days")}`}
          </div>
        ) : null}
        <div className="error">{error} </div>
      </div>
    );
  }
}

export default connect(null, null)(withTranslation()(DateRangePicker));
