import React, { Component } from "react";
import PropTypes from "prop-types";
import mapboxgl from "mapbox-gl";

import * as MAPLAYERS from "./ClusterLayers";
import { MAPDATA } from "./MapSettings";

mapboxgl.accessToken = process.env.CLUSTER_MAP_ACCESS_TOKEN;

class ClusterMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
    };
  }

  componentDidMount() {
    this.initializeMap();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.geoJsonSource !== this.props.geoJsonSource) {
      const { map } = this.state;
      if (map != null) {
        map.getSource(MAPDATA).setData(this.props.geoJsonSource);
        this.fitBounds(map, this.props.geoJsonSource);
      }
    }
  }

  setUpLayers(map) {
    return new Promise((resolve) => {
      map.once("load", () => {
        map.addSource(MAPDATA, MAPLAYERS.setSourceLayer(this.props.propertyToCluster));
        map.addLayer(MAPLAYERS.MARKER_LAYER);
        map.addLayer(MAPLAYERS.CLUSTER_LAYER);
        map.addLayer(MAPLAYERS.CLUSTER_COUNT_LAYER);
        map.getSource(MAPDATA).setData(this.props.geoJsonSource);
        resolve();
      });
    });
  }

  setUpPopups(map) {
    if (this.props.renderPopup === undefined) {
      return;
    }

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 12,
    });

    map.on(
      "mouseenter",
      "marker-layer",
      function (e) {
        const coordinates = e.features[0].geometry.coordinates.slice();
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        const item = e.features[0].properties;
        popup.setLngLat(coordinates).setHTML(this.props.renderPopup(item)).addTo(map);
      }.bind(this)
    );

    map.on("mouseenter", "marker-layer", function () {
      map.getCanvas().style.cursor = "pointer";
    });

    map.on("mouseleave", "marker-layer", function () {
      map.getCanvas().style.cursor = "";
      popup.remove();
    });
  }

  fitBounds(map, geoJson) {
    if (geoJson.features.length === 0) {
      return;
    }
    const bounds = new mapboxgl.LngLatBounds();
    geoJson.features.forEach((feature) => {
      bounds.extend(feature.geometry.coordinates);
    });
    map.fitBounds(bounds, { padding: 100 });
  }

  initializeMap() {
    const map = new mapboxgl.Map({
      container: "map-container",
      style: process.env.CLUSTER_MAP_STYLE_URL,
      zoom: 1,
    });
    map.addControl(new mapboxgl.NavigationControl());

    if (this.props.renderPopup) {
      this.setUpPopups(map);
    }

    this.fitBounds(map, this.props.geoJsonSource);

    this.setUpLayers(map).then(() => {
      this.setState({ map });
    });
  }

  render() {
    return (
      <div
        ref={(el) => (this.mapContainer = el)}
        id="map-container"
        className="cluster-map absolute top right left bottom"
      />
    );
  }
}

ClusterMap.propTypes = {
  renderPopup: PropTypes.func,
  geoJsonSource: PropTypes.object.isRequired,
  propertyToCluster: PropTypes.string.isRequired,
};

export default ClusterMap;
