import React from "react";
import PropTypes from "prop-types";
import { useFormContext, useWatch, Controller } from "react-hook-form";
import CampaignPropertySelectionContainer from "../../../Campaign/Creation/Property/views/updatedPropertySelection/CampaignPropertySelectionContainer";

function CampaignProposalPropertiesContainer({
  readOnly,
  selectedCampaignProposalIndex,
  elev8Properties,
}) {
  const { control } = useFormContext();

  const startAt = useWatch({
    name: fieldName("start_at"),
  });

  const endAt = useWatch({
    name: fieldName("end_at"),
  });

  const loopSpots = useWatch({
    name: fieldName("loop_spots"),
  });

  const baseRate = useWatch({
    name: fieldName("rate_type"),
  });

  const currentRateValue = parseFloat(
    useWatch({
      name: fieldName("rate_value_dollars"),
    })
  );

  const fullScreen = useWatch({
    name: fieldName("fullscreen"),
  });

  function fieldName(attributeName) {
    return `campaign_proposals.${selectedCampaignProposalIndex}.${attributeName}`;
  }

  return (
    <>
      <Controller
        control={control}
        defaultValue={[]}
        name={fieldName("property_ids")}
        render={({ field: { value, onChange } }) => (
          <CampaignPropertySelectionContainer
            readOnly={readOnly}
            selectedCampaignPropertyIds={value}
            elev8Properties={elev8Properties}
            startAt={startAt}
            endAt={endAt}
            baseRate={baseRate}
            currentRateValue={currentRateValue}
            fullScreen={fullScreen}
            loopSpotsPerProperty={parseInt(loopSpots, 10)}
            updatePropertySelection={onChange}
          />
        )}
      />
    </>
  );
}

CampaignProposalPropertiesContainer.defaultProps = {
  selectedCampaignProposalIndex: null,
};

CampaignProposalPropertiesContainer.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  selectedCampaignProposalIndex: PropTypes.number,
};

export default CampaignProposalPropertiesContainer;
