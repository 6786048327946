/* ACTIONS FOR MAIN FEED */

import _ from "lodash";

import { SET_MAIN_FEED_MEDIA_LIST } from "../../../../../App/actionTypes";
import Elev8ApiService from "../../../../../lib/services/Elev8Api/Elev8ApiService";

export function setMainFeedList(newList) {
  return {
    type: SET_MAIN_FEED_MEDIA_LIST,
    payload: newList,
  };
}

export function fetchMainFeedMedia() {
  return function (dispatch) {
    Elev8ApiService.getOldEndpoint("media/?category=big_notice_background")
      .then((response) => {
        const mediaData = response.data;
        mediaData.forEach(function (item) {
          item.imageUrl = item.url;
        });
        dispatch({
          type: SET_MAIN_FEED_MEDIA_LIST,
          payload: mediaData,
        });
      })
      .catch((e) => {
        console.log("Can't fetchMainFeedMedia", e);
      });
  };
}

export function fetchMainFeedMediaList(ids) {
  return function (dispatch) {
    const idQueryParams = ids.map((id) => `big_notice_background_for[]=${id}`).join("&");
    if (idQueryParams === "") {
      dispatch({
        type: SET_MAIN_FEED_MEDIA_LIST,
        payload: [],
      });
      return;
    }
    Elev8ApiService.getOldEndpoint(`media/?${idQueryParams}`)
      .then((response) => {
        dispatch({
          type: SET_MAIN_FEED_MEDIA_LIST,
          payload: response.data,
        });
      })
      .catch(() => {
        // //console.log("Can't fetch main feed images", e);
      });
  };
}

export function deleteMediaProperties(media) {
  return function () {
    return new Promise((resolve, reject) => {
      Elev8ApiService.oldDelete(`media/${media.id}/property_background`)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          console.log("Delete Media PropertyBackground failed", e);
          reject(e);
        });
    });
  };
}
