import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

class ConfirmModal extends Component {
  render() {
    const { children, cancel, confirm, show, title } = this.props;

    return (
      <Modal show={show} onHide={cancel.action} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant={confirm.variant} onClick={confirm.action}>
            {confirm.label}
          </Button>
          <Button variant={cancel.variant} onClick={cancel.action}>
            {cancel.label}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ConfirmModal.propTypes = {
  children: PropTypes.node.isRequired,
  cancel: PropTypes.shape({
    label: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }).isRequired,
  confirm: PropTypes.shape({
    label: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }).isRequired,
  show: PropTypes.bool.isRequired,
};

export default ConfirmModal;
