// Methods below are used for the BasicTable component
// In order to show which rows are selected, the ids of the selected data must be passed to the component in the following format:
// eg. { 1: true, 2:true, 5: true} <-- This signifies that rows with the id of 1,2 and 5 will be selected

export function selectedTableIdsFromApiObjectIds(idsInApiObjectFormat) {
  const selectedIds = {};
  idsInApiObjectFormat.forEach((item) => {
    selectedIds[item.id] = true;
  });

  return selectedIds;
}

export function apiObjectIdsFromSelectedTableIds(idsFromTableSelector, type) {
  const ids = Object.keys(idsFromTableSelector);
  const idsInApiObjectFormat = [];

  ids.forEach((id) => {
    const item = { id, type };
    idsInApiObjectFormat.push(item);
  });

  return idsInApiObjectFormat;
}
