/*
Gallery with images/icons
*/

import React, { Component } from "react";
import Select from "react-select";
import { withTranslation } from "react-i18next";

// MAIN CLASS
class DropDownWidget extends Component {
  constructor(props) {
    super(props);
    this.onClickSelection = this.onClickSelection.bind(this);
  }

  onClickSelection(option) {
    this.props.updateForm(this.props.formPropertyName, option.value, this.props.currentForm);
  }

  render() {
    const {
      required,
      title,
      formWidth,
      formValue,
      options,
      formType,
      multiSelect,
      isClearable,
      t,
    } = this.props;
    const selectedValue = options.find((option) => option.value === formValue);
    let errorMessage = "";

    if (required) {
      if (formType === "Boolean") {
        if (typeof this.props.formValue !== "boolean") {
          errorMessage = t("keywords.errorRelated.required");
        }
      } else {
        errorMessage = this.props.formValue === "" ? t("keywords.errorRelated.required") : "";
      }
    }

    return (
      <div className="dropDown-form-widget" style={{ flexBasis: formWidth }}>
        <div className="base-flex">
          <div className="title">{title} </div>
          <div className="error">{errorMessage} </div>
        </div>
        <Select
          isMulti={multiSelect}
          isClearable={isClearable}
          name="form-field-name"
          value={selectedValue}
          options={options}
          onChange={this.onClickSelection}
        />
      </div>
    );
  }
}

export default withTranslation()(DropDownWidget);
