export function getMaxFromObjectArray(dataList, attribute) {
  const max = dataList.reduce((acc, data) => (data[attribute] > acc[attribute] ? data : acc));
  return max[attribute];
}

export function getMinFromObjectArray(dataList, attribute) {
  const min = dataList.reduce((acc, data) => (data[attribute] < acc[attribute] ? data : acc));
  return min[attribute];
}

export function getSumFromObjectArray(dataList, attribute) {
  return dataList.reduce((total, data) => total + data[attribute], 0);
}

// Converts cent values (usually from API) to dollars so they can be displayed or modified
// Returns a string with two decimal places regardless of remainder cents (e.g. "1.00" instead of "1")
export function getDollarsFromCents(valueInCents) {
  const valueInDollars = parseInt(valueInCents, 10) / 100;
  return roundToDecimal(valueInDollars, 2).toFixed(2);
}

// Converts dollar strings back to cents (usually to be sent to API)
// Returns an integer
export function getCentsFromDollars(valueInDollars) {
  const valueInCents =  roundToDecimal(valueInDollars, 2) * 100;
  // floating point arithmetic can cause rounding errors (e.g. 5.1 * 100 = 509.99999999999994)
  // using Math.round here to actually get an integer value
  return Math.round(valueInCents);
}

// Source for this rounding method: https://www.jacklmoore.com/notes/rounding-in-javascript/
// This prevents floating point rounding errors by avoiding floating point math altogether.
// example: Number(Math.round(1.005+'e2')+'e-2'); >> 1.01
export function roundToDecimal(value, decimalPlaces) {
  const positiveExponent = `e+${decimalPlaces}`;
  const negativeExponent = `e-${decimalPlaces}`;
  const valueInNthPowerOfTen = Math.round(`${value}${positiveExponent}`);
  return +`${valueInNthPowerOfTen}${negativeExponent}`;
}

export function convertStringArrayToIntArray(strArray) {
  return strArray.map(Number);
}
