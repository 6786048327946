import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { determineNoticeTitleByRole } from "@utilities/UserRoleHelper";
import MainFeedForm from "./mainFeedForm";
import UpdatedNoticeForm from "./UpdatedBigNoticeForm";

export default function BigNoticeFormContainer({ loggedInUser }) {
  const { t } = useTranslation();
  const { role } = loggedInUser;
  const bigNoticeName = t(determineNoticeTitleByRole(role, "_plural"));
  const params = useParams();
  const location = useLocation();

  function renderBigNoticeForm() {
    if (role === "property_user") {
      return <MainFeedForm {...{ params, location, loggedInUser, bigNoticeName }} />;
    }
    return <UpdatedNoticeForm {...{ params, location, loggedInUser, bigNoticeName }} />;
  }

  return <div>{renderBigNoticeForm()}</div>;
}
