import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import CampaignPropertyTable from "./CampaignPropertyTable";

class CampaignPropertyViewOnlyContainer extends Component {
  enterEditMode = () => {
    this.props.toggleEditPropertyMode(true);
  };

  renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <h3>Properties</h3>
        <div>
          <Button variant="outline-primary" className="ml-2" onClick={this.enterEditMode}>
            Edit Properties
          </Button>
        </div>
      </div>
    );
  };

  getCampaignProperties = () => {
    const currentCampaignPropertyIds = this.props.campaign.properties.map(
      (property) => property.attributes.id
    );

    const campaignProperties = this.props.rawPropertyData.filter((property) => {
      if (currentCampaignPropertyIds.includes(parseInt(property.attributes.id))) {
        return property;
      }
    });
    return campaignProperties;
  };

  render() {
    const { campaign, handleRemoveSelectedProperty } = this.props;
    return (
      <>
        {this.renderHeader()}
        <CampaignPropertyTable
          properties={this.getCampaignProperties()}
          handleRemoveSelectedProperty={handleRemoveSelectedProperty}
          startAt={campaign.start_at}
          endAt={campaign.end_at}
          loopSpotsPerProperty={campaign.loop_spots_per_property}
        />
      </>
    );
  }
}

CampaignPropertyViewOnlyContainer.propTypes = {
  campaign: PropTypes.shape({
    properties: PropTypes.array,
    start_at: PropTypes.string.isRequired,
    end_at: PropTypes.string.isRequired,
    loop_spots_per_property: PropTypes.object.isRequired,
  }).isRequired,
  toggleEditPropertyMode: PropTypes.func.isRequired,
  handleRemoveSelectedProperty: PropTypes.func,
  rawPropertyData: PropTypes.array,
};

export default withTranslation()(CampaignPropertyViewOnlyContainer);
