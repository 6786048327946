import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { Form, InputGroup, NavDropdown } from "react-bootstrap";

import FilterDropdownItem from "./FilterDropdownItem";

class FilterDropdownContent extends Component {
  static defaultProps = {
    hideAutocomplete: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
    };
  }

  search = (event) => {
    this.setState({ searchValue: event.target.value });
  };

  searchResults() {
    const results = this.props.filterValues;
    if (!this.state.searchValue) {
      return results;
    }

    return results.filter((result) => {
      return result.toLowerCase().startsWith(this.state.searchValue.toLowerCase());
    });
  }

  renderAllCheck() {
    if (!!this.props.activeFilterValues && this.props.activeFilterValues.length === 0) {
      return <i className="fa fa-check" />;
    }

    return null;
  }

  render() {
    let autoComplete = (
      <div className="filterControl__dropdown__autoComplete">
        <NavDropdown.Item className="filterControl--noHover">
          <InputGroup>
            <i className="fa fa-search" />
            <Form.Control
              autoFocus
              className="w-auto"
              placeholder="Search"
              onChange={this.search}
              value={this.state.searchValue}
            />
          </InputGroup>
        </NavDropdown.Item>
        <NavDropdown.Divider />
      </div>
    );

    if (this.props.hideAutocomplete) {
      autoComplete = null;
    }

    return (
      <div className="filterControl__dropdown">
        {autoComplete}
        {this.searchResults().map((filter) => (
          <NavDropdown.Item
            key={filter}
            onClick={() => {
              this.props.toggleFilter(filter);
            }}
          >
            <FilterDropdownItem activeFilterValues={this.props.activeFilterValues} value={filter} />
          </NavDropdown.Item>
        ))}
        <NavDropdown.Item onClick={() => this.props.activateAllFilters()}>
          <button className="filterControl__dropdown__item w-100" type="button">
            {this.renderAllCheck()}
            <span className="filterControl__dropdown__item__label">All</span>
          </button>
        </NavDropdown.Item>
      </div>
    );
  }
}

FilterDropdownContent.propTypes = {
  filterValues: PropTypes.array.isRequired,
  activeFilterValues: PropTypes.array.isRequired,
  activateAllFilters: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  hideAutocomplete: PropTypes.bool,
};

export default withTranslation()(FilterDropdownContent);
