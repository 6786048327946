import React, { Component } from "react";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

let tooltipCounter = 0;

class BasicTooltip extends Component {
  render() {
    const { children, message, placement } = this.props;

    const uniqueId = `BasicTooltip-${tooltipCounter}`;
    tooltipCounter += 1;

    return (
      <OverlayTrigger placement={placement} overlay={<Tooltip id={uniqueId}>{message}</Tooltip>}>
        {children}
      </OverlayTrigger>
    );
  }
}

BasicTooltip.defaultProps = {
  placement: "top",
};

BasicTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  message: PropTypes.string.isRequired,
  placement: PropTypes.string,
};

export default BasicTooltip;
