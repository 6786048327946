import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import BasicTable from "../../General/BasicTable";
import WarningMessageTooltip from "../views/warningMessageTooltip";

export default function PropertyWithAddressTableSelector({
  selectedPropertyIds,
  updatePropertySelection,
  properties,
  tableContainerStyle,
}) {
  const { t } = useTranslation();

  const onSelectedRowsChange = useCallback((ids) => {
    updatePropertySelection(ids);
  }, []);

  function renderContentRestrictions({ cell }) {
    if (cell.value) {
      return <WarningMessageTooltip message={cell.value} place="bottom" />;
    }
    return null;
  }

  function columns() {
    const defaultColumns = [
      {
        Header: "",
        accessor: "attributes.content_restriction",
        Cell: renderContentRestrictions,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Name",
        accessor: "attributes.name",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Address",
        accessor: "attributes.address_line_1",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "City",
        accessor: "attributes.address_city",
        disableFilters: true,
        disableSortBy: true,
      },
    ];

    return defaultColumns;
  }

  return (
    <div className={`mainTable mainTable__withBorder ${tableContainerStyle}`}>
      <BasicTable
        tableHeader={() => null}
        noResultsDescription={t("propertyTerms.noPropertiesFound")}
        columns={columns()}
        data={properties}
        selectedRows={selectedPropertyIds}
        onSelectedRowsChange={onSelectedRowsChange}
      />
    </div>
  );
}

PropertyWithAddressTableSelector.defaultProps = {
  properties: null,
  selectedPropertyIds: {},
};

PropertyWithAddressTableSelector.propTypes = {
  selectedPropertyIds: PropTypes.object,
  updatePropertySelection: PropTypes.func.isRequired,
  properties: PropTypes.array,
};
