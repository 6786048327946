import _ from "lodash";

const PERIODS_OF_DAY_DEFAULT = generatePeriodsOfDay();

const DAYS_OF_WEEK_DEFAULT = [
  {
    value: "Monday",
    description: "keywords.dateRelated.daysOfWeek.monday",
    abbr: "keywords.dateRelated.daysOfWeek.m",
    present: true,
  },
  {
    value: "Tuesday",
    description: "keywords.dateRelated.daysOfWeek.tuesday",
    abbr: "keywords.dateRelated.daysOfWeek.t",
    present: true,
  },
  {
    value: "Wednesday",
    description: "keywords.dateRelated.daysOfWeek.wednesday",
    abbr: "keywords.dateRelated.daysOfWeek.w",
    present: true,
  },
  {
    value: "Thursday",
    description: "keywords.dateRelated.daysOfWeek.thursday",
    abbr: "keywords.dateRelated.daysOfWeek.r",
    present: true,
  },
  {
    value: "Friday",
    description: "keywords.dateRelated.daysOfWeek.friday",
    abbr: "keywords.dateRelated.daysOfWeek.f",
    present: true,
  },
  {
    value: "Saturday",
    description: "keywords.dateRelated.daysOfWeek.saturday",
    abbr: "keywords.dateRelated.daysOfWeek.s",
    present: true,
  },
  {
    value: "Sunday",
    description: "keywords.dateRelated.daysOfWeek.sunday",
    abbr: "keywords.dateRelated.daysOfWeek.su",
    present: true,
  },
];

const AGE_DEFAULT = [
  { name: "Young Adult", present: true, value: "young adult", _legacyValue: "young_adult" },
  { name: "Adult", present: true, value: "adult" },
  { name: "Senior", present: true, value: "senior" },
];

const GENDER_DEFAULT = [
  { name: "Male", present: false, value: ["male"] },
  { name: "Female", present: false, value: ["female"] },
  { name: "All", present: true, value: ["male", "female"] },
];

const WEATHER_DEFAULT = [
  {
    value: "sunny",
    present: true,
    description: "keywords.weatherRelated.types.sunny",
    cssValue: "wi-day-sunny",
  },
  {
    value: "partially cloudy",
    present: true,
    description: "keywords.weatherRelated.types.partial",
    cssValue: "wi-day-cloudy",
    _legacyValue: "partially_cloudy",
  },
  {
    value: "overcast",
    present: true,
    description: "keywords.weatherRelated.types.overcast",
    cssValue: "wi-cloudy",
  },
  {
    value: "raining",
    present: true,
    description: "keywords.weatherRelated.types.rainy",
    cssValue: "wi-rain",
  },
  {
    value: "snowing",
    present: true,
    description: "keywords.weatherRelated.types.snowy",
    cssValue: "wi-snowflake-cold",
  },
];

const TargetingSerializer = {
  defaultTargeting() {
    return {
      periodsOfDay: _.cloneDeep(PERIODS_OF_DAY_DEFAULT),
      daysOfWeek: _.cloneDeep(DAYS_OF_WEEK_DEFAULT),
      age: _.cloneDeep(AGE_DEFAULT),
      gender: _.cloneDeep(GENDER_DEFAULT),
      weather: _.cloneDeep(WEATHER_DEFAULT),
    };
  },

  serialize(targeting) {
    return {
      periodsOfDay: serializeTime(targeting.times),
      daysOfWeek: serializeDays(targeting.days),
      age: serializeAge(targeting.age),
      gender: serializeGender(targeting.gender),
      weather: serializeWeather(targeting.weather),
    };
  },

  deserialize(targetingData) {
    let genderList = _.map(_.filter(targetingData.gender, { present: true }), "value");
    genderList = genderList[0];

    const ageList = _.map(_.filter(targetingData.age, { present: true }), "value");

    const daysOfWeekList = _.map(_.filter(targetingData.daysOfWeek, { present: true }), "value");

    const periodsOfDayList = _.map(
      _.filter(targetingData.periodsOfDay, { present: true }),
      "label"
    );

    const weatherList = _.map(_.filter(targetingData.weather, { present: true }), "value");

    return {
      times: periodsOfDayList,
      days: daysOfWeekList,
      age: ageList,
      gender: genderList,
      weather: weatherList,
    };
  },
};

export default TargetingSerializer;

// -- Private -- //
function serializeTime(times) {
  const serializedTime = _.cloneDeep(PERIODS_OF_DAY_DEFAULT);

  if (times == null) {
    return serializedTime;
  }

  const hours = [];
  for (let k = 0; k < times.length; k++) {
    const currTime = times[k];
    hours.push(currTime[0]);
  }

  serializedTime.forEach((item) => {
    item.present = hours.indexOf(item.value) !== -1;

    return item;
  });

  return serializedTime;
}

function serializeDays(days) {
  const serializedDays = _.cloneDeep(DAYS_OF_WEEK_DEFAULT);

  if (days == null) {
    return serializedDays;
  }

  serializedDays.forEach((item) => {
    item.present = days.indexOf(item.value.toLowerCase()) !== -1;

    return item;
  });

  return serializedDays;
}

function serializeAge(age) {
  const serializedAge = _.cloneDeep(AGE_DEFAULT);

  if (age == null) {
    return serializedAge;
  }

  serializedAge.forEach((item) => {
    item.present = age.indexOf(item.value) !== -1;

    return item;
  });

  return serializedAge;
}

function serializeGender(gender) {
  const serializedGender = _.cloneDeep(GENDER_DEFAULT);

  if (gender.length === 2) {
    return serializedGender;
  }

  if (gender[0] === "male") {
    serializedGender[0].present = true;
    serializedGender[1].present = false;
    serializedGender[2].present = false;
  } else {
    serializedGender[0].present = false;
    serializedGender[1].present = true;
    serializedGender[2].present = false;
  }

  return serializedGender;
}

function serializeWeather(weather) {
  const serializedWeather = _.cloneDeep(WEATHER_DEFAULT);

  if (weather == null) {
    return serializedWeather;
  }

  serializedWeather.forEach((item) => {
    item.present = weather.indexOf(item.value) !== -1;
  });

  return serializedWeather;
}

function generatePeriodsOfDay() {
  return _.range(0, 24).map((hourOfDay) => {
    const isMorning = Math.floor(hourOfDay / 12) === 0;

    let hourOnClock = hourOfDay % 12;
    if (hourOnClock === 0) {
      hourOnClock = 12;
    }

    // { value: "1:00", name: "1:00 ", type: "AM", label: ["1:00", "2:00"], present: true }
    return {
      value: `${hourOfDay}:00`,
      description: `${hourOnClock}:00`,
      type: isMorning ? "AM" : "PM",
      label: [`${hourOfDay}:00`, `${hourOfDay + 1}:00`],
      present: true,
    };
  });
}
