/**
Navbar
* */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import { logoutUser } from "@components/Authentication/actions/sessionActions";
import BrandText from "@components/Global/General/brandText";

import { unstableHistory } from "@App/history";

import PermissionService, { Actions, Resources } from "@components/User/Services/permissionService";

class TopNavigation extends Component {
  handleLogOut = () => {
    this.props.logoutUser();
  };

  goToAccountSettings = () => {
    unstableHistory.push("/account-settings");
  };

  toggleBar = () => {
    this.props.toggleSideBar();
  };

  canCreateCampaignsOrContracts = () => {
    return (
      PermissionService.can(
        Actions.UPDATE,
        Resources.CAMPAIGN_GROUP_PROPOSALS,
        this.props.loggedInUser
      ) ||
      PermissionService.can(Actions.UPDATE, Resources.CAMPAIGNS, this.props.loggedInUser) ||
      PermissionService.can(
        Actions.UPDATE,
        Resources.PROPERTY_CONTRACT_DETAILS,
        this.props.loggedInUser
      )
    );
  };

  renderBrandText = () => {
    return (
      <BrandText
        useExternalLink={false}
        size="md"
        whiteBackground={false}
        useVCity={!this.canCreateCampaignsOrContracts()}
      />
    );
  };

  // this is temporary
  renderHeaderTag = () => {
    if (!this.canCreateCampaignsOrContracts())
      return (
        <Helmet>
          <title>{this.props.t("keywords.appRelated.verticalImpression")}</title>
          <meta name={this.props.t("keywords.appRelated.verticalImpression")} />
        </Helmet>
      );
  };

  render() {
    const { t } = this.props;

    if (!this.props.auth) {
      return null;
    }

    if (this.props.auth && !this.props.loggedInUser) {
      return null;
    }
    return (
      <div className="navbar-container">
        <Navbar fixed="top" variant="dark" className="elev8-navbar">
          {this.props.auth ? (
            <i
              className="fa fa-bars u-pad-right-xsmall u-cursor-pointer d-sm-none"
              style={{ color: "white" }}
              onClick={this.toggleBar}
            />
          ) : null}
          {this.renderBrandText()}

          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <button id="js-intercomSelector" type="button">
                <i
                  className="fa fa-question-circle fa-lg u-pad-right-xsmall my-auto"
                  style={{ color: "white" }}
                />
              </button>
              <NavDropdown title={this.props.loggedInUser.first_name} className="navbar__dropdown">
                <NavDropdown.Item onClick={this.goToAccountSettings}>
                  {t("pageNames.myAccount")}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.handleLogOut}>
                  {t("keywords.appRelated.logout")}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {this.renderHeaderTag()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logoutUser }, dispatch);
}

export default connect(null, mapDispatchToProps)(withTranslation()(TopNavigation));
