import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

export default function NavigationButton({ path, buttonText }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function goToPath() {
    navigate(path);
  }

  return (
    <Button variant="outline-dark" onClick={goToPath}>
      {buttonText || t("pageNames.dashboard")}
    </Button>
  );
}

NavigationButton.defaultProps = {
  path: "/dashboard",
  buttonText: "",
};

NavigationButton.propTypes = {
  path: PropTypes.string,
  buttonText: PropTypes.string,
};
