import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { withTranslation } from "react-i18next";

class TableSelector extends Component {
  constructor(props) {
    super(props);
    this.renderTableData = this.renderTableData.bind(this);
    this.renderTableLabels = this.renderTableLabels.bind(this);
    this.renderSingleItem = this.renderSingleItem.bind(this);
    this.selectRow = this.selectRow.bind(this);
  }

  selectRow(checked, rowData) {
    this.props.onSelectAction(rowData, !checked);
  }

  renderSingleItem(rowData, tableFormat) {
    const currField = tableFormat.field;
    if (currField === "address") {
      return (
        <td key={`tba_${rowData.id}`}>
          <p> To be Added </p>
        </td>
      );
    }
    return <td key={`${rowData[currField]}_${rowData.id}`}>{rowData[currField]}</td>;
  }

  renderTableData(rowData) {
    let checked = "";
    let rowHighlighted = "";
    const hoverable = this.props.tableType ? "hoverable" : "";
    const rowId = rowData.id;

    if (this.props.tableType) {
      checked = _.includes(this.props.selected, rowId);
      rowHighlighted = checked ? "highlighted" : "";
    }
    return (
      <tr
        key={rowId}
        className={`${rowHighlighted} ${hoverable}`}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={this.selectRow.bind(this, checked, rowData)}
      >
        {this.props.tableType ? (
          <td key={`checkbox${rowId}`}>
            <input type={this.props.tableType} defaultChecked={checked} />
          </td>
        ) : null}
        {this.props.tableFormat.map(this.renderSingleItem.bind(this, rowData))}
      </tr>
    );
  }

  renderTableLabels(tableFormat) {
    return (
      <th key={tableFormat.label} scope="col" style={{ width: tableFormat.size }}>
        {tableFormat.label}
      </th>
    );
  }

  render() {
    const {
      tableData,
      tableFormat,
      tableType,
      selectAll,
      deselectAll,
      t,
      sortByField,
      hideControls,
    } = this.props;

    const sortedTableData = [...tableData];
    if (sortByField) {
      sortedTableData.sort((a, b) => a[sortByField].localeCompare(b[sortByField]));
    }

    return (
      <div className="full">
        {tableType === "checkbox" && sortedTableData.length > 3 && !hideControls ? (
          <div className="selection-buttons">
            <button type="button" className="btn btn-success btn-lg" onClick={deselectAll}>
              {t("keywords.buttonActions.deselectAll")}
            </button>
            <button type="button" className="btn btn-success btn-lg" onClick={selectAll}>
              {t("keywords.buttonActions.selectAll")}
            </button>
          </div>
        ) : null}
        <div className="table-selecting-widget">
          <table style={{ border: "1", width: "100%" }}>
            <thead>
              <tr key="table-selector-header">
                {this.props.tableType ? (
                  <th key="null-header" scope="col" style={{ width: "5%" }}>
                    {" "}
                  </th>
                ) : null}
                {tableFormat.map(this.renderTableLabels.bind(this))}
              </tr>
            </thead>
            <tbody>{sortedTableData.map(this.renderTableData.bind(this))}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(withTranslation()(TableSelector));
