import React, { Component } from "react";
import TextInput from "../textInput";

export default class ReactDatePickerInput extends Component {
  noop() {}

  render() {
    return (
      <div className="custom-date-picker-input" onClick={this.props.onClick}>
        <TextInput
          readOnly={this.props.readOnly}
          label={this.props.label}
          value={this.props.value}
          handleChange={this.noop}
          type="text"
          titleStyle={this.props.titleStyle}
        />
      </div>
    );
  }
}
