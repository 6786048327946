import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import { Button, Card, Container } from "react-bootstrap";

export default function ProposalSection({ title, actions, children }) {
  function renderAction(action) {
    return (
      <div key={uuidv4()} className="actions__button">
        <Button disabled={action.disabled} variant={action.variant} onClick={action.onClick}>
          {action.label}
        </Button>
      </div>
    );
  }

  return (
    <Container fluid className="proposalSection">
      <Card>
        <div className="proposalSection__header proposalHeader">
          <div className="proposalHeader__title u-truncated">{title}</div>
          <div className="proposalHeader__actions">
            {actions.map((action) => renderAction(action))}
          </div>
        </div>
        <div className="proposalSection__body">{children}</div>
      </Card>
    </Container>
  );
}

ProposalSection.defaultProps = {
  actions: [],
};

ProposalSection.propTypes = {
  title: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
      variant: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
