// Configurations for creating a new campaign

import _ from "lodash";
import moment from "moment";
import { unstableHistory } from "@App/history";

import { SET_CAMPAIGN_CONFIG, SET_CONFIG_STATUS } from "../../../../../App/actionTypes";
import {
  addFlashMessage,
  currentlySaving,
} from "../../../../Interface/General/services/actions/applicationStateActions";

import CampaignSerializer from "../../../../../utilities/campaignSerializer";
import Elev8ApiService from "../../../../../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../../../../../lib/services/Elev8Api/lib";

/** ****************************
To Reducer
****************************** */

export function setCampaignConfig(updatedForm) {
  return {
    type: SET_CAMPAIGN_CONFIG,
    payload: updatedForm,
  };
}

export function clearCampaignConfig() {
  return {
    type: SET_CAMPAIGN_CONFIG,
    payload: null,
  };
}

/** ****************************
Initialize
****************************** */

export function setCampaignConfigFormat(data) {
  return (dispatch) => {
    const deserializedCampaignForm = CampaignSerializer.deserializeForm(data);
    dispatch(setCampaignConfig(deserializedCampaignForm));
  };
}

export function fetchCampaignConfigurations(id) {
  return (dispatch) => {
    Elev8ApiService.read(ApiTypes.campaign, id)
      .then((campaign) => {
        dispatch(setCampaignConfigFormat(campaign));
      })
      .catch((error) => {
        const errorCampaignForm = {};
        errorCampaignForm.mode = "Error";
        dispatch(setCampaignConfig(errorCampaignForm));
      });
  };
}

/** ****************************
To Config Form
****************************** */

export function openCampaignConfig() {
  return {
    type: SET_CONFIG_STATUS,
    payload: {
      active: true,
      status: "inactive",
    },
  };
}

export function updateCampaignConfig() {
  return {
    type: SET_CONFIG_STATUS,
    payload: {
      active: true,
      status: "saving",
    },
  };
}

export function closeCampaignConfig() {
  return {
    type: SET_CONFIG_STATUS,
    payload: {
      active: false,
      status: "inactive",
    },
  };
}

/** ****************************
To Database
****************************** */

export function createNewCampaign(initialSettings) {
  return (dispatch) => {
    saveNotifications(() => {
      return postNewCampaign(initialSettings);
    }, dispatch);
  };
}

function postNewCampaign(initialSettings) {
  const serializedForm = CampaignSerializer.serializeForm(initialSettings);
  return Elev8ApiService.create(ApiTypes.campaign, serializedForm).then((response) => {
    unstableHistory.push(`/campaign/configuration/${response.data.data.attributes.id}`);
  });
}

export function savingCampaignConfig() {
  // dispatch update
  // save configuration
  // dispatch update
  return {
    type: SET_CONFIG_STATUS,
    payload: {
      active: true,
      status: "saving",
    },
  };
}

export function savedCampaignConfig() {
  // dispatch update
  // save configuration
  // dispatch update
  return {
    type: SET_CONFIG_STATUS,
    payload: {
      active: true,
      status: "saved",
    },
  };
}

// updating ads

export function updateAds(campaign) {
  return (dispatch) => {
    Elev8ApiService.getOldEndpoint(`ads/?campaign_id=${campaign.id}`).then((response) => {
      return Promise.all(
        response.data.map((currRow) => {
          const videoEnabled = campaign.video_enabled;

          // update the time durations
          const adStart = currRow.start_date;
          const adEnd = currRow.end_date;

          const newStart = moment(adStart).isBetween(
            campaign.start_at,
            campaign.end_at,
            null,
            "[]"
          );
          const newEnd = moment(adEnd).isBetween(campaign.start_at, campaign.end_at, null, "[]");

          if (!newStart) {
            currRow.start_date = campaign.start_at;
          }
          if (!newEnd) {
            // //console.log("new end date");
            currRow.end_date = campaign.end_at;
          }
          if (!videoEnabled && currRow.media.type == "video") {
            return Elev8ApiService.oldDelete(`ads/${currRow.id}`);
          }

          return Elev8ApiService.oldPut(`ads/${currRow.id}`, currRow);
        })
      ).catch((e) => {
        console.log("Can't updateAds", e);
      });
    });
  };
}

export function savingCampaignConfigStatus(campaignForm) {
  const serializedForm = CampaignSerializer.serializeForm(campaignForm);
  return (dispatch) => {
    dispatch(savingCampaignConfig());
    Elev8ApiService.partialUpdate(ApiTypes.campaign, serializedForm, serializedForm.data.id).then(
      () => {
        // update the ads
        return Promise.all([dispatch(updateAds(serializedForm.data.attributes))])
          .then(() => {
            dispatch(savedCampaignConfig());
            dispatch(fetchCampaignConfigurations(serializedForm.data.id));
          })
          .catch((e) => {
            console.log("Can't saveCampaignConfigurations ", e);
          });
      }
    );
  };
}

// SAVE AND LEAVE CAMPAIGN
export function saveCampaignConfigurations(campaignForm, newRoute, reset) {
  // Campaign Form
  // Need to change start date and end date TIME to 1:00 AM
  return (dispatch) => {
    const serializedForm = CampaignSerializer.serializeForm(campaignForm);
    Elev8ApiService.update(ApiTypes.campaign, serializedForm, serializedForm.data.id)
      .then(() => {
        // console.log("pushing the browser", newRoute);
        if (reset) {
          dispatch(fetchCampaignConfigurations(serializedForm.data.id));
        }
        if (newRoute !== undefined) {
          unstableHistory.push(newRoute);
        }
      })
      .catch((e) => {
        console.log("Can't saveCampaignConfigurations ", e);
      });
  };
}

/** ***********************************
Form related local change
************************************ */

export function updateCampaignConfigurations(type, newData, oldData) {
  return (dispatch) => {
    const updatedForm = _.extend({}, oldData);
    switch (type) {
      // Setting available properties
      case "time":
        updatedForm.start_at = newData[0];
        updatedForm.end_at = newData[1];
        break;
      case "billTime":
        updatedForm.billable_start = newData[0];
        updatedForm.billable_end = newData[1];
        break;
      case "videoEnabled":
        updatedForm[type] = !updatedForm[type];
        break;
      case "monetized":
        updatedForm[type] = !updatedForm[type];
        break;
      default:
        updatedForm[type] = newData;
        break;
    }
    dispatch(setCampaignConfig(updatedForm));
  };
}

function saveNotifications(handleSave, dispatch) {
  currentlySaving(true)(dispatch);
  return handleSave()
    .then(() => {
      addFlashMessage("success", "keywords.appRelated.updateSuccess")(dispatch);
    })
    .catch(() => {
      addFlashMessage("danger", "keywords.errorRelated.invalidForm")(dispatch);
    })
    .finally(() => {
      currentlySaving(false)(dispatch);
    });
}
