/* eslint-disable func-names */

export default class CampaignProposalMarketBreakdownHelper {
  static getGroupingKey(grouping, property) {
    switch (grouping) {
      case "city":
        return property.attributes.address_city;
      case "province":
        return property.attributes.address_province;
      case "market":
        return property.market().attributes.name;
      default:
        return property.attributes.address_province;
    }
  }

  static createCampaignGroupSummaryBreakdown(campaignGroup, elev8Properties, grouping) {
    const totalBreakdown = campaignGroup.reduce((currentBreakdown, campaign) => {
      const updatedBreakdown = this.addCampaignToBreakdown(
        grouping,
        currentBreakdown,
        campaign,
        elev8Properties
      );
      return updatedBreakdown;
    }, {});
    return this.setBreakdownToTableFormat(totalBreakdown);
  }

  static createCampaignSummaryBreakdown(campaign, grouping, elev8Properties) {
    const breakdownByCampaign = this.addCampaignToBreakdown(
      grouping,
      {},
      campaign,
      elev8Properties
    );
    return this.setBreakdownToTableFormat(breakdownByCampaign);
  }

  static addCampaignToBreakdown(grouping, initialBreakdown, campaign, elev8Properties) {
    const campaignProperties = this.extractCampaignProperties(
      campaign.property_ids,
      elev8Properties
    );
    const campaignBreakdown = campaignProperties.reduce((currentBreakdown, property) => {
      const propertyWithBreakdownInfo = this.setUpPropertyValues(property, grouping, campaign);
      return this.addPropertyToBreakdown(propertyWithBreakdownInfo, currentBreakdown);
    }, initialBreakdown);
    return campaignBreakdown;
  }

  static extractCampaignProperties(campaignIds, elev8Properties) {
    const campaignProperties = elev8Properties.filter((elev8Property) =>
      campaignIds.includes(String(elev8Property.attributes.id))
    );
    return campaignProperties;
  }

  static addPropertyToBreakdown(newProperty, breakdown) {
    const { groupingKey } = newProperty;
    const currentBreakdown = breakdown;
    if (!(groupingKey in currentBreakdown)) {
      currentBreakdown[groupingKey] = newProperty;
      currentBreakdown[groupingKey].propertyIds = [newProperty.id];
      currentBreakdown[groupingKey].cpm = this.calculateCPM(
        newProperty.cost,
        newProperty.servedImpressions
      );
    } else {
      if (!currentBreakdown[groupingKey].propertyIds.includes(newProperty.id)) {
        currentBreakdown[groupingKey].propertyIds.push(newProperty.id);
        currentBreakdown[groupingKey].activeScreenCount += newProperty.activeScreenCount;
      }
      currentBreakdown[groupingKey].servedImpressions += newProperty.servedImpressions;
      currentBreakdown[groupingKey].circulation += newProperty.circulation;
      currentBreakdown[groupingKey].rateCard += newProperty.rateCard;
      currentBreakdown[groupingKey].cost += newProperty.cost;
      currentBreakdown[groupingKey].savings += newProperty.savings;
      currentBreakdown[groupingKey].cpm = this.calculateCPM(
        currentBreakdown[groupingKey].cost,
        currentBreakdown[groupingKey].servedImpressions
      );
    }
    return currentBreakdown;
  }

  static setUpPropertyValues(property, grouping, campaign) {
    const groupingKey = this.getGroupingKey(grouping, property);

    const priceInfo = property.getCampaignCost(
      campaign.rate_type,
      campaign.rate_value_dollars,
      campaign.start_at,
      campaign.end_at,
      campaign.loop_spots,
      campaign.fullscreen
    );

    const servedImpressions = property.getActiveBookedImpressions(
      campaign.start_at,
      campaign.end_at,
      campaign.loop_spots || 1
    );

    const propertyWithBreakdownInfo = {
      groupingKey,
      activeScreenCount: property.activeScreens.length,
      servedImpressions,
      rateCard: priceInfo.basePrice,
      cost: priceInfo.price,
      savings: priceInfo.basePrice - priceInfo.price,
      id: property.attributes.id,
      circulation: property.getCirculation(campaign.start_at, campaign.end_at),
    };
    return propertyWithBreakdownInfo;
  }

  static calculateCPM(cost, impressions) {
    if (impressions === 0) return 0;
    return (cost * 1000) / impressions;
  }

  static setBreakdownToTableFormat(summaryBreakdown) {
    if (Object.keys(summaryBreakdown).length === 0) {
      return [];
    }
    return Object.values(summaryBreakdown);
  }
}
