/*

GENERAL LISTING OF ALL PROPERTIES

*/

import {
  FETCH_PROPERTIES,
  SET_PROPERTY_FORM,
  SET_PROPERTY_CONTENT,
  SET_PROPERTY_LOGO,
  SET_PROPERTY_PHOTO,
} from "../../../../App/actionTypes";

const INITIAL_STATE = { all: null, photo: null, logo: null, form: null, content: null };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PROPERTIES:
      return { ...state, all: action.payload };
    case SET_PROPERTY_CONTENT:
      return { ...state, content: action.payload };
    case SET_PROPERTY_FORM:
      return { ...state, form: action.payload };
    case SET_PROPERTY_LOGO:
      return { ...state, logo: action.payload };
    case SET_PROPERTY_PHOTO:
      return { ...state, photo: action.payload };
  }
  return state;
}
