import React, { useContext, useMemo } from "react";
import { useWatch } from "react-hook-form";

import Elev8CampaignPropertiesContext from "@contexts/Elev8CampaignPropertiesContext";
import CampaignProposalMarketBreakdownHelper from "@utilities/CampaignProposalMarketBreakdownHelper";
import MarketBreakdownTable from "../CampaignProposals/CampaignProposal/MarketBreakdownTable";

export default function CampaignGroupProposalSummaryBreakdown() {
  const grouping = "province";

  const elev8Properties = useContext(Elev8CampaignPropertiesContext);
  const campaignProposals = useWatch({ name: "campaign_proposals" });

  const summaryBreakdown = useMemo(() => {
    if (!campaignProposals) return null;
    return CampaignProposalMarketBreakdownHelper.createCampaignGroupSummaryBreakdown(
      campaignProposals,
      elev8Properties,
      grouping
    );
  }, [campaignProposals]);

  return (
    <MarketBreakdownTable
      propertyData={summaryBreakdown}
      grouping={grouping}
      showTableFilters={false}
    />
  );
}
