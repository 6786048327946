import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";

import withRouter from "@src/hooks/withRouter";
import Header from "@components/Interface/MainList/header";
import LoadingPage from "@components/Global/General/loadingPage";
import { fetchRssFeeds } from "@components/Client/RssFeed/services/actions/rssFeedActions";
import ImagePreviewWidget from "@components/Global/Widgets/views/imagePreviewWidget";
import PropertyContentLoop from "@components/Global/Widgets/propertyContentLoop";
import {
  initiateEditPropertyForm,
  fetchPropertyContent,
} from "../services/actions/propertyFormActions";

class PropertySingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "Properties",
      titleColor: "rgb(76, 96, 232)",
      headerColor: "#fff",
    };
  }

  UNSAFE_componentWillMount() {
    this.props.fetchRssFeeds();
    this.props.initiateEditPropertyForm(this.props.router.params.id);
    this.props.fetchPropertyContent(this.props.router.params.id);
  }

  renderLogo() {
    return (
      <div className="form-ad-preview">
        {this.props.post.logo ? (
          <div>
            <h3>Logo</h3>
            <ImagePreviewWidget
              filePreview={this.props.post.logo.url}
              fileType={this.props.post.logo.type}
              autoplayVideo={false}
              width="100%"
              height={130}
            />
          </div>
        ) : null}

        {this.props.post.photo ? (
          <div>
            <h3>Building Photo</h3>
            <ImagePreviewWidget
              filePreview={this.props.post.photo.url}
              fileType={this.props.post.photo.type}
              autoplayVideo={false}
              width="100%"
              height={130}
            />
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    if (!this.props.post) {
      return <LoadingPage pageName="Property" />;
    }

    const { t, post } = this.props;

    return (
      <div>
        <Header
          bgColor={this.state.headerColor}
          title={this.state.subject}
          titleColor={this.state.titleColor}
          buttonColor={this.state.buttonColor}
          buttonAction={this.newRoute}
          searchEnabled={false}
          searchValue={this.state.searchValue}
          searchAction={this.updateSearch}
        />

        <div className="fillOut-form">
          <div className="form-container">
            <div className="view-form property">
              <h2>{post.name} </h2>
              <div className="description-box" style={{ width: "100%" }}>
                <hr />
              </div>
              <div className="description-box" style={{ width: "100%" }}>
                {this.renderLogo()}
              </div>
              {this.props.post.logo || this.props.post.photo ? (
                <div className="description-box" style={{ width: "100%" }}>
                  <hr />
                </div>
              ) : null}
              <div style={{ width: "100%" }} />

              <div className="description-box" style={{ width: "350px" }}>
                <h3 style={{ color: this.state.formColor }}> Contact </h3>
                <p>
                  <b>{t("keywords.general.name")}:</b> {post.contact_name}
                </p>
                <p>
                  <b>{t("keywords.userRelated.email.name")}:</b> {post.contact_email}
                </p>
                <p>
                  <b>{t("keywords.userRelated.phone.name")}:</b> {post.contact_phone}
                </p>
              </div>
              <div className="description-box" style={{ width: "350px" }}>
                <h3 style={{ color: this.state.formColor }}> Location </h3>
                <p>
                  <b>{t("keywords.locationRelated.address")}:</b> {post.address_line1}
                  {post.address_line2}
                </p>
                <p>
                  <b>{t("keywords.locationRelated.postalOrZipCode.name")}:</b>{" "}
                  {post.address_postal_code}
                </p>
                <p>
                  <b>{t("keywords.locationRelated.city.name")}:</b> {post.address_city}
                </p>
                <p>
                  <b>{t("keywords.locationRelated.provinceOrState.name")}:</b>{" "}
                  {post.address_province}
                </p>
                <p>
                  <b>{t("keywords.locationRelated.country.name")}:</b> {post.address_country}
                </p>
              </div>

              <div className="description-box" style={{ width: "350px" }}>
                <h3 style={{ color: this.state.formColor }}>Property Information</h3>
                <p>
                  <b>{t("networkTerms.network")}:</b> {post.network.name}
                </p>
                <p>
                  <b>{t("keywords.general.type")}:</b> {post.property_type}
                </p>
                <p>
                  <b>{t("keywords.propertyRelated.floors.numberOf")}:</b> {post.floors}
                </p>
                <p>
                  <b>{t("propertyTerms.numberOfScreens")}:</b> {post.screen_count}
                </p>
                <p>
                  <b>{t("keywords.propertyRelated.units.numberOf")}:</b> {post.number_of_units}
                </p>
              </div>

              <div className="description-box" style={{ width: "100%" }}>
                <hr />
                <h3 style={{ color: this.state.formColor }}>Pricing </h3>
                <div className="flex-columns">
                  <div>
                    <p>
                      <b>Weekly Price CAD:</b> {post.weekly_price_cents_CAD / 100}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>Average Income in Cents:</b> {post.avg_income_cents / 100}
                    </p>
                  </div>
                </div>
              </div>
              <div className="description-box" style={{ width: "100%" }}>
                <hr />
                <h3 style={{ color: this.state.formColor }}>Additional Information</h3>
                <p>
                  <b>Sync Key:</b> {post.sync_key}
                </p>
                {post.status ? (
                  <p>
                    <b>Status:</b> Active
                  </p>
                ) : (
                  <p>
                    <b>Status: </b>Inactive
                  </p>
                )}
              </div>
              <div className="description-box" style={{ width: "100%" }}>
                <hr />
                <h3 style={{ color: this.state.formColor }}>Content Scheduled for Today</h3>
                <PropertyContentLoop
                  propertyID={this.props.router.params.id}
                  screens={post.screens}
                  content={this.props.content}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    post: state.property.general.form,
    feeds: state.client.rssFeed.all,
    content: state.property.general.content,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { initiateEditPropertyForm, fetchRssFeeds, fetchPropertyContent },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PropertySingle))
);
