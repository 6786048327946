import React, { Component, Children } from "react";
import _ from "lodash";

class BasicLayout extends Component {
  getFooter = () => {
    const type = BasicLayout.Footer;
    return _.find(Children.toArray(this.props.children), { type });
  };

  getMainContent = () => {
    const type = BasicLayout.MainContent;
    return _.find(Children.toArray(this.props.children), { type });
  };

  render() {
    return (
      <div className="d-flex flex-column" style={{ minHeight: "100%" }}>
        <div className="d-flex flex-align-stretch flex-grow-1">{this.getMainContent()}</div>
        <div className="d-flex flex-align-stretch flex-shrink-1">{this.getFooter()}</div>
      </div>
    );
  }
}

function MainContent(props) {
  return <div style={{ width: "100%" }}>{props.children}</div>;
}

function Footer(props) {
  return <div style={{ width: "100%" }}>{props.children}</div>;
}

BasicLayout.MainContent = MainContent;
BasicLayout.Footer = Footer;

export default BasicLayout;
