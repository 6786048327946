import React from "react";
import PropTypes from "prop-types";

export default function SummaryItem({ label, value }) {
  return (
    <div className="summaryItem">
      <div className="summaryItem__label">{label}:</div>
      <div className="summaryItem__value">{value}</div>
    </div>
  );
}

SummaryItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
