/* Widget for a textbox form */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

// MAIN CLASS
class TextBox extends Component {
  constructor(props) {
    super(props);
    this.updateText = this.updateText.bind(this);
  }

  updateText(e) {
    if (this.props.inputType == "password" || this.props.inputType == "email") {
      // remove the white spaces
      let userInput = e.target.value.trim();
      userInput = userInput.replace(/\s+/, "");
      this.props.updateForm(
        this.props.formPropertyName,
        userInput,
        this.props.formProps,
        this.props.additionalFormProps
      );
    } else {
      this.props.updateForm(
        this.props.formPropertyName,
        e.target.value,
        this.props.formProps,
        this.props.additionalFormProps
      );
    }
  }

  getWordCountDescription = () => {
    const { t, maxLength, textValue, hideWordCount } = this.props;
    return maxLength && !hideWordCount
      ? `${t("keywords.general.charactersLeft")} ${maxLength - textValue.length}`
      : "";
  };

  showWordCountAlert = () => {
    const { maxLength, textValue } = this.props;
    if (maxLength && maxLength - textValue.length < 5) {
      return "error";
    }
    return "";
  };

  /* style={{"borderLeftColor": formColor} */
  render() {
    const {
      textValue,
      description,
      placeHolder,
      inputType,
      required,
      formWidth,
      maxLength,
      t,
    } = this.props;

    let errorMessage = "";
    let currentText = "";
    if (textValue == null) {
      currentText = "";
    } else {
      currentText = textValue.toString();
    }
    if (required) {
      if (inputType == "text" || inputType == "password" || inputType == "email") {
        errorMessage = currentText.trim() == "" ? t("keywords.errorRelated.required") : "";
      }
    }

    const descriptionComponent = (
      <div className="base-flex">
        <div className="title">{description} </div>
        <div className="error">{errorMessage} </div>
      </div>
    );

    const wordCountDescription = this.getWordCountDescription();
    const wordCountAlert = this.showWordCountAlert();

    return (
      <div className="text-form-widget" style={{ flexBasis: formWidth }}>
        {description || errorMessage ? descriptionComponent : null}
        <fieldset className="material">
          <input
            type={inputType}
            value={currentText}
            onChange={this.updateText.bind(this)}
            placeholder={placeHolder}
            maxLength={maxLength}
          />
        </fieldset>
        <div className={`title text-right ${wordCountAlert}`}>{wordCountDescription}</div>
      </div>
    );
  }
}

export default connect(null, null)(withTranslation()(TextBox));
