/* Widget for updated image uploader */

import Dropzone from "react-dropzone";

import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

const dropzoneRef = createRef();

// MAIN CLASS
class ImageUploader extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
    this.onClearFile = this.onClearFile.bind(this);
    this.renderPreview = this.renderPreview.bind(this);
    this.state = {
      files: [],
    };
  }

  componentWillUnmount() {
    this.cleanUpImagePreview();
  }

  onDrop(files) {
    if (files[0] === undefined) {
      return;
    }

    this.cleanUpImagePreview();

    const fileSize = files[0].size;
    if (fileSize <= this.props.fileMaxSize) {
      const media = {
        name: files[0].name,
        type: files[0].type,
        category: this.props.imageCategory,
        file: files[0],
        preview: URL.createObjectURL(files[0]),
        mediaId: null,
      };
      this.props.updateFormAction(this.props.formPropertyName, media, this.props.currentForm);
      this.setState({ files });
    } else {
      this.props.updateFormAction(this.props.formPropertyName, null, this.props.currentForm);
      this.setState({ files: [] });
    }
  }

  onClearFile() {
    this.cleanUpImagePreview();
    this.props.updateFormAction(this.props.formPropertyName, null, this.props.currentForm);
  }

  cleanUpImagePreview() {
    URL.revokeObjectURL(this.props?.currMedia?.preview);
  }

  renderPreview() {
    const files = this.state.files.map((file) => (
      <>
        <li key={file.name}>{file.name}</li>
        <li key="file size">({file.size} bytes)</li>
      </>
    ));
    const { currMedia, imageWidth, imageHeight } = this.props;

    if (currMedia.preview) {
      return (
        <div className="image-container" style={{ width: imageWidth, height: imageHeight }}>
          <img src={currMedia.preview} alt="preview" />
        </div>
      );
    }

    return (
      <aside>
        <h4 key="fileToUpload">File To Upload:</h4>
        <ul key="files">{files}</ul>
      </aside>
    );
  }

  render() {
    const {
      fileSizeText,
      dimensions,
      currMedia,
      imageTitle,
      required,
      maxWidth,
      imageDescription,
      t,
    } = this.props;

    return (
      <div className="main-dropzone-container" style={{ width: maxWidth }}>
        <div className="main-dropzone-container__title">{imageTitle}</div>
        <div className="main-dropzone-container__description">{imageDescription}</div>
        <div className="drop-zone-container">
          <div className="drop-zone-preview">
            <div className="dropzone-button">
              {currMedia ? (
                <div className="new-image-preview">
                  {this.renderPreview()}
                  <button type="button" className="btn btn-danger" onClick={this.onClearFile}>
                    {t("keywords.buttonActions.delete")}
                  </button>
                </div>
              ) : (
                <Dropzone
                  className="dropzone-zone"
                  onDrop={this.onDrop}
                  accept={{
                    "image/png": [".png"],
                    "image/jpeg": [".jpg", ".jpeg"],
                  }}
                  ref={dropzoneRef}
                  maxFiles={1}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="upload-icon" />
                      <b>{t("keywords.fileRelated.dragClick")}</b>
                      <p>
                        {t("keywords.fileRelated.maxSize")} {fileSizeText}
                      </p>
                      <p>
                        {t("imageTerms.dimensions")} {dimensions}
                      </p>
                    </div>
                  )}
                </Dropzone>
              )}
            </div>
          </div>
          {!currMedia && required ? (
            <div className="error"> {t("keywords.errorRelated.required")}</div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(null, null)(withTranslation()(ImageUploader));
