// eslint-disable-next-line import/prefer-default-export
export const StatusTypes = {
  active: {
    cssValue: "green",
    description: "keywords.statusRelated.active",
  },
  complete: {
    cssValue: "blue",
    description: "keywords.statusRelated.complete",
  },
  draft: {
    cssValue: "disabled",
    description: "keywords.statusRelated.draft",
  },
  inactive: {
    cssValue: "disabled",
    description: "keywords.statusRelated.inactive",
  },
  new: {
    cssValue: "disabled",
    description: "keywords.statusRelated.new",
  },
  pending: {
    cssValue: "yellow",
    description: "keywords.statusRelated.pending",
  },
  scheduled: {
    cssValue: "yellow",
    description: "keywords.statusRelated.scheduled",
  },
  scheduling: {
    cssValue: "disabled",
    description: "keywords.statusRelated.scheduling",
  },
  submitted: {
    cssValue: "orange",
    description: "keywords.statusRelated.submitted",
  },
};
