import React, { Component } from "react";
import { connect } from "react-redux";
import { Line as ProgressLine } from "rc-progress";

import moment from "moment";

class ProgressBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { start, end, className, statusOnly } = this.props;

    const currentDay = moment();
    const endDay = moment(end);
    const startDay = moment(start);
    let daysDone = Math.abs(startDay.diff(currentDay, "days"));
    const dateRange = Math.abs(endDay.diff(startDay, "days")) + 1;

    // calculate percentage
    let percentage = (daysDone / dateRange) * 100;
    let progress = "Running";

    if (currentDay < startDay) {
      daysDone = 0;
      percentage = 0;
      progress = "Pending";
    } else if (currentDay > endDay) {
      daysDone = dateRange;
      percentage = 100;
      progress = "Complete";
    }

    // set Color
    let strokeColor = "";

    switch (true) {
      case percentage < 16:
        strokeColor = "#FFA144";
        break;
      case percentage < 32:
        strokeColor = "#FFD230";
        break;
      case percentage < 48:
        strokeColor = "#B1E21D";
        break;
      case percentage < 64:
        strokeColor = "#2dd893";
        break;
      case percentage < 80:
        strokeColor = "#16E0D9";
        break;
      case percentage < 100:
        strokeColor = "#6821BA";
        break;
      default:
        strokeColor = "#3030D3";
        break;
    }

    return (
      <div>
        {statusOnly ? (
          <div className={progress}> {progress} </div>
        ) : (
          <div className={className}>
            <p>
              <b>{progress}: </b> {daysDone}/{dateRange} Days{" "}
            </p>
            <ProgressLine percent={percentage} strokeWidth="4" strokeColor={strokeColor} />
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, null)(ProgressBar);
