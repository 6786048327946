import { apiDateAsFormData, formDateAsApiData } from "./dateStringHelper";
import Elev8ApiService from "../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../lib/services/Elev8Api/lib";

const CampaignGroupSerializer = {
  deserializeForm(campaignGroupForm) {
    return deserializingForm(campaignGroupForm);
  },
  serializeForm(campaignGroupForm) {
    return serializingForm(campaignGroupForm);
  },
};

export default CampaignGroupSerializer;

function deserializingForm(campaignGroupForm) {
  const campaignGroupAttributes = campaignGroupForm.attributes;
  const startAt = apiDateAsFormData(campaignGroupAttributes.start_at);
  const endAt = apiDateAsFormData(campaignGroupAttributes.end_at);
  const campaignIds = campaignGroupForm.relationships.campaigns.map((c) => parseInt(c.id, 10));

  const deserializedForm = {
    id: campaignGroupAttributes.id,
    name: campaignGroupAttributes.name,
    start_at: startAt,
    end_at: endAt,
    campaign_type: campaignGroupAttributes.campaign_type,
    vi_salesforce_id: campaignGroupAttributes.vi_salesforce_id,
    rsm_oms_id: campaignGroupAttributes.rsm_oms_id,
    campaign_ids: campaignIds,
    advertiser_id: parseInt(campaignGroupForm.relationships.advertiser.id, 10),
    owner_id: parseInt(campaignGroupForm.relationships.owner.id, 10),
  };
  return deserializedForm;
}

function serializeCampaigns(campaignIds) {
  if (campaignIds === undefined) {
    return [];
  }

  return campaignIds.map((campaignId) => ({
    id: campaignId.toString(),
    type: ApiTypes.campaign.type,
  }));
}

function serializingForm(campaignGroupForm) {
  const startAt = formDateAsApiData(campaignGroupForm.start_at, "startOfDay");
  const endAt = formDateAsApiData(campaignGroupForm.end_at, "endOfDay");

  const campaignFormat = serializeCampaigns(campaignGroupForm.campaign_ids);

  // different data structures are currently being passed into campaignGroupForm
  // between CampaignGroupCreateModal, which has campaignType: {value: id, label: string}
  // and GampaignGroupEdit, which has campaignType: id
  // This is also the case for owner_id and advertiser_id
  const campaignType =
    typeof campaignGroupForm.campaign_type === "object"
      ? campaignGroupForm.campaign_type.value
      : campaignGroupForm.campaign_type;

  const attributes = {
    id: campaignGroupForm.id,
    name: campaignGroupForm.name,
    start_at: startAt,
    end_at: endAt,
    campaign_type: campaignType,
    vi_salesforce_id: campaignGroupForm.vi_salesforce_id,
    rsm_oms_id: campaignGroupForm.rsm_oms_id,
  };

  const ownerId =
    typeof campaignGroupForm.owner_id === "object"
      ? campaignGroupForm.owner_id.value
      : campaignGroupForm.owner_id;

  const advertiserId =
    typeof campaignGroupForm.advertiser_id === "object"
      ? campaignGroupForm.advertiser_id.value
      : campaignGroupForm.advertiser_id;

  const relationships = {
    campaigns: { data: campaignFormat },
    owner: Elev8ApiService.setToJsonApiFormat(ApiTypes.user.type, ownerId),
    advertiser: Elev8ApiService.setToJsonApiFormat(ApiTypes.advertiser.type, advertiserId),
  };

  return Elev8ApiService.setToJsonApiFormat(
    ApiTypes.campaign_group.type,
    campaignGroupForm.id,
    attributes,
    relationships
  );
}
