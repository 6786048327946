import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "react-bootstrap";

import withRouter from "@hooks/withRouter";
import { ApiTypes } from "@lib/services/Elev8Api/lib";
import Elev8ApiService from "@lib/services/Elev8Api/Elev8ApiService";
import { filterDataBySearchValue } from "@utilities/DataSearchFilterHelper";
import { SelectColumnFilter } from "@components/Global/General/BasicTableFilter";
import StatusPill from "@components/Global/General/StatusPill";
import BasicTable from "@components/Global/General/BasicTable";
import SearchBar from "@components/Global/General/SearchBar";
import LoadingPage from "@components/Global/General/loadingPage";

import CampaignGroupDeleteButton from "./CampaignGroupDeleteButton";
import CampaignGroupCreateModal from "./CampaignGroupCreateModal";

class CampaignGroupList extends Component {
  static sparseFieldSets = {
    include: ["campaigns", "advertiser", "owner"],
    fields: {
      campaign_groups: [
        "name",
        "id",
        "start_at",
        "end_at",
        "campaign_type",
        "rsm_oms_id",
        "salesfoce_id",
        "owner",
        "advertiser",
        "owner",
      ],
      campaign: ["status", "start_at", "end_at", "name"],
      advertiser: ["name"],
      owner: ["name"],
    },
  };

  static tableKeys = ["name", "advertiserName", "ownerName", "campaignTypeName"];

  constructor(props) {
    super(props);
    this.state = {
      campaignGroups: null,
      searchValue: "",
    };

    Elev8ApiService.index(ApiTypes.campaign_group, CampaignGroupList.sparseFieldSets).then(
      (campaignGroups) => {
        const campaignGroupData = this.transformCampaignGroupData(campaignGroups);
        this.setState({ campaignGroups: campaignGroupData });
      }
    );
  }

  transformCampaignGroupData = (campaignGroups) => {
    const { t } = this.props;
    const transformedCampaignGroupData = campaignGroups.map((campaignGroup) => {
      const transformedCampaignsData = campaignGroup
        .getCampaigns()
        .map((campaign) => ({ ...campaign.attributes, detailedStatus: campaign.getStatus() }));
      const campaignGroupAttributes = {
        ...campaignGroup.attributes,
        detailedStatus: campaignGroup.getStatus(),
        campaignTypeName: t(campaignGroup.getCampaignTypeName()),
        advertiserName: campaignGroup.getAdvertiserName(),
        ownerName: campaignGroup.getOwnerName(),
        numberOfCampaigns: campaignGroup.getCampaigns().length,
        campaigns: transformedCampaignsData,
      };
      return campaignGroupAttributes;
    });
    return transformedCampaignGroupData;
  };

  updateSearch = (searchValue) => {
    this.setState({ searchValue });
  };

  deleteCampaignGroup = (id) => {
    Elev8ApiService.delete(ApiTypes.campaign_group, parseInt(id, 10))
      .then(() => {
        window.location.reload();
        alert("Campaign and associated flights successfully deleted");
      })
      .catch(() => {
        alert("Something went wrong, please try again");
      });
  };

  renderEdit = (row) => {
    const { t } = this.props;

    return (
      <div className="d-flex">
        <Link to={`/campaign_group/edit/${row.id}`}>
          <Button variant="outline-success" size="sm mr-1">
            {t("keywords.buttonActions.edit")}
          </Button>
        </Link>
        <CampaignGroupDeleteButton
          deleteAction={() => this.deleteCampaignGroup(row.id)}
          campaignData={row.campaigns}
        />
      </div>
    );
  };

  renderStatusPill = ({ cell }) => <StatusPill status={cell.value} />;

  columns = () => {
    if (this.state.campaignGroups) {
      return [
        {
          Header: "ID",
          accessor: "id",
          disableFilters: true,
        },
        {
          Header: "Status",
          accessor: "detailedStatus",
          Cell: this.renderStatusPill,
          sortType: (rowA, rowB, attribute) => {
            const statusA = rowA.values[attribute] ? rowA.values[attribute].description : "";
            const statusB = rowB.values[attribute] ? rowB.values[attribute].description : "";
            if (statusA < statusB) return -1;
            if (statusA > statusB) return 1;
            return 0;
          },
          disableFilters: true,
        },
        {
          Header: "Start",
          accessor: "start_at",
          disableFilters: true,
        },
        {
          Header: "End",
          accessor: "end_at",
          disableFilters: true,
        },
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: "Type",
          accessor: "campaignTypeName",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Advertiser",
          accessor: "advertiserName",
        },
        {
          Header: "Owner",
          accessor: "ownerName",
        },
        {
          Header: "# Flights",
          accessor: "numberOfCampaigns",
        },
        {
          Header: "",
          accessor: "id",
          id: "actions",
          Cell: ({ row }) => this.renderEdit(row.original),
          disableSortBy: true,
          disableFilters: true,
        },
      ];
    }

    return null;
  };

  renderTableHeader = () => (
    <Card className="mb-2 px-3">
      <Row className="justify-content-between h-100 align-items-center">
        <Col sm={12} md={7}>
          <h4>Campaign Groups</h4>
        </Col>
        <Col sm={12} md={3}>
          <SearchBar searchText={this.state.searchValue} searchAction={this.updateSearch} />
        </Col>
        <Col sm={12} md={2}>
          <CampaignGroupCreateModal />
        </Col>
      </Row>
    </Card>
  );

  filteredData = () => {
    const searchResult = filterDataBySearchValue(
      CampaignGroupList.tableKeys,
      this.state.campaignGroups,
      this.state.searchValue
    );
    return searchResult;
  };

  render() {
    if (!this.state.campaignGroups) {
      return <LoadingPage pageName="Campaign Groups" />;
    }
    return (
      <div className="mainTable">
        {this.renderTableHeader()}
        <Card>
          <BasicTable
            tableHeader={() => null}
            initialSort={[{ id: "id", desc: true }]}
            headerClassName=""
            bodyClassName=""
            noResultsDescription="No campaigns available"
            columns={this.columns()}
            data={this.filteredData()}
          />
        </Card>
      </div>
    );
  }
}

export default withRouter(withTranslation()(CampaignGroupList));
