import Elev8ApiService from "../lib/services/Elev8Api/Elev8ApiService";
import { ApiTypes } from "../lib/services/Elev8Api/lib";

class UserDataHelper {
  static fetchAccountManagers() {
    const accountManagerFilter = {
      "filter[role]": "account_manager",
    };
    const sparseFieldSets = {
      fields: {
        user: ["id", "name"],
      },
    };
    return new Promise((resolve, reject) => {
      Elev8ApiService.index(ApiTypes.user, sparseFieldSets, accountManagerFilter).then(
        (response) => {
          resolve(response);
        }
      );
    });
  }
}

export default UserDataHelper;
