import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import _ from "lodash";

import { minFormDate, maxFormDate, formDurationInDays } from "@utilities/dateStringHelper";
import CampaignRates from "@utilities/CampaignRates";
import Elev8CampaignPropertiesContext from "@contexts/Elev8CampaignPropertiesContext";
import SummaryCallout from "../SummaryCallout";
import SummarySection from "../SummarySection";
import SummaryItem from "../SummaryItem";

export default function CampaignGroupProposalSummary() {
  const { t, i18n } = useTranslation();
  const elev8Properties = useContext(Elev8CampaignPropertiesContext);
  const missingDateString = t("keywords.general.notSet");
  const missingAttribute = t("keywords.general.missingAttribute");

  const campaignGroupProposalName = useWatch({ name: "name" });
  const campaignProposals = useWatch({ name: "campaign_proposals" });

  const currencyFormatter = new Intl.NumberFormat(t("language.locale"), {
    style: "currency",
    currency: "CAD",
    maximumFractionDigits: 2,
  });

  const percentageFormatter = new Intl.NumberFormat(t("language.locale"), {
    style: "percent",
    maximumFractionDigits: 2,
  });

  function renderSummaryTitle() {
    return `${campaignGroupProposalName || "Campaign"} ${t("keywords.general.summary")}`;
  }

  const campaignGroupProposalStartAt = useMemo(() => {
    if (!campaignProposals) return null;
    const startDates = campaignProposals
      .map((campaignProposal) => campaignProposal.start_at)
      .filter(Boolean);
    if (!startDates.length) return null;
    return minFormDate(startDates);
  }, [campaignProposals]);

  const campaignGroupProposalEndAt = useMemo(() => {
    if (!campaignProposals) return null;
    const endDates = campaignProposals
      .map((campaignProposal) => campaignProposal.end_at)
      .filter(Boolean);
    if (!endDates.length) return null;
    return maxFormDate(endDates);
  }, [campaignProposals]);

  const campaignProposalsDurationTotal = useMemo(() => {
    if (!campaignProposals) return null;
    const totalDuration = campaignProposals.reduce((total, campaignProposal) => {
      if (!campaignProposal.start_at || !campaignProposal.end_at) return total;
      const duration = formDurationInDays(campaignProposal.start_at, campaignProposal.end_at);
      return total + duration;
    }, 0);
    return totalDuration;
  }, [campaignProposals]);

  const campaignGroupProposalPropertyIds = useMemo(() => {
    if (!campaignProposals) return [];
    const propertyIdsByCampaignProposal = campaignProposals.map(
      (campaignProposal) => campaignProposal.property_ids
    );
    return _.union(...propertyIdsByCampaignProposal);
  }, [campaignProposals]);

  const totalCost = useMemo(() => {
    if (!campaignProposals) return 0;
    return campaignProposals.reduce(
      (total, campaignProposal) => total + parseFloat(campaignProposal.standard_price_dollars),
      0
    );
  }, [campaignProposals]);

  const totalDiscountPercentage = useMemo(() => {
    if (!campaignProposals) return 0;
    const totalDiscountInDollars = campaignProposals.reduce((total, campaignProposal) => {
      const discountedCampaignPrice = parseFloat(campaignProposal.standard_price_dollars);
      const campaignDiscount = parseFloat(campaignProposal.discount);
      const fullPrice = discountedCampaignPrice / (1 - campaignDiscount / 100);
      const discountInDollars = fullPrice - discountedCampaignPrice;
      return total + discountInDollars;
    }, 0);
    return totalDiscountInDollars / (totalCost + totalDiscountInDollars);
  }, [campaignProposals]);

  function renderDateRange() {
    if (!campaignGroupProposalStartAt && !campaignGroupProposalEndAt) return missingDateString;
    const startDate = campaignGroupProposalStartAt || missingDateString;
    const endDate = campaignGroupProposalEndAt || missingDateString;
    return `${startDate} – ${endDate}`;
  }

  function renderDuration() {
    return i18n.format(
      t("keywords.dateRelated.daysWithCount", {
        count: Math.round(campaignProposalsDurationTotal),
      }),
      "lowercase"
    );
  }

  function renderPropertyCount() {
    return String(campaignGroupProposalPropertyIds.length);
  }

  function renderScreenCount() {
    const screenCount = elev8Properties.reduce((totalCount, elev8Property) => {
      if (campaignGroupProposalPropertyIds.includes(String(elev8Property.attributes.id))) {
        return totalCount + elev8Property.activeScreens.length;
      }
      return totalCount;
    }, 0);
    return String(screenCount);
  }

  function renderAdLengths() {
    if (!campaignProposals || campaignProposals.length === 0) return `${missingAttribute}`;
    const proposalsWithRateTypes = campaignProposals.filter(
      (proposal) => proposal.rate_type !== "undefined"
    );
    if (!proposalsWithRateTypes) return `${missingAttribute}`;
    return proposalsWithRateTypes
      .map(
        (proposal) =>
          `${CampaignRates.adDurationFromRate(proposal.rate_type)}${t("timeTerms.secondsAbbr")}`
      )
      .join(", ");
  }

  function renderCampaignProposalCount() {
    if (!campaignProposals) return "0";
    return String(campaignProposals.length);
  }

  function renderFullScreenCount() {
    if (!campaignProposals || campaignProposals.length === 0) return "0";
    const proposalsWithFullscreens = campaignProposals.filter(
      (proposal) => proposal.fullscreen && proposal.fullscreen === "true"
    );
    return proposalsWithFullscreens.length.toString(10);
  }

  function renderTotalCost() {
    return currencyFormatter.format(totalCost);
  }

  function renderTotalDiscount() {
    const totalDiscount = totalDiscountPercentage;
    if (!totalDiscount || totalDiscount === 0) return "";
    return `${t("keywords.campaignRelated.discount")}: ${percentageFormatter.format(
      totalDiscount
    )}`;
  }

  return (
    <SummarySection
      title={renderSummaryTitle()}
      callout={
        <SummaryCallout
          label={t("keywords.campaignRelated.campaignGroupCost")}
          value={renderTotalCost()}
          info={renderTotalDiscount()}
        />
      }
    >
      <SummaryItem label={t("keywords.dateRelated.dates")} value={renderDateRange()} />
      <SummaryItem label={t("keywords.dateRelated.duration")} value={renderDuration()} />
      <SummaryItem label={t("keywords.screenRelated.screens")} value={renderScreenCount()} />
      <SummaryItem label={t("propertyTerms.properties")} value={renderPropertyCount()} />
      <SummaryItem label={t("keywords.campaignRelated.adLength")} value={renderAdLengths()} />
      <SummaryItem label={t("pageNames.campaigns")} value={renderCampaignProposalCount()} />
      <SummaryItem
        label={`${t("keywords.campaignRelated.fullscreen")} ${t("pageNames.campaigns")}`}
        value={renderFullScreenCount()}
      />
    </SummarySection>
  );
}
