import React, { Component } from "react";
import ClusterMap from "./ClusterMap";
import MapService from "../../../../lib/services/Map/mapService";

class PropertyClusterMap extends Component {
  getGeoJsonFormat() {
    return MapService.convertPropertiesToGeoJsonFormat(this.props.properties);
  }

  render() {
    const { height, renderPopup } = this.props;

    const geoJsonProperties = this.getGeoJsonFormat();

    return (
      <div style={{ position: "relative", height }}>
        <ClusterMap
          propertyToCluster="screen_count"
          geoJsonSource={geoJsonProperties}
          renderPopup={renderPopup}
        />
      </div>
    );
  }
}

export default PropertyClusterMap;
