import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Fuse from "fuse.js";

import { unstableHistory } from "@App/history";
import { ApiTypes } from "@lib/services/Elev8Api/lib";
import Elev8ApiService from "@lib/services/Elev8Api/Elev8ApiService";
import Header from "@components/Interface/MainList/header";
import GeneralTable from "@components/Interface/MainList/generalTable";
import Pill from "@components/Global/General/pill";
import LoadingPage from "@components/Global/General/loadingPage";
import PermissionService, { Resources, Actions } from "@components/User/Services/permissionService";

class PropertyList extends Component {
  // eslint-disable-next-line react/sort-comp
  static sparseFieldSets = {
    include: ["network"],
    fields: {
      property: [
        "id",
        "contact_name",
        "property_type",
        "status",
        "name",
        "contact_phone",
        "contact_email",
        "address_line1",
        "address_line2",
        "address_city",
        "address_province",
        "address_country",
        "address_postal_code",
        "screen_count",
        "network",
      ],
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      titleColor: "rgb(49, 50, 80)",
      headerColor: "#fff",
      buttonColor: "rgb(76, 96, 232)",
      searchValue: "",
      properties: null,
    };
    this.updateSearch = this.updateSearch.bind(this);
    this.goToProperty = this.goToProperty.bind(this);
    this.goToPreview = this.goToPreview.bind(this);

    Elev8ApiService.index(ApiTypes.property, PropertyList.sparseFieldSets).then((properties) => {
      // this hack is to stuff in a property_type that's translated
      // we should remove this when we switch to using the new table
      const propertiesWithAdditionalFields = properties.map((property) => {
        const propertyWithAdditionalFields = {
          ...property.attributes,
          network: property.networkName,
          translatedPropertyType: property.translatedPropertyType,
          presentableAddress: property.presentableAddress,
        };
        return propertyWithAdditionalFields;
      });

      this.setState({ properties: propertiesWithAdditionalFields });
    });
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.properties !== this.state.properties) {
      if (
        this.state.properties.length === 1 &&
        !PermissionService.can(
          Actions.CREATE,
          Resources.PROPERTY_CONTRACT_DETAILS,
          this.props.loggedInUser
        )
      ) {
        const property = this.state.properties[0];
        unstableHistory.push(`/my_property/edit/${property.id}/form`);
      }
    }
  }

  canAccessContractForm = () =>
    PermissionService.can(
      Actions.UPDATE,
      Resources.PROPERTY_CONTRACT_DETAILS,
      this.props.loggedInUser
    );

  updateSearch(event) {
    this.setState({ searchValue: event.target.value });
  }

  goToProperty(property) {
    unstableHistory.push(`/properties/${property.id}`);
  }

  goToPreview(row) {
    unstableHistory.push(`/property_content/${row.id}`);
  }

  pillColor(status) {
    switch (status) {
      case "active":
        return "green";
      case "pending":
        return "yellow";
      default:
        return "disabled";
    }
  }

  renderPill(status) {
    return <Pill text={status} type={this.pillColor(status)} />;
  }

  render() {
    const { t } = this.props;
    const propertyName = t("pageNames.properties");
    const propertyUrl = this.canAccessContractForm() ? "properties" : "my_property";
    const editAction = `/${propertyUrl}/edit/:id/form`;

    if (!this.state.properties) {
      return <LoadingPage pageName={propertyName} />;
    }
    if (this.state.properties.length === 0) {
      unstableHistory.push("/dashboard");
    }

    const adminTable = [
      {
        columnTitle: "",
        columnSize: "2%",
        fieldType: "padding",
      },
      {
        columnTitle: t("keywords.statusRelated.name"),
        columnSize: "6%",
        fieldType: "component",
        fieldValue: "statusPill",
      },
      {
        columnTitle: t("keywords.general.name"),
        columnSize: "18%",
        fieldType: "textLink",
        hasValue: true,
        fieldValue: "name",
        linkAction: this.goToProperty,
        extraCss: "",
      },
      {
        columnTitle: t("keywords.general.type"),
        columnSize: "8%",
        fieldType: "text",
        fieldValue: "translatedPropertyType",
        extraCss: "",
      },
      {
        columnTitle: t("keywords.userRelated.owner"),
        columnSize: "14%",
        fieldType: "text",
        fieldValue: "contact_name",
        extraCss: "",
      },
      {
        columnTitle: t("keywords.userRelated.phone.name"),
        columnSize: "14%",
        fieldType: "text",
        fieldValue: "contact_phone",
        extraCss: "",
      },
      {
        columnTitle: t("keywords.screenRelated.screens"),
        columnSize: "4%",
        fieldType: "text",
        fieldValue: "screen_count",
        extraCss: "",
      },
      {
        columnTitle: t("networkTerms.network"),
        columnSize: "8%",
        fieldType: "text",
        fieldValue: "network",
        extraCss: "",
      },
      {
        columnTitle: t("keywords.locationRelated.name"),
        columnSize: "34%",
        fieldType: "text",
        fieldValue: "presentableAddress",
        extraCss: "",
      },
      {
        columnTitle: t("keywords.screenRelated.preview"),
        columnSize: "5%",
        fieldType: "iconLink",
        iconName: "fa-television",
        linkAction: this.goToPreview,
        extraCss: "",
      },
      {
        columnTitle: "",
        columnSize: "5%",
        fieldType: "edit",
        editAction,
        deleteAction: null,
      },
    ];

    const pmTable = [
      {
        columnTitle: "",
        columnSize: "2%",
        fieldType: "padding",
      },
      {
        columnTitle: t("keywords.general.name"),
        columnSize: "18%",
        fieldType: "text",
        hasValue: true,
        fieldValue: "name",
        extraCss: "",
      },
      {
        columnTitle: t("keywords.general.type"),
        columnSize: "10%",
        fieldType: "text",
        fieldValue: "translatedPropertyType",
        extraCss: "",
      },
      {
        columnTitle: t("keywords.userRelated.phone.name"),
        columnSize: "15%",
        fieldType: "text",
        fieldValue: "contact_phone",
        extraCss: "",
      },
      {
        columnTitle: t("networkTerms.network"),
        columnSize: "8%",
        fieldType: "text",
        fieldValue: "network",
        extraCss: "",
      },
      {
        columnTitle: t("keywords.locationRelated.name"),
        columnSize: "35%",
        fieldType: "text",
        fieldValue: "area1",
        extraCss: "",
      },
      {
        columnTitle: t("keywords.screenRelated.preview"),
        columnSize: "5%",
        fieldType: "iconLink",
        iconName: "fa-television",
        linkAction: this.goToPreview,
        extraCss: "",
      },
      {
        columnTitle: "",
        columnSize: "5%",
        fieldType: "edit",
        editAction,
        deleteAction: null,
      },
    ];

    const rsmTable = [
      {
        columnTitle: "",
        columnSize: "2%",
        fieldType: "padding",
      },
      {
        columnTitle: t("keywords.statusRelated.name"),
        columnSize: "6%",
        fieldType: "component",
        fieldValue: "statusPill",
      },
      {
        columnTitle: t("keywords.general.name"),
        columnSize: "18%",
        fieldType: "text",
        hasValue: true,
        fieldValue: "name",
        extraCss: "",
      },
      {
        columnTitle: t("keywords.general.type"),
        columnSize: "8%",
        fieldType: "text",
        fieldValue: "translatedPropertyType",
        extraCss: "",
      },
      {
        columnTitle: t("keywords.screenRelated.screens"),
        columnSize: "4%",
        fieldType: "text",
        fieldValue: "screen_count",
        extraCss: "",
      },
      {
        columnTitle: t("networkTerms.network"),
        columnSize: "8%",
        fieldType: "text",
        fieldValue: "network",
        extraCss: "",
      },
      {
        columnTitle: t("keywords.locationRelated.name"),
        columnSize: "34%",
        fieldType: "text",
        fieldValue: "presentableAddress",
        extraCss: "",
      },
      {
        columnTitle: t("keywords.screenRelated.preview"),
        columnSize: "5%",
        fieldType: "iconLink",
        iconName: "fa-television",
        linkAction: this.goToPreview,
        extraCss: "",
      },
    ];

    const options = {
      keys: [
        "contact_name",
        "name",
        "translatedPropertyType",
        "contact_phone",
        "contact_email",
        "address_line1",
        "address_city",
        "address_province",
        "address_country",
        "address_postal_code",
        "screen_count",
        "network",
      ],
      threshold: 0.1,
      ignoreLocation: true,
    };

    let searchResult = this.state.properties;

    if (this.state.searchValue.trim() !== "") {
      const fuse = new Fuse(searchResult, options);
      searchResult = fuse.search(this.state.searchValue);
      searchResult = searchResult.map((result) => result.item);
    }

    let tableFormat = pmTable;

    if (
      PermissionService.can(
        Actions.CREATE,
        Resources.PROPERTY_CONTRACT_DETAILS,
        this.props.loggedInUser
      )
    ) {
      tableFormat = adminTable;
    }

    if (this.props.loggedInUser.role === "rsm_sales_associate") {
      tableFormat = rsmTable;
    }

    const searchResultWithStatusPill = searchResult.map((rowData) => ({
      ...rowData,
      statusPill: this.renderPill(rowData.status),
    }));

    return (
      <div>
        <Header
          bgColor={this.state.headerColor}
          title={propertyName}
          count={searchResultWithStatusPill.length}
          titleColor={this.state.titleColor}
          buttonColor={this.state.buttonColor}
          searchEnabled
          searchValue={this.state.searchValue}
          searchAction={this.updateSearch}
        />

        <GeneralTable tableData={searchResultWithStatusPill} tableFormat={tableFormat} />
      </div>
    );
  }
}

export default withTranslation()(PropertyList);
