import React, { Component, Children } from "react";

class ApplicationLayout extends Component {
  getHeader = () => {
    const type = ApplicationLayout.Header;
    return Children.toArray(this.props.children).find((el) => el.type === type);
  };

  getNavigation = () => {
    const type = ApplicationLayout.Navigation;
    return Children.toArray(this.props.children).find((el) => el.type === type);
  };

  getMainContent = () => {
    const type = ApplicationLayout.MainContent;
    return Children.toArray(this.props.children).find((el) => el.type === type);
  };

  render() {
    return (
      <div
        className={`d-flex flex-column w-100 h-100 ${this.props.wrapperClass}`}
        style={{ overflow: "auto" }}
      >
        <div className="d-flex flex-shrink-0 d-print-none">{this.getHeader()}</div>
        <div className="d-flex flex-row flex-grow-1">
          <div
            className="d-flex flex-grow-0 flex-shrink-0 d-print-none"
            style={{ overflow: "auto" }}
          >
            <div>{this.getNavigation()}</div>
          </div>
          <div
            className="js-mainContent d-flex flex-column flex-grow-1"
            style={{ overflow: "auto" }}
          >
            <div>{this.getMainContent()}</div>
          </div>
        </div>
      </div>
    );
  }
}

function Header(props) {
  return <React.Fragment>{props.children}</React.Fragment>;
}

function Navigation(props) {
  return <React.Fragment>{props.children}</React.Fragment>;
}

function MainContent(props) {
  return <React.Fragment>{props.children}</React.Fragment>;
}

ApplicationLayout.Header = Header;
ApplicationLayout.Navigation = Navigation;
ApplicationLayout.MainContent = MainContent;

export default ApplicationLayout;
