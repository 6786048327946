import { createSelector } from "reselect";

const propertiesSelector = (state) => state.property.general.all;
const campaignFormSelector = (state) => state.campaign.creation.config.form;

const getProperties = (availableProperties, campaignForm) => {
  if (!availableProperties || campaignForm == null) {
    return null;
  }

  const selectedProperties = availableProperties.filter(({ id }) =>
    campaignForm.property_ids.includes(id)
  );
  return selectedProperties;
};

export default createSelector(propertiesSelector, campaignFormSelector, getProperties);
