import React, { Component } from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class ReportingSection extends Component {
  render() {
    const { title, secondaryTitle, children } = this.props;

    return (
      <React.Fragment>
        <Row className="reportingContent__header align-items-center">
          <Col md="auto" className="reportingContent__title">
            {title}
          </Col>
          {secondaryTitle ? (
            <Col md="auto" className="reportingContent__secondaryTitle py-2 py-sm-0">
              {secondaryTitle}
            </Col>
          ) : null}
        </Row>
        {children ? (
          <Row>
            <Col> {children}</Col>
          </Row>
        ) : null}
      </React.Fragment>
    );
  }
}

ReportingSection.propTypes = {
  title: PropTypes.string,
  secondaryTitle: PropTypes.string,
  progressLabel: PropTypes.string,
  progressPercentage: PropTypes.number,
};

export default ReportingSection;
